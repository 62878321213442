import { Colors } from '@grayshift/cairn';
import * as Sentry from '@sentry/react';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ScaleLoader as RSScaleLoader } from 'react-spinners';
import { LoaderHeightWidthRadiusProps } from 'react-spinners/helpers/props';

export const ScaleLoader: React.FC<
  {
    enableTimeout?: boolean;
    timeoutMS?: number;
    sentryContext?: Record<string, string>;
  } & LoaderHeightWidthRadiusProps
> = ({
  enableTimeout = false,
  timeoutMS = 30_000,
  sentryContext,
  color = Colors.blue,
  ...props
}) => {
  const history = useHistory();
  useEffect(() => {
    if (enableTimeout) {
      const timer = setTimeout(() => {
        Sentry.captureMessage(
          `Page timed out, took longer than ${timeoutMS}ms to load.`,
          {
            contexts: {
              page: { path: history.location.pathname },
              ...sentryContext,
            },
          },
        );
      }, timeoutMS);

      return () => clearTimeout(timer);
    }

    return noop;
  }, [enableTimeout, history.location.pathname, sentryContext, timeoutMS]);
  return <RSScaleLoader color={color} data-gid="66156829" {...props} />;
};
