// Gainsight utility functions

/* eslint-disable  operator-assignment, no-param-reassign, no-var, @typescript-eslint/no-use-before-define, @typescript-eslint/unbound-method, unicorn/prefer-add-event-listener */
export function trackIdleTime(idleTimeSec: number): void {
  var t: NodeJS.Timeout;
  if (idleTimeSec < 1) {
    return;
  }
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.onclick = resetTimer;
  window.onkeypress = resetTimer;
  window.addEventListener('scroll', resetTimer, true);

  function fireCustomEvent(): void {
    window.aptrinsic('track', 'idleTime', { totalNumOfSeconds: idleTimeSec });
    idleTimeSec = 2 * idleTimeSec;
    resetTimer();
  }

  function resetTimer(): void {
    clearTimeout(t);
    t = setTimeout(fireCustomEvent, idleTimeSec * 1000);
  }
}

export class PXPageTimer {
  pageName: string | null | undefined;

  startTime: number | null;

  maxSecondsTracked: number;

  trackPagesOverMax: boolean;

  startTimer: (pageName: string) => void;

  endTimer: (pageUnloaded?: string | number | string[] | boolean) => void;

  constructor(maxSecondsTracked: number, trackPagesOverMax: boolean) {
    this.pageName = null;
    this.startTime = null;
    this.maxSecondsTracked = maxSecondsTracked || 3600 * 24;
    this.trackPagesOverMax = trackPagesOverMax !== false;
    this.startTimer = function start(pageName: string) {
      if (this.pageName && this.pageName !== pageName) {
        this.endTimer();
      }
      this.pageName = pageName;
      this.startTime = Date.now();
    };
    this.endTimer = function endTimer(pageUnloaded) {
      if (this.pageName) {
        const endTime = Date.now();
        const secondsOnPage = this.startTime
          ? (endTime - this.startTime) / 1000
          : 0;
        if (secondsOnPage <= this.maxSecondsTracked || this.trackPagesOverMax) {
          window.aptrinsic('track', 'timeOnPage', {
            pageName: this.pageName,
            seconds: Math.min(secondsOnPage, this.maxSecondsTracked),
            pageUnloaded: pageUnloaded as string,
          });
        }
      }
      this.pageName = undefined;
    };
  }
}

export function initializeTimer(): void {
  const maxSecondsTracked = 60 * 60; // Track time on page for up to one hour
  const trackPagesOverMax = false; // Ignore any pages over one hour
  const featureTimer = new PXPageTimer(maxSecondsTracked, trackPagesOverMax);

  // Initial page
  featureTimer.startTimer(window.location.href);

  window.addEventListener('hashchange', () => {
    featureTimer.startTimer(window.location.href);
  });
  window.addEventListener('popstate', () => {
    featureTimer.startTimer(window.location.href);
  });
  window.addEventListener('beforeunload', () => {
    featureTimer.endTimer(true);
  });
  const realPushState = window.history.pushState;
  window.history.pushState = function start(state, title, newLocation) {
    featureTimer.startTimer(newLocation as string);
    // eslint-disable-next-line prefer-rest-params, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return realPushState.apply(window.history, arguments as any);
  };
}
