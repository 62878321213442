import startsWith from 'lodash/startsWith';

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type PossibleFileNames = 'placeholder-image.png' | string;

export const publicStaticFileURL = (file: PossibleFileNames): string => {
  const correctedPath = startsWith('/', file) ? file : `/${file}`;

  return `${process.env.PUBLIC_URL}/static${correctedPath}`;
};

export const publicFileURL = (file: PossibleFileNames): string =>
  file && `${process.env.PUBLIC_URL}/${file}`;
