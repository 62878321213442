/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable sonarjs/no-duplicate-string */
import { useMutation } from '@apollo/client';
import { Colors } from '@grayshift/cairn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SaveIcon from '@mui/icons-material/Save';
import {
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';

import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import { ArchiveNote } from './graphql/generated/ArchiveNote';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import {
  NotesByClueQuery,
  NotesByClueQuery_notesByClue,
} from './graphql/generated/NotesByClueQuery';
import { UpdateNote } from './graphql/generated/UpdateNote';
import {
  archiveNoteMutation,
  createNoteMutation,
  updateNoteMutation,
} from './graphql/mutations/note';
import { notesByClueQuery } from './graphql/queries/notes';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const NoteSchema = Yup.object().shape({
  note: Yup.string().required('Required'),
});

export const Note: React.FC<{
  note: NotesByClueQuery_notesByClue;
}> = ({ note }) => {
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [editMode, setEditMode] = useState(false);
  const [showNoteActions, setShowNoteActions] = useState(false);
  const [showArchiveNotice, setShowArchiveNote] = useState(false);

  const [tempNote, setNote] = useState(note?.text ?? '');

  const [updateNote] = useMutation<UpdateNote>(updateNoteMutation, {
    refetchQueries: ['NotesByClueQuery', 'reportTaggedClues'],
    variables: {
      input: {
        id: note.id,
        text: tempNote,
        extractionId: note.extractionId,
      },
    },
  });

  const [archiveNote] = useMutation<ArchiveNote>(archiveNoteMutation, {
    refetchQueries: ['NotesByClueQuery', 'reportTaggedClues'],
    variables: {
      input: {
        id: note.id,
        extractionId: note.extractionId,
      },
    },
  });

  const EditSave = editMode ? (
    <IconButton
      sx={{
        padding: '4px',
        '&:hover': { '& .MuiSvgIcon-root': { fill: Colors.blue } },
      }}
      disabled={
        currentExtractionAccessType === ExtractionAccessTypeEnum.READ_ONLY
      }
      onClick={async () => {
        setEditMode(!editMode);
        try {
          await updateNote();
        } catch (error_) {
          // eslint-disable-next-line no-console
          console.log('Error saving note', error_);
        }
      }}
      data-gid="69024106"
    >
      <SaveIcon
        sx={{
          fontSize: 18,
          fill: '#E2E5ED',
        }}
        color="primary"
        data-gid="71734586"
      />
    </IconButton>
  ) : (
    <IconButton
      sx={{
        padding: '4px',
        '&:hover': { '& .MuiSvgIcon-root': { fill: Colors.blue } },
      }}
      disabled={
        currentExtractionAccessType === ExtractionAccessTypeEnum.READ_ONLY
      }
      onClick={() => setEditMode(!editMode)}
      data-gid="36964013"
    >
      <EditIcon
        sx={{
          fontSize: 18,
          fill: '#E2E5ED',
        }}
        data-gid="59194629"
      />
    </IconButton>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        border: '1px solid #E2E5ED',
        padding: '7px 14px',
        // borderRadius: '4px',
        borderRadius: '8px',

        position: 'relative',
        marginTop: '10px',
        marginBottom: '20px',
      }}
      onFocus={noop}
      onMouseOver={() => note.isOwner && setShowNoteActions(true)}
      onMouseLeave={() => {
        if (note.isOwner) {
          setShowNoteActions(false);
        }
        setShowArchiveNote(false);
      }}
      data-gid="26816218"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'space-between',
          width: '100%',
        }}
        data-gid="55079915"
      >
        {showNoteActions && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '7px',
              position: 'absolute',
              right: -8,
              top: -16,
              border: '1px solid #E2E5ED',
              padding: '3px 6px',
              borderRadius: '14px',
              backgroundColor: 'white',
            }}
            data-gid="96060286"
          >
            {showArchiveNotice && (
              <>
                <Typography
                  fontSize="12px"
                  fontFamily="Roboto"
                  fontWeight="medium"
                  sx={{ marginLeft: '4px' }}
                  data-gid="75335632"
                >
                  Delete this note?
                </Typography>
                <IconButton
                  sx={{
                    padding: '4px',
                    marginLeft: '2px',

                    '&:hover': { '& .MuiSvgIcon-root': { fill: Colors.blue } },
                  }}
                  disabled={
                    currentExtractionAccessType ===
                    ExtractionAccessTypeEnum.READ_ONLY
                  }
                  onClick={() => archiveNote()}
                  data-gid="53601598"
                >
                  <CheckCircleIcon
                    sx={{
                      fontSize: 18,
                      fill: '#E2E5ED',
                    }}
                    data-gid="35567873"
                  />
                </IconButton>
                <IconButton
                  sx={{
                    padding: '4px',
                    marginLeft: '2px',
                    '&:hover': { '& .MuiSvgIcon-root': { fill: 'red' } },
                  }}
                  onClick={() => setShowArchiveNote(false)}
                  data-gid="38738876"
                >
                  <CancelIcon
                    sx={{
                      fontSize: 18,
                      fill: '#E2E5ED',
                    }}
                    data-gid="11630462"
                  />
                </IconButton>
              </>
            )}
            {!showArchiveNotice && EditSave}
            {!showArchiveNotice && (
              <IconButton
                sx={{
                  padding: '4px',
                  marginLeft: '4px',
                  '&:hover': { '& .MuiSvgIcon-root': { fill: 'red' } },
                }}
                disabled={
                  currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                onClick={() => setShowArchiveNote(!showArchiveNotice)}
                data-gid="19372703"
              >
                {/* Using the delete icon until user can access archived notes  */}
                <DeleteIcon
                  sx={{
                    fontSize: 18,
                    fill: '#E2E5ED',
                  }}
                  data-gid="44216321"
                />
              </IconButton>
            )}
          </div>
        )}
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
          data-gid="15508888"
        >
          {!editMode && (
            <Typography
              style={{ overflowWrap: 'anywhere' }}
              fontSize={14}
              data-gid="44825972"
            >
              {tempNote}
            </Typography>
          )}
          {editMode && (
            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Add a note"
              style={{
                fontSize: '14px',
                width: '100%',
                fontFamily: 'Roboto',
                resize: 'none',
                marginTop: '4px',
                marginBottom: '4px',
                padding: '10px',
                borderRadius: '8px',
              }}
              onChange={(e) => setNote(e.target.value)}
              value={tempNote}
              data-gid="33415622"
            />
          )}
          <div
            style={{
              marginTop: '2px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
            data-gid="90781695"
          >
            {note.createdBy?.firstName && note.createdBy?.lastName && (
              <Typography
                fontSize={11}
                color="textSecondary"
                data-gid="32165642"
              >
                {note.createdBy?.firstName ?? ''}{' '}
                {note.createdBy?.lastName ?? ''}
              </Typography>
            )}
            {(note.recordUpdatedAt || note.recordCreatedAt) && (
              <Typography
                fontSize={11}
                color="textSecondary"
                data-gid="83879076"
              >
                {/* {`${DateTime.fromISO(
                  note.recordUpdatedAt || note.recordCreatedAt,
                ).toLocaleString(DateTime.DATE_MED)} */}
                {DateTime.fromISO(
                  note.recordUpdatedAt || note.recordCreatedAt,
                ).toRelative({
                  style: 'long',
                })}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Notes: React.FC<{ clueId: string; clueType: ClueTypes }> = ({
  clueId,
  clueType,
}) => {
  // const [showNewNoteForm, setShowNewNoteForm] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );

  const [extractionId] = useExtractionId();
  const { data, loading } = useQueryWithErrorBoundary<NotesByClueQuery>(
    notesByClueQuery,
    {
      variables: {
        clueId,
        clueType,
        extractionId,
      },
    },
  );

  const [createNewNote, { loading: loadingCreateNote }] = useMutation(
    createNoteMutation,
    {
      refetchQueries: ['NotesByClueQuery', 'reportTaggedClues'],
    },
  );

  const formik = useFormik({
    validationSchema: NoteSchema,
    initialValues: {
      note: '',
    },
    onSubmit: async (values, actions) => {
      await createNewNote({
        variables: {
          input: {
            note: values.note,
            clueType,
            extractionId,
            clueId,
          },
        },
      });
      actions.resetForm();
    },
  });

  useHotkeys(
    'enter',
    (e) => {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      formik.submitForm();
    },
    { enableOnTags: ['TEXTAREA'] },
    [formik.submitForm],
  );

  return (
    <ClueDetailsSlideoutMetadataSection
      title="Notes"
      data-gid="94997080"
      collapsed={collapsed}
      onClickCollapse={() => setCollapsed(!collapsed)}
    >
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: '100px',
          }}
          data-gid="10301685"
        >
          <ScaleLoader loading color="#1665D8" data-gid="66156829" />
        </div>
      )}

      {!loading &&
        data &&
        data?.notesByClue.map((note) => (
          <Note note={note} data-gid="97833213" key={note.id} />
        ))}

      <form data-gid="41250039" onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          data-gid="86553607"
        >
          <TextareaAutosize
            data-gid="31647835"
            value={formik.values.note}
            disabled={
              currentExtractionAccessType === ExtractionAccessTypeEnum.READ_ONLY
            }
            onChange={formik.handleChange}
            id="note"
            placeholder={
              currentExtractionAccessType === ExtractionAccessTypeEnum.READ_ONLY
                ? 'Cannot modify in read only'
                : 'Add a note'
            }
            minRows={3}
            style={{
              fontFamily: 'Roboto',
              resize: 'none',
              marginTop: '4px',
              marginBottom: '4px',
              padding: '10px',
              borderRadius: '8px',
            }}
          />
          <div
            style={{ position: 'absolute', bottom: 10, right: 10 }}
            data-gid="56487411"
          >
            <IconButton
              type="submit"
              disabled={
                !formik.isValid ||
                currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
              }
              color="primary"
              data-gid="24631648"
            >
              {loadingCreateNote ? (
                <CircularProgress size={15} data-gid="55056607" />
              ) : (
                <NoteAddIcon
                  color={
                    currentExtractionAccessType ===
                    ExtractionAccessTypeEnum.READ_ONLY
                      ? 'disabled'
                      : 'primary'
                  }
                  fontSize="small"
                  data-gid="35569025"
                />
              )}
            </IconButton>
          </div>
        </div>
      </form>
    </ClueDetailsSlideoutMetadataSection>
  );
};
