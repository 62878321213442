import { gql } from '@apollo/client';

export const allExtractionsByAccountIdAndUserId = gql`
  query extractionsByAccountIdAndUserId($userId: UUID!) {
    extractionsByAccountIdAndUserId(userId: $userId) {
      userId
      invitedUserId
      extractionId
      evidenceId
      type
      sharedBy
    }
  }
`;
