import { Colors, makeStyles } from '@grayshift/cairn';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';

import GlobalSearchContainer from './GlobalSearchContainer';
import GlobalTimeFrameContainer from './GlobalTimeFrameContainer';
import { currentGlobalFiltersActiveAtom } from './lib/atoms';

const useStyles = makeStyles({
  navBarContainer: {
    width: '100%',
    height: '4rem',
    borderBottom: 'solid 2px #eaedf3',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const SecondaryTopNavBar: React.FC<{
  extractionLoading: boolean;
}> = ({ extractionLoading }) => {
  const classes = useStyles();

  const [currentGlobalFiltersActive] = useRecoilState(
    currentGlobalFiltersActiveAtom,
  );

  return (
    <div
      className={classes.navBarContainer}
      style={{
        backgroundColor:
          currentGlobalFiltersActive.searchActive === true ||
          currentGlobalFiltersActive.timeframeActive === true
            ? '#E4ECF9'
            : 'white',
      }}
      data-gid="25322589"
    >
      {extractionLoading && (
        <ScaleLoader
          loading
          color={Colors.white}
          style={{ marginRight: 20 }}
          data-gid="62463372"
        />
      )}
      {!extractionLoading && (
        <div className={classes.filterContainer} data-gid="30854813">
          <GlobalTimeFrameContainer data-gid="65054223" />
          <GlobalSearchContainer data-gid="48462220" />
        </div>
      )}
    </div>
  );
};

export default SecondaryTopNavBar;
