import { gql } from '@apollo/client';

const UsersWithExtractionAccess = gql`
  query usersWithExtractionAccess($extractionId: UUID!) {
    usersWithExtractionAccess(extractionId: $extractionId) {
      id
      firstName
      lastName
      email
      accessType
      sharedByDateTime
      sharedByUserFirstName
      sharedByUserLastName
    }
  }
`;

export default UsersWithExtractionAccess;
