import { gql } from '@apollo/client';

export const allMediaTimelineQuery = gql`
  query getAllMedia(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $appNames: [String!]
    $timeframe: TimeFrame
  ) {
    allMedia(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeframe
      appNames: $appNames
    ) {
      edges {
        node {
          ... on Image {
            id
            timestamp
            thumbnailUrl
            containsNudity
            containsCSAM
            containsCSAMConfidence
            containsNudityConfidence
            ncmecMatch
            imageAnnotations {
              id
              annotation
            }
          }
          ... on Video {
            id
            timestamp
            thumbnailUrl
            containsNudity
            containsCSAM
            containsCSAMConfidence
            containsNudityConfidence
            ncmecMatch
            videoAnnotations {
              id
              annotation
            }
          }
        }
      }
    }
  }
`;
// rename
export const allMediaQuery = gql`
  query allMedia(
    $extractionId: UUID!
    $searchTerm: String
    $appNames: [String!]
    $timeframe: TimeFrame
    $page: Int
    $pageSize: Int
    $showNcmecCsam: Boolean
    $showAiCsam: Boolean
    $mediaCategoryFilter: MediaCategory
  ) {
    allMedia(
      extractionId: $extractionId
      searchTerm: $searchTerm
      appNames: $appNames
      timeframe: $timeframe
      page: $page
      pageSize: $pageSize
      showNcmecCsam: $showNcmecCsam
      showAiCsam: $showAiCsam
      mediaCategoryFilter: $mediaCategoryFilter
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          ... on Image {
            id
            thumbnailUrl
            labels
            containsNudity
            containsCSAM
            containsCSAMConfidence
            containsNudityConfidence
            ncmecMatch
            timestamp
            taggedAt
            imageAnnotations {
              id
              annotation
            }
            __typename
          }
          ... on Video {
            id
            thumbnailUrl
            originalUrl
            previewUrl
            containsNudity
            containsCSAM
            containsCSAMConfidence
            containsNudityConfidence
            ncmecMatch
            timestamp
            taggedAt
            durationSeconds
            thumbnailFrames {
              id
              storedFileUrl
              frameTimeSeconds
              frameIndex
            }
            videoAnnotations {
              id
              annotation
            }
            __typename
          }
        }
        page
        pageSize
      }
    }
  }
`;

export const mediaByIdQuery = gql`
  query mediaById($mediaById: ID!, $category: String, $extractionId: UUID!) {
    mediaById(
      id: $mediaById
      category: $category
      extractionId: $extractionId
    ) {
      ... on Image {
        id
        thumbnailUrl
        previewUrl
        originalUrl
        timestamp
        app {
          id
          displayName
        }
        appBundleId
        sourceFiles {
          id
          name
          checksum
          extension
          enclosingDirectory
          sizeBytes
          atime
          mtime
          ctime
        }
        meta {
          key
          value
        }
        taggedAt
        taggedBy {
          id
          email
          firstName
          lastName
        }
        storedFileChecksum
        imageAnnotations {
          id
          annotation
          annotatedBy {
            id
            firstName
            lastName
            email
          }
        }
        messageAttachment {
          messageThreadId
          messageId
        }
        storedFileSha1Checksum
        storedFileMd5Checksum
        ncmecMatch
        containsCSAM
        containsNudity
        containsNudityConfidence
      }
      ... on Video {
        id
        thumbnailUrl
        previewUrl
        originalUrl
        timestamp
        durationSeconds
        thumbnailFrames {
          id
          storedFileUrl
          frameTimeSeconds
        }
        app {
          id
          displayName
        }
        appBundleId
        sourceFiles {
          id
          name
          checksum
          extension
          enclosingDirectory
          sizeBytes
          atime
          mtime
          ctime
        }
        meta {
          key
          value
        }
        taggedAt
        taggedBy {
          id
          email
          firstName
          lastName
        }
        storedFileChecksum
        storedFileSha1Checksum
        storedFileMd5Checksum
        videoAnnotations {
          id
          annotation
          annotatedBy {
            id
            firstName
            lastName
            email
          }
        }
        ncmecMatch
        containsCSAM
        containsNudity
        containsNudityConfidence
      }
    }
  }
`;

export const csamMediaCountQuery = gql`
  query getMediaCsamCount($extractionId: UUID!) {
    csamMediaByExtractionIdCount(extractionId: $extractionId) {
      imageCsamCount
      videoCsamCount
    }
  }
`;
