import { Button, Text, TextBox } from '@grayshift/cairn';
import { useOktaAuth } from '@okta/okta-react';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import * as Yup from 'yup';

import { publicStaticFileURL } from './lib/publicFile';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

const WaitForOktaAccount: React.FC = () => {
  const { inviteCode } = useParams<{ inviteCode?: string }>();

  const checkInviteStatus = useCallback(async (): Promise<void> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ZEUS_API_URL as string}/inviteStatus`,
        {
          method: 'POST',
          body: JSON.stringify({ inviteCode }),
          headers: { 'Content-Type': 'application/json' },
        },
      );
      const result = (await response.json()) as { success: boolean };
      if (response.status === 200 && result.success && inviteCode) {
        window.close();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [inviteCode]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const timer = setInterval(checkInviteStatus, 5000);
    return () => clearInterval(timer);
  }, [checkInviteStatus]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
      data-gid="28979645"
    >
      <Text style={{ marginBottom: '28px' }} align="center" data-gid="15563661">
        Please check your inbox for a welcome email from Magnet Forensics to
        activate your Magnet Login Single Sign-On (SSO). This page will
        automatically close once activation is complete.
      </Text>
      <ScaleLoader loading color="#1665D8" data-gid="21840848" />
      <Text style={{ marginTop: '28px' }} align="center" data-gid="46175685" />
    </div>
  );
};

const InvalidOrExpiredCode: React.FC = () => (
  <div data-gid="64389307">
    <h1 data-gid="82939092">Invalid or Expired Invite Code</h1>
  </div>
);

const RegisterUser: React.FC = () => {
  const [stage, setStage] = useState<
    'FORM' | 'OKTA_ACCOUNT_SETUP' | 'INVALID_OR_EXPIRED_CODE'
  >('FORM');
  const { inviteCode } = useParams<{ inviteCode?: string }>();
  const { authState } = useOktaAuth();

  // If user is already logged in, redirect to invite page to accept invite
  if (authState?.isAuthenticated && inviteCode) {
    return <Redirect to={`/invite/${inviteCode}`} data-gid="94591228" />;
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
      data-gid="16210096"
    >
      <div
        style={{
          maxWidth: '40rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-gid="64495256"
      >
        <img
          src={publicStaticFileURL('/blackMagnetAIQLogo.png')}
          alt="Magnet Artifact IQ Logo"
          style={{ height: '135px', marginBottom: '15px' }}
          data-gid="27421627"
        />
        {stage === 'OKTA_ACCOUNT_SETUP' && (
          <WaitForOktaAccount data-gid="79066112" />
        )}
        {stage === 'INVALID_OR_EXPIRED_CODE' && (
          <InvalidOrExpiredCode data-gid="37595089" />
        )}
        {stage === 'FORM' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
            data-gid="38303579"
          >
            <Text heading="h1" size="6" weight={500} data-gid="12574492">
              Setup Your Account
            </Text>
            <Text style={{ marginBottom: '14px' }} size="3" data-gid="56542965">
              We need a few more details to setup your Artifact IQ account
            </Text>
            <Formik
              validationSchema={SignupSchema}
              isInitialValid={false}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                try {
                  const registerResult = await fetch(
                    `${
                      process.env.REACT_APP_ZEUS_API_URL as string
                    }/registerUser`,
                    {
                      body: JSON.stringify({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        inviteCode,
                      }),
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                    },
                  );

                  if (registerResult.status === 200) {
                    setStage('OKTA_ACCOUNT_SETUP');
                  } else if (registerResult.status === 404) {
                    setStage('INVALID_OR_EXPIRED_CODE');
                  }
                } finally {
                  setSubmitting(false);
                }
              }}
              initialValues={{ firstName: '', lastName: '', inviteCode }}
              data-gid="84893471"
            >
              {({
                handleSubmit,
                isSubmitting,
                handleChange,
                values,
                isValid,
                errors,
                touched,
                handleBlur,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  data-gid="85175402"
                >
                  <TextBox
                    placeholder="First Name"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    InputProps={{ name: 'firstName', id: 'firstName' }}
                    data-gid="45746138"
                    hasError={
                      errors.firstName !== undefined && touched.firstName
                    }
                  />
                  <TextBox
                    placeholder="Last Name"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    InputProps={{ name: 'lastName', id: 'lastName' }}
                    data-gid="45289010"
                    hasError={errors.lastName !== undefined && touched.lastName}
                  />
                  <input
                    type="hidden"
                    name="inviteCode"
                    value={inviteCode}
                    data-gid="53793726"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    data-gid="85114403"
                    disabled={!isValid}
                    loading={isSubmitting}
                    fullWidth={false}
                    style={{ width: '200px' }}
                  >
                    Create Account
                  </Button>
                </form>
              )}
            </Formik>
            {inviteCode && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '8px',
                }}
                data-gid="26664807"
              >
                Already have an account?
                <Link
                  style={{ marginLeft: '4px ' }}
                  to={`/invite/${inviteCode}`}
                  data-gid="44269777"
                >
                  Login
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterUser;
