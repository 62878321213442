import { useMutation } from '@apollo/client';
import { Button, Colors, makeStyles, Text } from '@grayshift/cairn';
import { Apps } from '@mui/icons-material';
import fileSize from 'filesize';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import { installedAppById } from './graphql/generated/installedAppById';
import {
  removeClueTagMutation,
  tagInstalledAppClueMutation,
} from './graphql/mutations';
import { getInstalledAppById } from './graphql/queries';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  iconImage: {
    marginRight: '10px',
    border: '1px solid gray',
    margin: '1rem',
    borderRadius: '100%',
  },
  noIconTextBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '11vh',
    border: '1px solid gray',
    padding: '2rem',
    margin: '1rem',
    borderRadius: '100%',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  metadataContainer: {
    paddingBottom: '40px',
  },
});

interface InstalledAppProps {
  clueId: string;
}

const InstalledAppDetails: React.FC<InstalledAppProps> = ({ clueId }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const location = useLocation();
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );

  const { data, loading } = useQueryWithErrorBoundary<installedAppById>(
    getInstalledAppById,
    {
      variables: {
        id: clueId,
        extractionId: currentExtractionId,
      },
    },
  );
  const [tagInstalledAppClue] = useMutation(tagInstalledAppClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);
  const allSourceFiles = data?.installedAppById?.sourceFiles;
  const isTagged = data?.installedAppById?.taggedAt !== null;
  const taggedBy = data?.installedAppById?.taggedBy;
  const onDashboard = location.pathname === '/dashboard';

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="62913575"
    >
      <div data-gid="30717684" className={classes.metadataContainer}>
        <ClueDetailsSlideoutMetadataSection title="Actions" data-gid="91881386">
          <div className={classes.tagBtnContainer} data-gid="84353207">
            <ReadOnlyTooltip
              verb="tag"
              bypass={
                currentExtractionAccessType !==
                ExtractionAccessTypeEnum.READ_ONLY
              }
              data-gid="52526073"
            >
              <Button
                color={isTagged ? 'primary' : 'default'}
                fullWidth
                disabled={
                  currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                onClick={() => {
                  if (!isTagged) {
                    tagInstalledAppClue({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                        },
                      },
                      refetchQueries: onDashboard
                        ? [getInstalledAppById, allTaggedCluesQuery]
                        : [getInstalledAppById],
                    }).catch((tagError: Error) => tagError);
                  }
                  if (isTagged) {
                    removeClueTag({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                          clueType: 'INSTALLED_APP',
                        },
                      },
                      refetchQueries: onDashboard
                        ? [getInstalledAppById, allTaggedCluesQuery]
                        : [getInstalledAppById],
                    }).catch((removeTagError: Error) => removeTagError);
                  }
                }}
                data-gid="20065452"
              >
                {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
              </Button>
            </ReadOnlyTooltip>
            {isTagged && (
              <Text size="2" data-gid="45078687">
                You can view all tagged clues on the Dashboard
              </Text>
            )}
          </div>
        </ClueDetailsSlideoutMetadataSection>
        <Notes
          clueId={clueId.toString()}
          clueType={ClueTypes.INSTALLED_APP}
          data-gid="99203011"
        />
        <ClueDetailsSlideoutMetadataSection
          title="Basic Details"
          data-gid="36294001"
        >
          {taggedBy && isTagged && (
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Tagged By:"
              rightText={
                taggedBy.firstName && taggedBy.lastName
                  ? `${taggedBy.firstName} ${taggedBy.lastName}`
                  : taggedBy.email
              }
              data-gid="37576584"
            />
          )}
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            data-gid="23751590"
          >
            {data?.installedAppById.iconUrl ? (
              <img
                className={classes.iconImage}
                width="50%"
                src={data?.installedAppById.iconUrl}
                alt="Installed App Icon"
                data-gid="58441626"
              />
            ) : (
              <Apps
                style={{
                  marginRight: '10px',
                  height: '50%',
                  width: '50%',
                  background: Colors.blue,
                  color: 'white',
                  borderRadius: '100%',
                  margin: '10px 0',
                }}
              />
            )}
          </div>
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Clue ID:"
            rightText={clueId.toString()}
            data-gid="84209424"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Display Name:"
            rightText={data?.installedAppById.displayName}
            data-gid="61444229"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Version:"
            rightText={data?.installedAppById.version}
            data-gid="25106259"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Bundle ID:"
            rightText={data?.installedAppById.bundleId}
            data-gid="48391228"
          />
        </ClueDetailsSlideoutMetadataSection>
        {allSourceFiles && allSourceFiles.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Source Files"
            collapsed={sourcefileSectionCollapsed}
            onClickCollapse={() =>
              setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
            }
            data-gid="50855613"
          >
            {allSourceFiles.map((sf) => (
              <ClueDetailsSlideoutMetadataSubsection
                title={sf.name}
                data-gid="91334597"
              >
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="File Path:"
                  rightText={`${sf.enclosingDirectory}/${sf.name}`}
                  data-gid="63321532"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="SHA256:"
                  rightText={`${sf.checksum}`}
                  data-gid="20450137"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Changed:"
                  rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="62263133"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Modified:"
                  rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="15039919"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Last Accessed:"
                  rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="45189498"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Size:"
                  rightText={`${fileSize(+sf.sizeBytes)}`}
                  data-gid="32435727"
                />
              </ClueDetailsSlideoutMetadataSubsection>
            ))}
          </ClueDetailsSlideoutMetadataSection>
        )}
      </div>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default InstalledAppDetails;
