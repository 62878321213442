import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextBox,
} from '@grayshift/cairn';
import { HighlightOff } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRecoilState } from 'recoil';

import {
  clueDetailsSlideoutDataAtom,
  currentGlobalFiltersActiveAtom,
  currentGlobalSearchTextAtom,
  currentMemoIdAtom,
} from './lib/atoms';
import { useQueryParams } from './useQueryParams';

const useStyles = makeStyles({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: 360,
    margin: '0px 15px 0px 10px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '75%',
    padding: '0 15px 0 20px',
    borderLeft: '1px solid #6B6C6F',
    width: '100%',
  },
  adornedEnd: {
    paddingRight: '10px',
  },
});

const GlobalSearchContainer = (): ReactElement => {
  const classes = useStyles();
  const [, setCurrentMemoState] = useRecoilState(currentMemoIdAtom);
  const [globalSearchTextState, setCurrentGlobalSearchText] = useRecoilState(
    currentGlobalSearchTextAtom,
  );
  const [, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const [currentGlobalFiltersActive, setCurrentGlobalFiltersActive] =
    useRecoilState(currentGlobalFiltersActiveAtom);
  const { clearSearchParams } = useQueryParams();

  const [localSearchState, setLocalSearchState] = useState(
    globalSearchTextState.currentGlobalSearchText,
  );

  const resetPagesStates = (): void => {
    setCurrentMemoState({
      currentMemoId: '',
    });
    clearSearchParams();
  };

  const debounceOpen = useRef(
    debounce((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocalSearchState(e.target.value);
      setCurrentGlobalSearchText({
        currentGlobalSearchText: e.target.value,
      });

      setCurrentGlobalFiltersActive({
        timeframeActive: currentGlobalFiltersActive.timeframeActive,
        searchActive: e.target.value !== '',
      });
      resetPagesStates();
      setClueDetailsSlideoutData({
        slideoutOpen: false,
        clueId: '',
        clueType: null,
      });
    }, 500),
  ).current;

  const focusRef = useRef<HTMLInputElement>(null);
  const debounceClose = useRef(
    debounce(() => {
      setLocalSearchState('');
      setCurrentGlobalSearchText({
        currentGlobalSearchText: '',
      });
      setCurrentGlobalFiltersActive({
        timeframeActive: currentGlobalFiltersActive.timeframeActive,
        searchActive: false,
      });
    }, 500),
  ).current;

  useHotkeys('Command+k', (e) => {
    e.preventDefault();
    if (focusRef.current) {
      focusRef.current.focus();
    }
  });

  return (
    <div className={classes.searchContainer} data-gid="42016235">
      <div className={classes.inputContainer} data-gid="96307930">
        <TextBox
          style={{
            backgroundColor: 'white',
            minHeight: '20%',
            borderRadius: '5px',
            fontStyle: 'italic',
          }}
          label="Filter By Keywords"
          value={localSearchState}
          inputRef={focusRef}
          onChange={(e) => {
            setLocalSearchState(e.target.value);
            debounceOpen(e);
          }}
          InputProps={{
            endAdornment: localSearchState && (
              <InputAdornment position="end" data-gid="24809824">
                <IconButton
                  aria-label="Global filter by keyword search"
                  onClick={() => {
                    setLocalSearchState('');
                    debounceClose();
                  }}
                  data-gid="55683957"
                >
                  <HighlightOff data-gid="97029393" />
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              adornedEnd: classes.adornedEnd,
            },
          }}
          data-gid="87846460"
        />
      </div>
    </div>
  );
};

export default GlobalSearchContainer;
