import { gql } from '@apollo/client';

import {
  CoreCallFields,
  CoreSourceFileFields,
  CoreTaggedByFields,
} from '../fragments';

export const callsQuery = gql`
  ${CoreCallFields}
  query callsByExtractionId(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $timeFrame: TimeFrame
    $searchTerm: String
    $appNames: [String!]!
  ) {
    callsByExtractionId(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeFrame
      searchTerm: $searchTerm
      appNames: $appNames
    ) {
      edges {
        page
        pageSize
        node {
          ...CoreCallFields

          contact {
            primaryDisplayName
            metacontact {
              primaryDisplayName
            }
            id
          }
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;

export const callByIdQuery = gql`
  ${CoreSourceFileFields}
  ${CoreTaggedByFields}
  ${CoreCallFields}
  query callById($callId: ID!) {
    callById(id: $callId) {
      ...CoreCallFields
      contact {
        id
        primaryDisplayName
        sourceFiles {
          ...CoreSourceFileFields
        }
      }

      taggedAt
      taggedBy {
        ...CoreTaggedByFields
      }
      sourceFiles {
        ...CoreSourceFileFields
      }
    }
  }
`;

export const allCallsTimelineQuery = gql`
  ${CoreCallFields}
  query getAllCallsTimeline(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $timeFrame: TimeFrame
    $searchTerm: String
    $appNames: [String!]
  ) {
    callsByExtractionId(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeFrame
      searchTerm: $searchTerm
      appNames: $appNames
    ) {
      edges {
        page
        pageSize
        node {
          ...CoreCallFields
          contact {
            primaryDisplayName
            id
          }
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;
