import { makeStyles } from '@grayshift/cairn';
import { ReactElement, Suspense } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

import ContactsBarChart from './ContactsBarChart';
import ContactsTable from './ContactsTable';
import useDocumentTitle from './lib/useDocumentTitle';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
});

function Contacts(): ReactElement {
  const classes = useStyles();
  useDocumentTitle('Contacts');

  return (
    <Suspense
      fallback={() => <ScaleLoader data-gid="97760376" />}
      data-gid="85456443"
    >
      <div className={classes.outerWrapper} data-gid="51654741">
        <div className={classes.rootContainer} data-gid="36088814">
          <ContactsBarChart data-gid="38629549" />
          <ContactsTable data-gid="66128640" />
        </div>
      </div>
    </Suspense>
  );
}

export default Contacts;
