import 'react-image-gallery/styles/css/image-gallery.css';

import { Colors, makeStyles, Modal } from '@grayshift/cairn';
import { Close } from '@mui/icons-material';
import { ReactElement } from 'react';
import ImageGallery from 'react-image-gallery';

import { mediaById_mediaById_Video_thumbnailFrames } from './graphql/generated/mediaById';

const useStyles = makeStyles({
  modalContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  modalSubContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    paddingTop: '100px',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  imageAndBtnContainer: {
    height: '100%',
    position: 'relative',
  },
  closeBtnContainer: {
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 5,
    right: -50,
    width: 40,
    height: 40,
    borderRadius: 25,
    backgroundColor: Colors.divider,
  },
  timeSecondsContainer: {
    position: 'absolute',
    padding: '1px',
    margin: '2px',
    top: 0,
    borderRadius: 10,
    backgroundColor: Colors.white,
  },
  timeSecondsText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageGalleryContainer: {
    maxHeight: '600px',
    maxWidth: '400px',
  },
});

const MODAL_ROOT_ID = 'modalRoot';

interface ModalProps {
  thumbnailFrameUrls: mediaById_mediaById_Video_thumbnailFrames[];
  onClose: () => void;
}

function VideoFrameGalleryModal({
  thumbnailFrameUrls,
  onClose,
}: ModalProps): ReactElement<ModalProps> {
  const classes = useStyles();

  const frames = thumbnailFrameUrls.map((frame) => ({
    original: frame.storedFileUrl,
    thumbnail: frame.storedFileUrl,
  }));

  return (
    <Modal open data-gid="56280319">
      <div
        className={classes.modalContentContainer}
        id={MODAL_ROOT_ID}
        onClick={(e) => {
          if ((e.target as HTMLElement).id === MODAL_ROOT_ID) {
            onClose();
          }
        }}
        role="presentation"
        data-gid="87466968"
      >
        <div className={classes.modalSubContentContainer} data-gid="84233369">
          <div className={classes.imageAndBtnContainer} data-gid="60495862">
            <div
              className={classes.closeBtnContainer}
              onClick={onClose}
              role="presentation"
              data-gid="79847969"
            >
              <Close data-gid="28141630" />
            </div>
            <ImageGallery
              items={frames}
              showIndex
              thumbnailPosition="right"
              slideInterval={250}
              data-gid="58225010"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default VideoFrameGalleryModal;
