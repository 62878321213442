import { gql } from '@apollo/client';

export const insertImageAnnotationMutation = gql`
  mutation insertImageAnnotation(
    $extractionId: UUID!
    $annotation: ImageAnnotationEnum!
    $userId: UUID!
    $imageId: UUID!
  ) {
    insertImageAnnotation(
      extractionId: $extractionId
      annotation: $annotation
      userId: $userId
      imageId: $imageId
    ) {
      id
      imageAnnotations {
        imageId
        annotation
      }
      thumbnailUrl
    }
  }
`;

export const removeImageAnnotationMutation = gql`
  mutation removeImageAnnotation(
    $imageId: UUID!
    $extractionId: UUID!
    $annotation: ImageAnnotationEnum!
  ) {
    removeImageAnnotation(
      imageId: $imageId
      extractionId: $extractionId
      annotation: $annotation
    ) {
      id
      imageAnnotations {
        imageId
        annotation
      }
      thumbnailUrl
    }
  }
`;
