import { makeStyles, Text } from '@grayshift/cairn';
import { ContentCopy, Difference } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState } from 'react';

const useStyles = makeStyles({
  urlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '25rem',
  },
  contentCopyButton: {
    marginleft: '1vw',
    cursor: 'pointer',
  },
});

interface BrowserHistoryUrlColumnProps {
  url: string;
}

export const getDomain = (fullUrl: string): string => {
  const splitUrl = fullUrl.split('/');
  if (splitUrl.length === 1) return fullUrl;
  const domain = `${[splitUrl[0], splitUrl[1], splitUrl[2]].join('/')}/ ...`;
  if (domain.length > 40) {
    const forcedSplitDomainByLength = domain.match(/.{1,40}/g) ?? [];
    return `${forcedSplitDomainByLength[0]} ...`;
  }
  return domain;
};

const BrowserHistoryUrlColumn: React.FC<BrowserHistoryUrlColumnProps> = ({
  url,
}) => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState('Copy URL');
  const [copyIcon, setCopyIcon] = useState(
    <ContentCopy style={{ marginLeft: '.45vw' }} data-gid="39633830" />,
  );

  const handleClick = async (copyUrl: string): Promise<void> => {
    setCopyIcon(
      <Difference style={{ marginLeft: '.45vw' }} data-gid="62269575" />,
    );
    setTooltip(`Copied: ${copyUrl}`);
    await navigator.clipboard.writeText(copyUrl);
    setTimeout(() => {
      setCopyIcon(
        <ContentCopy style={{ marginLeft: '.45vw' }} data-gid="58140719" />,
      );
      setTooltip('Copy URL');
    }, 1000);
  };

  return (
    <div
      role="presentation"
      className={classes.urlContainer}
      key={`browser-history-url-${url}`}
      data-gid="79023578"
    >
      <Tooltip title={url} placement="top" data-gid="24221860">
        <div data-gid="45913821">
          <Text data-gid="40616515">{getDomain(url)}</Text>
        </div>
      </Tooltip>
      <Tooltip title={tooltip} placement="right" data-gid="14829871">
        <div
          className={classes.contentCopyButton}
          onClick={() => handleClick(url)}
          role="presentation"
          data-gid="83973270"
        >
          {copyIcon}
        </div>
      </Tooltip>
    </div>
  );
};

export default BrowserHistoryUrlColumn;
