/* eslint-disable react/require-default-props */
import { Colors, makeStyles } from '@grayshift/cairn';
import { DateTime } from 'luxon';
import React from 'react';

import { DetailText } from './DetailText';
import { ExpandableCard } from './ExpandableCard';
import { ExtractionInfoWidgetQuery } from './graphql/generated/ExtractionInfoWidgetQuery';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { extractionInfoWidgetQuery } from './lib/queryExtractionDashboard';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    padding: '16px',
    backgroundColor: Colors.white,
    marginBottom: '16px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    padding: '16px',
    backgroundColor: Colors.white,
    marginBottom: '16px',
    '&:not(:first-child)': {
      marginLeft: '16px',
    },
  },
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    width: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  widgets: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '192px',
  },
  collapseButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  deviceInfo: {
    overflow: 'hidden',
    overflowY: 'auto',
  },
  loadingContainer: {
    display: 'flex',
    height: 400,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 30,
    marginBottom: 30,
  },
  centeredFlex: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  taggedClues: {
    width: '320px',
    height: 'calc(100% - 50px)',
  },
  centeredFlexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  flex: {
    display: 'flex',
  },
});

export const ExtractionInfoWidget: React.FC = () => {
  const [currentExtractionId] = useExtractionId();
  const { pollInterval } = useExtraction();

  const classes = useStyles();
  const { data } = useQueryWithErrorBoundary<ExtractionInfoWidgetQuery>(
    extractionInfoWidgetQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { extractionId: currentExtractionId },
      skip: !currentExtractionId,
      pollInterval,
    },
  );

  return (
    <ExpandableCard heading="Extraction Information" data-gid="20644266">
      <div style={{ display: 'flex' }} data-gid="38868750">
        <div className={classes.flexColumn1} data-gid="68626768">
          <DetailText
            key={data?.extractionById?.evidenceId}
            title="Evidence ID: "
            text={data?.extractionById?.evidenceId}
            titleStyle={{ flexShrink: 0, flex: 1 }}
            textStyle={{ flex: 2 }}
            data-gid="82261674"
          />
          <DetailText
            title="Extraction Started: "
            text={
              data?.extractionById?.extractionStartedAt
                ? `${DateTime.fromISO(
                    data?.extractionById?.extractionStartedAt,
                    DateTime.DATE_MED,
                  ).toLocaleString()} ${DateTime.fromISO(
                    data?.extractionById?.extractionStartedAt || '',
                  ).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`
                : ''
            }
            titleStyle={{ flexShrink: 0, flex: 1 }}
            textStyle={{ flex: 2 }}
            data-gid="14558743"
          />
        </div>
        <div
          className={classes.flexColumn1}
          style={{
            marginLeft: '16px',
          }}
          data-gid="52122355"
        >
          <DetailText
            title="Phone State: "
            text={data?.extractionById?.lockState}
            textStyle={{ textTransform: 'uppercase', flex: 2 }}
            titleStyle={{ flexShrink: 0, flex: 1 }}
            data-gid="75204133"
          />
          <DetailText
            title="Extraction Complete: "
            text={
              data?.extractionById?.aggregationsCompletedAt
                ? `${DateTime.fromISO(
                    data?.extractionById?.aggregationsCompletedAt,
                    DateTime.DATE_MED,
                  ).toLocaleString()} ${DateTime.fromISO(
                    data?.extractionById?.aggregationsCompletedAt || '',
                  ).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`
                : ''
            }
            titleStyle={{ flexShrink: 0, flex: 1 }}
            textStyle={{ flex: 2 }}
            data-gid="14558743"
          />

          <DetailText
            title="Examiner Name: "
            text={data?.extractionById?.examinerName}
            titleStyle={{ flexShrink: 0, flex: 1 }}
            textStyle={{ flex: 2 }}
            data-gid="26173201"
          />
        </div>
      </div>
    </ExpandableCard>
  );
};
