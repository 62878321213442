import { Colors, makeStyles, Modal } from '@grayshift/cairn';
import { Close } from '@mui/icons-material';
import { ReactElement } from 'react';

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  modalSubContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  imageAndBtnContainer: {
    height: '100%',
    position: 'relative',
  },
  image: {
    maxHeight: 'calc(100% - 200px)',
    marginTop: 100,
  },
  closeBtnContainer: {
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 50,
    right: 0,
    width: 40,
    height: 40,
    borderRadius: 25,
    backgroundColor: Colors.divider,
  },
});

const MODAL_ROOT_ID = 'modalRoot';

interface ModalProps {
  previewUrl: string | null | undefined;
  onClose: () => void;
}

function FullSizeImageModal({
  previewUrl,
  onClose,
}: ModalProps): ReactElement<ModalProps> {
  const classes = useStyles();

  return (
    <Modal open data-gid="98545328">
      <div
        className={classes.modalContentContainer}
        id={MODAL_ROOT_ID}
        onClick={(e) => {
          if ((e.target as HTMLElement).id === MODAL_ROOT_ID) {
            onClose();
          }
        }}
        role="presentation"
        data-gid="15582322"
      >
        <div className={classes.modalSubContentContainer} data-gid="77887205">
          <div className={classes.imageAndBtnContainer} data-gid="59132267">
            <div
              className={classes.closeBtnContainer}
              onClick={onClose}
              role="presentation"
              data-gid="13733605"
            >
              <Close data-gid="90308807" />
            </div>
            <img
              src={previewUrl || ''}
              className={classes.image}
              alt="media"
              data-gid="79356326"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FullSizeImageModal;
