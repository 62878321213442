import { useMutation } from '@apollo/client';
import { Colors } from '@grayshift/cairn';
import { useOktaAuth } from '@okta/okta-react';
import React, { useCallback, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { AcceptInvite as AcceptInviteType } from './graphql/generated/AcceptInvite';
import { acceptInviteMutation } from './graphql/mutations';
import { ScaleLoader } from './ScaleLoader';
import { useResetAppState } from './useResetAppState';

const AcceptInvite: React.FC = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();
  const { inviteCode } = useParams<{ inviteCode?: string }>();

  const [acceptInvite, { loading, error, called }] =
    useMutation<AcceptInviteType>(acceptInviteMutation);

  const { resetApolloCache, resetRecoilState } = useResetAppState({});

  const inviteMutation = useCallback(async () => {
    if (inviteCode) {
      const { data, errors } = await acceptInvite({
        variables: { inviteCode },
      });

      if (errors && errors.length > 0) {
        history.push('/dashboard');
      } else {
        const redirectTo = data?.acceptInvite?.extractionAccess?.extractionId
          ? `/dashboard?extractionId=${data?.acceptInvite?.extractionAccess?.extractionId}`
          : '/dashboard';

        window.location.assign(redirectTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode]);

  useEffect(() => {
    async function runMutation(): Promise<void> {
      await inviteMutation();

      await resetApolloCache();
      await resetRecoilState();
    }

    if (authState?.isAuthenticated && !called && inviteCode) {
      // eslint-disable-next-line no-void
      void runMutation();
    }
  }, [
    inviteCode,
    authState,
    called,
    resetApolloCache,
    resetRecoilState,
    inviteMutation,
  ]);

  if (!loading && error) {
    return <Redirect to="/dashboard" data-gid="25649196" />;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
      data-gid="90882478"
    >
      {loading && (
        <ScaleLoader
          loading
          color={Colors.blue}
          data-gid="66156829"
          enableTimeout
        />
      )}
    </div>
  );
};

export default AcceptInvite;
