/* eslint-disable no-underscore-dangle */
import { Card, makeStyles, Text } from '@grayshift/cairn';
import { Skeleton } from '@mui/material';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { DetailText, DetailTextSkeleton } from './DetailText';
import { extractionDashboard } from './graphql/generated/extractionDashboard';
import { phoneOperatingSystemAtom } from './lib/atoms';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { deviceOwnerWidgetQuery } from './lib/queryExtractionDashboard';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  centeredFlexRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const ExtractionDeviceOwnerWidget: React.FC = () => {
  const classes = useStyles();
  const [currentExtractionId] = useExtractionId();
  const { pollInterval, data: extractionData } = useExtraction();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneOperatingSystem, setPhoneOperatingSystem] = useRecoilState(
    phoneOperatingSystemAtom,
  );

  const { data, loading } = useQueryWithErrorBoundary<extractionDashboard>(
    deviceOwnerWidgetQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { extractionId: currentExtractionId },
      skip: !currentExtractionId,
      pollInterval,
    },
  );

  useEffect(() => {
    if (data?.extractionById?.targetMeta) {
      setPhoneOperatingSystem({ os: data?.extractionById?.targetMeta?.os });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const contactNums: string[] = [];
  const contactEmails: string[] = [];

  const percentLoaded = extractionData?.extractionById?.percentComplete || 0;
  const showSkeleton =
    loading || !data?.extractionById?.owner.contacts || percentLoaded < 80;

  data?.extractionById?.owner.contacts?.forEach((contact) => {
    const { attributes } = contact;
    attributes.forEach((attr) => {
      if (
        attr.__typename === 'PhoneNumberAttribute' &&
        attr.number &&
        !contactNums.includes(attr.number)
      ) {
        contactNums.push(attr.number);
      }
      if (
        attr.__typename === 'EmailAddressAttribute' &&
        attr.emailAddress &&
        !contactEmails.includes(attr.emailAddress)
      ) {
        contactEmails.push(attr.emailAddress);
      }
    });
  });

  const uniquePhoneNumbers = contactNums.map((number) => (
    <Text key={number} data-gid="51511490">
      {number}
    </Text>
  ));
  const uniqueEmails = contactEmails.map((email) => (
    <Text key={email} data-gid="51511490">
      {email}
    </Text>
  ));

  return (
    <Card
      fillColor="white"
      style={{
        flex: 1,
        padding: '16px',
        overflowY: 'scroll',
      }}
      data-gid="43278654"
    >
      <div className={classes.centeredFlexRow} data-gid="62513791">
        <div data-gid="30318601">
          <Text
            color="textSecondary"
            style={{ minWidth: '112px' }}
            data-gid="87979888"
          >
            Device Owner
          </Text>
          {loading && !data ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '18px' }}
              data-gid="54402694"
            />
          ) : (
            <Text heading="h2" size="4" weight={500} data-gid="40551441">
              {data?.extractionById?.owner.name &&
                startCase(toLower(data?.extractionById?.owner.name))}
            </Text>
          )}
        </div>
      </div>
      <div
        data-gid="95200202"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {showSkeleton ? (
          <DetailTextSkeleton randomWidth data-gid="58417726" />
        ) : (
          <DetailText
            title="Phone Number(s): "
            text={<>{uniquePhoneNumbers}</>}
            titleStyle={{ textAlign: 'right', flex: 1 }}
            textStyle={{ flex: 1 }}
            style={{
              justifyContent: 'center',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              alignItems: 'flex-start',
            }}
            data-gid="84461088"
          />
        )}
        {showSkeleton ? (
          <DetailTextSkeleton randomWidth data-gid="18653717" />
        ) : (
          <DetailText
            title="Email(s): "
            text={<>{uniqueEmails}</>}
            titleStyle={{ textAlign: 'right', flex: 1 }}
            textStyle={{ flex: 1 }}
            style={{
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
            data-gid="77694678"
          />
        )}

        {showSkeleton ? (
          <>
            <DetailTextSkeleton randomWidth data-gid="12231432" />
            <DetailTextSkeleton randomWidth data-gid="58211415" />
            <DetailTextSkeleton randomWidth data-gid="49487953" />
          </>
        ) : (
          data?.extractionById?.owner.contacts
            ?.filter((contact) =>
              contact.attributes.some(
                (attribute) =>
                  attribute.__typename === 'SocialHandleAttribute' &&
                  attribute.handle,
              ),
            )
            .map((contact) => {
              const socialHandle = contact.attributes.find(
                (attribute) =>
                  attribute.__typename === 'SocialHandleAttribute' &&
                  attribute.handle,
              );

              const text = contact.primaryDisplayName;
              let handle = '';

              if (
                socialHandle &&
                socialHandle.__typename === 'SocialHandleAttribute' &&
                socialHandle.handle
              ) {
                handle = socialHandle.handle;
              }

              return (
                <DetailText
                  key={contact.app?.id ?? contact.appBundleId}
                  title={`${contact.app?.displayName ?? contact.appBundleId}: `}
                  text={
                    <>
                      {' '}
                      {text} <br data-gid="77969477" /> Handle: {handle}
                    </>
                  }
                  titleStyle={{ textAlign: 'right', flex: 1 }}
                  textStyle={{ flex: 1 }}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                  data-gid="53887321"
                />
              );
            })
        )}
      </div>
    </Card>
  );
};
