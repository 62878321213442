import { Button, makeStyles } from '@grayshift/cairn';
import { Typography } from '@material-ui/core';
import { useRecoilState } from 'recoil';

import ExtractionSelectionModal from './ExtractionSelectionModal';
import { extractionSelectionModalIsOpenAtom } from './lib/atoms';
import { publicStaticFileURL } from './lib/publicFile';

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  header: {
    paddingTop: 5,
    fontSize: 75,
  },
  body: {
    fontSize: 40,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  notFound: {
    fontSize: 24,
    color: 'grey',
  },
  selectExtraction: {
    paddingTop: 10,
  },
  footer: { fontSize: 24 },
});

const UnauthorizedVisit = (): JSX.Element => {
  const classes = useStyles();
  const [, setExtractionSelectionModalIsOpen] = useRecoilState(
    extractionSelectionModalIsOpenAtom,
  );

  return (
    <div className={classes.rootContainer} data-gid="68557647">
      <img
        src={publicStaticFileURL('/blackMagnetAIQLogo.png')}
        style={{ height: '140px' }}
        alt="artifactiq-logo"
        data-gid="98330309"
      />
      <Typography className={classes.header} data-gid="99009617">
        WHOOPS!
      </Typography>
      <Typography className={classes.body} data-gid="66765463">
        WE CAN&apos;T FIND THAT.
      </Typography>
      <div className={classes.row} data-gid="72223267">
        <Typography className={classes.footer} data-gid="56552822">
          404
        </Typography>
        <Typography className={classes.notFound} data-gid="57844469">
          &nbsp;Not Found.&nbsp;
        </Typography>
        <Typography className={classes.footer} data-gid="78064838">
          Please try again.
        </Typography>
      </div>
      <div className={classes.selectExtraction} data-gid="18297825">
        <Button
          onClick={() => setExtractionSelectionModalIsOpen(true)}
          data-gid="56424438"
        >
          Select An Extraction
        </Button>
      </div>
      <ExtractionSelectionModal data-gid="95390423" />
    </div>
  );
};

export default UnauthorizedVisit;
