import { makeStyles, Text } from '@grayshift/cairn';
import React from 'react';

const useStyles = makeStyles({
  metadataTextItem: {
    display: 'flex',
    marginTop: 15,
  },
  metadataTextItemLeftContent: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  metadataTextItemRightContent: {
    marginLeft: 15,
    width: 215,
    wordBreak: 'break-word',
  },
});

interface metadataTextItem {
  leftText: string | number;
  rightText: string | number | undefined | null;
}

const ClueDetailsSlideoutMetadataTextItem: React.FC<metadataTextItem> = ({
  leftText,
  rightText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.metadataTextItem} data-gid="47512689">
      <div className={classes.metadataTextItemLeftContent} data-gid="68718468">
        <Text color="textSecondary" data-gid="27663526">
          {leftText}
        </Text>
      </div>
      <div className={classes.metadataTextItemRightContent} data-gid="80064345">
        <Text weight={500} data-gid="73229132">
          {rightText}
        </Text>
      </div>
    </div>
  );
};

export default ClueDetailsSlideoutMetadataTextItem;
