/* eslint-disable no-secrets/no-secrets, pii/no-email */
import './index.css';

import { InMemoryCache, StoreObject } from '@apollo/client';
import {
  ApolloProvider,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client/react';
import { Colors, ThemeProvider } from '@grayshift/cairn';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, useOktaAuth } from '@okta/okta-react';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, { lazy, memo, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { RecoilRoot, useRecoilState } from 'recoil';

import version from '../package.json';
import AcceptInvite from './AcceptInvite';
import { AppMaintenance } from './AppMaintenance';
import { AppUnavailable } from './AppUnavailable';
import { AuditLog } from './AuditLog';
import BrowserHistory from './BrowserHistory';
import Calls from './Calls';
import Contacts from './Contacts';
import ExtractionDashboard from './ExtractionDashboard';
import ExtractionImport from './ExtractionImport';
import { makeClient } from './graphql/client';
import { allMedia_allMedia } from './graphql/generated/allMedia';
import { extractionAccess as ExtractionAccessData } from './graphql/generated/extractionAccess';
import { extractionAccessSharedTimeframe } from './graphql/generated/extractionAccessSharedTimeframe';
import { getAllMessagesByMessageThreadId_allMessagesByThreadId } from './graphql/generated/getAllMessagesByMessageThreadId';
import { getAllMessageThreads_allMessageThreads } from './graphql/generated/getAllMessageThreads';
import { getImages_allImagesByExtractionId } from './graphql/generated/getImages';
import { getUserInfo } from './graphql/generated/getUserInfo';
import { AccountUserRole } from './graphql/generated/globalTypes';
import { paginatedLocationsByIds_paginatedLocationsByIds } from './graphql/generated/paginatedLocationsByIds';
import {
  extractionAccessQuery,
  extractionAccessSharedTimeframeQuery,
} from './graphql/queries';
import { AuditLogUserLoginEventMutation } from './graphql/queries/auditLog';
import { UserInfo } from './graphql/queries/user';
import InstalledApps from './InstalledApps';
import { Layout } from './Layout';
import {
  extractionAccessAtom,
  extractionAccessSharedTimeframeAtom,
  userInfoAtom,
} from './lib/atoms';
import useCurrentExtractionId, {
  useExtractionId,
} from './lib/hookUseCurrentExtractionId';
import { initializeTimer, trackIdleTime } from './lib/timeTracking';
import Memos from './Memos';
// import Media from './Media';
import RegisterUser from './RegisterUser';
// import Report from './Report';
import reportWebVitals from './reportWebVitals';
import { SecureAppRoute } from './SecureAppRoute';
import SplashScreen from './SplashScreen';
import TosAgreement from './TosAgreement';
import UnauthorizedVisit from './UnauthorizedVisit';
import { UserManagement } from './UserManagement';

const Report = lazy(() => import('./Report'));
const Messages = lazy(() => import('./Messages'));
// const Memos = lazy(() => import('./Memos'));
const Media = lazy(() => import('./Media'));
const Location = lazy(() => import('./Location'));
const Timeline = lazy(() => import('./Timeline'));

const isProduction = process.env.REACT_APP_ENV === 'production';

const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new TracingIntegrations.BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev',
    tracesSampleRate: 1,
    beforeBreadcrumb: (breadcrumb) => {
      // Don't duplicate breadcrumbs for apollo graphql queries.
      if (breadcrumb.category === 'fetch') {
        const url: string = isString(breadcrumb.data?.url)
          ? (breadcrumb.data?.url as string)
          : '';

        if (
          url.includes('/graphql') ||
          url === process.env.REACT_APP_ZEUS_API_URL
        ) {
          return null;
        }
      }

      return breadcrumb;
    },
  });
}

// TODO - Move this to @types - for some reason global types aren't being recognized. Putting them here for now.
// https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
declare global {
  interface Window {
    aptrinsic: (
      event: 'identify' | 'track' | 'set' | 'bot' | 'reset',
      payload?:
        | string
        | Record<string, string | number | string[] | null | boolean>,
      optionalPayload?: Record<string, string | number | string[]>,
    ) => void;
    embedded_svc: {
      settings: {
        displayHelpButton: boolean;
        prepopulatedPrechatFields: {
          FirstName: string | null | undefined;
          LastName: string | null | undefined;
          Email: string;
          Subject: string;
        };
        language: string;
        loadingText: string;
        enabledFeatures: [string];
        entryFeature: string;
      };
      init: (
        a: string,
        b: string,
        c: string,
        d: string,
        e: string,
        f: Record<string, string | boolean>,
      ) => void;
    };
  }
}
interface AccessToken {
  firstName?: string;
  lastName?: string;
  sfaccountid?: string;
  sfcontactid?: string;
  sub?: string;
}

const usePageTracking = (eaData: ExtractionAccessData | undefined): void => {
  const { authState } = useOktaAuth();
  const [currentExtractionId] = useCurrentExtractionId();

  const revealVersion = version.version;
  const artifactiqVersion = version.version;

  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const lockState = eaData?.extractionById?.lockState;
  const os = eaData?.extractionById?.targetMeta.os;

  useEffect(() => {
    setInitialized(true);
  }, [authState?.isAuthenticated, authState?.accessToken]);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);

  useEffect(() => {
    if (
      isProduction &&
      currentExtractionId &&
      revealVersion &&
      lockState &&
      os
    ) {
      window.aptrinsic('set', 'globalContext', {
        revealVersion,
        artifactiqVersion,
        currentExtractionId,
        lockState,
        os,
      });
    }
  }, [currentExtractionId, artifactiqVersion, revealVersion, lockState, os]);
};

const ArtifactIQLoginCallBack: React.FC = () => {
  const { authState } = useOktaAuth();
  const [createAuditLogEvent] = useMutation(AuditLogUserLoginEventMutation);

  useEffect(() => {
    if (isProduction) {
      ReactGA.initialize('G-FFYFXLYKK5');

      // GainSight PX
      if (authState?.isAuthenticated && authState?.accessToken?.claims) {
        const claims = authState?.accessToken?.claims;

        const {
          firstName = '',
          lastName = '',
          sub = '',
          sfaccountid = '',
          sfcontactid = '',
        } = claims as AccessToken;

        window.aptrinsic(
          'identify',
          {
            id: sfcontactid,
            sfdcContactId: sfcontactid,
            email: sub,
            firstName,
            lastName,
          },
          {
            id: sfaccountid,
          },
        );
      }
      window.aptrinsic('bot', 'search', { labels: ['reveal'] });
    }

    if (authState?.isAuthenticated && authState?.accessToken?.claims) {
      // eslint-disable-next-line no-void
      void createAuditLogEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated, authState?.accessToken?.claims]);

  return <LoginCallback data-gid="85808465" />;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const App = memo((): JSX.Element => {
  const { oktaAuth: auth, authState } = useOktaAuth();
  const history = useHistory();
  const [, setUserInfo] = useRecoilState(userInfoAtom);
  const [, setExtractionAccessSharedTimeframe] = useRecoilState(
    extractionAccessSharedTimeframeAtom,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [extractionId, _, isValidExtractionId] = useExtractionId();

  const [, setExtractionAccess] = useRecoilState(extractionAccessAtom);

  const {
    loading: eaLoading,
    data: eaData,
    error: eaError,
  } = useQuery<ExtractionAccessData>(extractionAccessQuery, {
    variables: { extractionId },
    skip: !isValidExtractionId(),
    onCompleted: () => {
      setExtractionAccess(true);
    },
  });

  useEffect(() => {
    if (eaError) {
      eaError.graphQLErrors.forEach((error) => {
        if (error.extensions.code === 'NO_ACCESS') {
          setExtractionAccess(false);
        }
        if (error.extensions.code === 'NOT_FOUND') {
          window.location.assign('/404');
        }
      });
    }
  }, [eaError, setExtractionAccess]);

  usePageTracking(eaData);

  const [fireGetUserInfoQuery, { loading, data }] = useLazyQuery<getUserInfo>(
    UserInfo,
    {
      fetchPolicy: 'network-only',
      onCompleted: (completedData) => {
        setUserInfo({ ...completedData.getUserInfo });

        // Stuart requested a single string to match against in Gainsight
        const licensedAccountIds = completedData.getUserInfo.entitledAccounts
          .map(({ id }) => id)
          .join(',');

        if (
          isProduction &&
          authState?.isAuthenticated &&
          authState?.accessToken?.claims
        ) {
          const claims = authState?.accessToken?.claims;

          const {
            firstName = '',
            lastName = '',
            sub = '',
            sfaccountid = '',
            sfcontactid = '',
          } = claims as AccessToken;

          const {
            eligibleForTrial,
            hasActiveTrial,
            trialActiveUntil,
            marketingOptIn,
          } = completedData.getUserInfo;

          window.aptrinsic(
            'identify',
            {
              id: sfcontactid,
              sfdcContactId: sfcontactid,
              email: sub,
              marketingOptIn,
              firstName,
              lastName,
              licensedAccountIds:
                licensedAccountIds.length > 0 ? licensedAccountIds : null,
              eligibleForTrial,
              hasActiveTrial,
              trialActiveUntil: trialActiveUntil
                ? new Date(trialActiveUntil).toISOString()
                : null,
            },
            {
              id: sfaccountid,
            },
          );
        }
      },
    },
  );

  const {
    id,
    hasAcceptedTos,
    role,
    firstName,
    lastName,
    email,
    entitledAccounts,
  } = data?.getUserInfo || {};

  const { data: sharedTimeframeData } =
    useQuery<extractionAccessSharedTimeframe>(
      extractionAccessSharedTimeframeQuery,
      {
        variables: { extractionId, userId: id },
        skip: !isValidExtractionId() || !id,
      },
    );

  useEffect(() => {
    if (sharedTimeframeData) {
      setExtractionAccessSharedTimeframe({
        extractionAccessSharedTimeframe: {
          startDate:
            sharedTimeframeData.extractionAccessSharedTimeframe?.startDate ||
            null,
          endDate:
            sharedTimeframeData.extractionAccessSharedTimeframe?.endDate ||
            null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedTimeframeData]);

  const { accessType } = eaData?.extractionById || {};

  const hasNotAcceptedTos = id && !hasAcceptedTos;

  useEffect(() => {
    if (
      authState?.isAuthenticated &&
      authState?.accessToken?.claims &&
      authState?.idToken?.claims.sub
    ) {
      // eslint-disable-next-line no-void
      void fireGetUserInfoQuery();
    }
  }, [authState, fireGetUserInfoQuery]);

  useEffect(() => {
    const chatSettings = window?.embedded_svc?.settings;
    const isEntitled = entitledAccounts && entitledAccounts.length > 0;

    if (chatSettings && isEntitled && isProduction && email) {
      chatSettings.displayHelpButton = true;
      chatSettings.prepopulatedPrechatFields = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Subject: '',
      };
      chatSettings.language = 'en';
      chatSettings.loadingText = 'Loading ...';

      chatSettings.enabledFeatures = ['LiveAgent'];
      chatSettings.entryFeature = 'LiveAgent';

      window?.embedded_svc.init(
        'https://magnetforensics.my.salesforce.com',
        'https://support.magnetforensics.com/',
        'https://service.force.com/',
        '00D40000000MlmI',
        'AIQ_Chat',
        {
          baseLiveAgentContentURL:
            'https://c.la1-core1.sfdc-58ktaz.salesforceliveagent.com/content',
          deploymentId: process.env.REACT_APP_SALESFORCE_DEPLOYMENT_ID ?? '',
          buttonId: '573JQ000000iyuv',
          baseLiveAgentURL:
            'https://d.la1-core1.sfdc-58ktaz.salesforceliveagent.com/chat',
          eswLiveAgentDevName:
            'EmbeddedServiceLiveAgent_Parent04IJQ000000BRg12AG_18b256b0540',
          isOfflineSupportEnabled: false,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (extractionId && accessType && isProduction) {
      window.aptrinsic('track', 'Selected Extraction', {
        extractionId,
        accessType,
      });
    }
  }, [extractionId, accessType]);

  // eslint-disable-next-line no-void
  void useMemo(async () => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      try {
        const user = await auth.getUser();
        // eslint-disable-next-line no-console
        console.log({ user });
        Sentry.setUser({ email: user.email });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [auth]);

  if (loading || eaLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
        data-gid="83836841"
      >
        {' '}
        <ScaleLoader loading color={Colors.blue} data-gid="28022768" />{' '}
      </div>
    );
  }

  if (extractionId) {
    const uuidRegexExp =
      /^[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}$/i;
    if (!uuidRegexExp.test(extractionId)) {
      return (
        <SecureAppRoute path="*">
          <UnauthorizedVisit data-gid="77273301" />
        </SecureAppRoute>
      );
    }
  }

  if (hasNotAcceptedTos && id) {
    return <TosAgreement id={id} data-gid="80186899" />;
  }

  const { pathname, search } = history.location;

  // Used only for seeing where we were prior to hitting <UnauthorizedVisit/> (race condition debug)
  history.location.state = {
    previousPathName: pathname,
    previousSearch: search,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Switch data-gid="16756425">
        <Route path="/login/callback" component={ArtifactIQLoginCallBack} />
        <Route path="/register/:inviteCode">
          <RegisterUser data-gid="60329982" />
        </Route>
        <Route path="/splash" exact component={SplashScreen} />
        <SecureAppRoute path="/" exact>
          <Redirect to="/dashboard" data-gid="89228181" />
        </SecureAppRoute>
        <SecureAppRoute path="/invite/:inviteCode">
          <AcceptInvite data-gid="63065772" />
        </SecureAppRoute>
        <SecureAppRoute path="/timeline">
          <Layout data-gid="98425590">
            <Timeline data-gid="20862334" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/installedApps">
          <Layout data-gid="10462353">
            <InstalledApps />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/report">
          <Layout data-gid="69696062">
            <Report data-gid="20862334" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/messages">
          <Layout data-gid="23850139">
            <Messages data-gid="10188205" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/calls">
          <Layout data-gid="97752485">
            <Calls data-gid="91053319" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/memos">
          <Layout data-gid="59572097">
            <Memos data-gid="82238663" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/contacts">
          <Layout data-gid="41409238">
            <Contacts data-gid="40170610" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/location">
          <Layout data-gid="58070586">
            <Location />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/browserHistory">
          <BrowserHistory data-gid="85145302" />
        </SecureAppRoute>
        {role === AccountUserRole.ADMIN && (
          <SecureAppRoute path="/user-management">
            <UserManagement data-gid="40754147" />
          </SecureAppRoute>
        )}
        {role === AccountUserRole.ADMIN && (
          <SecureAppRoute path="/audit-log">
            <AuditLog data-gid="80515693" />
          </SecureAppRoute>
        )}
        <SecureAppRoute path="/media">
          <Layout data-gid="93875993">
            <Media data-gid="79866614" />
          </Layout>
        </SecureAppRoute>
        <SecureAppRoute path="/import">
          <ExtractionImport data-gid="33477534" />
        </SecureAppRoute>
        <SecureAppRoute path="/dashboard">
          <ExtractionDashboard data-gid="84315089" />
        </SecureAppRoute>
        <SecureAppRoute path="/404">
          <UnauthorizedVisit data-gid="78430493" />
        </SecureAppRoute>
        <SecureAppRoute path="*">
          <UnauthorizedVisit data-gid="34148078" />
        </SecureAppRoute>
      </Switch>
    </LocalizationProvider>
  );
});

const SecureApp = memo((): JSX.Element => {
  useEffect(() => {
    if (isProduction) {
      trackIdleTime(15);
      initializeTimer();
    }
  });

  const history = useHistory();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ): Promise<void> => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  // Change this to true to take the app down & show the maintenance page
  const APP_UNAVAILABLE = process.env.REACT_APP_UNAVAILABLE === 'true';
  if (APP_UNAVAILABLE) {
    return <AppUnavailable />;
  }

  const APP_MAINTENANCE = process.env.REACT_APP_MAINTENANCE === 'true';
  if (APP_MAINTENANCE) {
    return <AppMaintenance />;
  }

  if (process.env.REACT_APP_SENTRY_DSN) {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Sentry.ErrorBoundary>
          <App />
        </Sentry.ErrorBoundary>
      </Security>
    );
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
});

const cache = new InMemoryCache({
  typePolicies: {
    DeviceOwner: {
      keyFields: ['name'],
    },
    // Needed to keep/readd otherwise bug where paginatedLocationByIds query doesn't load all edges
    DefaultLocation: {
      keyFields: false,
    },
    Query: {
      fields: {
        allImagesByExtractionId: {
          keyArgs: [
            'appNames',
            'timeframe',
            'searchTerm',
            'showNcmecCsam',
            'showAiCsam',
          ],
          merge(
            // eslint-disable-next-line unicorn/no-object-as-default-parameter
            existing: getImages_allImagesByExtractionId = {
              edges: [],
              __typename: 'QueryAllImagesByExtractionIdConnection',
              pageInfo: {
                __typename: 'FinitePageInfo',
                totalEdges: 0,
                totalPages: 0,
              },
            },
            // eslint-disable-next-line unicorn/no-object-as-default-parameter
            incoming: getImages_allImagesByExtractionId = {
              edges: [],
              __typename: 'QueryAllImagesByExtractionIdConnection',
              pageInfo: {
                __typename: 'FinitePageInfo',
                totalEdges: 0,
                totalPages: 0,
              },
            },
          ) {
            const { edges: mediaEdges, ...rest } = incoming;

            return {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              edges: uniqBy([...existing.edges, ...mediaEdges], 'node.__ref'),
              ...rest,
            };
          },
        },
        paginatedLocationsByIds: {
          keyArgs: ['extractionId', 'ids'],
          merge(
            existing: paginatedLocationsByIds_paginatedLocationsByIds,
            incoming: paginatedLocationsByIds_paginatedLocationsByIds,
          ) {
            const { edges: existingEdges } = existing ?? {
              edges: [],
              pageInfo: {
                totalEdges: 0,
                totalPages: 0,
                __typename: 'FinitePageInfo',
              },
            };
            const { edges: incomingEdges = [], pageInfo } = incoming;
            const edges = uniqBy(
              [...existingEdges, ...incomingEdges],
              ({ node }) => node.id,
            );
            return { edges, pageInfo };
          },
        },
        allMedia: {
          keyArgs: [
            'appNames',
            'timeframe',
            'searchTerm',
            'showNcmecCsam',
            'showAiCsam',
            'mediaCategoryFilter',
          ],
          merge(
            // eslint-disable-next-line unicorn/no-object-as-default-parameter
            existing: allMedia_allMedia = {
              edges: [],
              __typename: 'QueryAllMediaConnection',
              pageInfo: {
                __typename: 'FinitePageInfo',
                totalEdges: 0,
                totalPages: 0,
              },
            },
            // eslint-disable-next-line unicorn/no-object-as-default-parameter
            incoming: allMedia_allMedia = {
              edges: [],
              __typename: 'QueryAllMediaConnection',
              pageInfo: {
                __typename: 'FinitePageInfo',
                totalEdges: 0,
                totalPages: 0,
              },
            },
          ) {
            const { edges, ...rest } = incoming;
            return {
              edges: sortBy(
                uniqBy(
                  [...(existing?.edges ?? []), ...(edges ?? [])],
                  'node.__ref',
                ),
                ({ node }) => node.timestamp,
              ),
              ...rest,
            };
          },
        },
        allMessagesByThreadId: {
          keyArgs: ['messageThreadId', 'extractionId'],
          merge(
            existing: getAllMessagesByMessageThreadId_allMessagesByThreadId,
            incoming: getAllMessagesByMessageThreadId_allMessagesByThreadId,
            { readField },
          ) {
            const existingEdges = existing?.edges ?? [];
            const incomingEdges = incoming?.edges ?? [];

            const allEdges = sortBy(
              uniqBy([...existingEdges, ...incomingEdges], ({ node }) =>
                readField('id', node as unknown as StoreObject),
              ),
              ({ node }) =>
                readField('timestamp', node as unknown as StoreObject),
            );

            return { ...incoming, edges: allEdges };
          },
        },
        allMessageThreads: {
          merge(
            existing: getAllMessageThreads_allMessageThreads,
            incoming: getAllMessageThreads_allMessageThreads,
          ) {
            const existingEdges = existing?.edges ?? [];
            const incomingEdges = incoming?.edges ?? [];

            const edges = [...existingEdges, ...incomingEdges];

            return {
              ...incoming,
              edges,
            };
          },
        },
      },
    },
  },
});

const AppTree = memo((): JSX.Element | null => {
  const client = useMemo(() => makeClient(cache), []);

  return (
    <ThemeProvider>
      <ApolloProvider client={client}>
        <RecoilRoot>
          <Router>
            <SecureApp />
          </Router>
        </RecoilRoot>
      </ApolloProvider>
    </ThemeProvider>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <AppTree />
  </React.StrictMode>,
  document.querySelector('#root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
