import { useMutation } from '@apollo/client';
import { Button, makeStyles, Text } from '@grayshift/cairn';
import fileSize from 'filesize';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import { memoById } from './graphql/generated/memoById';
import {
  removeClueTagMutation,
  tagMemoClueMutation,
} from './graphql/mutations';
import { memoByIdQuery } from './graphql/queries/memos';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
});

interface MemoProps {
  clueId: string;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

const MemoClueDetails: React.FC<MemoProps> = ({ clueId }) => {
  const classes = useStyles();
  const location = useLocation();
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [currentExtractionId] = useCurrentExtractionId();
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [tagMemoClue] = useMutation(tagMemoClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  const { loading, data, error } = useQueryWithErrorBoundary<memoById>(
    memoByIdQuery,
    {
      variables: {
        memoId: clueId,
        extractionId: currentExtractionId,
      },
    },
  );

  if (error) {
    return null;
  }

  const memo = data?.memoById;
  const isTagged = data?.memoById?.taggedAt;

  const allSourceFiles: sourceFileProps[] = uniqBy(
    [...(memo?.sourceFiles || [])],
    'id',
  );

  const onDashboard = location.pathname === '/dashboard';

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="30065009"
    >
      <>
        <div className={classes.metadataContainer} data-gid="82240586">
          <ClueDetailsSlideoutMetadataSection
            title="Actions"
            data-gid="71534669"
          >
            <div className={classes.tagBtnContainer} data-gid="97695478">
              <ReadOnlyTooltip
                verb="tag"
                bypass={
                  currentExtractionAccessType !==
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                data-gid="78703368"
              >
                <Button
                  color={isTagged ? 'primary' : 'default'}
                  disabled={
                    currentExtractionAccessType ===
                    ExtractionAccessTypeEnum.READ_ONLY
                  }
                  fullWidth
                  onClick={() => {
                    if (!isTagged) {
                      tagMemoClue({
                        variables: {
                          input: {
                            clueId,
                            extractionId: currentExtractionId,
                          },
                        },
                        refetchQueries: onDashboard
                          ? [memoByIdQuery, allTaggedCluesQuery]
                          : [memoByIdQuery],
                      }).catch((tagError: Error) => tagError);
                    }
                    if (isTagged) {
                      removeClueTag({
                        variables: {
                          input: {
                            clueId,
                            extractionId: currentExtractionId,
                            clueType: 'MEMO',
                          },
                        },
                        refetchQueries: onDashboard
                          ? [memoByIdQuery, allTaggedCluesQuery]
                          : [memoByIdQuery],
                      }).catch((removeTagError: Error) => removeTagError);
                    }
                  }}
                  data-gid="24198744"
                >
                  {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
                </Button>
              </ReadOnlyTooltip>
              {isTagged && (
                <Text size="2" data-gid="75671295">
                  You can view all tagged clues on the Dashboard
                </Text>
              )}
            </div>
          </ClueDetailsSlideoutMetadataSection>
          <Notes
            clueId={clueId}
            clueType={ClueTypes.MEMO}
            data-gid="45587865"
          />
          <ClueDetailsSlideoutMetadataSection
            title="Basic Details"
            data-gid="43548999"
          >
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Clue Id:"
              rightText={memo?.id}
              data-gid="14636567"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Title:"
              rightText={memo?.title ?? 'No title'}
              data-gid="35086562"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Modified At: "
              rightText={
                memo?.modifiedAt
                  ? `${DateTime.fromISO(memo.modifiedAt).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromISO(memo.modifiedAt).toLocaleString(
                      DateTime.DATE_MED,
                    )}`
                  : 'No date'
              }
              data-gid="46016597"
            />
          </ClueDetailsSlideoutMetadataSection>
          {allSourceFiles && allSourceFiles?.length > 0 && (
            <ClueDetailsSlideoutMetadataSection
              title="Source Files"
              collapsed={sourcefileSectionCollapsed}
              onClickCollapse={() =>
                setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
              }
              data-gid="14797872"
            >
              {allSourceFiles.map((sf) => (
                <ClueDetailsSlideoutMetadataSubsection
                  key={sf.id}
                  title={sf.name}
                  data-gid="54472388"
                >
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="File Path:"
                    rightText={`${sf.enclosingDirectory}/${sf.name}`}
                    data-gid="99154112"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="SHA256:"
                    rightText={`${sf.checksum}`}
                    data-gid="60197907"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Changed:"
                    rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="73612886"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Modified:"
                    rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="78631838"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Last Accessed:"
                    rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="49856027"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Size:"
                    rightText={`${fileSize(+sf.sizeBytes)}`}
                    data-gid="82951530"
                  />
                </ClueDetailsSlideoutMetadataSubsection>
              ))}
            </ClueDetailsSlideoutMetadataSection>
          )}
        </div>
      </>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default MemoClueDetails;
