/* eslint-disable no-underscore-dangle */
import { useMutation } from '@apollo/client';
import { Button, Colors, LinkButton, makeStyles, Text } from '@grayshift/cairn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import fileSize from 'filesize';
import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Marker, StaticMap } from 'react-map-gl';
import { useHistory, useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import VisibilitySensor from 'react-visibility-sensor';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataMediaItem from './ClueDetailsSlideoutMetadataMediaItem';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import { paginatedLocationsByIds } from './graphql/generated/paginatedLocationsByIds';
import {
  removeClueTagMutation,
  tagLocationClueMutation,
} from './graphql/mutations';
import { getPaginatedLocationsByIdsQuery } from './graphql/queries';
import {
  currentExtractionAccessTypeAtom,
  currViewStateAtom,
  linkedLocationAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { publicStaticFileURL } from './lib/publicFile';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  maxLocationsTextContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  locationMultipleTitleContainer: {
    textAlign: 'center',
    color: Colors.textSecondary,
  },
  locationMultipleDivider: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.divider,
  },
  locationDetailContainer: {
    marginTop: 20,
  },
  locationDetailContainerTail: {
    marginTop: 20,
    paddingBottom: 40,
    borderColor: 'grey',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  viewLocation: {
    paddingTop: 5,
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'end',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
    },
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface LocationProps {
  clueIds: string[] | string;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

const LocationClueDetails: React.FC<LocationProps> = ({ clueIds }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: extraction } = useExtraction();

  const [currentExtractionId] = useCurrentExtractionId();
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const maskCsam = extraction?.extractionById?.maskCsam;

  const [metaDataSections, setMetaDataSections] = useState<{
    [key: number]: {
      sourceFiles: null | boolean;
      additionalInfo: null | boolean;
    };
  }>();

  const [, setLinkedLocation] = useRecoilState(linkedLocationAtom);

  const [, setCurrentViewState] = useRecoilState(currViewStateAtom);
  const [currentPage, setCurrentPage] = useState(1);

  const locationLink = useRef(
    debounce<(lat: string, long: string) => void>((lat, long) => {
      setCurrentViewState({
        longitude: parseFloat(long),
        latitude: parseFloat(lat),
        pitch: 0,
        bearing: 0,
        transitionDuration: 1500,
        zoom: 16,
      });
    }, 1000),
  ).current;

  const { loading, error, data, fetchMore } =
    useQueryWithErrorBoundary<paginatedLocationsByIds>(
      getPaginatedLocationsByIdsQuery,
      {
        variables: {
          extractionId: currentExtractionId,
          ids: clueIds,
          page: currentPage,
          pageSize: 10,
        }, // TODO: this is an array, need to make other uses consistent with this
      },
    );
  const locations = data?.paginatedLocationsByIds?.edges ?? [];

  const previousPageRef = useRef(clueIds);

  useEffect(() => {
    if (previousPageRef.current !== clueIds && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [clueIds, currentPage]);

  const [tagLocationClue] = useMutation(tagLocationClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  if (error) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchMoreData = async () => {
    const findNextPageNum =
      Math.ceil((data?.paginatedLocationsByIds?.edges.length ?? 0) / 10) + 1;
    setCurrentPage(findNextPageNum);

    await fetchMore({
      variables: {
        extractionId: currentExtractionId,
        ids: clueIds,
        page: findNextPageNum,
        pageSize: 10,
      },
    });
  };

  const scrollableParent = document.querySelector('#rootScrollContainer');

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="63218502"
    >
      <div className={classes.metadataContainer} data-gid="91753853">
        <InfiniteScroll
          dataLength={data?.paginatedLocationsByIds?.edges.length ?? 0}
          next={fetchMoreData}
          hasMore={locations.length < clueIds.length}
          loader={
            <div className={classes.loadingContainer} data-gid="12448039">
              <ScaleLoader loading color={Colors.blue} data-gid="66156829" />
            </div>
          }
          scrollableTarget={scrollableParent}
        >
          {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
          {locations?.map((location, index) => {
            const {
              id,
              latitude,
              longitude,
              timestamp,
              media,
              sourceFiles,
              meta,
              taggedAt,
              taggedBy,
            } = location.node;
            const locationSourceFiles: sourceFileProps[] = uniqBy(
              [
                ...flatten(
                  media
                    ?.filter((m) => 'sourceFiles' in m)
                    .map((m) => m.sourceFiles) || [],
                ),
                ...(sourceFiles || []),
              ],
              'id',
            );

            const latAndLong =
              latitude && longitude
                ? `${latitude || ''}, ${longitude || ''}`
                : 'Not Found';

            const image = media[0]?.__typename === 'Image';

            const thumbnailUrl = image && media[0]?.thumbnailUrl;

            const previewUrl =
              (media[0]?.__typename !== 'Video' && media[0]?.previewUrl) || '';

            const imageId =
              media[0]?.__typename !== 'Video' ? media[0]?.id : '';

            const imageContext = {
              maskCsam: maskCsam || false,
              ncmecMatch:
                media[0]?.__typename !== 'Video' ? media[0]?.ncmecMatch : false,
              containsNudity:
                media[0]?.__typename !== 'Video'
                  ? media[0]?.containsNudity
                  : false,
              containsNudityConfidence:
                media[0]?.__typename !== 'Video'
                  ? media[0]?.containsNudityConfidence
                  : 0,
              containsCSAM:
                media[0]?.__typename !== 'Video'
                  ? media[0]?.containsCSAM
                  : false,
              containsCSAMConfidence:
                media[0]?.__typename !== 'Video'
                  ? media[0]?.containsCSAMConfidence
                  : 0,
              isExplicit:
                media[0]?.__typename !== 'Video'
                  ? media[0]?.imageAnnotations &&
                    media[0].imageAnnotations.length > 0
                  : false,
            };
            const storedFileChecksum =
              (media[0]?.__typename !== 'Video' &&
                media[0]?.storedFileChecksum) ||
              '';

            const isTagged = taggedAt;
            const onDashboard = pathname === '/dashboard';

            const sourceFileSectionOpen =
              metaDataSections && metaDataSections[index]?.sourceFiles;

            const additionalInfoSectionOpen =
              metaDataSections && metaDataSections[index]?.additionalInfo;

            return (
              <div key={`locationStackDetails-${id}`} data-gid="10732666">
                <div
                  className={
                    index !== locations.length - 1
                      ? classes.locationDetailContainer
                      : classes.locationDetailContainerTail
                  }
                  data-gid="82334782"
                >
                  {locations.length > 1 && (
                    <div
                      className={classes.locationMultipleTitleContainer}
                      data-gid="52071636"
                    >
                      <Text size="2" weight={500} data-gid="99533566">
                        Location #{index + 1}
                      </Text>
                      <div
                        className={classes.locationMultipleDivider}
                        data-gid="50002293"
                      />
                    </div>
                  )}
                  <ClueDetailsSlideoutMetadataSection
                    title="Actions"
                    data-gid="28501649"
                  >
                    <div
                      className={classes.tagBtnContainer}
                      data-gid="92026025"
                    >
                      <ReadOnlyTooltip
                        verb="tag"
                        bypass={
                          currentExtractionAccessType !==
                          ExtractionAccessTypeEnum.READ_ONLY
                        }
                        data-gid="80392210"
                      >
                        <Button
                          color={isTagged ? 'primary' : 'default'}
                          fullWidth
                          disabled={
                            currentExtractionAccessType ===
                            ExtractionAccessTypeEnum.READ_ONLY
                          }
                          onClick={() => {
                            if (!isTagged) {
                              tagLocationClue({
                                variables: {
                                  input: {
                                    clueId: id,
                                    extractionId: currentExtractionId,
                                  },
                                },
                                refetchQueries: onDashboard
                                  ? [
                                      getPaginatedLocationsByIdsQuery,
                                      allTaggedCluesQuery,
                                    ]
                                  : [getPaginatedLocationsByIdsQuery],
                              }).catch((tagError: Error) => tagError);
                            }
                            if (isTagged) {
                              removeClueTag({
                                variables: {
                                  input: {
                                    clueId: id,
                                    extractionId: currentExtractionId,
                                    clueType: 'LOCATION',
                                  },
                                },
                                refetchQueries: onDashboard
                                  ? [
                                      getPaginatedLocationsByIdsQuery,
                                      allTaggedCluesQuery,
                                    ]
                                  : [getPaginatedLocationsByIdsQuery],
                              }).catch(
                                (removeTagError: Error) => removeTagError,
                              );
                            }
                          }}
                          data-gid="16036169"
                        >
                          {isTagged
                            ? 'Untag (Currently Tagged)'
                            : 'Tag As Important'}
                        </Button>
                      </ReadOnlyTooltip>
                      {isTagged && (
                        <Text size="2" data-gid="92978889">
                          You can view all tagged clues on the Dashboard
                        </Text>
                      )}
                    </div>
                  </ClueDetailsSlideoutMetadataSection>
                  <Notes
                    clueId={id}
                    clueType={ClueTypes.LOCATION}
                    data-gid="52559704"
                  />
                  <ClueDetailsSlideoutMetadataSection
                    title="Basic Details"
                    data-gid="14800155"
                  >
                    {latitude && longitude ? (
                      <>
                        <VisibilitySensor
                          containment={scrollableParent}
                          key={id}
                          partialVisibility
                          data-gid="48474189"
                        >
                          {({ isVisible }) =>
                            isVisible ? (
                              <StaticMap
                                mapboxApiAccessToken={
                                  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                                }
                                mapStyle={
                                  process.env.REACT_APP_MAPBOX_SATELLITE_STYLE
                                }
                                latitude={parseFloat(latitude)}
                                longitude={parseFloat(longitude)}
                                zoom={15}
                                width="100%"
                                height="325px"
                                data-gid="94446379"
                              >
                                <Marker
                                  latitude={parseFloat(latitude)}
                                  longitude={parseFloat(longitude)}
                                  offsetTop={-20}
                                  data-gid="71114500"
                                >
                                  <img
                                    height="10%"
                                    width="10%"
                                    src={publicStaticFileURL('/marker.png')}
                                    alt="map-marker"
                                    data-gid="91722367"
                                  />
                                </Marker>
                              </StaticMap>
                            ) : (
                              <div data-gid="81360615">Loading...</div>
                            )
                          }
                        </VisibilitySensor>
                        <div
                          className={classes.viewLocation}
                          data-gid="10684341"
                        >
                          <LinkButton
                            onClick={() => {
                              if (history.location.pathname !== '/location') {
                                history.push(
                                  `/location${history.location.search}`,
                                );
                              }
                              setLinkedLocation({
                                id,
                                longitude: parseFloat(longitude) || null,
                                latitude: parseFloat(latitude) || null,
                                timestamp: timestamp || '',
                              });
                              locationLink(latitude, longitude);
                            }}
                            data-gid="12452579"
                          >
                            <LocationOnIcon fontSize="small" />
                            View Location
                          </LinkButton>
                        </div>
                      </>
                    ) : undefined}
                    {taggedBy && isTagged && (
                      <ClueDetailsSlideoutMetadataTextItem
                        leftText="Tagged By:"
                        rightText={
                          taggedBy.firstName
                            ? `${taggedBy.firstName} ${taggedBy.lastName || ''}`
                            : taggedBy.email
                        }
                        data-gid="21024727"
                      />
                    )}
                    <ClueDetailsSlideoutMetadataTextItem
                      leftText="Location ID:"
                      rightText={id}
                      data-gid="97411409"
                    />
                    <ClueDetailsSlideoutMetadataTextItem
                      leftText="Location:"
                      rightText={latAndLong}
                      data-gid="40277576"
                    />
                    <ClueDetailsSlideoutMetadataTextItem
                      leftText="Timestamp:"
                      rightText={
                        timestamp
                          ? `${DateTime.fromISO(timestamp).toLocaleString(
                              DateTime.TIME_WITH_SHORT_OFFSET,
                            )} ${DateTime.fromISO(timestamp).toLocaleString(
                              DateTime.DATE_MED,
                            )}`
                          : undefined
                      }
                      data-gid="61573077"
                    />
                    {image && (
                      <>
                        <div data-gid="48040409">
                          <ClueDetailsSlideoutMetadataMediaItem
                            leftText="Image:"
                            thumbnailUrl={thumbnailUrl || ''}
                            messageImageContext={imageContext}
                            previewUrl={previewUrl}
                            imageId={imageId}
                            isLocationMedia
                            storedFileChecksum={storedFileChecksum}
                            data-gid="81727097"
                          />
                        </div>
                        {storedFileChecksum && (
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="SHA256 Hash Value:"
                            rightText={storedFileChecksum}
                            data-gid="45120431"
                          />
                        )}
                      </>
                    )}
                  </ClueDetailsSlideoutMetadataSection>
                  {locationSourceFiles && locationSourceFiles?.length > 0 && (
                    <ClueDetailsSlideoutMetadataSection
                      title="Source Files"
                      key={`sourceFiles-${id}`}
                      collapsed={!sourceFileSectionOpen}
                      onClickCollapse={() => {
                        const clonedMetaDataSections = {
                          ...metaDataSections,
                        };
                        if (clonedMetaDataSections[index]?.sourceFiles) {
                          clonedMetaDataSections[index].sourceFiles = false;
                        } else {
                          clonedMetaDataSections[index] = {
                            ...clonedMetaDataSections[index],
                            sourceFiles: true,
                          };
                        }
                        setMetaDataSections(clonedMetaDataSections);
                      }}
                      data-gid="90793872"
                    >
                      {locationSourceFiles.map((sf) => (
                        <ClueDetailsSlideoutMetadataSubsection
                          key={sf.id}
                          title={sf.name}
                          data-gid="19413718"
                        >
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="File Path:"
                            rightText={`${sf.enclosingDirectory}/${sf.name}`}
                            data-gid="10258758"
                          />
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="SHA256:"
                            rightText={`${sf.checksum}`}
                            data-gid="26714380"
                          />
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="Changed:"
                            rightText={`${DateTime.fromMillis(
                              sf.ctime,
                            ).toLocaleString(
                              DateTime.TIME_WITH_SHORT_OFFSET,
                            )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                              DateTime.DATE_MED,
                            )}`}
                            data-gid="77769357"
                          />
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="Modified:"
                            rightText={`${DateTime.fromMillis(
                              sf.mtime,
                            ).toLocaleString(
                              DateTime.TIME_WITH_SHORT_OFFSET,
                            )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                              DateTime.DATE_MED,
                            )}`}
                            data-gid="30545418"
                          />
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="Last Accessed:"
                            rightText={`${DateTime.fromMillis(
                              sf.atime,
                            ).toLocaleString(
                              DateTime.TIME_WITH_SHORT_OFFSET,
                            )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                              DateTime.DATE_MED,
                            )}`}
                            data-gid="18401514"
                          />
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText="Size:"
                            rightText={`${fileSize(+sf.sizeBytes)}`}
                            data-gid="56707297"
                          />
                        </ClueDetailsSlideoutMetadataSubsection>
                      ))}
                    </ClueDetailsSlideoutMetadataSection>
                  )}
                  {meta && meta?.length > 0 && (
                    <ClueDetailsSlideoutMetadataSection
                      title="Additional Info"
                      collapsed={!additionalInfoSectionOpen}
                      onClickCollapse={() => {
                        const clonedMetaDataSections = {
                          ...metaDataSections,
                        };
                        if (clonedMetaDataSections[index]?.additionalInfo) {
                          clonedMetaDataSections[index].additionalInfo = false;
                        } else {
                          clonedMetaDataSections[index] = {
                            ...clonedMetaDataSections[index],
                            additionalInfo: true,
                          };
                        }
                        setMetaDataSections(clonedMetaDataSections);
                      }}
                      data-gid="31099765"
                    >
                      {meta.length > 0 &&
                        meta.map((metaSet) => (
                          <ClueDetailsSlideoutMetadataTextItem
                            leftText={metaSet?.key || ''}
                            rightText={metaSet?.value || ''}
                            data-gid="17096971"
                          />
                        ))}
                    </ClueDetailsSlideoutMetadataSection>
                  )}
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default LocationClueDetails;
