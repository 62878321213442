import {
  Colors,
  GSMenuListItemProps,
  makeStyles,
  MenuList,
  PageWithSideMenuLayout as CairnPageWithSideMenuLayout,
  Text,
} from '@grayshift/cairn';
import { Snackbar } from '@material-ui/core';
import { History } from '@mui/icons-material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory, useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideout from './ClueDetailsSlideout';
import { ErrorFallback } from './ErrorBoundary';
import ExtractionSelectionModal from './ExtractionSelectionModal';
import ExtractionShareModal from './ExtractionShareModal';
import ExtractionTopNavBar from './ExtractionTopNavBar';
import { getUserInfo } from './graphql/generated/getUserInfo';
import { UserInfo } from './graphql/queries/user';
import {
  clickToolTipAtom,
  clueDetailsSlideoutDataAtom,
  sendToReviewModalControllerAtom,
  sidebarCollapsedAtom,
  tryProModalAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import NoCurrentExtractionPlaceholder from './NoCurrentExtractionPlaceholder';
import ReviewModal from './review/ReviewModal';
import SecondaryTopNavBar from './SecondaryTopNavBar';
import { TryProModal } from './TryProModal';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles(() => ({
  layoutRootContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  appPanesContainer: {
    display: 'flex',
    flex: 1,
  },
  leftPaneContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  leftPaneTopContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },
  rootContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '95%',
    margin: -30, // hack around padding in PageWithSideMenuLayout
    overflow: 'hidden',
  },
  childrenContainer: {
    display: 'flex',
    flex: 1,
    padding: 20,
    paddingTop: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  tryProBannerContainer: {
    position: 'relative',
    zIndex: 100,
  },
  longLogo: {
    display: 'flex',
    width: '80%',
    alignSelf: 'center',
  },
}));

const CTABanner: React.FC = ({ children }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    }}
    data-gid="44108240"
  >
    {children}
  </div>
);

const TrialCTA: React.FC = () => {
  const [, setTryProModalIsOpen] = useRecoilState(tryProModalAtom);
  const { data: userInfo, loading: userLoading } =
    useQueryWithErrorBoundary<getUserInfo>(UserInfo, {
      fetchPolicy: 'cache-and-network',
    });

  if (
    userLoading ||
    !userInfo?.getUserInfo ||
    (!userInfo.getUserInfo.hasActiveTrial &&
      !userInfo.getUserInfo.eligibleForTrial) ||
    userInfo.getUserInfo.entitledAccounts.length > 0
  ) {
    return null;
  }

  const { trialActiveUntil } = userInfo?.getUserInfo;
  const dateDiff = DateTime.fromISO(trialActiveUntil ?? '').diff(
    DateTime.now(),
    ['days', 'hours', 'seconds'],
  );

  if (!userInfo.getUserInfo.hasActiveTrial) {
    return (
      <CTABanner data-gid="57055075">
        <div
          style={{
            border: '1px solid #6ABF46',
            borderRadius: '4px',
            padding: '2px 24px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: 'none',
            backgroundColor: '#EDF5EB',
            color: Colors.blue,
            fontWeight: 500,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          data-gid="35933215"
          onClick={() => setTryProModalIsOpen(true)}
          role="presentation"
          onKeyDown={noop}
        >
          Use select Pro Features FREE for 30 days!
        </div>
      </CTABanner>
    );
  }

  return (
    <CTABanner data-gid="43257885">
      <div
        style={{
          border: '1px solid #6ABF46',
          borderRadius: '4px',
          padding: '2px 24px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: 'none',
          backgroundColor: '#EDF5EB',
          color: Colors.blue,
          fontWeight: 500,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        data-gid="73481290"
      >
        {`Pro Trial active for ${dateDiff.days} days ${dateDiff.hours} hours`}
      </div>
    </CTABanner>
  );
};

const resetQueryParams = (search: string): string => {
  const oldParams = new URLSearchParams(search);

  const newParams = new URLSearchParams();

  const extractionId = oldParams.get('extractionId');

  if (extractionId) {
    newParams.set('extractionId', extractionId);
  }
  return `?${newParams.toString()}`;
};

const Layout: React.FC<{ disableLinks?: boolean }> = ({
  children,
  disableLinks = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [clickToolTip, setClickToolTip] = useRecoilState(clickToolTipAtom);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [currentExtractionId] = useCurrentExtractionId();
  const extraction = useExtraction();
  const [, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const [collapse, setCollapse] = useRecoilState(sidebarCollapsedAtom);

  const extractionLoading = extraction.loading;
  const [, setSendToReviewModalController] = useRecoilState(
    sendToReviewModalControllerAtom,
  );

  useEffect(() => {
    if (!extraction.data) return;
    const openModal = extraction.data?.extractionById?.isReviewEnabled;

    if (openModal === null) {
      setSendToReviewModalController({ isOpen: true, isReplay: false });
    } else {
      setSendToReviewModalController({ isOpen: false, isReplay: false });
    }
    // Ignoring extraction.data dependency because of too often re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    extraction.data?.extractionById?.isReviewEnabled,
    setSendToReviewModalController,
  ]);

  // Uncomment this variable to feature flag any object below off only for production environment
  // const isProduction = process.env.REACT_APP_ENV === 'production';

  const layoutList: Array<GSMenuListItemProps | null> = [
    {
      text: 'Dashboard',
      onClick: () => {
        history.push(`/dashboard${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'Dashboard',
      selected: location.pathname.includes('/dashboard'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: 'Dashboard',
    },
    {
      text: 'Timeline',
      onClick: () => {
        history.push(`/timeline${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'ArrowRightAlt',
      selected: location.pathname.includes('/timeline'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: 'Timeline',
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.messages)
        ? `Messages (${
            extraction.data?.extractionById?.clueCounts?.messages || 0
          })`
        : 'Messages',
      onClick: () => {
        history.push(`/messages${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'QuestionAnswer',
      selected: location.pathname.includes('/messages'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: isNumber(
        extraction.data?.extractionById?.clueCounts?.messages,
      )
        ? `Messages (${
            extraction.data?.extractionById?.clueCounts?.messages || 0
          })`
        : 'Messages',
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.calls)
        ? `Calls (${extraction.data?.extractionById?.clueCounts?.calls || 0})`
        : 'Calls',
      onClick: () => {
        history.push(`/calls${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'Phone',
      selected: location.pathname.includes('/calls'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: isNumber(extraction.data?.extractionById?.clueCounts?.calls)
        ? `Calls (${extraction.data?.extractionById?.clueCounts?.calls || 0})`
        : 'Calls',
    },
    {
      text: isNumber(extraction?.data?.extractionById?.clueCounts?.media)
        ? `Media (${extraction?.data?.extractionById?.clueCounts?.media || 0})`
        : 'Media',
      onClick: () => {
        history.push(`/media${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'Theaters',
      selected: location.pathname.includes('/media'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: isNumber(extraction?.data?.extractionById?.clueCounts?.media)
        ? `Media (${extraction?.data?.extractionById?.clueCounts?.media || 0})`
        : 'Media',
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.locations)
        ? `Location (${
            extraction.data?.extractionById?.clueCounts?.locations || 0
          })`
        : 'Location',
      onClick: () => {
        history.push(`/location${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'LocationOn',
      selected: location.pathname.includes('/location'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: isNumber(
        extraction.data?.extractionById?.clueCounts?.locations,
      )
        ? `Location (${
            extraction.data?.extractionById?.clueCounts?.locations || 0
          })`
        : 'Location',
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.contacts)
        ? `Contacts (${
            extraction.data?.extractionById?.clueCounts?.contacts || 0
          })`
        : 'Contacts',
      onClick: () => {
        history.push(`/contacts${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'Person',
      selected: location.pathname.includes('/contacts'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: extraction.data?.extractionById?.clueCounts?.contacts
        ? `Contacts (${extraction.data?.extractionById?.clueCounts?.contacts})`
        : 'Contacts',
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.installedApps)
        ? `Installed Apps (${
            extraction.data?.extractionById?.clueCounts?.installedApps || 0
          })`
        : 'Installed Apps',
      onClick: () => {
        history.push(
          `/installedApps${resetQueryParams(history.location.search)}`,
        );
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'PhoneIphone',
      toolTip: collapse,
      toolTipText: isNumber(
        extraction.data?.extractionById?.clueCounts?.installedApps,
      )
        ? `Installed Apps (${
            extraction.data?.extractionById?.clueCounts?.installedApps || 0
          })`
        : 'Installed Apps',
      customText({ isSelected, text }) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            data-gid="36333107"
          >
            <Text
              size="2"
              weight={500}
              color={isSelected ? 'primary' : 'textPrimary'}
              data-gid="39794156"
            >
              {text}
            </Text>
          </div>
        );
      },
      selected: location.pathname.includes('/installedApps'),
      disabled: disableLinks,
    },
    {
      text: isNumber(extraction.data?.extractionById?.clueCounts?.memos)
        ? `Memos (${extraction.data?.extractionById?.clueCounts?.memos || 0})`
        : 'Memos',
      onClick: () => {
        history.push(`/memos${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      customIcon({ isSelected }) {
        return (
          <NoteAltIcon
            data-gid="64815924"
            style={{ color: isSelected ? Colors.blue : Colors.textSecondary }}
          />
        );
      },
      customText({ isSelected, text }) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            data-gid="46035784"
          >
            <Text
              size="2"
              weight={500}
              color={isSelected ? 'primary' : 'textPrimary'}
              data-gid="82322642"
            >
              {text}
            </Text>
          </div>
        );
      },
      selected: location.pathname.includes('/memos'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: isNumber(extraction.data?.extractionById?.clueCounts?.memos)
        ? `Memos (${extraction.data?.extractionById?.clueCounts?.memos || 0})`
        : 'Memos',
    },
    {
      text: isNumber(
        extraction.data?.extractionById?.clueCounts?.browserHistory,
      )
        ? `Browser History (${
            extraction.data?.extractionById?.clueCounts?.browserHistory || 0
          })`
        : 'Browser History',
      onClick: () => {
        history.push(
          `/browserHistory${resetQueryParams(history.location.search)}`,
        );
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      customIcon({ isSelected }) {
        return (
          <History
            style={{ color: isSelected ? Colors.blue : Colors.textSecondary }}
            data-gid="41580668"
          />
        );
      },
      customText({ isSelected, text }) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            data-gid="14284209"
          >
            <Text
              size="2"
              weight={500}
              color={isSelected ? 'primary' : 'textPrimary'}
              data-gid="45814991"
            >
              {text}
            </Text>
          </div>
        );
      },
      selected: location.pathname.includes('/browserHistory'),
      disabled: disableLinks,
    },
    {
      text: 'Report',
      onClick: () => {
        history.push(`/report${resetQueryParams(history.location.search)}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      },
      icon: 'Assessment',
      customText({ isSelected, text }) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            data-gid="46035784"
          >
            <Text
              size="2"
              weight={500}
              color={isSelected ? 'primary' : 'textPrimary'}
              data-gid="82322642"
            >
              {text}
            </Text>
          </div>
        );
      },
      selected: location.pathname.includes('/report'),
      disabled: disableLinks,
      toolTip: collapse,
      toolTipText: 'Report',
    },
  ];

  // Purpose of the filtered list is to be able to quickly feature flag off a left nav list item in production only
  const productionOnlyList: GSMenuListItemProps[] = [];
  layoutList.forEach((item) => {
    if (item !== null) productionOnlyList.push(item);
  });

  return (
    <div className={classes.layoutRootContainer} data-gid="44005853">
      {clickToolTip && (
        <Snackbar
          open={!!clickToolTip}
          autoHideDuration={30_000}
          onClose={() => setClickToolTip(null)}
          message={clickToolTip.message}
          style={{
            position: 'absolute',
            top: clickToolTip.clientY,
            left: clickToolTip.clientX,
            padding: 0,
            margin: 0,
            height: 100,
          }}
          data-gid="82469905"
        />
      )}
      <ExtractionTopNavBar data-gid="11374253" />
      <div className={classes.tryProBannerContainer} data-gid="76017455">
        <TrialCTA data-gid="44844975" />
      </div>
      <SecondaryTopNavBar
        extractionLoading={extractionLoading}
        data-gid="13641552"
      />
      <div className={classes.appPanesContainer} data-gid="95001857">
        <CairnPageWithSideMenuLayout
          collapse={setCollapse}
          isCollapsed={collapse}
          iconColor="#1665D8"
          width="200px"
          menuContent={
            <div className={classes.leftPaneContainer} data-gid="34944820">
              <div className={classes.leftPaneTopContent} data-gid="51890496">
                <MenuList listItems={productionOnlyList} data-gid="86133350" />
              </div>
            </div>
          }
          pageContent={
            currentExtractionId ||
            location.pathname.includes('/user-management') ||
            location.pathname.includes('/audit-log') ? (
              <div className={classes.rootContentContainer} data-gid="77362449">
                <div className={classes.childrenContainer} data-gid="49795744">
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense
                      fallback={() => <ScaleLoader data-gid="97760376" />}
                      data-gid="85456443"
                    >
                      {children}
                    </Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            ) : (
              <NoCurrentExtractionPlaceholder data-gid="72207835" />
            )
          }
          data-gid="53784937"
        />
        <ClueDetailsSlideout data-gid="70268661" />
        <ExtractionSelectionModal data-gid="95390423" />
        <ExtractionShareModal data-gid="46374181" />
        <ReviewModal
          extraction={extraction.data?.extractionById}
          data-gid="85356239"
        />
        <TryProModal data-gid="29150069" />
      </div>
    </div>
  );
};

export { Layout };
