export enum renderedMediaContext {
  'NCMEC_MATCH',
  'AI_CSAM_MATCH',
  'AI_NUDITY_MATCH',
  'FLAGGED_EXPLICIT',
  'ACTUAL_IMAGE',
}

function determineMediaRenderContext(
  ncmecMatch: boolean | null,
  AiCsam: boolean,
  containsNudity: boolean,
  maskCsam: boolean,
  isExplicit: boolean,
): renderedMediaContext {
  if (isExplicit) {
    return renderedMediaContext.FLAGGED_EXPLICIT;
  }
  if (maskCsam && !isExplicit) {
    if (ncmecMatch) return renderedMediaContext.NCMEC_MATCH;
    if (AiCsam) return renderedMediaContext.AI_CSAM_MATCH;
    if (containsNudity) return renderedMediaContext.AI_NUDITY_MATCH;
  } else {
    if (containsNudity) return renderedMediaContext.AI_NUDITY_MATCH;
    return renderedMediaContext.ACTUAL_IMAGE;
  }
  return renderedMediaContext.ACTUAL_IMAGE;
}

export default determineMediaRenderContext;
