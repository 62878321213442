/* eslint-disable react/require-default-props */
import { makeStyles } from '@grayshift/cairn';
import { Label, LabelOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import noop from 'lodash/noop';
import React, { CSSProperties, useState } from 'react';

import { useExtraction } from './useExtraction';

interface TaggableProps {
  isTagged: boolean;
  hovered?: boolean;
  borderWhenTagged?: boolean;
  onToggle?: (tagged: boolean) => void;
  position?: 'relative' | 'absolute';
}

const useStyles = makeStyles({
  tagLabel: {
    '&:hover': {
      borderRadius: '7px',
    },
  },
});

export const Taggable: React.FC<TaggableProps> = ({
  isTagged,
  onToggle = noop,
  position = 'relative',
  hovered = false,
  borderWhenTagged = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHovered] = useState(false);
  const { readOnlyMode } = useExtraction();
  const classes = useStyles();

  const toggleTag: React.MouseEventHandler<HTMLButtonElement> | undefined = (
    e,
  ): void => {
    e.stopPropagation();
    if (onToggle) {
      onToggle(!isTagged);
    }
  };

  const getPositionStyle = (): CSSProperties => {
    if (position === 'absolute') {
      return {
        position: 'absolute',
        top: '10px',
        right: '10px',
      };
    }
    return {};
  };

  const getToolTipText = (): string => {
    if (readOnlyMode) {
      return 'Cannot tag in read only mode';
    }

    return isTagged ? 'Tagged' : 'Untagged';
  };

  return (
    <Box
      sx={{
        position: 'relative',
        background: 'white',
        // boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.2)',
        border:
          hovered || borderWhenTagged
            ? '1px solid rgb(216, 220, 230)'
            : '1px solid transparent',
        borderRadius: '7px',
        ...getPositionStyle(),
      }}
      data-gid="13106855"
    >
      <Tooltip title={getToolTipText()} data-gid="12457500">
        <IconButton
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={toggleTag}
          data-gid="67492385"
          disabled={readOnlyMode}
          className={classes.tagLabel}
        >
          {isTagged ? (
            <Label data-gid="48563597" />
          ) : (
            <LabelOutlined data-gid="20703256" />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Taggable;
