import { useMutation } from '@apollo/client';
import { Button, Colors, makeStyles, Text } from '@grayshift/cairn';
import { Restore } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';

import { ExtractionAccessRequestStatusByUserId } from './graphql/generated/ExtractionAccessRequestStatusByUserId';
import { userInfoAtom } from './lib/atoms';
import {
  GetExtractionAccessRequestStatus,
  UpdateAccessRequestStatustMutation,
} from './lib/extractionAccessRequest';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  centeredFlex: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  requestContainer: {
    display: 'flex',
    width: '28%',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '25px',
    border: `1px solid ${Colors.divider}`,
    borderRadius: '5px',
  },
  requestHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  requestBody: {
    paddingTop: '20px',
    padding: '20px 0 0 5px',
  },
});

export const RequestAccess: React.FC = () => {
  const classes = useStyles();
  const [extractionId] = useExtractionId();
  const [userInfo] = useRecoilState(userInfoAtom);
  const { id: userId } = userInfo;

  const { data, loading } =
    useQueryWithErrorBoundary<ExtractionAccessRequestStatusByUserId>(
      GetExtractionAccessRequestStatus,
      {
        variables: {
          extractionId,
          userId,
        },
        skip: !userId,
      },
    );

  const [updateExtractionAccessStatus] = useMutation(
    UpdateAccessRequestStatustMutation,
    {
      variables: { input: { extractionId, userId, status: 'REQUESTED' } },
      refetchQueries: [GetExtractionAccessRequestStatus],
    },
  );

  const requestAccess = async (): Promise<void> => {
    if (extractionId && process.env.REACT_APP_ENV === 'production')
      window.aptrinsic('track', 'Request Access', {
        extractionId,
        userId,
        status: 'REQUESTED',
      });
    await updateExtractionAccessStatus();
  };

  const { accessRequestStatus } =
    data?.extractionAccessRequestStatusByUserId ?? {};

  if (loading) {
    return (
      <div className={classes.centeredFlex} data-gid="24905042">
        <ScaleLoader loading color={Colors.blue} data-gid="96861390" />
      </div>
    );
  }
  const color = 'textPrimary';
  const padding = '3px 0 0 10px';

  if (accessRequestStatus === 'REQUESTED') {
    return (
      <div className={classes.centeredFlex} data-gid="24905042">
        <div className={classes.requestContainer} data-gid="89340350">
          <div className={classes.requestHeader} data-gid="29990398">
            <LockClockIcon
              fontSize="large"
              color="primary"
              data-gid="24278578"
            />
            <Text
              size="8"
              weight={700}
              style={{
                padding,
              }}
              color={color}
              data-gid="48619995"
            >
              Access Requested
            </Text>
          </div>
          <div className={classes.requestBody} data-gid="59827287">
            <Text weight={500} color="inherit" data-gid="25174949">
              Your request to access this extraction is pending. We&apos;ll let
              you know when access has been granted.
            </Text>
          </div>
          <div
            style={{ paddingTop: '50px', marginLeft: 'auto' }}
            data-gid="27909909"
          >
            <Button
              role="presentation"
              onClick={() => requestAccess()}
              disabled
              data-gid="95064851"
            >
              <Restore style={{ marginRight: '4px' }} data-gid="15383669" />
              Request Access
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (accessRequestStatus === 'DENIED') {
    return (
      <div className={classes.centeredFlex} data-gid="24905042">
        <div className={classes.requestContainer} data-gid="10397204">
          <div className={classes.requestHeader} data-gid="71013405">
            <NoEncryptionIcon
              fontSize="large"
              color="error"
              data-gid="48136975"
            />
            <Text
              size="8"
              weight={700}
              style={{
                padding,
              }}
              color={color}
              data-gid="21344015"
            >
              Access Denied
            </Text>
          </div>
          <div className={classes.requestBody} data-gid="28627710">
            <Text weight={500} color="inherit" data-gid="83037386">
              Your request to access this extraction has been denied.
            </Text>
          </div>
          <div className={classes.requestBody} data-gid="87818363">
            <Text weight={500} color="inherit" data-gid="97393385">
              If you believe this to be an error, please contact an extraction
              owner directly.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.centeredFlex} data-gid="24905042">
      <div className={classes.requestContainer} data-gid="86624628">
        <div className={classes.requestHeader} data-gid="34468885">
          <LockIcon fontSize="large" color="primary" data-gid="60887964" />
          <Text
            size="8"
            weight={700}
            style={{
              padding,
            }}
            color={color}
            data-gid="50943101"
          >
            Request Access
          </Text>
        </div>
        <div className={classes.requestBody} data-gid="75654644">
          <Text weight={500} color="inherit" data-gid="55986004">
            You don&apos;t have permission to view this resource. You can
            request access below, and we&apos;ll let you know when an owner has
            granted access.
          </Text>
        </div>
        <div
          style={{ paddingTop: '50px', marginLeft: 'auto' }}
          data-gid="46118984"
        >
          <Button
            color="primary"
            role="presentation"
            onClick={() => requestAccess()}
            data-gid="46038172"
          >
            <Restore style={{ marginRight: '4px' }} data-gid="15383669" />
            Request Access
          </Button>
        </div>
      </div>
    </div>
  );
};
