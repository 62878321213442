import { useMutation } from '@apollo/client';
import { Button, Colors, LinkButton, makeStyles, Text } from '@grayshift/cairn';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import fileSize from 'filesize';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import {
  contactByIdAndExtractionId,
  contactByIdAndExtractionId_contactByIdAndExtractionId_attributes_MiscellaneousAttribute,
} from './graphql/generated/contactByIdAndExtractionId';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import {
  removeClueTagMutation,
  tagContactClueMutation,
} from './graphql/mutations';
import { contactByIdAndExtractionIdQuery } from './graphql/queries';
import { StaticAppIcon } from './helpers/AppIcons';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import calculateCallDuration from './lib/calculateCallDuration';
import convertCamelToSpaceString from './lib/convertCamelToSpaceString';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { usePageForMessage } from './usePageForMessage';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  chatBubbleContainer: {
    margin: '15px 0 25px 0',
  },
  chatBubbleSenderName: {
    marginLeft: 7,
  },
  chatBubble: {
    display: 'flex',
    borderRadius: 15,
    padding: '7px 14px',
    backgroundColor: Colors.lightDivider,
    justifyContent: 'center',
    alignItems: 'center',
  },
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  centeredBtn: {
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'end',
    paddingTop: '10px',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
    },
  },
});

interface MessageCount {
  count: number;
  platform: string;
}

interface ContactProps {
  clueId: string;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

/* eslint-disable sonarjs/cognitive-complexity */
const ContactClueDetails: React.FC<ContactProps> = ({ clueId }) => {
  const { navigateToMessageInThread } = usePageForMessage();
  const classes = useStyles();
  const location = useLocation();
  const [currentExtractionId] = useCurrentExtractionId();
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const { loading, error, data } =
    useQueryWithErrorBoundary<contactByIdAndExtractionId>(
      contactByIdAndExtractionIdQuery,
      {
        variables: { contactById: clueId, extractionId: currentExtractionId },
        skip: !currentExtractionId,
      },
    );

  const [tagContactClue] = useMutation(tagContactClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  if (error) {
    return null;
  }

  const contactData = data?.contactByIdAndExtractionId;

  const allSourceFiles: sourceFileProps[] = uniqBy(
    [
      ...(contactData?.sourceFiles || []),
      ...(contactData?.mostRecentMessageSent?.sourceFiles || []),
      ...flatten(
        contactData?.attributes
          ?.filter((attr) => 'sourceFiles' in attr)
          .map((attr) => attr.sourceFiles) || [],
      ),
    ],
    'id',
  );

  const messageCounts = contactData?.platformMessageCounts.map(
    (value: MessageCount) => {
      if (value.platform === '__total') {
        return ['Total', value.count];
      }
      return [value.platform, value.count];
    },
  );

  const callCounts = contactData?.platformCallCounts.map(
    (value: MessageCount) => {
      if (value.platform === '__total') {
        return ['Total', value.count];
      }
      return [value.platform, value.count];
    },
  );

  const isTagged = data?.contactByIdAndExtractionId?.taggedAt !== null;
  const taggedBy = data?.contactByIdAndExtractionId?.taggedBy;

  const miscAttributes = contactData?.attributes.filter(
    (
      attr,
    ): attr is contactByIdAndExtractionId_contactByIdAndExtractionId_attributes_MiscellaneousAttribute =>
      // eslint-disable-next-line no-underscore-dangle
      attr.__typename === 'MiscellaneousAttribute',
  );

  const onDashboard = location.pathname === '/dashboard';

  return (
    <ClueDetailsSlideoutContentWrapper loading={loading} data-gid="95198889">
      <div className={classes.metadataContainer} data-gid="53198993">
        <ClueDetailsSlideoutMetadataSection title="Actions" data-gid="91881386">
          <div className={classes.tagBtnContainer} data-gid="84353207">
            <ReadOnlyTooltip
              verb="tag"
              bypass={
                currentExtractionAccessType !==
                ExtractionAccessTypeEnum.READ_ONLY
              }
              data-gid="89966211"
            >
              <Button
                color={isTagged ? 'primary' : 'default'}
                disabled={
                  currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                fullWidth
                onClick={() => {
                  if (!isTagged) {
                    tagContactClue({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                        },
                      },
                      refetchQueries: onDashboard
                        ? [contactByIdAndExtractionIdQuery, allTaggedCluesQuery]
                        : [contactByIdAndExtractionIdQuery],
                    }).catch((tagError: Error) => tagError);
                  }
                  if (isTagged) {
                    removeClueTag({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                          clueType: 'CONTACT',
                        },
                      },
                      refetchQueries: onDashboard
                        ? [contactByIdAndExtractionIdQuery, allTaggedCluesQuery]
                        : [contactByIdAndExtractionIdQuery],
                    }).catch((removeTagError: Error) => removeTagError);
                  }
                }}
                data-gid="20065452"
              >
                {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
              </Button>
            </ReadOnlyTooltip>
            {isTagged && (
              <Text size="2" data-gid="45078687">
                You can view all tagged clues on the Dashboard
              </Text>
            )}
          </div>
        </ClueDetailsSlideoutMetadataSection>
        <Notes
          clueId={clueId}
          clueType={ClueTypes.CONTACT}
          data-gid="56995685"
        />
        <ClueDetailsSlideoutMetadataSection
          title="Basic Details"
          data-gid="13722814"
        >
          {taggedBy && isTagged && (
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Tagged By:"
              rightText={
                taggedBy.firstName && taggedBy.lastName
                  ? `${taggedBy.firstName} ${taggedBy.lastName}`
                  : taggedBy.email
              }
              data-gid="23520643"
            />
          )}
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Clue ID:"
            rightText={clueId}
            data-gid="63408132"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Contact Name:"
            rightText={
              contactData?.metacontact
                ? contactData.metacontact.primaryDisplayName
                : contactData?.primaryDisplayName
            }
            data-gid="85697875"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Phone Owner:"
            rightText={contactData?.isDeviceOwner ? 'Yes' : 'No'}
            data-gid="68792185"
          />
        </ClueDetailsSlideoutMetadataSection>
        {messageCounts && messageCounts?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Message Counts By Platform"
            data-gid="35804215"
          >
            <TableContainer style={{ marginTop: '7px' }} data-gid="22829515">
              <Table
                sx={{ width: 320 }}
                size="small"
                aria-label="message counts by platform"
                data-gid="34373958"
              >
                <TableHead
                  sx={{ background: Colors.divider }}
                  data-gid="10007964"
                >
                  <TableRow data-gid="78468830">
                    <TableCell data-gid="87543715">Total Messages</TableCell>
                    <TableCell data-gid="22731933">
                      {messageCounts[1][1]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-gid="13419697">
                  {messageCounts.map((msg) => {
                    if (msg[0] !== 'Total') {
                      return (
                        <TableRow key={msg[0]} data-gid="37898851">
                          <TableCell data-gid="90945609">
                            <div
                              style={{ display: 'flex', gap: '10px' }}
                              data-gid="31412295"
                            >
                              <StaticAppIcon
                                displayName={(msg[0] as string) ?? 'default'}
                                height="20px"
                                width="20px"
                              />
                              <div data-gid="75058678">{msg[0]}</div>
                            </div>
                          </TableCell>
                          <TableCell data-gid="68068367">{msg[1]}</TableCell>
                        </TableRow>
                      );
                    }
                    return <></>;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ClueDetailsSlideoutMetadataSection>
        )}{' '}
        {contactData?.mostRecentMessageSent?.textContent && (
          <ClueDetailsSlideoutMetadataSection
            title="Most Recent Message"
            data-gid="46088101"
          >
            <div className={classes.chatBubbleContainer} data-gid="91718446">
              <div className={classes.chatBubbleSenderName} data-gid="56129392">
                <Text size="2" color="textSecondary" data-gid="15496386">
                  {
                    contactData?.mostRecentMessageSent?.sender
                      .primaryDisplayName
                  }
                </Text>
              </div>
              <div className={classes.chatBubble} data-gid="86221356">
                {contactData?.mostRecentMessageSent?.textContent}
              </div>
              <div className={classes.centeredBtn} data-gid="88704510">
                <LinkButton
                  onClick={async () => {
                    if (
                      data?.contactByIdAndExtractionId?.mostRecentMessageSent
                    ) {
                      await navigateToMessageInThread({
                        messageId:
                          data?.contactByIdAndExtractionId
                            ?.mostRecentMessageSent?.id,
                        messageThreadId:
                          data?.contactByIdAndExtractionId
                            ?.mostRecentMessageSent?.thread?.id,
                      });
                    }
                  }}
                  data-gid="68112393"
                >
                  <QuestionAnswerIcon
                    sx={{ marginTop: '4px' }}
                    fontSize="small"
                    data-gid="51190232"
                  />
                  View in Messages
                </LinkButton>
              </div>
            </div>
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Platform:"
              rightText={contactData?.mostRecentMessageSent?.app?.displayName}
              data-gid="50440867"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Date:"
              rightText={`${DateTime.fromISO(
                contactData?.mostRecentMessageSent?.timestamp,
              ).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET,
              )} ${DateTime.fromISO(
                contactData?.mostRecentMessageSent?.timestamp,
              ).toLocaleString(DateTime.DATE_MED)}`}
              data-gid="77016080"
            />
          </ClueDetailsSlideoutMetadataSection>
        )}
        {callCounts && callCounts?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Call Counts By Platform"
            data-gid="35804215"
          >
            <TableContainer style={{ marginTop: '7px' }} data-gid="39402582">
              <Table
                sx={{ width: 320 }}
                size="small"
                aria-label="call counts by platform"
                data-gid="87384521"
              >
                <TableHead
                  sx={{ background: Colors.divider }}
                  data-gid="74872606"
                >
                  <TableRow data-gid="76953997">
                    <TableCell data-gid="35255661">Total Calls</TableCell>
                    <TableCell data-gid="77470788">
                      {callCounts[1][1]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-gid="17314222">
                  {callCounts.map((call) => {
                    if (call[0] !== 'Total') {
                      return (
                        <TableRow key={call[0]} data-gid="21177169">
                          <TableCell data-gid="51991371">
                            <div
                              style={{ display: 'flex', gap: '10px' }}
                              data-gid="54740062"
                            >
                              <StaticAppIcon
                                displayName={(call[0] as string) ?? 'default'}
                                height="20px"
                                width="20px"
                              />
                              <div data-gid="87187139">{call[0]}</div>
                            </div>
                          </TableCell>
                          <TableCell data-gid="47395946">{call[1]}</TableCell>
                        </TableRow>
                      );
                    }
                    return <></>;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ClueDetailsSlideoutMetadataSection>
        )}
        {contactData?.mostRecentCallSent && (
          <ClueDetailsSlideoutMetadataSection
            title="Most Recent Call"
            data-gid="46088101"
          >
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Platform:"
              rightText={
                contactData?.mostRecentCallSent?.app?.displayName ??
                contactData?.mostRecentCallSent?.appBundleId
              }
              data-gid="50440867"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Duration:"
              rightText={
                contactData?.mostRecentCallSent?.duration ||
                contactData?.mostRecentCallSent?.duration === 0
                  ? calculateCallDuration(
                      contactData?.mostRecentCallSent?.duration,
                    )
                  : 'Unknown'
              }
              data-gid="94498075"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Date:"
              rightText={`${DateTime.fromISO(
                contactData?.mostRecentCallSent?.timestamp,
              ).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET,
              )} ${DateTime.fromISO(
                contactData?.mostRecentCallSent?.timestamp,
              ).toLocaleString(DateTime.DATE_MED)}`}
              data-gid="77016080"
            />
          </ClueDetailsSlideoutMetadataSection>
        )}
        {miscAttributes && miscAttributes?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Additional Info"
            data-gid="68216975"
          >
            {miscAttributes.map(
              (
                attr: contactByIdAndExtractionId_contactByIdAndExtractionId_attributes_MiscellaneousAttribute,
              ) => (
                <ClueDetailsSlideoutMetadataTextItem
                  leftText={`${convertCamelToSpaceString(attr.key || '')}:`}
                  rightText={attr.value}
                  data-gid="43964034"
                />
              ),
            )}
          </ClueDetailsSlideoutMetadataSection>
        )}
        {allSourceFiles && allSourceFiles?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Source Files"
            collapsed={sourcefileSectionCollapsed}
            onClickCollapse={() =>
              setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
            }
            data-gid="32229761"
          >
            {allSourceFiles.map((sf) => (
              <ClueDetailsSlideoutMetadataSubsection
                title={sf.name}
                data-gid="86322821"
              >
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="File Path:"
                  rightText={`${sf.enclosingDirectory}/${sf.name}`}
                  data-gid="78138764"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="SHA256:"
                  rightText={`${sf.checksum}`}
                  data-gid="38419906"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Changed:"
                  rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="40650007"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Modified:"
                  rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="61160267"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Last Accessed:"
                  rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="42117678"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Size:"
                  rightText={`${fileSize(+sf.sizeBytes)}`}
                  data-gid="22096686"
                />
              </ClueDetailsSlideoutMetadataSubsection>
            ))}
          </ClueDetailsSlideoutMetadataSection>
        )}
      </div>
    </ClueDetailsSlideoutContentWrapper>
  );
};
/* eslint-enable sonarjs/cognitive-complexity */
export default ContactClueDetails;
