import { gql } from '@apollo/client';

export const AuditLogUserLogoutEventMutation = gql`
  mutation UserLogoutAuditLogEvent {
    createAuditLogEvent(input: { action: USER_LOGOUT, data: {} }) {
      auditLog {
        id
      }
    }
  }
`;

export const AuditLogUserLoginEventMutation = gql`
  mutation UserLoginAuditLogEvent {
    createAuditLogEvent(input: { action: USER_LOGIN, data: {} }) {
      auditLog {
        id
      }
    }
  }
`;

export const EXTRACTION_AUDIT_LOG_QUERY = gql`
  query ExtractionAuditLogs($page: Int, $pageSize: Int) {
    extractionAuditLogs(page: $page, pageSize: $pageSize) {
      edges {
        node {
          id
          actor {
            id
            firstName
            lastName
            email
            role
          }
          extraction {
            id
            evidenceId
          }
          action
          data

          recordCreatedAt
          recordUpdatedAt
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;

export const GLOBAL_AUDIT_LOG_QUERY = gql`
  query GlobalAuditLogs($page: Int, $pageSize: Int) {
    globalAuditLogs(page: $page, pageSize: $pageSize) {
      edges {
        page
        pageSize
        node {
          id
          actor {
            id
            firstName
            lastName
            email
            role
          }
          action
          data

          recordCreatedAt
          recordUpdatedAt
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;
