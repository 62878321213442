/* eslint-disable security/detect-unsafe-regex */

import { useMutation } from '@apollo/client';
import {
  Button,
  Colors,
  LinkButton,
  makeStyles,
  Text,
  Tooltip,
} from '@grayshift/cairn';
import { ContentCopy, Difference } from '@mui/icons-material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import fileSize from 'filesize';
import * as linkify from 'linkifyjs';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataMediaItem from './ClueDetailsSlideoutMetadataMediaItem';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import { messageById } from './graphql/generated/messageById';
import {
  removeMessagesClueTagsMutation,
  tagMessagesClueMutation,
} from './graphql/mutations';
import {
  getAllMessagesByMessageThreadIdQuery,
  messageByIdQuery,
} from './graphql/queries';
import {
  currentExtractionAccessTypeAtom,
  singleMessageThreadAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { publicStaticFileURL } from './lib/publicFile';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { usePageForMessage } from './usePageForMessage';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  chatBubbleContainer: {
    margin: '15px 0 25px 0',
  },
  chatBubbleSenderName: {
    display: 'flex',
    marginLeft: 7,
  },
  chatBubble: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    padding: '7px 14px',
    backgroundColor: Colors.lightDivider,
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  sentByOwner: {
    color: Colors.white,
    background: '#0e8beb',
  },
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  viewMessage: {
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'end',
    paddingTop: '5px',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
    },
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  imageAttached: {
    display: 'flex',
    paddingBottom: '1px',
  },
  text: {
    display: 'flex',
    textAlign: 'center',
  },
});

interface messageProps {
  clueId: string;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const MessageClueDetails: React.FC<messageProps> = ({ clueId }) => {
  const { navigateToMessageInThread } = usePageForMessage();
  const classes = useStyles();
  const location = useLocation();
  const [currentExtractionId] = useCurrentExtractionId();
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );

  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [clueMetaDataSectionCollapsed, setClueMetaDataSectionCollapsed] =
    useState(true);
  const { loading, error, data } = useQueryWithErrorBoundary<messageById>(
    messageByIdQuery,
    {
      variables: { messageById: clueId },
    },
  );

  const [tagMessagesClue, { loading: tagLoading }] = useMutation(
    tagMessagesClueMutation,
  );
  const [removeMessagesClueTags, { loading: untagLoading }] = useMutation(
    removeMessagesClueTagsMutation,
  );
  const [, setSingleMessageThread] = useRecoilState(singleMessageThreadAtom);

  const [linksDetected, setLinksDetected] = useState<string[]>([]);

  const [tooltip, setTooltip] = useState('Copy Link');

  const [copiedUrl, setCopiedUrl] = useState<string | null>(null);

  useEffect(() => {
    if (data?.messageById?.textContent) {
      setLinksDetected(
        linkify
          .find(data?.messageById.textContent || '')
          .map((link) => link.value),
      );
    }
  }, [data]);

  const handleBubbleClick = async (url: string): Promise<void> => {
    setCopiedUrl(url);
    setTooltip(`Copied: ${url || ''}`);
    await navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopiedUrl(null);
      setTooltip('Copy Link');
    }, 1500);
  };

  if (error) {
    return null;
  }

  const messageData = data?.messageById;
  const clueMetaData = messageData?.meta;

  const allSourceFiles: sourceFileProps[] = uniqBy(
    [
      ...(messageData?.sender?.sourceFiles || []),
      ...(messageData?.thread?.sourceFiles || []),
      ...(messageData?.sourceFiles || []),
    ],
    'id',
  );

  const isTagged = data?.messageById?.taggedAt;
  const images = messageData?.media.map((image) => {
    /* eslint no-underscore-dangle: 0 */
    if (image.__typename === 'Image') {
      return (
        <img
          key={image.id}
          src={
            image.thumbnailUrl || publicStaticFileURL('//placeholder-image.png')
          }
          width="99%"
          alt="Attachment Present"
          data-gid="86886285"
        />
      );
    }
    return null;
  });

  const numAttachments = messageData?.media && messageData?.media.length;

  const onDashboard = location.pathname === '/dashboard';

  const isRead = clueMetaData?.find((val): boolean => val?.key === 'isRead');
  const readDate = clueMetaData?.find(
    (val): boolean => val?.key === 'dateRead',
  );

  const textContent = messageData?.textContent?.replace(
    /[\u{0080}-\u{10FFFF}]/gu,
    '',
  );

  // PSA: Firefox cannot handle datestring with dashes - Chrome can parse date of with or without
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Invalid_date
  let cleanReadDate;
  if (readDate) {
    cleanReadDate = readDate?.value.replaceAll(/[^\d:A-Z]/g, ' ');
  }

  return (
    <ClueDetailsSlideoutContentWrapper loading={loading} data-gid="12575958">
      <div className={classes.metadataContainer} data-gid="74372881">
        <ClueDetailsSlideoutMetadataSection title="Actions" data-gid="39065349">
          <div className={classes.tagBtnContainer} data-gid="55858826">
            <ReadOnlyTooltip
              verb="tag"
              bypass={
                currentExtractionAccessType !==
                ExtractionAccessTypeEnum.READ_ONLY
              }
              data-gid="42780032"
            >
              <Button
                color={isTagged ? 'primary' : 'default'}
                fullWidth
                disabled={
                  currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                loading={tagLoading || untagLoading}
                onClick={() => {
                  if (!isTagged) {
                    tagMessagesClue({
                      variables: {
                        input: {
                          ids: [clueId],
                          extractionId: currentExtractionId,
                        },
                      },
                      refetchQueries: onDashboard
                        ? [messageByIdQuery, allTaggedCluesQuery]
                        : [
                            messageByIdQuery,
                            getAllMessagesByMessageThreadIdQuery,
                          ],
                    }).catch((tagError: Error) => tagError);
                  }
                  if (isTagged) {
                    removeMessagesClueTags({
                      variables: {
                        input: {
                          ids: [clueId],
                          extractionId: currentExtractionId,
                        },
                      },
                      refetchQueries: onDashboard
                        ? [messageByIdQuery, allTaggedCluesQuery]
                        : [
                            messageByIdQuery,
                            getAllMessagesByMessageThreadIdQuery,
                          ],
                    }).catch((removeTagError: Error) => removeTagError);
                  }
                }}
                data-gid="37563341"
              >
                {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
              </Button>
            </ReadOnlyTooltip>
            {isTagged && (
              <Text size="2" data-gid="95196058">
                You can view all tagged clues on the Dashboard
              </Text>
            )}
          </div>
        </ClueDetailsSlideoutMetadataSection>
        <Notes
          clueId={clueId}
          clueType={ClueTypes.MESSAGE}
          data-gid="33378971"
        />
        <ClueDetailsSlideoutMetadataSection
          title="Basic Details"
          data-gid="52543244"
        >
          <div className={classes.chatBubbleContainer} data-gid="83211585">
            <div className={classes.chatBubbleSenderName} data-gid="51620694">
              <Text size="2" color="textSecondary" data-gid="61233413">
                {messageData?.sender.metacontact
                  ? messageData?.sender.metacontact.primaryDisplayName
                  : messageData?.sender.primaryDisplayName}
              </Text>
              {messageData?.sender && messageData?.sentByOwner ? (
                <CallMadeIcon
                  sx={{
                    margin: '4px 0 0 4px',
                    fontSize: '12px',
                    color: '#9EA0A5',
                  }}
                  data-gid="96110010"
                />
              ) : (
                <CallReceivedIcon
                  sx={{
                    margin: '4px 0 0 4px',
                    fontSize: '12px',
                    color: '#9EA0A5',
                  }}
                  data-gid="81478633"
                />
              )}
            </div>
            <div
              className={classnames(classes.chatBubble, {
                [classes.sentByOwner]: messageData?.sender?.isDeviceOwner,
              })}
              data-gid="50764229"
            >
              <Typography
                className={classnames({
                  [classes.text]: images && images?.length > 0,
                })}
                data-gid="39766330"
              >
                {textContent}
              </Typography>
              <Typography
                color={messageData?.sender?.isDeviceOwner ? 'white' : '#9EA0A5'}
                fontSize={12}
                className={classes.text}
                data-gid="51233295"
              >
                {' '}
                {numAttachments
                  ? `Attachment: ${numAttachments} Image(s)`
                  : ''}{' '}
              </Typography>
            </div>
            <div className={classes.viewMessage} data-gid="46023355">
              <LinkButton
                onClick={async () => {
                  if (messageData?.id && messageData?.thread.id) {
                    // Sets single message thread view on message list
                    setSingleMessageThread({
                      id: messageData?.id,
                    });
                    await navigateToMessageInThread({
                      messageId: messageData?.id,
                      messageThreadId: messageData?.thread.id,
                    });
                  }
                }}
                data-gid="80497239"
              >
                <QuestionAnswerIcon
                  sx={{ marginTop: '4px' }}
                  fontSize="small"
                  data-gid="92443207"
                />
                View in Messages
              </LinkButton>
            </div>
          </div>
          {messageData?.taggedBy && isTagged && (
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Tagged By:"
              rightText={
                messageData?.taggedBy.firstName && messageData.taggedBy.lastName
                  ? `${messageData.taggedBy.firstName} ${messageData.taggedBy.lastName}`
                  : messageData.taggedBy.email
              }
              data-gid="44429197"
            />
          )}
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Message ID:"
            rightText={clueId}
            data-gid="88847259"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="App:"
            rightText={
              messageData?.app?.displayName ?? messageData?.appBundleId
            }
            data-gid="89171400"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Sent At:"
            rightText={
              messageData?.timestamp
                ? `${DateTime.fromISO(messageData?.timestamp).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} 
          ${DateTime.fromISO(messageData?.timestamp).toLocaleString(
            DateTime.DATE_MED,
          )}`
                : undefined
            }
            data-gid="42030192"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Contact Name:"
            rightText={
              data?.messageById?.sender.metacontact
                ? data?.messageById?.sender.metacontact.primaryDisplayName
                : data?.messageById?.sender.primaryDisplayName
            }
            data-gid="66186465"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Phone Owner:"
            rightText={data?.messageById?.sender.isDeviceOwner ? 'Yes' : 'No'}
            data-gid="37495823"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Message Thread: "
            rightText={data?.messageById?.thread.threadDisplayName}
            data-gid="23328335"
          />
          {linksDetected.length > 0 &&
            linksDetected.map((url, i) => (
              <div
                style={{ display: 'flex', flexDirection: 'row' }}
                data-gid="93343688"
              >
                <ClueDetailsSlideoutMetadataTextItem
                  leftText={`Hyperlink #${i + 1}`}
                  rightText={url.toString()}
                  data-gid="95699394"
                />
                <Tooltip
                  title={tooltip}
                  style={{
                    position: 'relative',
                  }}
                  data-gid="27242762"
                >
                  {copiedUrl === url ? (
                    <Difference
                      fontSize="small"
                      onClick={() => handleBubbleClick(url)}
                      style={{ margin: 5 }}
                      data-gid="58140719"
                    />
                  ) : (
                    <ContentCopy
                      onClick={() => handleBubbleClick(url)}
                      style={{ margin: 5 }}
                      fontSize="small"
                      data-gid="58140719"
                    />
                  )}
                </Tooltip>
              </div>
            ))}
          {isRead && isRead.value !== '0' && readDate && cleanReadDate && (
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Status: "
              rightText={
                // Leaving unread receipt display code in but commented out for future use.
                // To add back in, uncomment the 2 lines below and take out "isRead.value !== '0'" check in conditional rendering above

                // isRead.value === '0'
                //   ? 'Unread' :
                `Read on ${DateTime.fromISO(
                  new Date(cleanReadDate).toISOString(),
                ).toLocaleString(
                  DateTime.TIME_WITH_SHORT_OFFSET,
                )} ${DateTime.fromISO(
                  new Date(cleanReadDate).toISOString(),
                ).toLocaleString(DateTime.DATE_MED)}`
              }
              data-gid="23328335"
            />
          )}
          {messageData?.media.map((image) => {
            if (image.__typename !== 'Image') return null;
            const { imageAnnotations, thumbnailUrl, previewUrl, id } = image;
            const isExplicit = imageAnnotations && imageAnnotations.length > 0;
            const imageContext = { isExplicit };
            return (
              <ClueDetailsSlideoutMetadataMediaItem
                leftText="Image:"
                thumbnailUrl={
                  thumbnailUrl || publicStaticFileURL('placeholder-image.png')
                }
                messageImageContext={imageContext}
                previewUrl={previewUrl}
                imageId={id}
                data-gid="81727097"
              />
            );
          })}
        </ClueDetailsSlideoutMetadataSection>
        {allSourceFiles && allSourceFiles?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Source Files"
            collapsed={sourcefileSectionCollapsed}
            onClickCollapse={() =>
              setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
            }
            data-gid="20057130"
          >
            {allSourceFiles.map((sf) => (
              <ClueDetailsSlideoutMetadataSubsection
                title={sf.name}
                key={sf.id}
                data-gid="26084468"
              >
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="File Path:"
                  rightText={`${sf.enclosingDirectory}/${sf.name}`}
                  data-gid="72140876"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="SHA256:"
                  rightText={`${sf.checksum}`}
                  data-gid="33677810"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Changed:"
                  rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="98506503"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Modified:"
                  rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="73406123"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Last Accessed:"
                  rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="26732213"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Size:"
                  rightText={`${fileSize(+sf.sizeBytes)}`}
                  data-gid="54947406"
                />
              </ClueDetailsSlideoutMetadataSubsection>
            ))}
          </ClueDetailsSlideoutMetadataSection>
        )}
        {clueMetaData && clueMetaData?.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Additional Info"
            collapsed={clueMetaDataSectionCollapsed}
            onClickCollapse={() =>
              setClueMetaDataSectionCollapsed(!clueMetaDataSectionCollapsed)
            }
            data-gid="99670106"
          >
            {clueMetaData.length > 0 &&
              clueMetaData.map((meta) => (
                <ClueDetailsSlideoutMetadataTextItem
                  key={meta?.key}
                  leftText={meta?.key || ''}
                  rightText={meta?.value || ''}
                  data-gid="61740713"
                />
              ))}
          </ClueDetailsSlideoutMetadataSection>
        )}
      </div>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default MessageClueDetails;
