import { gql } from '@apollo/client';

export const removeExtractionAccess = gql`
  mutation RemoveExtractionAccess($userId: UUID!, $extractionId: UUID!) {
    removeExtractionAccess(
      input: { userId: $userId, extractionId: $extractionId }
    ) {
      id
    }
  }
`;
