import { NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { createContext, useContext } from 'react';

export const CachePersistorContext =
  createContext<null | CachePersistor<NormalizedCacheObject>>(null);

export const useCachePersistor =
  (): null | CachePersistor<NormalizedCacheObject> =>
    useContext(CachePersistorContext);

export const CachePersistorProvider: React.FC<{
  persistor: null | CachePersistor<NormalizedCacheObject>;
}> = ({ persistor, children }) => (
  <CachePersistorContext.Provider value={persistor}>
    {children}
  </CachePersistorContext.Provider>
);
