import { Colors, makeStyles, Text } from '@grayshift/cairn';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const useStyles = makeStyles({
  messageBanner: {
    alignItems: 'center',
    backgroundColor: '#F9E9E8',
    border: `1px solid ${Colors.red}`,
    display: 'flex',
    padding: '5px 0',
  },
});

interface ErrorMessageProps {
  text: string;
}

export const ErrorMessageBanner: React.FC<ErrorMessageProps> = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.messageBanner} data-gid="70109883">
      <ErrorRoundedIcon
        style={{ color: 'red', margin: '0 15px' }}
        data-gid="86938212"
      />
      <Text size="3" weight={300} align="left" data-gid="78822206">
        {text}
      </Text>
    </div>
  );
};
