import { gql } from '@apollo/client';

export const allImagesTimelineQuery = gql`
  query getAllImages(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $appNames: [String!]
    $timeframe: TimeFrame
  ) {
    allImagesByExtractionId(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeframe
      appNames: $appNames
    ) {
      edges {
        node {
          id
          timestamp
          thumbnailUrl
          containsNudity
          containsCSAM
          containsCSAMConfidence
          containsNudityConfidence
          ncmecMatch
          imageAnnotations {
            id
            annotation
          }
        }
      }
    }
  }
`;

export const allMediaQuery = gql`
  query getImages(
    $extractionId: UUID!
    $searchTerm: String
    $appNames: [String!]
    $timeframe: TimeFrame
    $page: Int
    $pageSize: Int
    $showNcmecCsam: Boolean
    $showAiCsam: Boolean
  ) {
    allImagesByExtractionId(
      extractionId: $extractionId
      searchTerm: $searchTerm
      appNames: $appNames
      timeframe: $timeframe
      page: $page
      pageSize: $pageSize
      showNcmecCsam: $showNcmecCsam
      showAiCsam: $showAiCsam
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          id
          thumbnailUrl
          labels
          containsNudity
          containsCSAM
          containsCSAMConfidence
          containsNudityConfidence
          ncmecMatch
          imageAnnotations {
            id
            annotation
          }
        }
        page
        pageSize
      }
    }
  }
`;

// PSA: Soon to be fully deprecated - awaiting completion of video work
export const imageByIdQuery = gql`
  query imageById($imageById: ID!) {
    imageById(id: $imageById) {
      id
      thumbnailUrl
      previewUrl
      timestamp
      app {
        id
        displayName
      }
      appBundleId
      sourceFiles {
        id
        name
        checksum
        extension
        enclosingDirectory
        sizeBytes
        atime
        mtime
        ctime
      }
      meta {
        key
        value
      }
      taggedAt
      taggedBy {
        id
        email
        firstName
        lastName
      }
      storedFileChecksum
      storedFileSha1Checksum
      storedFileMd5Checksum
      imageAnnotations {
        id
        annotation
        annotatedBy {
          id
          firstName
          lastName
          email
        }
      }
      messageAttachment {
        messageThreadId
        messageId
      }
    }
  }
`;

// eslint-disable-next-line no-secrets/no-secrets
export const getAllMediaCategories = gql`
  query topMediaLabelsByExtractionId($extractionId: UUID!) {
    topMediaLabelsByExtractionId(extractionId: $extractionId) {
      label
      totalHits
    }
  }
`;

export const csamImagesCountQuery = gql`
  query getImageCsamCount($extractionId: UUID!) {
    csamImagesByExtractionIdCount(extractionId: $extractionId) {
      totalNcmecHits
      totalAiHits
    }
  }
`;
