const determineCallStatus = (
  originated: boolean,
  answered: boolean,
): string => {
  switch (true) {
    case !originated && answered:
      return 'Incoming';

    case !originated && !answered:
      return 'Missed';

    default:
      return 'Outgoing';
  }
};

export default determineCallStatus;
