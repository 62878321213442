import { gql } from '@apollo/client';

const getCallTypes = gql`
  query appsByCallType(
    $extractionId: UUID!
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    appsByCallType(
      extractionId: $extractionId
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      appDisplayName
      hits
    }
  }
`;

export default getCallTypes;
