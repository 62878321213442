import {
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerParseError,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import * as Sentry from '@sentry/react';
import { Transaction } from '@sentry/types';
import { SentryLink } from 'apollo-link-sentry';
import { getMainDefinition } from 'apollo-utilities';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

const OMIT_FROM_SENTRY_ERROR_CODES = new Set(['ILLEGAL_USER_OPERATION']);

function getFirstNonNil<T>(
  object: ExplicitAny,
  paths: (string | number)[][],
  defaultVal: T | null = null,
): T | null {
  let i;
  let outVal = defaultVal;

  // Traditional for loop used for ability to break
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < paths.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const tmpOutVal = get(object, paths[i]);
    if (!isNil(tmpOutVal)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      outVal = tmpOutVal;
      break;
    }
  }

  return outVal as unknown as T | null;
}

const getOperationName = (operation: GraphQLRequest): string =>
  getFirstNonNil<string>(
    operation,
    [
      ['operationName'],
      ['query', 'definitions', 0, 'name', 'value'],
      ['definitions', 0, 'name', 'value'],
      ['operation', 'definitions', 0, 'name', 'value'],
    ],
    'NO_NAME',
  ) as unknown as string;

/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-member-access,scanjs-rules/identifier_localStorage */
const authLink = setContext((operation, context) => {
  const { headers } = context;

  let transaction;
  const extraHeaders: Record<string, string> = {};
  if (process.env.REACT_APP_SENTRY_DSN) {
    const scope = Sentry.getCurrentHub().getScope();
    transaction = scope?.getTransaction();
    transaction = transaction
      ? transaction.startChild({
          op: 'gql',
          description: getOperationName(operation),
        })
      : Sentry.startTransaction({
          name: getOperationName(operation),
          op: 'gql',
        });
    scope?.setTransactionName(getOperationName(operation));
    scope?.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    });
    extraHeaders['sentry-trace'] = transaction.toTraceparent();
  }

  // get the authentication token from local storage if it exists
  const tokenStorageObject: string | null =
    localStorage.getItem('okta-token-storage');

  // return the headers to the context so httpLink can read them
  return {
    transaction,
    headers: {
      ...headers,
      ...extraHeaders,
      authorization: tokenStorageObject
        ? `Bearer ${JSON.parse(tokenStorageObject)?.accessToken?.accessToken}`
        : '',
    },
  };
});
/* eslint-enable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-member-access,scanjs-rules/identifier_localStorage */

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_ZEUS_API_URL as string}/graphql`,
  credentials: 'include',
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(
      'GQL ErrorLink: Something went wrong with a query',
      { graphQLErrors },
      { networkError },
    );
  }
  if (!process.env.REACT_APP_SENTRY_DSN) return;
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext('apolloGraphQLOperation', {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: {
        ...operation.extensions,
        wifiConnectionActive: navigator.onLine,
      },
    });
    if (networkError) {
      if (networkError.name === 'ServerParseError') {
        const serverParseError = networkError as ServerParseError;
        // Check if error response is JSON
        try {
          JSON.parse(serverParseError.bodyText);
        } catch (error) {
          // If not replace parsing error message with real one
          serverParseError.message = serverParseError.bodyText;
        }
        Sentry.captureMessage(networkError.message, {
          level: 'error',
          contexts: {
            apolloNetworkError: {
              error: serverParseError,
              statusCode: serverParseError.statusCode,
              extensions: [{ wifiConnectionActive: navigator.onLine }],
            },
          },
        });
      }
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        contexts: {
          apolloNetworkError: {
            error: networkError,
            extensions: [{ wifiConnectionActive: navigator.onLine }],
          },
        },
      });
    }

    graphQLErrors?.forEach((error) => {
      if (!OMIT_FROM_SENTRY_ERROR_CODES.has(error.extensions.code as string)) {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
        // if (error.extensions?.code === 'NOT_FOUND') {
        //   // can't use hooks here. is there a better way to do this?
        //   window.location.assign('/404');
        // }
      }
    });
  });
});

const finishTransactionLink = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    const context = operation.getContext();
    if ('transaction' in context) {
      const txn = context.transaction as unknown as Transaction | undefined;
      txn?.finish();
    }
    return data;
  }),
);

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_ZEUS_API_URL as string}/graphql`,
  options: {
    reconnect: true,
    // connectionParams: () => getContextParams(),
    lazy: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
export const makeClient = (
  cache: InMemoryCache,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    // eslint-disable-next-line unicorn/prefer-spread
    link: ApolloLink.from([
      finishTransactionLink,
      errorLink,
      ...(process.env.REACT_APP_SENTRY_DSN
        ? [
            new SentryLink({
              uri: `${process.env.REACT_APP_ZEUS_API_URL as string}/graphql`,
              attachBreadcrumbs: {
                includeQuery: true,
                includeVariables: true,
                includeFetchResult: true,
                includeError: true,
                includeCache: false,
                includeContext: ['headers'],
              },
            }),
          ]
        : []),
      authLink,
      splitLink,
    ]),
    cache,
  });
