import { useMutation } from '@apollo/client';
import { Button, makeStyles, Text } from '@grayshift/cairn';
import fileSize from 'filesize';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import { callById } from './graphql/generated/callById';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import {
  removeClueTagMutation,
  tagCallClueMutation,
} from './graphql/mutations';
import { callByIdQuery } from './graphql/queries/calls';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import calculateCallDuration from './lib/calculateCallDuration';
import determineCallStatus from './lib/determineCallStatus';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  image: {
    width: '100%',
    margin: '10px 0',
    cursor: 'pointer',
  },
  viewFullSizeTextContainer: {
    cursor: 'pointer',
    marginBottom: 20,
  },
  centeredBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
});

interface CallProps {
  clueId: string;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

const CallClueDetails: React.FC<CallProps> = ({ clueId }) => {
  const classes = useStyles();
  const location = useLocation();
  const [currentExtractionId] = useCurrentExtractionId();
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [tagCallClue] = useMutation(tagCallClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);
  const { loading, error, data } = useQueryWithErrorBoundary<callById>(
    callByIdQuery,
    {
      variables: { callId: clueId },
    },
  );

  if (error) {
    return null;
  }

  const call = data?.callById;
  const isTagged = data?.callById?.taggedAt;

  const allSourceFiles: sourceFileProps[] = uniqBy(
    [...(call?.contact?.sourceFiles || []), ...(call?.sourceFiles || [])],
    'id',
  );

  const onDashboard = location.pathname === '/dashboard';

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="15890843"
    >
      <>
        <div className={classes.metadataContainer} data-gid="42495015">
          <ClueDetailsSlideoutMetadataSection
            title="Actions"
            data-gid="71534669"
          >
            <div className={classes.tagBtnContainer} data-gid="97695478">
              <ReadOnlyTooltip
                verb="tag"
                bypass={
                  currentExtractionAccessType !==
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                data-gid="78703368"
              >
                <Button
                  color={isTagged ? 'primary' : 'default'}
                  disabled={
                    currentExtractionAccessType ===
                    ExtractionAccessTypeEnum.READ_ONLY
                  }
                  fullWidth
                  onClick={() => {
                    if (!isTagged) {
                      tagCallClue({
                        variables: {
                          input: {
                            clueId,
                            extractionId: currentExtractionId,
                          },
                        },
                        refetchQueries: onDashboard
                          ? [callByIdQuery, allTaggedCluesQuery]
                          : [callByIdQuery],
                      }).catch((tagError: Error) => tagError);
                    }
                    if (isTagged) {
                      removeClueTag({
                        variables: {
                          input: {
                            clueId,
                            extractionId: currentExtractionId,
                            clueType: 'CALL',
                          },
                        },
                        refetchQueries: onDashboard
                          ? [callByIdQuery, allTaggedCluesQuery]
                          : [callByIdQuery],
                      }).catch((removeTagError: Error) => removeTagError);
                    }
                  }}
                  data-gid="88796720"
                >
                  {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
                </Button>
              </ReadOnlyTooltip>
              {isTagged && (
                <Text size="2" data-gid="61610202">
                  You can view all tagged clues on the Dashboard
                </Text>
              )}
            </div>
          </ClueDetailsSlideoutMetadataSection>
          <Notes
            clueId={clueId}
            clueType={ClueTypes.CALL}
            data-gid="57448008"
          />
          <ClueDetailsSlideoutMetadataSection
            title="Basic Details"
            data-gid="22926534"
          >
            {call?.taggedBy && isTagged && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="Tagged By:"
                rightText={
                  call?.taggedBy.firstName && call?.taggedBy.lastName
                    ? `${call.taggedBy.firstName} ${call.taggedBy.lastName}`
                    : call.taggedBy.email
                }
                data-gid="60443513"
              />
            )}
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Clue Id:"
              rightText={call?.id}
              data-gid="34552479"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Phone Number:"
              rightText={call?.displayName}
              data-gid="56454297"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Timestamp: "
              rightText={
                call?.timestamp
                  ? `${DateTime.fromISO(call.timestamp).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} 
            ${DateTime.fromISO(call.timestamp).toLocaleString(
              DateTime.DATE_MED,
            )}`
                  : undefined
              }
              data-gid="86069551"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Status:"
              rightText={
                call?.originated !== undefined && call?.answered !== undefined
                  ? determineCallStatus(call?.originated, call?.answered)
                  : ''
              }
              data-gid="35002673"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Duration:"
              rightText={
                call?.duration || call?.duration === 0
                  ? calculateCallDuration(call.duration)
                  : 'Unknown'
              }
              data-gid="94498075"
            />
          </ClueDetailsSlideoutMetadataSection>
          {allSourceFiles && allSourceFiles?.length > 0 && (
            <ClueDetailsSlideoutMetadataSection
              title="Source Files"
              collapsed={sourcefileSectionCollapsed}
              onClickCollapse={() =>
                setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
              }
              data-gid="98013602"
            >
              {allSourceFiles.map((sf) => (
                <ClueDetailsSlideoutMetadataSubsection
                  key={sf.id}
                  title={sf.name}
                  data-gid="49035844"
                >
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="File Path:"
                    rightText={`${sf.enclosingDirectory}/${sf.name}`}
                    data-gid="29564790"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="SHA256:"
                    rightText={`${sf.checksum}`}
                    data-gid="40808861"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Changed:"
                    rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="32113969"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Modified:"
                    rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="87380375"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Last Accessed:"
                    rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="65514629"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Size:"
                    rightText={`${fileSize(+sf.sizeBytes)}`}
                    data-gid="50816056"
                  />
                </ClueDetailsSlideoutMetadataSubsection>
              ))}
            </ClueDetailsSlideoutMetadataSection>
          )}
        </div>
      </>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default CallClueDetails;
