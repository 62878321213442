import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { clueDetailsSlideoutDataAtom, ClueType } from './lib/atoms';

type QueryParams = Record<string, string | number | undefined> & {
  page: number;
  pageSize: number;
  messageThreadId?: string;
  id?: string;
  threadId?: string;
  messageId?: string;
  clueId?: string;
  clueType?: string;
  slidoutOpen?: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useQueryParams(
  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  defaults: QueryParams = { pageSize: 50, page: 1 },
): {
  search: URLSearchParams;
  append: (key: string, value: string) => void;
  setPageNumber: (pageNumber: number) => void;
  setActiveMessageThread: (
    messageThreadId: string,
    messageId?: string,
    pageNumber?: number,
  ) => void;
  setActiveThread: (threadId: string, id?: string, pageNumber?: number) => void;
  setActiveMessage: (messageId: string) => void;
  setClue: (options: {
    clueId: string | null;
    clueType: ClueType | null;
    clueSubType?: string;
    slideoutOpen?: boolean;
  }) => void;
  clearSearchParams: () => void;
  params: QueryParams;
} {
  const [clueDetailsSlideOutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const history = useHistory();
  const location = useLocation<QueryParams>();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const params = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams],
  );

  // Open Clue Details if query params are present
  useEffect(() => {
    if (
      searchParams.has('clueId') &&
      searchParams.has('clueType') &&
      !clueDetailsSlideOutData.slideoutOpen
    ) {
      setClueDetailsSlideoutData({
        slideoutOpen: true,
        clueId: searchParams.get('clueId') ?? '',
        clueType: searchParams.get('clueType') as ClueType,
        clueSubType: searchParams.get('clueSubType') ?? undefined,
      });
    }
  });

  // const [paramsState, setParamsState] = useState(searchParams);

  function append(key: string, value: string): void {
    searchParams.append(key, value);
  }

  // Handle Page Info
  const page = searchParams.get('page')
    ? parseInt(searchParams.get('page') ?? '', 10)
    : 1;

  const pageSize = searchParams.get('pageSize')
    ? parseInt(searchParams.get('pageSize') ?? '', 10)
    : defaults.pageSize;

  const setPageNumber = (pageNumber: number | undefined): void => {
    if (!pageNumber) {
      searchParams.delete('pageSize');
      return;
    }

    if (pageNumber >= 1) {
      searchParams.set('page', pageNumber.toString());
      history.replace({ search: searchParams.toString() });
    }
  };

  const setClue = ({
    clueId,
    clueType, // slideoutOpen = false,
    clueSubType,
  }: {
    clueId: string | null;
    clueType: ClueType | null;
    clueSubType?: string;
    slideoutOpen?: boolean;
  }): void => {
    if (clueId === null) {
      searchParams.delete('clueId');
    } else if (clueId) {
      searchParams.set('clueId', clueId);
    }

    if (clueType === null) {
      searchParams.delete('clueType');
    } else if (clueType) {
      searchParams.set('clueType', clueType);
    }

    if (clueSubType === null) {
      searchParams.delete('clueSubType');
    } else if (clueSubType) {
      searchParams.set('clueSubType', clueSubType);
    }
    // if (slideoutOpen) {
    //   searchParams.set('slideoutOpen', slideoutOpen.toString());
    // }

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });

    if (clueId) {
      setClueDetailsSlideoutData({
        slideoutOpen: true,
        clueId,
        clueType,
        clueSubType,
      });
    }
  };

  const setActiveThread = (
    threadId: string,
    id?: string,
    pageNumber: number = 1,
  ): void => {
    searchParams.set('threadId', threadId);
    if (id) {
      searchParams.set('id', id);
    }
    if (pageNumber) {
      searchParams.set('page', pageNumber.toString());
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const setActiveMessageThread = (
    messageThreadId: string,
    messageId?: string,
    pageNumber: number = 1,
  ): void => {
    searchParams.set('messageThreadId', messageThreadId);
    if (messageId) {
      searchParams.set('messageId', messageId);
    }
    if (pageNumber) {
      searchParams.set('page', pageNumber.toString());
    }
    history.push({ pathname: '/messages', search: searchParams.toString() });
  };
  const setActiveMessage = (messageId: string): void => {
    searchParams.set('messageId', messageId);
    // setParamsState(searchParams);
    history.push({ search: searchParams.toString() });
  };

  const clearSearchParams = (): void => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('extractionId', searchParams.get('extractionId') ?? '');
    // setParamsState(newSearchParams);
    history.push({ search: newSearchParams.toString() });
  };

  return {
    search: searchParams,
    params: { ...params, page, pageSize },
    append,
    setPageNumber,
    setActiveMessageThread,
    setActiveThread,
    setActiveMessage,
    clearSearchParams,
    setClue,
  };
}
