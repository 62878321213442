import { gql } from '@apollo/client';

export const CoreSourceFileFields = gql`
  fragment CoreSourceFileFields on SourceFile {
    id
    name
    checksum
    extension
    enclosingDirectory
    sizeBytes
    atime
    mtime
    ctime
  }
`;
