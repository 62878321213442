import { TableCell, TableRow } from '@mui/material';

import { pendingUsers_pendingUsers } from './graphql/generated/pendingUsers';

interface UserManagementPendingUserTableProps {
  key: string;
  pendingUser: pendingUsers_pendingUsers;
}

export const UserManagementPendingUserTable: React.FC<
  UserManagementPendingUserTableProps
> = ({ pendingUser }) => (
  <>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} data-gid="76363449">
      <TableCell style={{ width: 0 }} data-gid="76779925" />
      <TableCell component="th" scope="row" align="left" data-gid="97214192">
        {pendingUser.firstName}
      </TableCell>
      <TableCell align="left" data-gid="41318815">
        {pendingUser.lastName}
      </TableCell>
      <TableCell align="left" data-gid="86632486">
        {pendingUser.email}
      </TableCell>
    </TableRow>
  </>
);
