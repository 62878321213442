/* eslint-disable no-secrets/no-secrets */
import { useMutation } from '@apollo/client';
import { Colors, lighten, makeStyles, Text } from '@grayshift/cairn';
import NoteIcon from '@mui/icons-material/Note';
import { DateTime } from 'luxon';
import { MutableRefObject, ReactElement, useEffect, useRef } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import reactStringReplace from 'react-string-replace';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ActionBar } from './ActionBar';
import {
  allMemosByExtractionId,
  allMemosByExtractionId_allMemosByExtractionId,
} from './graphql/generated/allMemosByExtractionId';
import {
  removeClueTagMutation,
  tagMemoClueMutation,
} from './graphql/mutations';
import { memosQuery } from './graphql/queries/memos';
import { AppIcon } from './helpers/AppIcons';
import {
  currentGlobalSearchTextAtom,
  currentMemoIdAtom,
  textSearchAtom,
  timeFrameStateAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useMemoBody } from './useMemoBody';
import { useQueryParams } from './useQueryParams';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { useUser } from './useUser';

const useStyles = makeStyles({
  threadListContainer: {
    height: '100%',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    borderRight: `solid 1px ${Colors.divider}`,
    justifyContent: 'flex-start',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  threadListLoadingContainer: {
    height: '100%',
    width: 400,
    display: 'flex',
    borderRight: `solid 1px ${Colors.divider}`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  threadContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 0 10px 5px',
    '&:hover': {
      background: Colors.divider,
    },
  },
  divider: {
    borderBottom: `solid 1px ${Colors.divider}`,
    margin: '0px 5px',
  },
  threadListLoading: {
    height: '100%',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    borderRight: `solid 1px ${Colors.divider}`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedThreadContainer: {
    background: Colors.blue,
    color: Colors.white,
    '&:hover': {
      background: Colors.blue,
    },
  },
  threadInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5px',
    '& p': {
      width: '310px',
      display: '-webkit-box',
      lineClamp: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxOrient: 'vertical',
    },
  },
  threadIconContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  threadIcon: {
    alignItems: 'center',
    textAlign: 'center',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  },
  threadName: {
    display: 'flex',
    wordBreak: 'break-word',
    paddingRight: '5px',
  },
  threadContent: {
    paddingTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '5px',
    wordBreak: 'break-word',
  },
  noInfo: {
    color: `${Colors.textSecondary}`,
    fontStyle: 'italic',
  },
});

interface ThreadProps {
  thread: allMemosByExtractionId_allMemosByExtractionId;
  navigatedThread: MutableRefObject<HTMLDivElement | null> | null;
  executeScroll: () => void;
}

const MemoThread = ({
  thread,
  navigatedThread,
  executeScroll,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ThreadProps): ReactElement => {
  const classes = useStyles();
  const { setActiveThread } = useQueryParams();
  const [textSearchState] = useRecoilState(textSearchAtom);
  const [tagMemoClue] = useMutation(tagMemoClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);
  const [currentMemoState, setCurrentMemoState] =
    useRecoilState(currentMemoIdAtom);
  const [currentExtractionId] = useCurrentExtractionId();
  const { user } = useUser();

  const replace = textSearchState?.memoContent ?? '';

  useEffect(() => {
    if (navigatedThread?.current) {
      executeScroll();
    }
  }, [navigatedThread, executeScroll]);

  // Can be removed once old aggs with non-parsed JSON and HTML content are removed
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formattedNote = useMemoBody(thread?.noteBody);
  const memoTitle = thread.title ?? '';
  const searchTerm = textSearchState.memoContent?.toLowerCase() ?? '';

  return (
    <>
      {formattedNote.toLowerCase().includes(searchTerm) ||
      memoTitle.toLowerCase().includes(searchTerm) ? (
        <div key={thread.id} data-gid="76084815">
          <ActionBar
            onTag={async (tagged) => {
              await (tagged
                ? removeClueTag({
                    variables: {
                      input: {
                        clueId: thread?.id ?? '',
                        extractionId: currentExtractionId,
                        clueType: 'MEMO',
                      },
                    },
                    refetchQueries: ['allMemosByExtractionId'],
                    optimisticResponse: {
                      removeClueTag: {
                        clueId: thread?.id ?? '',
                        __typename: 'RemoveClueTagPayload',
                      },
                    },
                  })
                : tagMemoClue({
                    variables: {
                      input: {
                        clueId: thread?.id || '',
                        extractionId: currentExtractionId,
                      },
                    },
                    refetchQueries: ['allMemosByExtractionId'],
                    optimisticResponse: {
                      tagMemoClue: {
                        memo: {
                          id: thread?.id || '',
                          taggedAt: DateTime.now().toISO(),
                          taggedBy: {
                            id: user?.id ?? '',
                            firstName: user?.firstName ?? '',
                            lastName: user?.lastName ?? '',
                            email: user?.email ?? '',
                            __typename: 'User',
                          },
                          __typename: 'Memo',
                        },
                        __typename: 'TagMemoCluePayload',
                      },
                    },
                  }));
            }}
            isTagged={!!thread.taggedAt}
            position="absolute"
            top={-1}
            right={1}
            data-gid="95403989"
          >
            <div
              ref={navigatedThread}
              className={`${classes.threadContainer} ${
                currentMemoState?.currentMemoId === thread.id
                  ? classes.selectedThreadContainer
                  : ''
              }`}
              role="presentation"
              onClick={() => {
                setActiveThread(thread.id);
                setCurrentMemoState({
                  currentMemoId: thread.id,
                });
              }}
              data-gid="71326039"
            >
              <div className={classes.threadIconContainer} data-gid="42729765">
                <div className={classes.threadIcon} data-gid="56331356">
                  {thread.app?.iconUrl ? (
                    <AppIcon
                      iconUrl={thread.app?.iconUrl}
                      displayName={thread.app.displayName ?? ''}
                      height="65%"
                      width="65%"
                    />
                  ) : (
                    <NoteIcon
                      sx={{ color: lighten(Colors.blue, 0.3) }}
                      data-gid="23318571"
                    />
                  )}
                </div>
              </div>
              <div className={classes.threadInfoContainer} data-gid="70099103">
                <div className={classes.threadName} data-gid="88532011">
                  <Text weight={500} data-gid="69406282">
                    {reactStringReplace(
                      thread.title || '',
                      replace,
                      (match) => (
                        <span
                          style={{ fontWeight: 'bold' }}
                          key={Math.random()}
                          data-gid="48290000"
                        >
                          {match}
                        </span>
                      ),
                    )}
                  </Text>
                </div>
                <div className={classes.threadContent} data-gid="80538139">
                  {thread.modifiedAt ? (
                    <Text
                      color={
                        currentMemoState?.currentMemoId === thread.id
                          ? undefined
                          : 'textSecondary'
                      }
                      size="2"
                      data-gid="77744234"
                    >
                      {`${DateTime.fromISO(thread.modifiedAt).toLocaleString(
                        DateTime.DATE_MED,
                      )}
                ${DateTime.fromISO(thread.modifiedAt).toLocaleString(
                  DateTime.TIME_WITH_SHORT_OFFSET,
                )} `}
                    </Text>
                  ) : (
                    <div className={classes.noInfo} data-gid="26135731">
                      <Text size="2" data-gid="43020800">
                        No Date
                      </Text>
                    </div>
                  )}
                  {thread.noteBody ? (
                    <Text data-gid="29673104">
                      {reactStringReplace(
                        formattedNote.replace(/\uFFFC/, '') || '',
                        replace,
                        (match) => (
                          <span
                            style={{ fontWeight: 'bold' }}
                            key={Math.random()}
                            data-gid="48290000"
                          >
                            {match}
                          </span>
                        ),
                      )}
                    </Text>
                  ) : (
                    <div className={classes.noInfo} data-gid="89581072">
                      <Text size="2" data-gid="58165718">
                        No memo body
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.divider} data-gid="63797548" />
          </ActionBar>
        </div>
      ) : null}
    </>
  );
};

function MemoThreadList(): ReactElement {
  const classes = useStyles();
  const { params } = useQueryParams();
  const [currentExtractionId] = useCurrentExtractionId();

  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { loading, data, error } =
    useQueryWithErrorBoundary<allMemosByExtractionId>(memosQuery, {
      variables: {
        extractionId: currentExtractionId,
        searchTerm: globalSearchTextState.currentGlobalSearchText,
        timeframe:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
      },
    });

  const memoThreads = data?.allMemosByExtractionId;

  const navigatedThread = useRef<HTMLDivElement | null>(null);

  const executeScroll = (): void => {
    navigatedThread?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  if (loading) {
    return (
      <div className={classes.threadListLoadingContainer} data-gid="50241872">
        <ScaleLoader loading color={Colors.blue} data-gid="84709823" />
      </div>
    );
  }

  if ((!memoThreads || memoThreads?.length === 0) && !loading && !error) {
    return (
      <div className={classes.threadListLoadingContainer} data-gid="92385587">
        <Text data-gid="39919005">No memos to display</Text>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.threadListLoadingContainer} data-gid="36946039">
        <Text data-gid="85173014">There was an error loading this data</Text>
      </div>
    );
  }

  return (
    <div className={classes.threadListContainer} data-gid="89270235">
      {!loading &&
        memoThreads?.map((thread) => (
          <MemoThread
            key={thread.id}
            thread={thread}
            navigatedThread={thread.id === params.id ? navigatedThread : null}
            executeScroll={executeScroll}
            data-gid="21703583"
          />
        ))}
    </div>
  );
}

export default MemoThreadList;
