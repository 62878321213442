import { useMutation } from '@apollo/client';
import { Colors, DataTable, makeStyles, Text } from '@grayshift/cairn';
import {
  Apps,
  CheckCircleOutlineOutlined,
  Clear,
  PublishedWithChanges,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { Suspense, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ActionBar } from './ActionBar';
import {
  allInstalledAppsByExtractionId,
  allInstalledAppsByExtractionId_allInstalledAppsByExtractionId,
} from './graphql/generated/allInstalledAppsByExtractionId';
import {
  ClueTypes,
  ContentSupportedEnum,
} from './graphql/generated/globalTypes';
import { installedAppById } from './graphql/generated/installedAppById';
import {
  removeClueTagMutation,
  tagInstalledAppClueMutation,
} from './graphql/mutations';
import {
  getAllInstalledAppsByExtractionId,
  getInstalledAppById,
} from './graphql/queries';
import {
  ClueType,
  currentGlobalSearchTextAtom,
  userInfoAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { useUser } from './useUser';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    overflow: 'scroll',
    width: '100%',
  },
  rootContainer: {
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    backgroundColor: Colors.white,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  },
  spacer: {
    height: 10,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
  hideBundleIdCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 0 1rem 2rem',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
  },
  cellContainer: {
    cursor: 'pointer',
  },
  supportedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  requestSupportContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: Colors.blue,
    marginLeft: '.25vw',
  },
  contentSupportedCellContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  contentSupportedText: {
    marginLeft: '.25vw',
  },
});

/* eslint-disable sonarjs/cognitive-complexity */
const InstalledApps: React.FC = () => {
  const classes = useStyles();
  const { user } = useUser();
  const [currentExtractionId] = useCurrentExtractionId();
  const [sortField, setSortField] = useState('contentIsSupported');
  const [ascending, setAscending] = useState(true);
  const [rowSupportRequested, setRowSupportRequested] = useState<string[]>([]);
  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);
  const [userInfo] = useRecoilState(userInfoAtom);
  const { setClue } = useQueryParams();
  const { params, setPageNumber } = useQueryParams();
  const [tagInstalledAppClue] = useMutation(tagInstalledAppClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);
  const { data, loading } =
    useQueryWithErrorBoundary<allInstalledAppsByExtractionId>(
      getAllInstalledAppsByExtractionId,
      {
        variables: {
          extractionId: currentExtractionId,
          sortField,
          ascending,
          searchTerm: globalSearchTextState.currentGlobalSearchText,
        },
        skip: !currentExtractionId,
      },
    );
  const rowData = data?.allInstalledAppsByExtractionId || [];

  const onClickRow = (rowId: string): void => {
    setClue({
      clueId: rowId,
      clueType: ClueType.INSTALLED_APP,
      slideoutOpen: true,
    });
  };

  const onClickRequestSupport = (
    row: allInstalledAppsByExtractionId_allInstalledAppsByExtractionId,
  ): void => {
    const newValue = [...rowSupportRequested, row.id];
    setRowSupportRequested(newValue);
    if (process.env.REACT_APP_ENV === 'production') {
      window.aptrinsic('track', 'Request App Support', {
        extractionId: currentExtractionId ?? '',
        displayName: row.displayName ?? '',
        version: row.version,
        userId: userInfo.id,
        bundleId: row.bundleId,
      });
    }
  };

  const handleSort = (header: string): void => {
    if (sortField === header) {
      setAscending(!ascending);
    } else {
      setAscending(true);
    }

    setSortField(header);
  };

  // const needsUpdate = !!rowData.some(
  //   (rowDataToUpdate) =>
  //     rowDataToUpdate.contentIsSupported ===
  //     ContentSupportedEnum.supportedPostExtraction,
  // );

  return (
    <Suspense
      fallback={() => <ScaleLoader data-gid="97760376" />}
      data-gid="85456443"
    >
      <div className={classes.outerWrapper} data-gid="55402246">
        <div className={classes.rootContainer} data-gid="94124377">
          <Text heading="h2" size="5" weight={500} data-gid="63352956">
            Installed Apps
          </Text>
          <div className={classes.spacer} data-gid="13836293" />
          {loading && (
            <div className={classes.loadingContainer} data-gid="83370867">
              <ScaleLoader loading color="#1665D8" data-gid="71274959" />
            </div>
          )}
          {!loading && (
            <div className={classes.tableContainer} data-gid="54788867">
              <DataTable
                data={rowData}
                pageChangeHandler={(pageNumber) => setPageNumber(pageNumber)}
                page={params.page ?? 1}
                onSortHandler={(id) => handleSort(id)}
                sortDirection={ascending ? 'asc' : 'desc'}
                columnDefinitions={[
                  {
                    id: 'displayName',
                    heading: 'Name',
                    align: 'left',
                    sortable: true,
                    content: (row) => (
                      <div
                        onClick={() => onClickRow(row.id)}
                        role="presentation"
                        className={classes.requestSupportContainer}
                        key={`installed-app-display-name-${row.id}`}
                        data-gid="41284980"
                      >
                        {row?.iconUrl ? (
                          <img
                            style={{
                              marginRight: '10px',
                              borderRadius: '100%',
                            }}
                            height="4%"
                            width="4%"
                            src={row.iconUrl}
                            alt="Installed App Icon"
                            data-gid="50267816"
                          />
                        ) : (
                          <Apps
                            style={{
                              marginRight: '10px',
                              height: '4%',
                              width: '4%',
                              background: Colors.blue,
                              color: 'white',
                              borderRadius: '100%',
                            }}
                          />
                        )}
                        <Text data-gid="37684220">
                          {row.displayName ?? row.bundleId}
                        </Text>
                      </div>
                    ),
                  },
                  {
                    id: 'Version',
                    heading: 'Version',
                    align: 'left',
                    content: (row) => (
                      <div
                        role="presentation"
                        key={`installed-app-version-${row.id}`}
                        data-gid="44427701"
                      >
                        <Text data-gid="21592701">{row.version}</Text>
                      </div>
                    ),
                  },
                  {
                    id: 'contentIsSupported',
                    heading: 'Supported',
                    align: 'left',
                    sortable: true,
                    content: (row) => (
                      <div
                        role="presentation"
                        key={`installed-app-content-is-supported-${row.id}`}
                        data-gid="19084388"
                      >
                        <div
                          className={classes.contentSupportedCellContainer}
                          data-gid="73701139"
                        >
                          {(row.contentIsSupported ===
                            ContentSupportedEnum.supported ||
                            row.contentIsSupported ===
                              ContentSupportedEnum.supportedWithoutData) && (
                            <CheckCircleOutlineOutlined
                              color="success"
                              data-gid="47213265"
                            />
                          )}
                          {row.contentIsSupported ===
                            ContentSupportedEnum.supportedPostExtraction && (
                            <PublishedWithChanges
                              color="warning"
                              data-gid="51668982"
                            />
                          )}
                          {row.contentIsSupported ===
                            ContentSupportedEnum.notSupported && (
                            <Clear color="error" data-gid="83941190" />
                          )}
                          {row.contentIsSupported ===
                          ContentSupportedEnum.notSupported ? (
                            <div
                              className={
                                rowSupportRequested.includes(row.id)
                                  ? classes.contentSupportedText
                                  : classes.requestSupportContainer
                              }
                              data-gid="14665681"
                              onClick={() => onClickRequestSupport(row)}
                              role="presentation"
                              key={`installed-apps-action-${row.id}`}
                            >
                              <div
                                data-gid="70981287"
                                style={{ marginBottom: '.1rem' }}
                              >
                                {rowSupportRequested.includes(row.id)
                                  ? 'No | Requested'
                                  : 'No | Request Support'}
                              </div>
                            </div>
                          ) : (
                            <div
                              className={classes.contentSupportedText}
                              data-gid="14665681"
                            >
                              Yes
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'dataPresent',
                    heading: 'Data Present',
                    align: 'left',
                    content: (row) => (
                      <div
                        role="presentation"
                        key={`installed-app-content-is-supported-${row.id}`}
                        data-gid="19084388"
                      >
                        <div
                          className={classes.contentSupportedCellContainer}
                          data-gid="73701139"
                        >
                          {row.contentIsSupported ===
                          ContentSupportedEnum.supported ? (
                            <CheckCircleOutlineOutlined
                              color="success"
                              data-gid="47213265"
                            />
                          ) : (
                            <Clear color="error" data-gid="83941190" />
                          )}
                          {row.contentIsSupported ===
                          ContentSupportedEnum.supported ? (
                            <div
                              className={classes.contentSupportedText}
                              data-gid="14665681"
                            >
                              Yes
                            </div>
                          ) : (
                            <div
                              className={classes.contentSupportedText}
                              data-gid="14665681"
                            >
                              No
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'tag',
                    heading: 'Actions',
                    align: 'center',
                    content: (row) => (
                      <ActionBar
                        isTagged={!!row?.taggedAt ?? false}
                        position="relative"
                        onTag={async (tagged) => {
                          if (tagged && row?.id) {
                            await removeClueTag({
                              variables: {
                                input: {
                                  clueId: row.id,
                                  extractionId: currentExtractionId,
                                  clueType: ClueTypes.CALL,
                                },
                              },
                              optimisticResponse: {
                                removeClueTag: {
                                  clueId: row.id,
                                  __typename: 'RemoveClueTagPayload',
                                },
                              },
                              update: (proxy) => {
                                const rqData = proxy.readQuery<
                                  installedAppById,
                                  { id: string; extractionId: string }
                                >({
                                  query: getInstalledAppById,
                                  variables: {
                                    id: row.id,
                                    extractionId: currentExtractionId as string,
                                  },
                                });

                                if (rqData?.installedAppById) {
                                  proxy.writeQuery({
                                    query: getInstalledAppById,
                                    data: {
                                      installedAppById: {
                                        ...rqData.installedAppById,
                                        taggedAt: null,
                                        taggedBy: null,
                                      },
                                    },
                                    variables: {
                                      contactById: row.id,
                                      extractionId: currentExtractionId,
                                    },
                                  });
                                }
                              },
                            });
                          } else if (row?.id) {
                            await tagInstalledAppClue({
                              variables: {
                                input: {
                                  clueId: row.id,
                                  extractionId: currentExtractionId,
                                },
                              },
                              optimisticResponse: {
                                tagInstalledAppClue: {
                                  installedApp: {
                                    id: row.id,
                                    taggedAt: DateTime.now().toISO(),
                                    taggedBy: {
                                      id: user?.id ?? '',
                                      firstName: user?.firstName ?? '',
                                      lastName: user?.lastName ?? '',
                                      email: user?.email ?? '',
                                      __typename: 'User',
                                    },
                                    __typename: 'InstalledApp',
                                  },
                                  __typename: 'TagInstalledAppCluePayload',
                                },
                              },
                              // update: (proxy) => {},
                            });
                          }
                        }}
                        // onTag={async (tagged) => {
                        //   await (tagged
                        //     ? removeClueTag({
                        //         variables: {
                        //           input: {
                        //             clueId: row.id,
                        //             extractionId: currentExtractionId,
                        //             clueType: ClueTypes.INSTALLED_APP,
                        //           },
                        //         },
                        //       })
                        //     : tagInstalledAppClue({
                        //         variables: {
                        //           input: {
                        //             clueId: row.id,
                        //             extractionId: currentExtractionId,
                        //           },
                        //         },
                        //       }));
                        // }}
                        data-gid="39832061"
                      />
                    ),
                  },
                ]}
                hoverable
                size="small"
                noRecordsMessage="No Installed Apps Found"
                data-gid="68242639"
              />
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
};

/* eslint-enable sonarjs/cognitive-complexity */
export default InstalledApps;
