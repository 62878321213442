import { gql } from '@apollo/client';

export const getMessageThreadTypes = gql`
  query appByMessageThreadType(
    $extractionId: UUID!
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    appsByMessageThreadType(
      extractionId: $extractionId
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      appDisplayName
      hits
    }
  }
`;

export const getVideoTypes = gql`
  query appByVideoType(
    $extractionId: UUID!
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    appsByVideoType(
      extractionId: $extractionId
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      appDisplayName
      hits
    }
  }
`;

export const getAllInstalledAppsByExtractionId = gql`
  query allInstalledAppsByExtractionId(
    $extractionId: UUID!
    $sortField: InstalledAppsSortField!
    $ascending: Boolean!
    $searchTerm: String!
  ) {
    allInstalledAppsByExtractionId(
      extractionId: $extractionId
      sortField: $sortField
      ascending: $ascending
      searchTerm: $searchTerm
    ) {
      id
      displayName
      version
      contentIsSupported
      bundleId
      iconUrl
      taggedAt
      sourceFiles {
        id
        name
        checksum
        extension
        enclosingDirectory
        sizeBytes
        atime
        mtime
        ctime
      }
    }
  }
`;

export const getInstalledAppById = gql`
  query installedAppById($id: UUID!, $extractionId: String!) {
    installedAppById(id: $id, extractionId: $extractionId) {
      id
      displayName
      version
      bundleId
      iconUrl
      taggedAt
      taggedBy {
        id
        firstName
        lastName
        email
      }
      sourceFiles {
        id
        name
        checksum
        extension
        enclosingDirectory
        sizeBytes
        atime
        mtime
        ctime
      }
      notes {
        id
        text
        recordCreatedAt
        createdBy {
          id
          firstName
          lastName
        }
        archivedAt
        isOwner
      }
    }
  }
`;
