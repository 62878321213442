import { ReactElement } from 'react';
import { useRecoilState } from 'recoil';

import BrowserHistoryDetails from './ClueDetailsSlideoutContentBrowserHistory';
import CallClueDetails from './ClueDetailsSlideoutContentCall';
import ContactClueDetails from './ClueDetailsSlideoutContentContacts';
import InstalledAppDetails from './ClueDetailsSlideoutContentInstalledApp';
import LocationClueDetails from './ClueDetailsSlideoutContentLocation';
import MediaClueDetails from './ClueDetailsSlideoutContentMedia';
import MemoClueDetails from './ClueDetailsSlideoutContentMemo';
import MessageClueDetails from './ClueDetailsSlideoutContentMessages';
import { clueDetailsSlideoutDataAtom } from './lib/atoms';
// import { useQueryParams } from './useQueryParams';

function ClueDetailsSlideoutContainer(): ReactElement | null {
  const [clueDetailsSlideOutData] = useRecoilState(clueDetailsSlideoutDataAtom);
  const { clueId, clueType, clueSubType } = clueDetailsSlideOutData;

  const isArray = Array.isArray(clueId);

  switch (clueType) {
    case 'Call':
      return (
        <CallClueDetails clueId={!isArray ? clueId : ''} data-gid="80213279" />
      );

    case 'Memo':
      return (
        <MemoClueDetails clueId={!isArray ? clueId : ''} data-gid="54848857" />
      );

    case 'Contact':
      return (
        <ContactClueDetails
          clueId={!isArray ? clueId : ''}
          data-gid="61601715"
        />
      );

    case 'Location':
      return <LocationClueDetails clueIds={clueId} />;

    case 'Message':
      return (
        <MessageClueDetails
          clueId={!isArray ? clueId : ''}
          data-gid="40837582"
        />
      );

    case 'Media':
      return (
        <MediaClueDetails
          clueId={!isArray ? clueId : ''}
          clueSubType={clueSubType}
          data-gid="73404732"
        />
      );

    case 'Installed App':
      return <InstalledAppDetails clueId={!isArray ? clueId : ''} />;

    case 'Browser History':
      return (
        <BrowserHistoryDetails
          clueId={!isArray ? clueId : ''}
          data-gid="88870984"
        />
      );

    default:
      return null;
  }
}

export default ClueDetailsSlideoutContainer;
