import { gql } from '@apollo/client';

export const getReviewCases = gql`
  query getCases {
    getCases {
      cases {
        createdAt
        deletedAt
        evidenceSourceCount
        id
        longDescription
        name
        shortDescription
        tenantId
        timezone
      }
    }
  }
`;

export const getReviewLink = gql`
  query getReviewLink($extractionId: String!) {
    getReviewLink(extractionId: $extractionId) {
      link
    }
  }
`;

export const getHasReviewUser = gql`
  query hasReviewUser {
    hasReviewUser {
      hasReviewUser
    }
  }
`;

export const sendReplayToReview = gql`
  mutation replayToReview($extractionId: UUID!) {
    replayToReview(extractionId: $extractionId) {
      hasReplayed
    }
  }
`;

export const getReviewForwardingEnabled = gql`
  query reviewForwardingEnabled {
    reviewForwardingEnabled
  }
`;

export const reviewConfigInfo = gql`
  query getReviewConfigInfo {
    getReviewConfigInfo {
      reviewForward
      reviewApiUrl
      reviewOldestReplayExtractionDate
    }
  }
`;
