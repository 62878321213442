import { gql, useLazyQuery } from '@apollo/client';

import { messageThreadPageForMessage } from './graphql/generated/messageThreadPageForMessage';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';

const getMessagePageQuery = gql`
  query messageThreadPageForMessage(
    $messageId: UUID!
    $messageThreadId: UUID!
    $extractionId: UUID!
  ) {
    messageThreadPageForMessage(
      messageId: $messageId
      messageThreadId: $messageThreadId
      extractionId: $extractionId
    ) {
      page
    }
  }
`;

export const usePageForMessage = (): {
  getPage: (messageId: string, messageThreadId: string) => Promise<number>;
  navigateToMessageInThread: (messageInfo: {
    messageId?: string;
    messageThreadId: string;
  }) => Promise<void>;
} => {
  const { setActiveMessageThread } = useQueryParams();
  const [currentExtractionId] = useCurrentExtractionId();

  const [queryForPage] =
    useLazyQuery<messageThreadPageForMessage>(getMessagePageQuery);

  const getPage = async (
    messageId: string,
    messageThreadId: string,
  ): Promise<number> => {
    const { data } = await queryForPage({
      variables: {
        messageId,
        messageThreadId,
        extractionId: currentExtractionId,
      },
    });

    const {
      messageThreadPageForMessage: { page },
    } = data ?? { messageThreadPageForMessage: { page: 1 } };

    return page;
  };

  const navigateToMessageInThread = async ({
    messageId,
    messageThreadId,
  }: {
    messageId?: string;
    messageThreadId: string;
  }): Promise<void> => {
    if (messageId) {
      const page = await getPage(messageId, messageThreadId);
      setActiveMessageThread(messageThreadId, messageId, page);
    } else {
      setActiveMessageThread(messageThreadId);
    }
  };

  return { getPage, navigateToMessageInThread };
};
