import { gql } from '@apollo/client';

export const updateExtractionAccessType = gql`
  mutation UpdateExtractionAccessTypeByUserId(
    $type: ExtractionAccessTypeEnum!
    $userId: UUID!
    $extractionId: UUID!
  ) {
    updateExtractionAccessType(
      input: { type: $type, userId: $userId, extractionId: $extractionId }
    ) {
      id
      type
    }
  }
`;
