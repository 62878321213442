import { gql } from '@apollo/client';

export const createNoteMutation = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        id
        text
        extractionId
      }
    }
  }
`;

export const updateNoteMutation = gql`
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
        text
        extractionId
      }
    }
  }
`;

export const archiveNoteMutation = gql`
  mutation ArchiveNote($input: ArchiveNoteInput!) {
    archiveNote(input: $input) {
      note {
        id
        text
        extractionId
      }
    }
  }
`;
