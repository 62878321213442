import { makeStyles } from '@grayshift/cairn';
import React, { memo } from 'react';

import { ExtractionDashboardWidgets } from './ExtractionDashboardWidgets';
import { ExtractionDeviceInfoWidget } from './ExtractionDeviceInfoWidget';
import { ExtractionDeviceOwnerWidget } from './ExtractionDeviceOwnerWidget';
import { ExtractionInfoWidget } from './ExtractionInfoWidget';
import { Layout } from './Layout';
import useDocumentTitle from './lib/useDocumentTitle';
import { TaggedCluesWidget } from './TaggedCluesWidget';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  largeWidgetRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '80%',
    overflow: 'auto',
  },
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const ExtractionDashboard: React.FC = memo(() => {
  const classes = useStyles();

  useDocumentTitle('Dashboard');

  return (
    <Layout data-gid="70240139">
      <div className={classes.container} data-gid="43032981">
        <div className={classes.content} data-gid="79048098">
          <ExtractionInfoWidget data-gid="13167231" />
          <ExtractionDashboardWidgets data-gid="83602650" />

          <div className={classes.largeWidgetRow} data-gid="93401637">
            <ExtractionDeviceOwnerWidget data-gid="58737207" />
            <ExtractionDeviceInfoWidget data-gid="67604420" />
            <TaggedCluesWidget data-gid="61460966" />
          </div>
        </div>
      </div>
    </Layout>
  );
});

export default ExtractionDashboard;
