/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountUserRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum AccountUsersSortField {
  accountUserRole = "accountUserRole",
  email = "email",
  firstName = "firstName",
  isEntitled = "isEntitled",
  lastName = "lastName",
}

export enum AllContactsSortField {
  app = "app",
  contactVolume = "contactVolume",
  messageVolume = "messageVolume",
  primaryDisplayName = "primaryDisplayName",
  timestamp = "timestamp",
  topContacts = "topContacts",
}

export enum BrowserHistorySortField {
  browser = "browser",
  timestamp = "timestamp",
  title = "title",
  url = "url",
}

export enum ClueTypes {
  BROWSER_HISTORY = "BROWSER_HISTORY",
  CALL = "CALL",
  CONTACT = "CONTACT",
  IMAGE = "IMAGE",
  INSTALLED_APP = "INSTALLED_APP",
  LOCATION = "LOCATION",
  MEMO = "MEMO",
  MESSAGE = "MESSAGE",
  VIDEO = "VIDEO",
}

export enum ContentSupportedEnum {
  notSupported = "notSupported",
  supported = "supported",
  supportedPostExtraction = "supportedPostExtraction",
  supportedWithoutData = "supportedWithoutData",
}

export enum ExtractionAccessRequestStatusEnum {
  DENIED = "DENIED",
  GRANTED = "GRANTED",
  REQUESTED = "REQUESTED",
}

export enum ExtractionAccessType {
  EDITOR = "EDITOR",
  OWNER = "OWNER",
}

export enum ExtractionAccessTypeEnum {
  EDITOR = "EDITOR",
  OWNER = "OWNER",
  READ_ONLY = "READ_ONLY",
}

export enum ExtractionAuditLogEventEnum {
  CALL_LOG_EXPORT = "CALL_LOG_EXPORT",
  CLUE_TAGGED = "CLUE_TAGGED",
  CLUE_UNTAGGED = "CLUE_UNTAGGED",
  DENIED_EXTRACTION_ACCESS = "DENIED_EXTRACTION_ACCESS",
  EXTRACTION_INVITE_ACCEPTED = "EXTRACTION_INVITE_ACCEPTED",
  EXTRACTION_INVITE_CREATED = "EXTRACTION_INVITE_CREATED",
  EXTRACTION_SELECTED = "EXTRACTION_SELECTED",
  GRANTED_EXTRACTION_ACCESS = "GRANTED_EXTRACTION_ACCESS",
  LOCATION_EXPORT = "LOCATION_EXPORT",
  NOTE_ARCHIVED = "NOTE_ARCHIVED",
  NOTE_CREATED = "NOTE_CREATED",
  NOTE_UPDATED = "NOTE_UPDATED",
  REPORT_LOCKED = "REPORT_LOCKED",
  REPORT_SAVED = "REPORT_SAVED",
  REPORT_UNLOCKED = "REPORT_UNLOCKED",
  REQUESTED_EXTRACTION_ACCESS = "REQUESTED_EXTRACTION_ACCESS",
}

export enum ExtractionSourceEnum {
  GRAYKEY = "GRAYKEY",
  VERAKEY = "VERAKEY",
}

export enum ExtractionState {
  ABORTED = "ABORTED",
  ABORTED_DISCONNECT = "ABORTED_DISCONNECT",
  ABORTED_ERROR = "ABORTED_ERROR",
  ABORTED_FAILURE = "ABORTED_FAILURE",
  ABORTED_USER = "ABORTED_USER",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
  QUEUED = "QUEUED",
  STALLED = "STALLED",
  TOS_TIMEOUT = "TOS_TIMEOUT",
  TOS_UNSIGNED = "TOS_UNSIGNED",
}

export enum GlobalAuditLogEventEnum {
  CALL_LOG_EXPORT = "CALL_LOG_EXPORT",
  CSAM_DETECTION_DISABLED = "CSAM_DETECTION_DISABLED",
  CSAM_DETECTION_ENABLED = "CSAM_DETECTION_ENABLED",
  EXTRACTION_SELECTED = "EXTRACTION_SELECTED",
  LOCATION_EXPORT = "LOCATION_EXPORT",
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
}

export enum ImageAnnotationEnum {
  CSAM = "CSAM",
  EXPLICIT = "EXPLICIT",
  NUDITY = "NUDITY",
}

export enum InstalledAppsSortField {
  contentIsSupported = "contentIsSupported",
  contentWasSupported = "contentWasSupported",
  displayName = "displayName",
}

export enum InvitationAccessType {
  EDITOR = "EDITOR",
  OWNER = "OWNER",
  READ_ONLY = "READ_ONLY",
}

export enum InviteStatus {
  ACCEPTED = "ACCEPTED",
  DISMISSED = "DISMISSED",
  INVALID_OR_EXPIRED_CODE = "INVALID_OR_EXPIRED_CODE",
}

export enum MediaCategory {
  Image = "Image",
  Video = "Video",
}

export enum PendingUsersSortField {
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
}

export enum Sort {
  asc = "asc",
  desc = "desc",
}

export enum VideoAnnotationEnum {
  CSAM = "CSAM",
  EXPLICIT = "EXPLICIT",
  NUDITY = "NUDITY",
}

export interface AddUserInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ArchiveExtractionInput {
  id: GQLUUID;
  archive: boolean;
}

export interface ArchiveNoteInput {
  id: GQLUUID;
  extractionId: GQLUUID;
}

export interface CreateCaseAndUpdateExtractionReviewInfoInput {
  extractionId: GQLUUID;
  reviewCaseName: string;
  isReviewEnabled: boolean;
  sendToReviewDecision?: boolean | null;
  accountId: string;
}

export interface CreateExtractionAuditLogEventInput {
  action: ExtractionAuditLogEventEnum;
  data: GQLJSONObject;
  extractionId: GQLUUID;
}

export interface CreateNoteInput {
  note: string;
  extractionId: GQLUUID;
  clueType: ClueTypes;
  clueId: GQLUUID;
}

export interface InRangeTimeframe {
  messageTimeframe?: TimeFrame | null;
  locationTimeframe?: TimeFrame | null;
  callTimeframe?: TimeFrame | null;
  memoTimeframe?: TimeFrame | null;
  mediaTimeframe?: TimeFrame | null;
  browserHistoryTimeframe?: TimeFrame | null;
}

export interface InvitationInput {
  email: string;
  accessType: InvitationAccessType;
  sharedTimeframe?: TimeFrame | null;
}

export interface ReactivateExtractionInput {
  extractionId: GQLUUID;
}

export interface RemoveClueTagInput {
  clueId: string;
  clueType: string;
  extractionId: string;
}

export interface RemoveMessagesClueTagsInput {
  ids: string[];
  extractionId: string;
}

export interface ReportClue {
  id: GQLUUID;
  included: boolean;
}

export interface SaveReportConfigInput {
  id: GQLUUID;
  extractionId: GQLUUID;
  showNotes?: boolean | null;
  locked?: boolean | null;
  caseName?: string | null;
  caseId?: string | null;
  evidenceId?: string | null;
  legalAuthority?: string | null;
  crimeType?: string | null;
  reportStatus?: string | null;
  reportDescription?: string | null;
}

export interface SaveReportInput {
  id?: GQLUUID | null;
  callIds: ReportClue[];
  memoIds: ReportClue[];
  contactIds: ReportClue[];
  messageIds: ReportClue[];
  locationIds: ReportClue[];
  imageIds: ReportClue[];
  videoIds: ReportClue[];
  installedAppIds: ReportClue[];
  browserHistoryIds: ReportClue[];
  extractionId: GQLUUID;
}

export interface TagBrowserHistoryClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagCallClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagContactClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagImageClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagInstalledAppClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagLocationClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagMediaClueInput {
  clueId: string;
  extractionId: string;
  mediaType: string;
}

export interface TagMemoClueInput {
  clueId: string;
  extractionId: string;
}

export interface TagMessagesClueInput {
  ids: string[];
  extractionId: string;
}

export interface TimeFrame {
  startDate: GQLDateTime;
  endDate: GQLDateTime;
}

export interface UpdateExtractionAccessRequestStatusInput {
  userId: GQLUUID;
  extractionId: GQLUUID;
  status: ExtractionAccessRequestStatusEnum;
  accessType?: ExtractionAccessTypeEnum | null;
  sharedTimeframe?: TimeFrame | null;
}

export interface UpdateExtractionMaskCsamInput {
  extractionId: GQLUUID;
  maskCsam: boolean;
}

export interface UpdateExtractionReviewInfoInput {
  extractionId: GQLUUID;
  reviewCaseId?: string | null;
  isReviewEnabled: boolean;
  sendToReviewDecision?: boolean | null;
  accountId: string;
}

export interface UpdateNoteInput {
  id: GQLUUID;
  text: string;
  extractionId: GQLUUID;
}

export interface UpdateUserMarketingOptInInput {
  id: GQLUUID;
  optIn: boolean;
}

export interface UpdateUserTosInput {
  id: GQLUUID;
}

export interface upsertAccountUserInput {
  role: AccountUserRole;
  isEntitled: boolean;
  userId: GQLUUID;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
export type ProjectName = 'ZEUS';
