import { useQuery } from '@apollo/client';
import {
  makeStyles,
  MenuItem,
  RadioGroup,
  Select,
  TextBox,
} from '@grayshift/cairn';
import { FormControl, FormControlLabel, Radio } from '@material-ui/core';
import { Dispatch, SetStateAction, useState } from 'react';

import {
  getCases,
  getCases_getCases_cases,
} from '../graphql/generated/getCases';
import { getReviewCases } from '../graphql/queries/reviewLink';

const useStyles = makeStyles({
  inputContainer: {
    height: '3rem',
  },
});

interface Props {
  newCaseName: string | null;
  setNewCaseName: Dispatch<SetStateAction<string | null>>;
  setSelectedCase: Dispatch<SetStateAction<string | null | undefined>>;
  caseTypeEnum: string;
  setCaseTypeEnum: Dispatch<SetStateAction<string>>;
  hasReviewUser: boolean | undefined;
}

const SendToReview: React.FC<Props> = ({
  newCaseName,
  setNewCaseName,
  setSelectedCase,
  caseTypeEnum,
  setCaseTypeEnum,
  hasReviewUser,
}) => {
  const classes = useStyles();

  const [selectedDisplayName, setSelectedDisplayName] = useState<string | null>(
    '',
  );

  let caseList: getCases_getCases_cases[] | null | undefined;
  const { data: reviewCaseList } = useQuery<getCases>(getReviewCases);
  if (hasReviewUser === true) {
    caseList = reviewCaseList?.getCases.cases;
  }

  return (
    <div data-gid="27679281">
      <div style={{ marginTop: '2rem' }} data-gid="32135310">
        <FormControl style={{ minHeight: '0' }} data-gid="32621702">
          <RadioGroup
            defaultValue="create"
            name="review-case-type-group"
            row
            onChange={(e) => {
              setNewCaseName(null);
              setSelectedCase(null);
              setSelectedDisplayName(null);
              setCaseTypeEnum(e.target.value);
            }}
            data-gid="80618386"
          >
            <FormControlLabel
              disabled={!hasReviewUser}
              value="create"
              control={<Radio data-gid="63233922" />}
              label="Create New REVIEW Case"
              data-gid="74723688"
            />
            <FormControlLabel
              disabled={!caseList}
              value="associate"
              control={<Radio data-gid="20397953" />}
              label="Add to Existing REVIEW Case"
              data-gid="69581476"
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.inputContainer} data-gid="55653243">
          {caseTypeEnum === 'associate' && caseList?.length ? (
            <div style={{ padding: '8px 0 4px 0' }} data-gid="60972995">
              <Select
                disabled={!caseList}
                labelId="caseSelect"
                value={selectedDisplayName}
                onChange={(e) => {
                  const fullCase = caseList?.find(
                    (caseIndex) => caseIndex.name === e.target.value,
                  );
                  setSelectedCase(JSON.stringify(fullCase?.id));
                  setSelectedDisplayName(fullCase?.name ?? null);
                }}
                data-gid="29945626"
              >
                {caseList.map((caseIndex) => (
                  <MenuItem
                    key={caseIndex.name}
                    value={caseIndex.name}
                    data-gid="85042205"
                  >
                    {caseIndex.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div data-gid="29778382">
              <TextBox
                disabled={!hasReviewUser}
                value={newCaseName}
                onChange={(e) => setNewCaseName(e.target.value)}
                data-gid="49717074"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendToReview;
