import { SecureRoute } from '@okta/okta-react';
import { OnAuthRequiredFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { ErrorBoundary } from 'react-error-boundary';
import * as ReactRouterDom from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ErrorFallback } from './ErrorBoundary';
import { ExtractionExpired } from './ExtractionExpired';
import { extractionAccess as ExtractionAccessData } from './graphql/generated/extractionAccess';
import { extractionAccessQuery } from './graphql/queries';
import { Layout } from './Layout';
import { extractionAccessAtom, userInfoAtom } from './lib/atoms';
import { isISOTimestampExpired } from './lib/date';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { RequestAccess } from './RequestAccess';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

export const SecureAppRoute: React.FC<
  {
    // eslint-disable-next-line react/require-default-props
    onAuthRequired?: OnAuthRequiredFunction;
    // eslint-disable-next-line react/require-default-props
    errorComponent?: React.ComponentType<{
      error: Error;
    }>;
  } & ReactRouterDom.RouteProps &
    React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...rest }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [extractionId, _, isValidExtractionId] = useExtractionId();
  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;
  const extractionAccess = useRecoilValue(extractionAccessAtom);

  const { data } = useQueryWithErrorBoundary<ExtractionAccessData>(
    extractionAccessQuery,
    {
      variables: { extractionId },
      skip: !isValidExtractionId() || !extractionAccess,
    },
  );

  const extractionIsExpired =
    data &&
    !entitledAccess &&
    data?.extractionById?.expiresAt &&
    isISOTimestampExpired(data?.extractionById?.expiresAt);

  const location = useLocation();
  const onUserMgmt = location.pathname.includes('/user-management'); // User management is account bound and does not consider Extraction Access

  if (!extractionAccess && !onUserMgmt) {
    return (
      <SecureRoute {...rest}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Layout disableLinks data-gid="44321898">
            <RequestAccess data-gid="28917524" />
          </Layout>
        </ErrorBoundary>
      </SecureRoute>
    );
  }

  if (extractionIsExpired) {
    return (
      <SecureRoute {...rest}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ExtractionExpired data-gid="18122729" />
        </ErrorBoundary>
      </SecureRoute>
    );
  }

  return (
    <SecureRoute {...rest}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </SecureRoute>
  );
};
