import { makeStyles, Text } from '@grayshift/cairn';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React from 'react';

const useStyles = makeStyles({
  metadataSection: {
    marginTop: 25,
  },
  metadataSectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    cursor: (collapsed) =>
      // it seems that jss coerces the argument to '{}' when passed in as 'undefined'
      // this expression "works", but if it worked as expected it would look like:
      // return collapsed !== undefined ? 'pointer' : 'auto';
      typeof collapsed === 'boolean' ? 'pointer' : 'auto',
  },
});
interface metadataSection {
  title: string;
  collapsed?: boolean;
  onClickCollapse?: () => void;
}

const ClueDetailsSlideoutMetadataSection: React.FC<metadataSection> = ({
  title,
  children,
  collapsed,
  onClickCollapse,
  ...rest
}) => {
  const classes = useStyles(collapsed);
  return (
    <div className={classes.metadataSection} data-gid="71190864" {...rest}>
      <div
        className={classes.metadataSectionTitle}
        onClick={onClickCollapse}
        role="presentation"
        data-gid="44677957"
      >
        <Text weight={500} data-gid="22102201">
          {title}
        </Text>
        {collapsed !== undefined &&
          (collapsed === true ? (
            <KeyboardArrowDown color="primary" data-gid="61465098" />
          ) : (
            <KeyboardArrowUp color="primary" data-gid="10847156" />
          ))}
      </div>
      {(collapsed === false || collapsed === undefined) && children}
    </div>
  );
};
ClueDetailsSlideoutMetadataSection.defaultProps = {
  collapsed: undefined,
  onClickCollapse: undefined,
};

export default ClueDetailsSlideoutMetadataSection;
