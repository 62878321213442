import { atom } from 'recoil';

import { allExtractions_allExtractions_edges_node } from '../graphql/generated/allExtractions';
import { extractionByIdQuery_extractionById } from '../graphql/generated/extractionByIdQuery';
import { getUserInfo_getUserInfo } from '../graphql/generated/getUserInfo';
import { ExtractionAccessTypeEnum } from '../graphql/generated/globalTypes';
import { reportTaggedClues_allTaggedClues_edges_node_Contact_taggedBy } from '../graphql/generated/reportTaggedClues';
import { TimelineEventTypes } from '../types';

export enum ClueType {
  MEMO = 'Memo',
  MESSAGE = 'Message',
  LOCATION = 'Location',
  MEDIA = 'Media',
  CALL = 'Call',
  CONTACT = 'Contact',
  INSTALLED_APP = 'Installed App',
  BROWSER_HISTORY = 'Browser History',
}

export const clueDetailsSlideoutDataAtom = atom<{
  slideoutOpen: boolean;
  clueId: string | string[];
  clueType: ClueType | null;
  clueSubType?: string;
  locationCount?: number;
  onCloseCallback?: () => void;
  onOpenCallback?: () => void;
}>({
  key: 'clueDetailsSlideoutDataAtom',
  default: {
    slideoutOpen: false,
    clueId: '',
    clueType: null,
    clueSubType: undefined,
    onCloseCallback: () => null,
    onOpenCallback: () => null,
  },
});

interface clueHoverTooltipDataType {
  clueId?: string;
  clueType?: string;
  data: {
    latitude?: string;
    longitude?: string;
    textContent?: string;
    sender?: string;
    image?: string;
    threadDisplayName?: string;
  };
}

export const clueHoverTooltipDataAtom = atom({
  key: 'clueHoverTooltipDataAtom',
  default: {} as clueHoverTooltipDataType,
});

export const extractionSelectionModalIsOpenAtom = atom({
  key: 'extractionSelectionModalisOpenAtom',
  default: false,
});

export const tryProModalAtom = atom({
  key: 'tryProModal',
  default: false,
});

export const hideBiomeMessagesAtom = atom({
  key: 'hideBiomeMessagesAtom',
  default: false,
});

export const extractionShareModalControllerAtom = atom({
  key: 'extractionShareModalControllerAtom',
  default: {
    isOpen: false,
    shareModalWasEntry: false,
    extraction: {} as
      | extractionByIdQuery_extractionById
      | allExtractions_allExtractions_edges_node,
  },
});

export const sendToReviewModalControllerAtom = atom({
  key: 'sendToReviewModalControllerAtom',
  default: {
    isOpen: false,
    isReplay: false,
  },
});

export type TimeFrame = {
  globalTimeframe: { start: null | Date; end: null | Date };
} | null;

export const timeFrameStateAtom = atom<TimeFrame>({
  key: 'timeFrameState',
  default: {
    globalTimeframe: { start: null, end: null },
  },
});

export const currentGlobalSearchTextAtom = atom<{
  currentGlobalSearchText: string;
}>({
  key: 'globalSearchTextState',
  default: { currentGlobalSearchText: '' },
});

export const currentGlobalFiltersActiveAtom = atom<{
  timeframeActive: boolean;
  searchActive: boolean;
}>({
  key: 'currentGlobalFiltersActive',
  default: {
    timeframeActive: false,
    searchActive: false,
  },
});
export type ExtractionAccessSharedTimeframe = {
  extractionAccessSharedTimeframe: {
    startDate: null | string;
    endDate: null | string;
  };
} | null;

export const extractionAccessSharedTimeframeAtom =
  atom<ExtractionAccessSharedTimeframe>({
    key: 'extractionAccessSharedTimeframeState',
    default: null,
  });

export const textSearchAtom = atom<{
  messageThreadParticipantName?: string | undefined;
  contactName?: string;
  mediaClassification?: string;
  extractionName?: string;
  callerName?: string;
  memoContent?: string;
}>({
  key: 'textSearchState',
  default: {
    messageThreadParticipantName: '',
    contactName: '',
    mediaClassification: '',
    extractionName: '',
    callerName: '',
    memoContent: '',
  },
});

export const currentMessageThreadIdAtom = atom<{
  currentMessageThreadId: string | undefined;
  currentMessageId: string | undefined;
}>({
  key: 'currentMessageThreadId',
  default: {
    currentMessageThreadId: '',
    currentMessageId: '',
  },
});

export const currentMemoIdAtom = atom<{
  currentMemoId: string | undefined;
}>({
  key: 'currentMemoId',
  default: {
    currentMemoId: '',
  },
});

export const showCsamAtom = atom<{
  showNcmecCsam?: boolean;
  showAiCsam?: boolean;
}>({
  key: 'showPotentialCsam',
  default: {
    showNcmecCsam: false,
    showAiCsam: false,
  },
});

export type AppFilterState = {
  messageAppFilter?: Array<string>;
  locationAppFilter?: Array<string>;
  contactAppFilter?: Array<string> | null;
  callAppFilter?: Array<string>;
  imageAppFilter?: Array<string>;
  videoAppFilter?: Array<string>;
};

export const appFilterStateAtom = atom<AppFilterState>({
  key: 'appFilterState',
  default: {
    messageAppFilter: [],
    imageAppFilter: [],
    videoAppFilter: [],
    locationAppFilter: [],
    contactAppFilter: null,
    callAppFilter: [],
  },
});

export const messageContentSearchOpen = atom<{
  searchMessageContentOpen: boolean;
}>({
  key: 'messageContentSearchIsOpenState',
  default: {
    searchMessageContentOpen: false,
  },
});

export const contactSortStateAtom = atom<{
  sortField: string;
  ascending: boolean;
}>({
  key: 'contactSortState',
  default: {
    sortField: 'Name',
    ascending: true,
  },
});

export const currViewStateAtom = atom<{
  longitude: number;
  latitude: number;
  zoom: number;
  maxZoom?: number;
  pitch?: number;
  bearing?: number;
  transitionDuration?: number;
  transitionInterpolator?: unknown;
}>({
  key: 'currViewState',
  default: {
    longitude: -100,
    latitude: 35,
    zoom: 3,
    maxZoom: 20,
    pitch: 30,
    bearing: 0,
  },
});

export const linkedLocationAtom = atom<{
  id: string;
  longitude: number | null;
  latitude: number | null;
  timestamp: string;
}>({
  key: 'linkedLocation',
  default: {
    id: '',
    longitude: null,
    latitude: null,
    timestamp: '',
  },
});

export const userInfoAtom = atom<getUserInfo_getUserInfo>({
  key: 'userInfo',
  default: {
    accountId: '',
    role: null,
    email: '',
    firstName: '',
    hasAcceptedTos: '',
    id: '',
    lastName: '',
    marketingOptIn: false,
    entitledAccounts: [],
    trialActiveUntil: null,
    hasActiveTrial: false,
    eligibleForTrial: false,
    __typename: 'User',
  },
});

export const timelineCategoryAtom = atom<{
  options: Array<TimelineEventTypes>;
}>({
  key: 'timelineCategory',
  default: {
    options: [
      'Calls',
      'Location',
      'Media',
      'Messages',
      'Memos',
      'BrowserHistory',
    ],
  },
});

export const reportCategoryAtom = atom<{ options: string[] }>({
  key: 'reportCategory',
  default: {
    options: [
      'Calls',
      'Memos',
      'Contact',
      'Location',
      'Media',
      'Messages',
      'InstalledApps',
      'BrowserHistory',
    ],
  },
});

export const reportTaggedByAtom = atom<{
  options: Array<reportTaggedClues_allTaggedClues_edges_node_Contact_taggedBy>;
  selectedOptions: Array<reportTaggedClues_allTaggedClues_edges_node_Contact_taggedBy>;
}>({
  key: 'reportTaggedBy',
  default: { options: [], selectedOptions: [] },
});

export type ReportSortByOptions =
  | 'date-desc'
  | 'date-asc'
  | 'taggedby-desc'
  | 'taggedby-asc'
  | 'artifactCategory-asc'
  | 'artifactCategory-desc';

export const reportSortByAtom = atom<ReportSortByOptions>({
  key: 'reportSortBy',
  default: 'date-desc',
});

export const singleMessageThreadAtom = atom<{
  id: string | null;
}>({
  key: 'singleMessageThread',
  default: {
    id: null,
  },
});

export const mediaCategoryFilterAtom = atom<{
  filterBy: string;
  gridView?: boolean;
  imagePage?: number;
  videoPage?: number;
}>({
  key: 'mediaCategoryFilter',
  default: {
    filterBy: 'Image',
    gridView: false,
    videoPage: 1,
    imagePage: 1,
  },
});

export const phoneOperatingSystemAtom = atom<{ os: string | null }>({
  key: 'phoneOperatingSystem',
  default: {
    os: null,
  },
});

export const locationExportDataAtom = atom<{
  kml: string | null;
  csv: (string | null)[][] | null;
}>({
  key: 'locationExportData',
  default: {
    kml: null,
    csv: null,
  },
});

export const currentExtractionAccessTypeAtom =
  atom<ExtractionAccessTypeEnum | null>({
    key: 'currentExtractionAccessType',
    default: null,
  });

export const clickToolTipAtom = atom<{
  clientX: number;
  clientY: number;
  message: string;
} | null>({
  key: 'clickToolTip',
  default: null,
});

export const sidebarCollapsedAtom = atom<boolean>({
  key: 'sidebarCollapsed',
  default: false,
});

export const extractionAccessAtom = atom<boolean>({
  key: 'extractionAccess',
  default: true,
});

export const isReviewEnabledAtom = atom<boolean | null>({
  key: 'isReviewEnabled',
  default: null,
});

export const reviewButtonStatusAtom = atom<string>({
  key: 'reviewButtonStatus',
  default: '',
});

export const reviewSignUpLinkAtom = atom<string>({
  key: 'reviewSignUpLink',
  default: '',
});
