import { gql } from '@apollo/client';

export const CoreCallFields = gql`
  fragment CoreCallFields on Call {
    id
    displayName
    timestamp
    duration
    originated
    answered
    taggedAt
    taggedBy {
      id
      firstName
      lastName
      email
    }
    app {
      id
      bundleId
      displayName
      iconUrl
      version
    }
    appBundleId
  }
`;
