import { Card, Colors, makeStyles, Text } from '@grayshift/cairn';
import LabelIcon from '@mui/icons-material/Label';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';

import { getAllTaggedClues } from './graphql/generated/getAllTaggedClues';
import { clueDetailsSlideoutDataAtom } from './lib/atoms';
import { eventTypeToClueType } from './lib/eventTypeToClueType';
import { generateTaggedClueEvents } from './lib/generateTimelineEvents';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { TimelineListView } from './TimelineListView';
import { TimelineEvent } from './types';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  taggedClues: {
    height: 'calc(100% - 50px)',
  },
  centeredFlexRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-5px',
  },
  loadingContainer: {
    display: 'flex',
    height: 400,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 30,
    marginBottom: 30,
  },
});

export const TaggedCluesWidget: React.FC = () => {
  const [currentExtractionId] = useExtractionId();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clueDetailsSlideoutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const classes = useStyles();

  const {
    data: taggedCluesData,
    loading: taggedCluesLoading,
    error: taggedCluesError,
  } = useQueryWithErrorBoundary<getAllTaggedClues>(allTaggedCluesQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      extractionId: currentExtractionId,
      page: 1,
      pageSize: 100_000,
    },
    skip: !currentExtractionId,
  });

  const taggedNodes = taggedCluesData?.allTaggedClues?.edges;
  const t = generateTaggedClueEvents(taggedNodes);
  const timelineEvents = Object.values(t);

  return (
    <Card
      fillColor="white"
      style={{
        marginLeft: '16px',
        padding: '16px',
        paddingBottom: '32px',
        overflow: 'hidden',
        flex: '1 1 0%',
      }}
      data-gid="26829536"
    >
      <div className={classes.centeredFlexRow} data-gid="16614904">
        <LabelIcon color="primary" fontSize="large" data-gid="63096415" />
        <div
          style={{ marginLeft: '8px', marginBottom: '1px' }}
          data-gid="94380873"
        >
          <Text weight={500} data-gid="81286621">
            Tagged Clues
          </Text>
        </div>
      </div>
      {taggedCluesLoading && !taggedCluesError && (
        <div className={classes.loadingContainer} data-gid="10421452">
          <ScaleLoader loading color={Colors.blue} data-gid="94045510" />
        </div>
      )}
      {timelineEvents?.length === 0 &&
        !taggedCluesError &&
        !taggedCluesLoading && (
          <Text
            style={{ textAlign: 'center', marginTop: '100px' }}
            data-gid="49623529"
          >
            No Clues Tagged
          </Text>
        )}
      {timelineEvents?.length > 0 &&
        !taggedCluesLoading &&
        !taggedCluesError && (
          <div className={classes.taggedClues} data-gid="11649092">
            <TimelineListView
              number={timelineEvents?.length || 0}
              data={timelineEvents || []}
              scrollToRef={null}
              selectedEventId=""
              onHover={() => null}
              onClick={(event: TimelineEvent) => {
                setClueDetailsSlideoutData({
                  slideoutOpen: true,
                  clueId: event.data.id,
                  clueType: eventTypeToClueType(event.type),
                  clueSubType: event.data.mediaType,
                });
              }}
              data-gid="59791798"
            />{' '}
          </div>
        )}
    </Card>
  );
};
