import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Colors,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Text,
} from '@grayshift/cairn';
import { FormGroup } from '@material-ui/core';
import { useState } from 'react';

import {
  updateUserHasAcceptedTos,
  updateUserMarketingOptIn,
} from './graphql/mutations/user';
import { publicStaticFileURL } from './lib/publicFile';

const useStyles = makeStyles({
  tosContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 900,
    height: '80%',
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  header: {
    alignSelf: 'center',
    paddingBottom: '10px',
  },

  footer: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: 30,
    cursor: 'pointer',
  },
});

interface Props {
  id: string;
}

const TosAgreement: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const [acceptTos] = useMutation(updateUserHasAcceptedTos);
  const [updateUserMarketOptIn] = useMutation(updateUserMarketingOptIn);
  const [marketingOptIn, setMarketingOptIn] = useState(true);
  const [localTosAcceptance, setLocalTosAcceptance] = useState(false);

  return (
    <Modal open data-gid="33791551">
      <div className={classes.tosContainer} data-gid="31877384">
        <div className={classes.header} data-gid="18024294">
          <Text size="8" data-gid="98400725">
            Terms of Service
          </Text>
        </div>
        <iframe
          title="ArtifactIQ - Terms of Service"
          style={{ height: '100%' }}
          data-gid="90562052"
          src={publicStaticFileURL('/Magnet Artifact IQ Terms of Service.pdf')}
        />
        <div className={classes.footer} data-gid="24193490">
          <FormGroup data-gid="30869170">
            <FormControlLabel
              control={
                <Checkbox
                  checked={localTosAcceptance}
                  onChange={() => setLocalTosAcceptance(!localTosAcceptance)}
                  data-gid="71075428"
                />
              }
              label="I have read, understood, and agree to be bound by the Artifact IQ Terms of Service and Magnet Forensics Privacy Policy."
              data-gid="91862198"
            />
            <br data-gid="79313469" />
            <Text data-gid="84328544">
              Magnet Forensics Sales and Marketing would like to keep in touch
              with you about product features and usage of your Artifact IQ
              software by email, post, SMS, phone and other electronic means.
              Magnet Forensics will treat your personal details with the utmost
              care and will never sell them to other companies for marketing
              purposes.
            </Text>
            <br data-gid="34663065" />
            <RadioGroup defaultValue="marketingOptIn" data-gid="85577378">
              <FormControlLabel
                value="marketingOptIn"
                onChange={() => {
                  setMarketingOptIn(true);
                }}
                control={<Radio id="marketingOptIn" data-gid="16713917" />}
                label="Yes, I'd like to keep in touch about new product features and usage."
                data-gid="35051497"
              />
              <FormControlLabel
                value="marketingOptOut"
                onChange={() => {
                  setMarketingOptIn(false);
                }}
                control={<Radio id="marketingOptOut" data-gid="44948908" />}
                label="No, I don't want to hear about new product features and usage."
                data-gid="18438179"
              />
            </RadioGroup>
          </FormGroup>
        </div>
        <br data-gid="26729799" />
        <Button
          color="primary"
          style={{ height: 100, width: 200, alignSelf: 'center' }}
          disabled={!localTosAcceptance}
          onClick={() => {
            // eslint-disable-next-line no-void
            void acceptTos({ variables: { input: { id } } });
            // eslint-disable-next-line no-void
            void updateUserMarketOptIn({
              variables: { input: { id, optIn: marketingOptIn } },
            });
          }}
          data-gid="42728490"
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default TosAgreement;
