/* eslint-disable @typescript-eslint/no-unused-vars, sonarjs/cognitive-complexity,  @typescript-eslint/no-redundant-type-constituents */
import { useMutation } from '@apollo/client';
import { Button, Chip, Colors, LinkButton, makeStyles } from '@grayshift/cairn';
import { Avatar } from '@material-ui/core';
import { Description } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import NotesIcon from '@mui/icons-material/Notes';
import PersonIcon from '@mui/icons-material/Person';
import {
  Alert,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useCachePersistor } from './CachePersistorContext';
import ExtractionProgressBar from './ExtractionProgressBar';
import { ExtractionSelection } from './ExtractionSelection';
import { extractionProgressText, generateTimeLeft } from './extractionText';
import { getReviewConfigInfo } from './graphql/generated/getReviewConfigInfo';
import {
  AccountUserRole,
  ExtractionAccessTypeEnum,
  ExtractionState,
} from './graphql/generated/globalTypes';
import { AuditLogUserLogoutEventMutation } from './graphql/queries/auditLog';
import { reviewConfigInfo } from './graphql/queries/reviewLink';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';
import {
  clueDetailsSlideoutDataAtom,
  currentExtractionAccessTypeAtom,
  extractionSelectionModalIsOpenAtom,
  reviewSignUpLinkAtom,
  userInfoAtom,
} from './lib/atoms';
import { getPrettyDateShortOffset } from './lib/getPrettyDate';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { publicStaticFileURL } from './lib/publicFile';
import ReviewLink from './review/ReviewLink';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  navBarRootContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '70px',
    backgroundColor: Colors.darkModeBackgroundPaper,
  },
  extractionStatusAlert: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F6AB2F',
    marginRight: '1rem',
  },
  logoContainer: {
    width: '250px',
    cursor: 'pointer',
    display: 'flex',
    margin: '5px 0 5px 0',
    borderRight: `1px solid ${Colors.nevada}`,
    alignItems: 'center',
    padding: '20px',
  },
  logoImg: {
    height: '45px',
    width: '138px',
  },
  selectExtractionBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
  },
  extractionInfoContainer: {
    paddingLeft: 15,
    whiteSpace: 'nowrap',
  },
  extractionProgressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -4,
  },
  extractionProgressBar: {
    height: '9px',
    width: '200px',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  extractionProgressText: { paddingLeft: '10px' },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    backgroundColor: Colors.darkModeBackgroundPaper,
    margin: '5px 0 5px 0',
  },
});

const ExtractionTopNavBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useExtraction();
  const { oktaAuth } = useOktaAuth();
  const cachePersistor = useCachePersistor();
  const [currentExtractionId] = useCurrentExtractionId();
  const [createAuditLogEvent] = useMutation(AuditLogUserLogoutEventMutation);

  const { data: reviewForward } =
    useQueryWithErrorBoundary<getReviewConfigInfo>(reviewConfigInfo);

  const [, setReviewSignUpLink] = useRecoilState(reviewSignUpLinkAtom);
  if (reviewForward?.getReviewConfigInfo) {
    setReviewSignUpLink(reviewForward?.getReviewConfigInfo.reviewApiUrl);
  }

  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const isEntitledAccessState = useRecoilValue(userInfoAtom);
  const entitledAccess = isEntitledAccessState.entitledAccounts.length > 0;

  const [_, setExtractionSelectionModalIsOpen] = useRecoilState(
    extractionSelectionModalIsOpenAtom,
  );

  const [__, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );

  const [userInfo] = useRecoilState(userInfoAtom);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [profileControlAnchorEl, setProfileControlAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const extraction = data?.extractionById;

  const isExpired =
    extraction?.expiresAt &&
    DateTime.fromISO(extraction?.expiresAt) < DateTime.now();

  const extractionTimeLeft = generateTimeLeft(extraction?.expiresAt, isExpired);

  const handleProfileControlClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setProfileControlAnchorEl(event.currentTarget);
  };

  const handleProfileControlClose = (): void => {
    setProfileControlAnchorEl(null);
  };

  const logout = async (): Promise<void> => {
    await createAuditLogEvent();
    window.aptrinsic('reset');
    await cachePersistor?.purge();
    await oktaAuth.signOut({
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI,
    });
  };

  const profileControlOpen = Boolean(profileControlAnchorEl);

  const { firstName, lastName, role, email } = userInfo;
  const firstChar = firstName && firstName?.length > 0 ? firstName[0] : '';
  const lastChar = lastName && lastName?.length > 0 ? lastName[0] : '';
  const avatarText = `${firstChar}${lastChar}`;

  const {
    state,
    percentComplete,
    aggregationsCompletedAt,
    lastRealActivityAt,
    evidenceId,
  } = data?.extractionById || {};

  const isComplete =
    state === ExtractionState.COMPLETE && aggregationsCompletedAt;

  const width = isComplete ? '100%' : `${percentComplete || 0}%`;

  const completedAt = aggregationsCompletedAt
    ? getPrettyDateShortOffset(aggregationsCompletedAt)
    : '';
  const lastActivity = lastRealActivityAt
    ? getPrettyDateShortOffset(lastRealActivityAt)
    : '';

  const extractionText = extractionProgressText(
    state,
    completedAt,
    lastActivity,
    percentComplete,
  );

  return (
    <div className={classes.navBarRootContainer} data-gid="66315722">
      <LinkButton
        onClick={() => history.push(`/dashboard${history.location.search}`)}
        data-gid="27340410"
      >
        <div className={classes.logoContainer} data-gid="96727964">
          <img
            className={classes.logoImg}
            src={publicStaticFileURL('/MagnetLogo.png')}
            alt="none"
            data-gid="78481351"
          />
        </div>
      </LinkButton>
      <div className={classes.extractionInfoContainer} data-gid="39836264">
        {data?.extractionById && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              data-gid="35449373"
            >
              <Typography
                fontFamily="Roboto"
                fontSize={16}
                sx={{ color: 'white', marginRight: 1 }}
                data-gid="67716991"
              >
                {evidenceId}{' '}
              </Typography>
              {currentExtractionAccessType && (
                <Chip
                  style={{ height: 15, borderRadius: 20, fontWeight: 400 }}
                  label={
                    currentExtractionAccessType.includes('_')
                      ? LowerAllButFirstLetter(
                          currentExtractionAccessType.split('_').join(' '),
                        )
                      : LowerAllButFirstLetter(currentExtractionAccessType)
                  }
                  data-gid="97001994"
                />
              )}
            </div>
            <div
              className={classes.extractionProgressBarContainer}
              data-gid="29286033"
            >
              <div
                className={classes.extractionProgressBar}
                data-gid="54409215"
              >
                <ExtractionProgressBar
                  state={state}
                  width={width}
                  complete={!!completedAt}
                  data-gid="98763080"
                />
              </div>
              <div
                className={classes.extractionProgressText}
                data-gid="43823244"
              >
                <Typography
                  fontFamily="Roboto"
                  fontSize={12}
                  sx={{ color: 'white' }}
                  data-gid="25682647"
                >
                  {extractionText}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classes.avatarContainer} data-gid="57488092">
        {extraction && !entitledAccess && (
          <Alert
            severity={isExpired ? 'error' : 'warning'}
            className={classes.extractionStatusAlert}
            data-gid="14882589"
            style={{
              borderRadius: '0px',
              height: '100%',
            }}
          >
            <Typography fontFamily="Roboto" fontSize={10} data-gid="29609576">
              {extractionTimeLeft}
              <br data-gid="17141555" />
              For full access,{' '}
              <a
                href="https://www.magnetforensics.com/resources/artifact-iq-upgrade/"
                data-gid="56807398"
                rel="noopener noreferrer"
                target="_blank"
              >
                upgrade to Pro.
              </a>
            </Typography>
          </Alert>
        )}
        {reviewForward?.getReviewConfigInfo.reviewForward &&
          data?.extractionById &&
          reviewForward?.getReviewConfigInfo.reviewOldestReplayExtractionDate <
            data?.extractionById?.recordCreatedAt &&
          currentExtractionAccessType !==
            ExtractionAccessTypeEnum.READ_ONLY && (
            <ReviewLink extraction={extraction} data-gid="49691278" />
          )}
        <ExtractionSelection data-gid="58325077" />
        <div
          className={classes.selectExtractionBtnContainer}
          data-gid="62819046"
        >
          <div
            onClick={() => setExtractionSelectionModalIsOpen(true)}
            role="presentation"
            onKeyDown={noop}
            style={{
              borderRadius: 5,
              display: 'flex',
              alignItems: 'center',
              color: 'white',
              height: '30px',
              cursor: 'pointer',
            }}
            data-gid="68986511"
          >
            <Description fontSize="small" data-gid="18181200" />
            <Typography
              style={{
                marginLeft: '.25vw',
                marginRight: '1rem',
              }}
              fontSize={14}
              data-gid="27582647"
            >
              {currentExtractionId ? 'Switch' : 'Open'} Extraction
            </Typography>
          </div>
        </div>
        <Button
          ref={anchorRef}
          id="profile-customized-button"
          aria-haspopup="true"
          variant="text"
          disableElevation
          style={{
            paddingLeft: 30,
            borderLeft: '1px solid #6B6C6F',
            borderRadius: 0,
            height: '30px',
          }}
          onClick={handleProfileControlClick}
          endIcon={
            <KeyboardArrowDownIcon
              style={{ color: 'white' }}
              data-gid="67078140"
            />
          }
          data-gid="82398824"
        >
          <Avatar
            style={{
              backgroundColor: Colors.green,
            }}
            data-gid="88300930"
          >
            {avatarText}
          </Avatar>
        </Button>
        <Popover
          id="profile-control-menu"
          anchorEl={profileControlAnchorEl}
          open={profileControlOpen}
          onClose={handleProfileControlClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          data-gid="68463099"
        >
          <MenuList
            style={{ background: Colors.darkModeBackgroundPaper }}
            data-gid="26399050"
          >
            <ListItemText
              style={{
                color: Colors.mystic,
                padding: 10,
                borderBottom: '2px solid white',
                margin: 0,
              }}
              data-gid="97854395"
            >
              {email}
            </ListItemText>
            <MenuItem onClick={logout} data-gid="37649141">
              <ListItemIcon data-gid="27026365">
                <LogoutIcon
                  style={{ color: Colors.mystic }}
                  data-gid="85567608"
                />
              </ListItemIcon>
              <ListItemText
                style={{ color: Colors.mystic }}
                data-gid="97854395"
              >
                Log Out
              </ListItemText>
            </MenuItem>
            {role === AccountUserRole.ADMIN && (
              <>
                <MenuItem
                  onClick={() => {
                    history.push(`/user-management${history.location.search}`);
                    setClueDetailsSlideoutData({
                      slideoutOpen: false,
                      clueId: '',
                      clueType: null,
                    });
                  }}
                  data-gid="20065560"
                >
                  <ListItemIcon data-gid="35944897">
                    <PersonIcon
                      style={{ color: Colors.mystic }}
                      data-gid="76646187"
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: Colors.mystic }}
                    data-gid="15332714"
                  >
                    User Management
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    history.push(`/audit-log${history.location.search}`)
                  }
                  data-gid="20065560"
                >
                  <ListItemIcon data-gid="35944897">
                    <NotesIcon
                      style={{ color: Colors.mystic }}
                      data-gid="76646187"
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: Colors.mystic }}
                    data-gid="15332714"
                  >
                    Audit Log
                  </ListItemText>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Popover>
      </div>
    </div>
  );
};

export default ExtractionTopNavBar;
