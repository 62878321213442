import { Colors, makeStyles } from '@grayshift/cairn';
import { Typography } from '@mui/material';
import noop from 'lodash/noop';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';

import { getUserInfo_getUserInfo } from '../graphql/generated/getUserInfo';
import { reviewSignUpLinkAtom } from '../lib/atoms';

const useStyles = makeStyles({
  signupLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem',
  },
});

interface Props {
  hasReviewUser: boolean | undefined;
  userInfo: getUserInfo_getUserInfo;
  refetchReviewUser: () => void;
  waitingForReviewSignup: boolean;
  setWaitingForReviewSignup: Dispatch<SetStateAction<boolean>>;
}

const SignUpToReview: React.FC<Props> = ({
  hasReviewUser,
  userInfo,
  refetchReviewUser,
  waitingForReviewSignup,
  setWaitingForReviewSignup,
}) => {
  const classes = useStyles();

  const reviewSignUpLink = useRecoilValue(reviewSignUpLinkAtom);

  const handleUserSignupPolling = (): void => {
    window.open(reviewSignUpLink, '_blank')?.focus();
    setWaitingForReviewSignup(true);
    let count = 0;
    const interval = setInterval(() => {
      count += 1;
      refetchReviewUser();
      if (hasReviewUser || count === 600) {
        clearInterval(interval);
        setWaitingForReviewSignup(false);
      }
    }, 1000);
  };

  return (
    <div data-gid="49179898">
      {hasReviewUser === false ? (
        <div className={classes.signupLinkContainer} data-gid="28683224">
          <Typography fontSize={14} data-gid="83687518">
            Don&apos;t have an account?
          </Typography>
          {!waitingForReviewSignup ? (
            <div
              onClick={() => {
                handleUserSignupPolling();
              }}
              role="presentation"
              onKeyDown={noop}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '.35vw',
                marginRight: '1rem',
                height: '30px',
                paddingRight: '1.5em',
                cursor: 'pointer',
              }}
              data-gid="71094619"
            >
              <Typography
                style={{
                  color: Colors.blue,
                }}
                fontSize={14}
                data-gid="71729118"
              >
                Sign up
              </Typography>
            </div>
          ) : (
            <div data-gid="93928615">
              <Typography
                fontSize={14}
                style={{ color: Colors.grayshift, marginLeft: '.5rem' }}
                data-gid="76657732"
              >
                Waiting on signup
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <Typography
          style={{ marginTop: '2rem' }}
          fontSize={14}
          data-gid="87724103"
        >{`We found a user in Review that matches the email address: ${userInfo.email}`}</Typography>
      )}
    </div>
  );
};

export default SignUpToReview;
