import { Colors, makeStyles, Text } from '@grayshift/cairn';
import { ReactElement } from 'react';
import { useRecoilState } from 'recoil';

import { currentMemoIdAtom } from './lib/atoms';
import useDocumentTitle from './lib/useDocumentTitle';
import MemoThreadContent from './MemoThreadContent';
import MemoThreadList from './MemoThreadList';

const useStyles = makeStyles({
  rootContainer: {
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    paddingTop: 20,
  },
  memoContainer: {
    backgroundColor: Colors.white,
    borderTop: `solid 1px ${Colors.divider}`,
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  leftPaneLoading: {
    height: '100%',
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    borderRight: `solid 1px ${Colors.divider}`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerWrapper: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    width: '100%',
  },
  threadListLoadingContainer: {
    height: '100%',
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacer: {
    height: 30,
  },
  sharedTimeframeAlert: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F6AB2F',
  },
});

const EmptyMemoThreadContent = (): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.threadListLoadingContainer} data-gid="17450705" />
  );
};

function Memos(): ReactElement {
  const classes = useStyles();
  useDocumentTitle('Memos');

  const [currentMemoState] = useRecoilState(currentMemoIdAtom);

  return (
    <div className={classes.outerWrapper} data-gid="15904973">
      <div className={classes.rootContainer} data-gid="16074167">
        <Text
          heading="h2"
          size="5"
          weight={500}
          style={{ marginLeft: '1.5em' }}
          data-gid="73836933"
        >
          Memos
        </Text>
        <div className={classes.spacer} data-gid="13836293" />
        <div className={classes.memoContainer} data-gid="24108142">
          <MemoThreadList data-gid="72937825" />
          {currentMemoState?.currentMemoId ? (
            <MemoThreadContent data-gid="23339138" />
          ) : (
            <EmptyMemoThreadContent data-gid="81969636" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Memos;
