/* eslint-disable  react/require-default-props */
import { Tooltip } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';

export const ReadOnlyTooltip = ({
  children,
  verb,
  bypass,
}: {
  verb?: string;
  bypass: boolean;
  children: ReactNode;
}): ReactElement =>
  bypass ? (
    <div data-gid="81064894">{children}</div>
  ) : (
    <div data-gid="94652933">
      <Tooltip
        title={`Cannot ${verb || 'modify'} in Read Only.`}
        style={{ zIndex: 100 }}
        data-gid="27242762"
      >
        <div data-gid="68033182">{children}</div>
      </Tooltip>
    </div>
  );
