/* eslint-disable @typescript-eslint/no-floating-promises */

import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Colors,
  IconButton,
  makeStyles,
  Modal,
  Text,
  TextBox,
} from '@grayshift/cairn';
import { Typography } from '@material-ui/core';
import { CheckCircleOutline, Close, HighlightOff } from '@mui/icons-material';
import { Alert, InputAdornment } from '@mui/material';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilState, useRecoilValue } from 'recoil';

import ExtractionSelectionModalCard from './ExtractionSelectionModalCard';
import { AcceptInvite } from './graphql/generated/AcceptInvite';
import { allExtractions } from './graphql/generated/allExtractions';
import { DismissInvite } from './graphql/generated/DismissInvite';
import {
  acceptInviteMutation,
  dismissInviteMutation,
} from './graphql/mutations';
import { allExtractionsQuery } from './graphql/queries';
import {
  clueDetailsSlideoutDataAtom,
  extractionSelectionModalIsOpenAtom,
  textSearchAtom,
  userInfoAtom,
} from './lib/atoms';
import { isISOTimestampExpired } from './lib/date';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { useResetAppState } from './useResetAppState';

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 900,
    height: '80%',
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  emptyModalContentContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  extractionCardContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'scroll',
    paddingRight: 5,
  },
  bottomButtonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  extractionSearch: {
    display: 'flex',
    paddingTop: 10,
    width: 380,
  },
  clearSearch: {
    position: 'relative',
    left: -30,
    cursor: 'pointer',
    paddingTop: 15,
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'pointer',
    top: 8,
    color: '#9EA0A5',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
});

const SelectExtraction = gql`
  mutation SelectExtraction {
    createAuditLogEvent(input: { action: EXTRACTION_SELECTED, data: {} }) {
      auditLog {
        id
      }
    }
  }
`;

function ExtractionSelectionModal(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentExtractionId, setCurrentExtractionId] =
    useCurrentExtractionId();

  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;

  const [extractionSelectionModalIsOpen, setExtractionSelectionModalIsOpen] =
    useRecoilState(extractionSelectionModalIsOpenAtom);
  const [, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const [textSearchState, setTextSearchState] = useRecoilState(textSearchAtom);

  const [temporarilyselectedExtractionId, setTemporaryilyselectedExtractionId] =
    useState<null | string>(null);

  const { resetRecoilState, resetApolloCache } = useResetAppState({});

  const [selectExtractionMutation] = useMutation(SelectExtraction);

  const fireResetOperations = async (): Promise<void> => {
    if (temporarilyselectedExtractionId) {
      // setCurrentExtractionId(temporarilyselectedExtractionId);
      // CRITICAL -- This history.push must fire before all subsequent reseting
      // of atom state (resetAppFilters, resetTimeFrameFilters etc)
      // because these filter resets will trigger re-renders in the page from
      // which you are leaving, which may kick off fetch requests that
      // populate state with wrongful data.
      // history.push(`/dashboard${history.location.search}`);

      setClueDetailsSlideoutData({
        slideoutOpen: false,
        clueId: '',
        clueType: null,
      });

      // Reset all relevant app state and apollo cache if switching extractions
      if (temporarilyselectedExtractionId !== currentExtractionId) {
        await resetApolloCache();
        await resetRecoilState();
      }

      /*
       * Defer the history push to the next tick so that the above state/cache reset finishes.
       * There is a known bug with apollo resetStore/clearStore that causes the cache to not be
       * cleared if the resetStore/clearStore is called in the same tick as a query is fired.
       * This is a workaround for that bug.
       */
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(async () => {
        setCurrentExtractionId(temporarilyselectedExtractionId);

        await selectExtractionMutation({
          variables: { extractionId: temporarilyselectedExtractionId },
        });

        history.push(`/dashboard${history.location.search}`);
        setClueDetailsSlideoutData({
          slideoutOpen: false,
          clueId: '',
          clueType: null,
        });
      }, 0);
    }
  };

  const [localDisplayNameSearch, setLocalExtractionNameSearch] = useState(
    textSearchState?.extractionName || '',
  );

  const { loading, data } = useQueryWithErrorBoundary<allExtractions>(
    allExtractionsQuery,
    {
      variables: {
        evidenceId: textSearchState?.extractionName,
      },
      skip: !extractionSelectionModalIsOpen,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [acceptInvite] = useMutation<AcceptInvite>(acceptInviteMutation, {
    refetchQueries: ['allExtractions'],
  });
  const [dismissInvite] = useMutation<DismissInvite>(dismissInviteMutation, {
    refetchQueries: ['allExtractions'],
  });

  useEffect(() => {
    if (
      !temporarilyselectedExtractionId &&
      data &&
      data?.allExtractions?.edges.length === 1 &&
      data?.allExtractions?.edges[0].node &&
      data.allExtractions.edges[0].node.expiresAt &&
      !isISOTimestampExpired(data.allExtractions.edges[0].node.expiresAt)
    ) {
      setTemporaryilyselectedExtractionId(
        data?.allExtractions?.edges[0].node.id,
      );
    }
  }, [data, temporarilyselectedExtractionId]);

  useEffect(() => {
    if (!extractionSelectionModalIsOpen) {
      setTemporaryilyselectedExtractionId(null);
    }
  }, [extractionSelectionModalIsOpen]);

  const debounceExtractionSearch = useRef(
    debounce((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTextSearchState({ extractionName: e.target.value });
    }, 500),
  ).current;

  return (
    <Modal
      open={extractionSelectionModalIsOpen}
      onClose={() => setExtractionSelectionModalIsOpen(false)}
      data-gid="33791551"
    >
      <div className={classes.modalContentContainer} data-gid="15351737">
        <Text size="6" weight={500} align="center" data-gid="95917921">
          Select an Extraction
        </Text>
        <div className={classes.extractionSearch} data-gid="74026714">
          <TextBox
            value={localDisplayNameSearch}
            label="Search Extractions"
            onChange={(e) => {
              setLocalExtractionNameSearch(e.target.value);
              debounceExtractionSearch(e);
            }}
            InputProps={{
              endAdornment: localDisplayNameSearch && (
                <InputAdornment position="end" data-gid="76608621">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setLocalExtractionNameSearch('');
                      setTextSearchState({
                        extractionName: '',
                      });
                    }}
                    data-gid="60080800"
                  >
                    <HighlightOff data-gid="54925201" />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                adornedEnd: classes.adornedEnd,
              },
            }}
          />
        </div>
        {loading && !data ? (
          <div className={classes.extractionCardContainer} data-gid="45836866">
            <div
              className={classes.emptyModalContentContainer}
              data-gid="81198967"
            >
              <ScaleLoader loading color={Colors.blue} data-gid="44125260" />
            </div>
          </div>
        ) : (
          <>
            <div
              className={classes.extractionCardContainer}
              data-gid="80965139"
            >
              {data &&
                data.sharedExtractions &&
                data.sharedExtractions.edges.length > 0 && (
                  <>
                    {data.sharedExtractions.edges.map((edge) => (
                      <Alert
                        icon={false}
                        severity="info"
                        style={{
                          border: `1px solid ${Colors.blue}`,
                          padding: '0px 8px 0 16px',
                          marginBottom: '8px',
                        }}
                        sx={{
                          '& .MuiAlert-message': {
                            padding: '2px 0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                          },
                        }}
                        data-gid="29609595"
                      >
                        <Typography
                          style={{ fontWeight: 500 }}
                          data-gid="39172027"
                          variant="body2"
                        >
                          Extraction Invite: {edge.node.evidenceId}
                          {` | Shared ${DateTime.fromISO(
                            edge.node.recordCreatedAt ?? '',
                          ).toLocaleString(DateTime.DATE_MED)}`}
                        </Typography>
                        <div style={{ display: 'flex' }} data-gid="17480546">
                          <Button
                            variant="text"
                            startIcon={
                              <CheckCircleOutline
                                color="primary"
                                width="24px"
                                height="24px"
                                data-gid="74363746"
                              />
                            }
                            color="primary"
                            onClick={async () => {
                              const { data: acceptInviteRes } =
                                await acceptInvite({
                                  variables: { inviteCode: edge.node.code },
                                });

                              if (
                                acceptInviteRes &&
                                acceptInviteRes.acceptInvite
                              ) {
                                await resetApolloCache();
                                await resetRecoilState();

                                setExtractionSelectionModalIsOpen(false);
                                history.push(
                                  `/dashboard?extractionId=${edge.node.id}`,
                                );
                              }
                            }}
                            data-gid="67972846"
                          >
                            Accept
                          </Button>
                          <Button
                            variant="text"
                            startIcon={
                              <HighlightOff color="error" data-gid="10849109" />
                            }
                            style={{ color: '#D73619' }}
                            onClick={async () =>
                              dismissInvite({
                                variables: { inviteCode: edge.node.code },
                              })
                            }
                            data-gid="51589221"
                          >
                            Dismiss
                          </Button>
                        </div>
                      </Alert>
                    ))}
                    <hr
                      style={{
                        width: '100%',
                        marginTop: '4px',
                        marginBottom: '12px',
                      }}
                      data-gid="33700752"
                    />
                  </>
                )}
              {data &&
              data.allExtractions &&
              data.allExtractions.edges.length > 0 ? (
                data.allExtractions.edges.map((edge) => (
                  <ExtractionSelectionModalCard
                    extraction={edge.node}
                    selected={edge.node.id === temporarilyselectedExtractionId}
                    onClick={(eid: string): void => {
                      if (
                        edge.node.expiresAt &&
                        (DateTime.fromISO(edge.node.expiresAt) >
                          DateTime.now() ||
                          entitledAccess === true)
                      ) {
                        setTemporaryilyselectedExtractionId(eid);
                      }
                    }}
                    key={edge.node.id}
                    data-gid="37762020"
                  />
                ))
              ) : (
                <div
                  className={classes.emptyModalContentContainer}
                  data-gid="51094241"
                >
                  <Text data-gid="49717070">No extractions to display</Text>
                </div>
              )}
            </div>
            <div className={classes.bottomButtonContainer} data-gid="87877207">
              <Button
                color="primary"
                onClick={async () => {
                  setExtractionSelectionModalIsOpen(false);
                  fireResetOperations();
                }}
                data-gid="20768089"
              >
                Confirm
              </Button>
            </div>
          </>
        )}
        <Close
          onClick={() => {
            setExtractionSelectionModalIsOpen(false);
          }}
          className={classes.closeIcon}
          data-gid="44720668"
        />
      </div>
    </Modal>
  );
}

export default ExtractionSelectionModal;
