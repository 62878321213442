import { useMutation } from '@apollo/client';
import { Button, makeStyles, Modal } from '@grayshift/cairn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { ActivateProTrial } from './graphql/generated/ActivateProTrial';
import { tryProMutation } from './graphql/mutations';
import { tryProModalAtom } from './lib/atoms';
import { publicStaticFileURL } from './lib/publicFile';
import { CloseModalButton, ModalContent } from './Modal';

const useStyles = makeStyles({
  bottomButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginTop: 30,
  },
  featureStack: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  featureItem: {
    display: 'flex',
    flexDirction: 'row',
    justifyContent: 'flex-start',
    padding: '0.5rem 0',
  },
  logoImg: {
    maxHeight: '200px',
    width: '400px',
  },
});

const FeatureItem: React.FC<{ text: string }> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.featureItem} data-gid="46951282">
      <CheckCircleOutlineIcon
        style={{ color: '#13AAE6' }}
        data-gid="26672581"
      />
      <Typography style={{ marginLeft: '0.75rem' }} data-gid="42768709">
        {text}
      </Typography>
    </div>
  );
};

export const TryProModal: React.FC = () => {
  const classes = useStyles();

  const [tryProModalIsOpen, setTryProModalIsOpen] =
    useRecoilState(tryProModalAtom);

  const [activateProTrial, { data, loading }] = useMutation<ActivateProTrial>(
    tryProMutation,
    {
      refetchQueries: ['extractionAccess'],
    },
  );

  const handleActivateProTrial = useCallback(async () => {
    const { data: trialData } = await activateProTrial();
    if (trialData && trialData.activateTrial.trialActiveUntil) {
      setTryProModalIsOpen(false);
    }
  }, [activateProTrial, setTryProModalIsOpen]);

  if (data?.activateTrial?.trialActiveUntil) {
    return (
      <Modal
        open={tryProModalIsOpen}
        onClose={() => setTryProModalIsOpen(false)}
        data-gid="49194504"
      >
        <ModalContent data-gid="37286477">
          <div data-gid="75264653">Pending content</div>
          <CloseModalButton
            onClick={() => setTryProModalIsOpen(false)}
            data-gid="75896442"
          />
          <div className={classes.bottomButtonContainer} data-gid="87877207">
            <Button
              color="primary"
              onClick={handleActivateProTrial}
              loading={loading}
              data-gid="20768089"
            >
              Activate Free 30-day Trial
            </Button>
          </div>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      open={tryProModalIsOpen}
      onClose={() => setTryProModalIsOpen(false)}
      data-gid="14625884"
    >
      <ModalContent data-gid="70514424">
        <div
          style={{ position: 'relative', height: '630px' }}
          data-gid="92733899"
        >
          <div data-gid="51275450">
            <Typography textAlign="center" variant="h4" data-gid="38411079">
              Get Pro Features Free for 30 days*
            </Typography>
          </div>
          <CloseModalButton
            onClick={() => setTryProModalIsOpen(false)}
            data-gid="75896442"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxHeight: 400,
            }}
            data-gid="72923371"
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem',
                marginBottom: '2rem',
                backgroundColor: '#EAECEE',
              }}
              data-gid="12787910"
            >
              <div style={{ padding: '2rem' }} data-gid="54248338">
                <img
                  className={classes.logoImg}
                  src={publicStaticFileURL('/AIQLogo-Black-FreeTrial.png')}
                  alt="none"
                  data-gid="78481351"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding: '2rem',
                }}
                data-gid="90268728"
              >
                <div className={classes.featureStack} data-gid="31343953">
                  <FeatureItem
                    text="Easily Detect CSAM Images"
                    data-gid="63827581"
                  />
                  <FeatureItem
                    text="Filter by Know Hash List and Artifact IQ Identified CSAM"
                    data-gid="66628191"
                  />
                  <FeatureItem
                    text="Customize report layout"
                    data-gid="10915365"
                  />
                  <FeatureItem
                    text="Lock and save reports"
                    data-gid="37180464"
                  />
                  <FeatureItem
                    text="Apply specific time scope when sharing extractions, to support limited scoped warrants, consent phones etc"
                    data-gid="71194192"
                  />
                </div>
              </div>
            </div>
            <div data-gid="74612633">
              <Typography marginBottom="1rem" data-gid="65406664">
                {`We hope you're enjoying the free version of Magnet Artifact IQ, but there's more to help accelerate your investigations.
              Experience some of what Pro brings to the table free for 30 days.`}
              </Typography>
              <Typography data-gid="48166206">
                Your free trial will last 30 days from the time you click Start
                Trial. Since we enhance and release new features on a regular
                basis, selected features included are subject to change.
              </Typography>
            </div>
            <div className={classes.bottomButtonContainer} data-gid="87877207">
              <Button
                color="primary"
                onClick={handleActivateProTrial}
                loading={loading}
                data-gid="20768089"
                style={{ width: 'fit-content', marginBottom: 15 }}
              >
                Start Your Free Trial Now!
              </Button>
              <div
                style={{ margin: '14px 0', paddingBottom: 14 }}
                data-gid="97805899"
              >
                <Typography
                  variant="caption"
                  fontWeight="medium"
                  data-gid="86401482"
                >
                  * Free trial does not include every Pro-level feature.
                  Excluded features: User Management, Audit Log, Extraction
                  Storage, and Dedicated Live Chat.
                  <br data-gid="54256774" />
                  The free version extraction expiration policies and countdowns
                  will continue to apply after the trial period.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
