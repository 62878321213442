/* eslint-disable sonarjs/cognitive-complexity */
import { useMutation } from '@apollo/client';
import { LinkButton, makeStyles, Text } from '@grayshift/cairn';
import ExplicitIcon from '@mui/icons-material/Explicit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import TheatersIcon from '@mui/icons-material/Theaters';
import { Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import ExplicitImagePlaceholder from './ExplicitImagePlaceholder';
import FullSizeImageModal from './FullSizeImageModal';
import {
  insertImageAnnotationMutation,
  removeImageAnnotationMutation,
} from './graphql/mutations/image';
import { messagesQuery } from './lib/atomMessagesByThreadIdQuery';
import {
  appFilterStateAtom,
  currentMessageThreadIdAtom,
  textSearchAtom,
  // hideBiomeMessagesAtom,
  timeFrameStateAtom,
  userInfoAtom,
} from './lib/atoms';
import determineMediaRenderContext, {
  renderedMediaContext,
} from './lib/determineMediaRenderContext';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import makeSerializable from './lib/makeSerializable';
import { useExtraction } from './useExtraction';

const useStyles = makeStyles({
  metadataTextItem: {
    display: 'flex',
    marginTop: 15,
  },
  leftContent: {
    flex: 1,
    textAlign: 'right',
  },
  rightContent: {
    display: 'block',
    marginLeft: 15,
    width: 215,
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  imagePlaceholder: {
    display: 'flex',
    justifyContent: 'end',
  },
  warningText: {
    zIndex: 1,
    padding: '0px 25px',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    color: 'white',
    textShadow: '0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black',
    background: 'black',
  },
  tagBtnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.7rem',
    justifyContent: 'end',
    paddingTop: '10px',
    marginLeft: '-35px',
  },
  imageContainer: {
    position: 'relative',
    '& svg': {
      backgroundColor: 'white',
      borderRadius: '50%',
      cursor: 'pointer',
      position: 'absolute',
      top: 5,
      right: 5,
    },
  },
  explicitBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    gap: '0.3rem',
  },
  mediaBtnContainer: {
    fontWeight: 500,
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
    },
  },
});

interface MetadataMediaItem {
  leftText: string;
  previewUrl: string | null | undefined;
  thumbnailUrl: string;
  imageId: string;
  messageImageContext: {
    ncmecMatch?: boolean | null;
    containsCSAM?: boolean;
    containsCSAMConfidence?: number | null;
    containsNudity?: boolean;
    containsNudityConfidence?: number | null;
    maskCsam?: boolean;
    isExplicit: boolean;
  };
  isLocationMedia?: boolean | null;
  storedFileChecksum?: string | null;
}

const ClueDetailsSlideoutMetadataMediaItem: React.FC<MetadataMediaItem> = ({
  leftText,
  previewUrl,
  thumbnailUrl,
  imageId,
  messageImageContext,
  isLocationMedia,
  storedFileChecksum,
}) => {
  const classes = useStyles();
  const { authState } = useOktaAuth();
  const [currentExtractionId] = useCurrentExtractionId();
  const { data } = useExtraction();
  const { maskCsam } = data?.extractionById ?? {};
  const history = useHistory();

  const [fullSizeImageModalOpen, setFullSizeImageModalOpen] = useState(false);
  const [userInfo] = useRecoilState(userInfoAtom);

  const currentMessageThreadState = useRecoilValue(currentMessageThreadIdAtom);
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const appFilterState = useRecoilValue(appFilterStateAtom);

  // const hideBiomeMessagesState = useRecoilValue(hideBiomeMessagesAtom);

  const [insertImageAnnotation] = useMutation(insertImageAnnotationMutation);
  const [removeImageAnnotation] = useMutation(removeImageAnnotationMutation);
  const [, setTextSearchState] = useRecoilState(textSearchAtom);

  const { currentMessageThreadId } = currentMessageThreadState || {};
  const { globalTimeframe } = timeFrameState || {};
  const token = authState?.accessToken?.accessToken || '';

  const {
    ncmecMatch = false,
    containsNudity = false,
    containsNudityConfidence = 0,
    containsCSAM = false,
    isExplicit,
  } = messageImageContext;

  const mediaContext = determineMediaRenderContext(
    ncmecMatch,
    containsCSAM,
    containsNudity,
    maskCsam || false,
    isExplicit,
  );

  const queryParams = makeSerializable({
    token,
    messageThreadId: currentMessageThreadId || '',
    sources: appFilterState.messageAppFilter ?? [],
    timeframe:
      globalTimeframe?.start && globalTimeframe?.end
        ? {
            startDate: globalTimeframe.start,
            endDate: globalTimeframe.end,
          }
        : null,
    hideBiome: false,
    extractionId: currentExtractionId,
  });

  const showImage =
    mediaContext === renderedMediaContext.ACTUAL_IMAGE ||
    mediaContext === renderedMediaContext.AI_NUDITY_MATCH;

  const clearMessagesQueryCache = useRecoilRefresher_UNSTABLE(
    messagesQuery(queryParams),
  );

  return (
    <div className={classes.metadataTextItem} data-gid="87598053">
      <div className={classes.leftContent} data-gid="34412565">
        <Text color="textSecondary" data-gid="41112195">
          {leftText}
        </Text>
      </div>
      <div className={classes.rightContent} data-gid="55742377">
        {showImage && (
          <div
            className={classes.imageContainer}
            role="presentation"
            onClick={() => setFullSizeImageModalOpen(true)}
            data-gid="91425551"
          >
            <Tooltip title="View Full Size" data-gid="33170512">
              <FullscreenIcon color="primary" data-gid="44894013" />
            </Tooltip>
            <img
              src={thumbnailUrl}
              className={classes.image}
              role="presentation"
              alt="media"
              data-gid="76801760"
            />
          </div>
        )}
        {!showImage && (
          <div className={classes.imagePlaceholder} data-gid="10536061">
            <ExplicitImagePlaceholder
              mediaContext={mediaContext}
              containsNudityConfidence={containsNudityConfidence || 0}
              data-gid="20862991"
            />
          </div>
        )}
        <div className={classes.tagBtnContainer} data-gid="17246223">
          <LinkButton
            color={isExplicit ? 'warning' : 'default'}
            onClick={async () => {
              if (!isExplicit) {
                await insertImageAnnotation({
                  variables: {
                    extractionId: currentExtractionId,
                    annotation: 'EXPLICIT',
                    userId: userInfo?.id,
                    imageId,
                  },
                }).catch((flagImageError: Error) => flagImageError);
              }
              if (isExplicit) {
                await removeImageAnnotation({
                  variables: {
                    imageId,
                    extractionId: currentExtractionId,
                    annotation: 'EXPLICIT',
                  },
                }).catch((flagImageError: Error) => flagImageError);
              }
              clearMessagesQueryCache();
            }}
            data-gid="38944822"
          >
            {isExplicit ? (
              <div
                className={classes.explicitBtnWrapper}
                style={{ color: 'red' }}
                data-gid="64895234"
              >
                <ExplicitIcon
                  fontSize="small"
                  sx={{ color: 'red' }}
                  data-gid="22579043"
                />
                Unflag (Currently Flagged)
              </div>
            ) : (
              <div className={classes.explicitBtnWrapper} data-gid="64895234">
                <ExplicitIcon fontSize="small" data-gid="22579043" />
                Flag as Explicit
              </div>
            )}
          </LinkButton>
          {isLocationMedia ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className={classes.mediaBtnContainer}
              data-gid="79895654"
            >
              <LinkButton
                color="default"
                data-gid="97160585"
                onClick={() => {
                  if (storedFileChecksum) {
                    setTextSearchState({
                      mediaClassification: storedFileChecksum,
                    });
                  }

                  history.push(`/media${history.location.search}`);
                }}
              >
                <TheatersIcon fontSize="small" data-gid="33759296" />
                View in Media
              </LinkButton>
            </div>
          ) : null}
        </div>
      </div>
      {fullSizeImageModalOpen && (
        <FullSizeImageModal
          previewUrl={previewUrl}
          onClose={() => setFullSizeImageModalOpen(false)}
          data-gid="26457520"
        />
      )}
    </div>
  );
};

ClueDetailsSlideoutMetadataMediaItem.defaultProps = {
  isLocationMedia: false,
  storedFileChecksum: null,
};

export default ClueDetailsSlideoutMetadataMediaItem;
