/* eslint-disable react/require-default-props */
import { Colors, makeStyles, Text } from '@grayshift/cairn';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 4,
    padding: '0.75rem',
    backgroundColor: Colors.white,
    marginBottom: '0.5rem',
  },
  collapseButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  topContainerHeader: { display: 'flex', justifyContent: 'space-between' },
});

export const ExpandableCard: React.FC<{
  heading: string;
  defaultExpanded?: boolean;
}> = ({ heading, defaultExpanded = false, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <div className={classes.container} data-gid="95653467">
      <div className={classes.topContainerHeader} data-gid="81577624">
        <Text heading="h2" size="4" weight={500} data-gid="32363143">
          {heading}
        </Text>
        <div
          className={classes.collapseButton}
          role="presentation"
          onClick={() => setExpanded(!expanded)}
          data-gid="74230250"
        >
          <Text data-gid="76421475">{expanded ? 'Collapse' : 'Expand'}</Text>
          {expanded ? (
            <KeyboardArrowUp color="primary" data-gid="18873359" />
          ) : (
            <KeyboardArrowDown color="primary" data-gid="34961172" />
          )}
        </div>
      </div>
      {expanded && <div data-gid="33049221">{children}</div>}
    </div>
  );
};
