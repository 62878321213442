/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-underscore-dangle */
import { useMutation } from '@apollo/client';
import { Button, LinkButton, makeStyles, Text } from '@grayshift/cairn';
import ExplicitIcon from '@mui/icons-material/Explicit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TheatersIcon from '@mui/icons-material/Theaters';
import { Tooltip } from '@mui/material';
import fileSize from 'filesize';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import ExplicitImagePlaceholder from './ExplicitImagePlaceholder';
import FullSizeImageModal from './FullSizeImageModal';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import {
  mediaById,
  mediaById_mediaById_Image,
  mediaById_mediaById_Video,
} from './graphql/generated/mediaById';
import {
  removeClueTagMutation,
  tagMediaClueMutation,
} from './graphql/mutations';
import {
  insertImageAnnotationMutation,
  removeImageAnnotationMutation,
} from './graphql/mutations/image';
import {
  insertVideoAnnotationMutation,
  removeVideoAnnotationMutation,
} from './graphql/mutations/video';
import { mediaByIdQuery } from './graphql/queries/media';
import {
  currentExtractionAccessTypeAtom,
  textSearchAtom,
  userInfoAtom,
} from './lib/atoms';
import determineMediaRenderContext, {
  renderedMediaContext,
} from './lib/determineMediaRenderContext';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useExtraction } from './useExtraction';
import { usePageForMessage } from './usePageForMessage';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import VideoFrameGalleryModal from './VideoFrameGalleryModal';

const useStyles = makeStyles({
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  image: {
    width: '100%',
    marginTop: '10px',
    cursor: 'pointer',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  explicitImageBtnContainer: {
    paddingTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  explicitBtn: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    gap: '0.2rem',
  },
  imageContainer: {
    position: 'relative',
    '& svg': {
      backgroundColor: 'white',
      borderRadius: '50%',
      boxShadow:
        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      cursor: 'pointer',
      position: 'absolute',
      top: 15,
      right: 7,
    },
  },
  mainMediaBtnWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
  },
  viewInMessageBtn: {
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'end',
    marginLeft: 'auto',
    marginTop: 5,
    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.2rem',
    },
  },
  frameModalBtn: {
    position: 'absolute',
    top: 1,
    right: 3,
    zIndex: 5,
    cursor: 'pointer',
  },
});

interface MediaProps {
  clueId: string;
  clueSubType: string | undefined;
}

interface sourceFileProps {
  id: string;
  name: string;
  checksum: string;
  extension: string;
  enclosingDirectory: string;
  sizeBytes: string;
  atime: number;
  mtime: number;
  ctime: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const MediaClueDetails: React.FC<MediaProps> = ({ clueId, clueSubType }) => {
  const { navigateToMessageInThread } = usePageForMessage();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [currentExtractionId] = useCurrentExtractionId();
  const { data: extraction } = useExtraction();
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [clueMetaDataSectionCollapsed, setClueMetaDataSectionCollapsed] =
    useState(true);
  const [, setTextSearchState] = useRecoilState(textSearchAtom);
  const [userInfo] = useRecoilState(userInfoAtom);

  const [fullSizeImageModalOpen, setFullSizeImageModalOpen] = useState(false);
  const [videoFrameGalleryIsOpen, setVideoFrameGalleryOpen] = useState(false);

  const { loading, data } = useQueryWithErrorBoundary<mediaById>(
    mediaByIdQuery,
    {
      variables: {
        mediaById: clueId,
        category: clueSubType,
        extractionId: currentExtractionId,
      },
    },
  );

  const [tagMediaClue] = useMutation(tagMediaClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  const [insertImageAnnotation] = useMutation(insertImageAnnotationMutation);
  const [removeImageAnnotation] = useMutation(removeImageAnnotationMutation);

  const [insertVideoAnnotation] = useMutation(insertVideoAnnotationMutation);
  const [removeVideoAnnotation] = useMutation(removeVideoAnnotationMutation);

  const [videoExtractionPath, setVideoExtractionPath] = useState('');

  const media = data?.mediaById;

  const {
    thumbnailUrl,
    previewUrl,
    originalUrl,
    __typename: mediaType,
    storedFileChecksum,
    meta: clueMetaData,
    sourceFiles,
    taggedAt: isTagged,
    containsCSAM,
    containsNudity,
    containsNudityConfidence,
    ncmecMatch,
    storedFileMd5Checksum = '',
    storedFileSha1Checksum = '',
  } = data?.mediaById || {};

  const { imageAnnotations = [] } =
    (data?.mediaById as mediaById_mediaById_Image) ?? {};
  const { videoAnnotations = [], thumbnailFrames = [] } =
    (data?.mediaById as mediaById_mediaById_Video) ?? {};

  useEffect(() => {
    clueMetaData?.map((meta) => {
      if (meta?.key === 'originalPath') {
        setVideoExtractionPath(meta?.value);
      }
      return true;
    });
  });

  const allSourceFiles: sourceFileProps[] = uniqBy(
    [...(sourceFiles || [])],
    'id',
  );

  const onDashboard = location.pathname === '/dashboard';
  const isVideo = mediaType === 'Video';
  const annotation = isVideo
    ? videoAnnotations[0]?.annotatedBy
    : imageAnnotations[0]?.annotatedBy;
  const annotatedBy =
    annotation?.firstName && annotation?.lastName
      ? `${annotation?.firstName} ${annotation?.lastName}`
      : annotation?.email;
  const isExplicit = isVideo
    ? videoAnnotations && videoAnnotations.length > 0
    : imageAnnotations && imageAnnotations.length > 0;

  const maskCsam = extraction?.extractionById?.maskCsam;
  const mediaRenderContext = determineMediaRenderContext(
    ncmecMatch ?? false,
    containsCSAM ?? false,
    containsNudity ?? false,
    maskCsam || false,
    isExplicit,
  );

  const showMedia =
    mediaRenderContext === renderedMediaContext.ACTUAL_IMAGE ||
    mediaRenderContext === renderedMediaContext.AI_NUDITY_MATCH;

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="10967951"
    >
      <>
        <div className={classes.metadataContainer} data-gid="69560850">
          <ClueDetailsSlideoutMetadataSection
            title="Actions"
            data-gid="23567820"
          >
            <div className={classes.tagBtnContainer} data-gid="96545180">
              <ReadOnlyTooltip
                verb="tag"
                bypass={
                  currentExtractionAccessType !==
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                data-gid="77036952"
              >
                <Button
                  fullWidth
                  disabled={
                    currentExtractionAccessType ===
                    ExtractionAccessTypeEnum.READ_ONLY
                  }
                  color={isTagged ? 'primary' : 'default'}
                  onClick={() => {
                    if (!isTagged) {
                      tagMediaClue({
                        variables: {
                          input: {
                            clueId,
                            mediaType,
                            extractionId: currentExtractionId,
                          },
                        },
                        refetchQueries: onDashboard
                          ? [mediaByIdQuery, allTaggedCluesQuery]
                          : [mediaByIdQuery],
                      }).catch((tagError: Error) => tagError);
                    }
                    if (isTagged) {
                      removeClueTag({
                        variables: {
                          input: {
                            clueId,
                            extractionId: currentExtractionId,
                            clueType: mediaType === 'Image' ? 'IMAGE' : 'VIDEO',
                          },
                        },
                        refetchQueries: onDashboard
                          ? [mediaByIdQuery, allTaggedCluesQuery]
                          : [mediaByIdQuery],
                      }).catch((removeTagError: Error) => removeTagError);
                    }
                  }}
                  data-gid="66355823"
                >
                  {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
                </Button>
              </ReadOnlyTooltip>
              {isTagged && (
                <Text size="2" data-gid="50122769">
                  You can view all tagged clues on the Dashboard
                </Text>
              )}
            </div>
          </ClueDetailsSlideoutMetadataSection>
          <Notes
            clueId={clueId}
            clueType={mediaType === 'Image' ? ClueTypes.IMAGE : ClueTypes.VIDEO}
            data-gid="23860592"
          />
          <ClueDetailsSlideoutMetadataSection
            title="Basic Details"
            data-gid="32808803"
          >
            <div data-gid="46047007">
              {mediaType === 'Image' && showMedia && (
                <div
                  className={classes.imageContainer}
                  role="presentation"
                  onClick={() => setFullSizeImageModalOpen(true)}
                  data-gid="80838777"
                >
                  <Tooltip title="View Full Size" data-gid="33170512">
                    <FullscreenIcon color="primary" data-gid="44894013" />
                  </Tooltip>
                  <img
                    src={thumbnailUrl || undefined}
                    className={classes.image}
                    alt="media"
                    data-gid="70537088"
                  />
                </div>
              )}

              {mediaType === 'Video' && showMedia && (
                <div
                  className={classes.imageContainer}
                  role="presentation"
                  data-gid="80838777"
                >
                  {thumbnailFrames && thumbnailFrames.length > 0 && (
                    <div className={classes.frameModalBtn} data-gid="12448216">
                      <Tooltip
                        title="View Video Frame Gallery"
                        data-gid="56179353"
                      >
                        <FullscreenIcon
                          color="primary"
                          onClick={() => setVideoFrameGalleryOpen(true)}
                          data-gid="24524192"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <video
                    controls
                    key={previewUrl ?? originalUrl}
                    style={{ width: '100%', height: '100%' }}
                    data-gid="70537088"
                  >
                    <source
                      src={previewUrl ?? originalUrl ?? ''}
                      data-gid="16089641"
                    />
                  </video>
                </div>
              )}
              {!showMedia && (
                <div
                  style={{ height: '200px' }}
                  role="presentation"
                  data-gid="90231509"
                >
                  <ExplicitImagePlaceholder
                    mediaContext={mediaRenderContext}
                    containsNudityConfidence={containsNudityConfidence ?? 0}
                    data-gid="20862991"
                  />
                </div>
              )}
            </div>
            {mediaType === 'Image' && (
              <>
                <div
                  className={classes.mainMediaBtnWrapper}
                  data-gid="94377540"
                >
                  <div
                    className={classes.explicitImageBtnContainer}
                    data-gid="66686520"
                  >
                    <LinkButton
                      color={isExplicit ? 'warning' : 'default'}
                      disabled={
                        currentExtractionAccessType ===
                        ExtractionAccessTypeEnum.READ_ONLY
                      }
                      onClick={async () => {
                        if (!isExplicit) {
                          await insertImageAnnotation({
                            variables: {
                              extractionId: currentExtractionId,
                              annotation: 'EXPLICIT',
                              userId: userInfo?.id,
                              imageId: clueId,
                            },
                          }).catch((flagImageError: Error) => flagImageError);
                        }
                        if (isExplicit) {
                          await removeImageAnnotation({
                            variables: {
                              imageId: clueId,
                              extractionId: currentExtractionId,
                              annotation: 'EXPLICIT',
                            },
                          }).catch((flagImageError: Error) => flagImageError);
                        }
                      }}
                      data-gid="38944822"
                    >
                      {isExplicit ? (
                        <div
                          className={classes.explicitBtn}
                          style={{ color: 'red' }}
                          data-gid="64895234"
                        >
                          <ExplicitIcon
                            fontSize="small"
                            sx={{ color: 'red' }}
                            data-gid="22579043"
                          />
                          Unflag (Currently Flagged)
                        </div>
                      ) : (
                        <div
                          className={classes.explicitBtn}
                          data-gid="64895234"
                        >
                          <ExplicitIcon fontSize="small" data-gid="22579043" />
                          Flag as Explicit
                        </div>
                      )}
                    </LinkButton>
                  </div>
                  <>
                    {media?.__typename === 'Image' &&
                      media?.messageAttachment?.messageThreadId &&
                      media.messageAttachment.messageId && (
                        <div
                          data-gid="28421905"
                          className={classes.viewInMessageBtn}
                        >
                          <LinkButton
                            onClick={async () => {
                              if (media?.messageAttachment) {
                                await navigateToMessageInThread({
                                  messageThreadId:
                                    media?.messageAttachment?.messageThreadId,
                                  messageId:
                                    media?.messageAttachment?.messageId,
                                });
                              }
                            }}
                            data-gid="31471783"
                          >
                            <QuestionAnswerIcon
                              sx={{ marginTop: '4px' }}
                              fontSize="small"
                              data-gid="32555119"
                            />
                            View in Messages
                          </LinkButton>
                        </div>
                      )}
                  </>
                  <>
                    {location.pathname !== '/media' && (
                      <div
                        className={classes.viewInMessageBtn}
                        data-gid="84748353"
                      >
                        <LinkButton
                          onClick={() => {
                            if (
                              storedFileChecksum &&
                              storedFileChecksum.length > 0
                            ) {
                              setTextSearchState({
                                mediaClassification: storedFileChecksum,
                              });
                            }

                            history.push(`/media${history.location.search}`);
                          }}
                          data-gid="58188725"
                        >
                          <TheatersIcon fontSize="small" data-gid="98007626" />
                          View in Media
                        </LinkButton>
                      </div>
                    )}
                  </>
                </div>
              </>
            )}
            {isVideo && (
              <>
                <div
                  className={classes.mainMediaBtnWrapper}
                  data-gid="63544427"
                >
                  <div
                    className={classes.explicitImageBtnContainer}
                    data-gid="64267086"
                  >
                    <LinkButton
                      color={isExplicit ? 'warning' : 'default'}
                      disabled={
                        currentExtractionAccessType ===
                        ExtractionAccessTypeEnum.READ_ONLY
                      }
                      onClick={async () => {
                        if (!isExplicit) {
                          await insertVideoAnnotation({
                            variables: {
                              extractionId: currentExtractionId,
                              annotation: 'EXPLICIT',
                              userId: userInfo?.id,
                              videoId: clueId,
                            },
                          }).catch((flagImageError: Error) => flagImageError);
                        }
                        if (isExplicit) {
                          await removeVideoAnnotation({
                            variables: {
                              videoId: clueId,
                              extractionId: currentExtractionId,
                              annotation: 'EXPLICIT',
                            },
                          }).catch((flagImageError: Error) => flagImageError);
                        }
                      }}
                      data-gid="57273295"
                    >
                      {isExplicit ? (
                        <div
                          className={classes.explicitBtn}
                          style={{ color: 'red' }}
                          data-gid="73187916"
                        >
                          <ExplicitIcon
                            fontSize="small"
                            sx={{ color: 'red' }}
                            data-gid="35161711"
                          />
                          Unflag (Currently Flagged)
                        </div>
                      ) : (
                        <div
                          className={classes.explicitBtn}
                          data-gid="69520968"
                        >
                          <ExplicitIcon fontSize="small" data-gid="22579043" />
                          Flag as Explicit
                        </div>
                      )}
                    </LinkButton>
                  </div>
                </div>

                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Video Path:"
                  rightText={videoExtractionPath}
                  data-gid="38646503"
                />
              </>
            )}
            {media?.taggedBy && isTagged && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="Tagged By:"
                rightText={
                  media?.taggedBy.firstName && media.taggedBy.lastName
                    ? `${media.taggedBy.firstName} ${media.taggedBy.lastName}`
                    : media.taggedBy.email
                }
                data-gid="16716849"
              />
            )}

            {annotation && isExplicit && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="Flagged Explicit By:"
                rightText={annotatedBy || ''}
                data-gid="16716849"
              />
            )}
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Clue Id:"
              rightText={media?.id}
              data-gid="38646503"
            />
            {storedFileChecksum && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="SHA256 Hash Value:"
                rightText={storedFileChecksum}
                data-gid="25998898"
              />
            )}
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Platform:"
              rightText={media?.app?.displayName ?? media?.appBundleId}
              data-gid="25998898"
            />
            <ClueDetailsSlideoutMetadataTextItem
              leftText="Timestamp: "
              rightText={
                media?.timestamp
                  ? `${DateTime.fromISO(media.timestamp).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} 
            ${DateTime.fromISO(media.timestamp).toLocaleString(
              DateTime.DATE_MED,
            )}`
                  : undefined
              }
              data-gid="66149759"
            />
            {data?.mediaById?.__typename === 'Video' && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="Duration:"
                rightText={`${
                  data?.mediaById?.durationSeconds
                    ? new Date(data?.mediaById?.durationSeconds * 1000)
                        .toISOString()
                        .slice(11, 19)
                    : 0
                } (hh:mm:ss)`}
                data-gid="25998898"
              />
            )}
            {storedFileSha1Checksum && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="SHA1 Hash Value:"
                rightText={storedFileSha1Checksum}
                data-gid="24355316"
              />
            )}
            {storedFileMd5Checksum && (
              <ClueDetailsSlideoutMetadataTextItem
                leftText="MD5 Hash Value:"
                rightText={storedFileMd5Checksum}
                data-gid="14663613"
              />
            )}
          </ClueDetailsSlideoutMetadataSection>
          {allSourceFiles && allSourceFiles?.length > 0 && (
            <ClueDetailsSlideoutMetadataSection
              title="Source Files"
              collapsed={sourcefileSectionCollapsed}
              onClickCollapse={() =>
                setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
              }
              data-gid="82095605"
            >
              {allSourceFiles.map((sf) => (
                <ClueDetailsSlideoutMetadataSubsection
                  key={sf.id}
                  title={sf.name}
                  data-gid="65494580"
                >
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="File Path:"
                    rightText={`${sf.enclosingDirectory}/${sf.name}`}
                    data-gid="73403890"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="SHA256:"
                    rightText={`${sf.checksum}`}
                    data-gid="48087499"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Changed:"
                    rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="39717583"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Modified:"
                    rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="92616123"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Last Accessed:"
                    rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                      DateTime.DATE_MED,
                    )}`}
                    data-gid="59602265"
                  />
                  <ClueDetailsSlideoutMetadataTextItem
                    leftText="Size:"
                    rightText={`${fileSize(+sf.sizeBytes)}`}
                    data-gid="42442436"
                  />
                </ClueDetailsSlideoutMetadataSubsection>
              ))}
            </ClueDetailsSlideoutMetadataSection>
          )}
          {clueMetaData && clueMetaData?.length > 0 && (
            <ClueDetailsSlideoutMetadataSection
              title="Additional Info"
              collapsed={clueMetaDataSectionCollapsed}
              onClickCollapse={() =>
                setClueMetaDataSectionCollapsed(!clueMetaDataSectionCollapsed)
              }
              data-gid="33765826"
            >
              {clueMetaData.length > 0 &&
                clueMetaData.map((meta) => (
                  <ClueDetailsSlideoutMetadataTextItem
                    key={meta?.key}
                    leftText={meta?.key || ''}
                    rightText={meta?.value || ''}
                    data-gid="93094995"
                  />
                ))}
            </ClueDetailsSlideoutMetadataSection>
          )}
        </div>
        {fullSizeImageModalOpen && (
          <FullSizeImageModal
            previewUrl={previewUrl || thumbnailUrl}
            onClose={() => setFullSizeImageModalOpen(false)}
            data-gid="59570228"
          />
        )}
        {videoFrameGalleryIsOpen && (
          <VideoFrameGalleryModal
            thumbnailFrameUrls={thumbnailFrames}
            onClose={() => setVideoFrameGalleryOpen(false)}
            data-gid="15074470"
          />
        )}
      </>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default MediaClueDetails;
