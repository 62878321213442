import { useHistory, useLocation } from 'react-router-dom';

export const useExtractionId: () => [
  string | null,
  (tempExtractionId: string) => void,
  () => boolean,
] = () => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const extractionId = urlSearchParams.get('extractionId');
  const history = useHistory();

  const isValidExtractionId = (): boolean => {
    if (!extractionId) return false;

    const uuidRegexExp =
      /^[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}$/i;
    return uuidRegexExp.test(extractionId);
  };

  return [
    urlSearchParams.get('extractionId'),
    (tempExtractionId: string) => {
      if (history.location.pathname === '/') {
        history.push({
          pathname: 'timeline',
          search: `extractionId=${tempExtractionId ?? ''}`,
        });
      } else {
        history.push({
          search: `extractionId=${tempExtractionId ?? ''}`,
        });
      }
    },
    isValidExtractionId,
  ];
};

export default useExtractionId;
