import { useMutation, useQuery } from '@apollo/client';
import { Colors, makeStyles, Text } from '@grayshift/cairn';
import { Apps } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ActionBar } from './ActionBar';
import BrowserHistoryUrlColumn, { getDomain } from './BrowserHistoryUrlColumn';
import { DataTable } from './DataTable';
import {
  browserHistoryByExtractionId,
  browserHistoryByExtractionId_browserHistoryByExtractionId_edges_node,
} from './graphql/generated/browserHistoryByExtractionId';
import { browserHistoryById } from './graphql/generated/browserHistoryById';
import { TagBrowserHistoryClue } from './graphql/generated/TagBrowserHistoryClue';
import {
  removeClueTagMutation,
  tagBrowserHistoryClueMutation,
} from './graphql/mutations';
import {
  getAllBrowserHistoriesByExtractionId,
  getBrowserHistoryById,
} from './graphql/queries/browserHistory';
import { Layout } from './Layout';
import {
  ClueType,
  currentGlobalSearchTextAtom,
  timeFrameStateAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';
import { useUser } from './useUser';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    overflow: 'scroll',
    width: '100%',
  },
  rootContainer: {
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    backgroundColor: Colors.white,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  },
  spacer: {
    height: 10,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'break-word',
    inlineSize: '15rem',
    cursor: 'pointer',
  },
  appInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
  appIconContainer: {
    justifyContent: 'center',
    marginLeft: '-15px',
  },
});

type browserHistoryByExtractionIdOrderByFields = keyof Omit<
  browserHistoryByExtractionId_browserHistoryByExtractionId_edges_node,
  'id' | '__typename' | 'recordUpdatedAt' | 'data'
>;

const BrowserHistory: React.FC = () => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const [orderByDir, setOrderByDir] = useState<'asc' | 'desc'>('desc');
  const { user } = useUser();
  const [sortField, setSortField] =
    useState<browserHistoryByExtractionIdOrderByFields>('timestamp');
  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);

  const { params, setPageNumber, setClue } = useQueryParams();
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { data, loading } = useQuery<browserHistoryByExtractionId>(
    getAllBrowserHistoriesByExtractionId,
    {
      variables: {
        extractionId: currentExtractionId,
        searchTerm: globalSearchTextState.currentGlobalSearchText,
        pageSize: 15,
        page: params.page ?? 1,
        sortField,
        orderByDir,
        timeframe:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
      },
      skip: !currentExtractionId,
    },
  );

  const [tagBrowserHistoryClue] = useMutation<TagBrowserHistoryClue>(
    tagBrowserHistoryClueMutation,
  );
  const [removeClueTag] = useMutation(removeClueTagMutation);

  const handleSort = (header: string): void => {
    if (sortField === header) {
      setOrderByDir(orderByDir === 'asc' ? 'desc' : 'asc');
    }
    setSortField(header as browserHistoryByExtractionIdOrderByFields);
  };

  const onClickRow = (rowId: string): void => {
    setClue({
      clueId: rowId,
      clueType: ClueType.BROWSER_HISTORY,
      slideoutOpen: true,
    });
  };

  return (
    <Layout data-gid="33529857">
      <div className={classes.outerWrapper} data-gid="89506858">
        <div className={classes.rootContainer} data-gid="31273797">
          <Text heading="h2" size="5" weight={500} data-gid="96491307">
            Browser History
          </Text>
          <div className={classes.spacer} data-gid="13836293" />

          <div className={classes.tableContainer} data-gid="32751217">
            <DataTable
              data={
                data?.browserHistoryByExtractionId?.edges.map(
                  ({ node }) => node,
                ) ?? []
              }
              page={params.page ?? 1}
              pageChangeHandler={(page) => setPageNumber(page)}
              columnDefinitions={[
                {
                  id: 'timestamp',
                  heading: 'Date/ Time',
                  align: 'left',
                  sortable: true,
                  content: (row) => (
                    <div
                      onClick={() => onClickRow(row.id)}
                      role="presentation"
                      className={classes.appInfoContainer}
                      key={`browser-history-timestamp-${row.id}`}
                      data-gid="63426353"
                    >
                      <Text data-gid="69592128">
                        {`${DateTime.fromISO(row.timestamp).toLocaleString(
                          DateTime.DATE_MED,
                        )} ${DateTime.fromISO(row.timestamp).toLocaleString(
                          DateTime.TIME_WITH_SHORT_OFFSET,
                        )} `}
                      </Text>
                    </div>
                  ),
                },
                {
                  id: 'browser',
                  heading: 'browser',
                  align: 'left',
                  sortable: true,
                  content: (row) => (
                    <div
                      onClick={() => onClickRow(row.id)}
                      role="presentation"
                      className={`${classes.appInfoContainer} ${classes.appIconContainer}`}
                      key={`browser-history-app-info-${row.id}`}
                      data-gid="71445487"
                    >
                      {row?.app?.iconUrl ? (
                        <Tooltip
                          placement="top"
                          title={row.app?.displayName ?? row.appBundleId}
                          data-gid="55736200"
                        >
                          <img
                            style={{
                              marginRight: '10px',
                              borderRadius: '100%',
                            }}
                            height="30px"
                            width="30px"
                            src={row.app.iconUrl}
                            alt="Installed App Icon"
                            data-gid="46142073"
                          />
                        </Tooltip>
                      ) : (
                        <Apps
                          style={{
                            marginRight: '10px',
                            height: '30px',
                            width: '30px',
                            background: Colors.blue,
                            color: 'white',
                            borderRadius: '100%',
                          }}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  id: 'title',
                  heading: 'Title',
                  align: 'left',
                  sortable: true,
                  content: (row) => (
                    <div
                      onClick={() => onClickRow(row.id)}
                      role="presentation"
                      className={classes.titleContainer}
                      key={`browser-history-title-${row.id}`}
                      data-gid="43231263"
                    >
                      <Text data-gid="24530910">
                        {row.title ? getDomain(row.title) : ''}
                      </Text>
                    </div>
                  ),
                },
                {
                  id: 'url',
                  heading: 'url',
                  align: 'left',
                  sortable: true,
                  content: (row) => (
                    <BrowserHistoryUrlColumn
                      url={row.url}
                      data-gid="35507188"
                    />
                  ),
                },
                {
                  id: 'actions',
                  heading: 'Actions',
                  align: 'center',
                  // eslint-disable-next-line sonarjs/cognitive-complexity
                  content: (row) => (
                    <ActionBar
                      position="relative"
                      isTagged={!!row?.taggedAt}
                      onTag={async (tagged) => {
                        if (tagged && row?.id) {
                          await removeClueTag({
                            variables: {
                              input: {
                                clueId: row.id,
                                extractionId: currentExtractionId,
                                clueType: 'BROWSER_HISTORY',
                              },
                            },
                            optimisticResponse: {
                              removeClueTag: {
                                clueId: row.id,
                                __typename: 'RemoveClueTagPayload',
                              },
                            },
                            update: (proxy) => {
                              const rqData = proxy.readQuery<
                                browserHistoryById,
                                {
                                  browserHistoryId: string;
                                  extractionId: string;
                                }
                              >({
                                query: getBrowserHistoryById,
                                variables: {
                                  browserHistoryId: row.id,
                                  extractionId: currentExtractionId as string,
                                },
                              });

                              if (rqData?.browserHistoryById) {
                                proxy.writeQuery({
                                  query: getBrowserHistoryById,
                                  data: {
                                    browserHistoryById: {
                                      ...rqData.browserHistoryById,
                                      taggedAt: null,
                                      taggedBy: null,
                                    },
                                  },
                                  variables: {
                                    extractionId: currentExtractionId as string,
                                    searchTerm:
                                      globalSearchTextState.currentGlobalSearchText,
                                    pageSize: 15,
                                    page: params.page ?? 1,
                                    sortField,
                                    orderByDir,
                                    timeframe:
                                      globalTimeframe?.start &&
                                      globalTimeframe?.end
                                        ? {
                                            startDate:
                                              globalTimeframe?.start.toISOString(),
                                            endDate:
                                              globalTimeframe?.end.toISOString(),
                                          }
                                        : null,
                                  },
                                });
                              }
                            },
                          });
                        } else if (row?.id) {
                          await tagBrowserHistoryClue({
                            variables: {
                              input: {
                                clueId: row.id,
                                extractionId: currentExtractionId,
                              },
                            },
                            optimisticResponse: {
                              tagBrowserHistoryClue: {
                                browserHistory: {
                                  id: row.id,
                                  taggedAt: DateTime.now().toISO(),
                                  taggedBy: {
                                    id: user?.id ?? '',
                                    firstName: user?.firstName ?? '',
                                    lastName: user?.lastName ?? '',
                                    email: user?.email ?? '',
                                    __typename: 'User',
                                  },
                                  __typename: 'BrowserHistory',
                                },
                                // eslint-disable-next-line no-secrets/no-secrets
                                __typename: 'TagBrowserHistoryCluePayload',
                              },
                            },
                            // update: (proxy) => {},
                          });
                        }
                      }}
                      // onTag={async (tagged) => {
                      //   if (tagged && row?.id) {
                      //     await removeClueTag({
                      //       variables: {
                      //         input: {
                      //           clueId: row.id,
                      //           extractionId: currentExtractionId,
                      //           clueType: 'BROWSER_HISTORY',
                      //         },
                      //       },
                      //     });
                      //   } else if (row?.id) {
                      //     await tagBrowserHistoryClue({
                      //       variables: {
                      //         input: {
                      //           clueId: row.id,
                      //           extractionId: currentExtractionId,
                      //         },
                      //       },
                      //     });
                      //   }
                      // }}
                      data-gid="51081642"
                    />
                  ),
                },
              ]}
              onSortHandler={(id) => handleSort(id)}
              sortColumn={sortField}
              sortDirection={orderByDir}
              totalRecordCount={
                data?.browserHistoryByExtractionId?.pageInfo?.totalEdges ?? 0
              }
              recordsPerPage={15}
              hoverable
              size="small"
              noRecordsMessage="No Browser History Found"
              loading={loading && !data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BrowserHistory;
