import * as Sentry from '@sentry/react';

export default function captureException(
  ...args: Parameters<typeof Sentry.captureException>
): ReturnType<typeof Sentry.captureException> {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return '';
  }
  return Sentry.captureException(...args);
}
