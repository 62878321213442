import { gql } from '@apollo/client';

export const extractionStatusQuery = gql`
  query extractionStatus($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      state
      progress
    }
  }
`;

const extractionByIdQuery = gql`
  query extractionByIdQuery(
    $extractionId: UUID!
    $timeframe: TimeFrame
    $searchText: String
  ) {
    extractionById(
      extractionId: $extractionId
      timeframe: $timeframe
      searchText: $searchText
    ) {
      id
      expiresAt
      evidenceId
      examinerName
      extractionStartedAt
      aggregationsCompletedAt
      lastRealActivityAt
      state
      lockState
      percentComplete
      progress
      recordCreatedAt
      recordUpdatedAt
      timeToFirstClue
      expirationResetCount
      accessType
      cluesLastProcessed
      isArchived
      isVideoEnabled
      maskCsam
      source
      isReviewEnabled
      sharedTimeframe {
        startDate
        endDate
      }
      clueCounts {
        locations
        contacts
        messages
        calls
        media
        memos
        installedApps
        browserHistory
      }
      target {
        hardwareId
        extra {
          model
          serial
        }
      }
    }
  }
`;

export default extractionByIdQuery;
