import { gql } from '@apollo/client';

export const allExtractionsQuery = gql`
  query allExtractions($evidenceId: String) {
    sharedExtractions(page: 1, pageSize: 50) {
      edges {
        node {
          id
          code
          evidenceId
          examinerName
          expiresAt
          recordCreatedAt
        }
      }
    }
    allExtractions(
      evidenceId: $evidenceId
      page: 1
      pageSize: 500
      sortBy: EXTRACTION_ACCESS_DESC
    ) {
      edges {
        node {
          id
          evidenceId
          examinerName
          state
          percentComplete
          progress
          recordUpdatedAt
          extractionStartedAt
          aggregationsCompletedAt
          lastRealActivityAt
          accessType
          isArchived
          expiresAt
          expirationResetCount
          sharedTimeframe {
            startDate
            endDate
          }
          target {
            hardwareId
            extra {
              model
            }
          }
        }
      }
    }
  }
`;

export const extractionAccessQuery = gql`
  query extractionAccess($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      expiresAt
      lockState
      accessType
      targetMeta {
        os
      }
    }
  }
`;

export const extractionAccessSharedTimeframeQuery = gql`
  query extractionAccessSharedTimeframe($extractionId: UUID!, $userId: UUID!) {
    extractionAccessSharedTimeframe(
      extractionId: $extractionId
      userId: $userId
    ) {
      startDate
      endDate
    }
  }
`;
