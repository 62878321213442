import { gql } from '@apollo/client';

export const UsersRequestingExtractionAccess = gql`
  query usersRequestingExtractionAccess($extractionId: UUID!) {
    usersRequestingExtractionAccess(extractionId: $extractionId) {
      id
      firstName
      lastName
      email
      accountId
      recordCreatedAt
    }
  }
`;

export const UpdateAccessRequestStatustMutation = gql`
  mutation UpdateAccessRequest(
    $input: UpdateExtractionAccessRequestStatusInput!
  ) {
    updateExtractionAccessRequestStatus(input: $input) {
      id
      status
    }
  }
`;

export const GetExtractionAccessRequestStatus = gql`
  query ExtractionAccessRequestStatusByUserId(
    $extractionId: UUID!
    $userId: UUID!
  ) {
    extractionAccessRequestStatusByUserId(
      extractionId: $extractionId
      userId: $userId
    ) {
      accessRequestStatus
    }
  }
`;
