import { useMutation } from '@apollo/client';
import { Button, MenuItem, Select, Text } from '@grayshift/cairn';
import { useState } from 'react';

import { ExtractionAccessTypeEnum } from './graphql/generated/globalTypes';
import { RemoveExtractionAccess } from './graphql/generated/RemoveExtractionAccess';
import { UpdateExtractionAccessTypeByUserId } from './graphql/generated/UpdateExtractionAccessTypeByUserId';
import { allExtractionsByAccountIdAndUserId } from './lib/allExtractionsByAccountIdAndUserId';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { removeExtractionAccess } from './lib/removeExtractionAccess';
import { updateExtractionAccessType } from './lib/updateExtractionAccessType';

interface ExtractionAccessManagerSelectProps {
  accessType: string;
  userId: string | undefined;
  extractionIdInQuestion: string | undefined;
  refetchExtractionAccessById: () => void;
}

export const UserManagementExtractionAccess = ({
  accessType,
  userId,
  extractionIdInQuestion,
  refetchExtractionAccessById,
}: ExtractionAccessManagerSelectProps): React.ReactElement => {
  const [extractionId] = useExtractionId();
  const [value, setValue] = useState<string>(accessType);
  const userActionEnum = [...Object.keys(ExtractionAccessTypeEnum), 'REMOVE'];
  const [removalConfirmation, setRemovalConfirmation] = useState(false);
  const [displayImmutableRoleRule, setDisplayImmutableRoleRule] =
    useState<boolean>(false);
  const [
    displayCannotRemoveExtractionForOnlyOneOwner,
    setDisplayCannotRemoveExtractionForOnlyOneOwner,
  ] = useState<boolean>(false);

  const [fireUpdateUserExtractionAccess] =
    useMutation<UpdateExtractionAccessTypeByUserId>(
      updateExtractionAccessType,
      {
        refetchQueries: [
          {
            query: allExtractionsByAccountIdAndUserId,
            variables: { userId },
          },
          'extractionsByAccountIdAndUserId',
        ],
      },
    );

  const [fireRemoveExtractionAccess] = useMutation<RemoveExtractionAccess>(
    removeExtractionAccess,
    {
      refetchQueries: [
        {
          query: allExtractionsByAccountIdAndUserId,
          variables: { userId },
        },
        'extractionsByAccountIdAndUserId',
      ],
    },
  );

  const confirmRemoval = async (confirmed: boolean): Promise<void> => {
    setRemovalConfirmation(false);
    setDisplayImmutableRoleRule(false);
    if (confirmed) {
      const { data } = await fireRemoveExtractionAccess({
        variables: {
          userId,
          extractionId: extractionIdInQuestion || extractionId,
        },
      });
      refetchExtractionAccessById();
      if (!data?.removeExtractionAccess) {
        setDisplayCannotRemoveExtractionForOnlyOneOwner(true);
      }
    } else {
      setValue(accessType);
    }
  };

  const handleChange = async (
    newValue: string,
    oldValue: string,
  ): Promise<void> => {
    setDisplayImmutableRoleRule(false);
    setDisplayCannotRemoveExtractionForOnlyOneOwner(false);
    if (newValue === 'REMOVE') {
      setValue(newValue);
      setRemovalConfirmation(true);
      setValue(oldValue);
    } else {
      setRemovalConfirmation(false);
      const { data } = await fireUpdateUserExtractionAccess({
        variables: {
          type: newValue,
          userId,
          extractionId: extractionIdInQuestion || extractionId,
        },
      });
      if (data?.updateExtractionAccessType === null) {
        setDisplayImmutableRoleRule(true);
        setValue(oldValue);
      } else {
        const { type } = data?.updateExtractionAccessType || {};
        if (type) {
          setValue(type);
        }
      }
    }
  };
  return (
    <>
      <>
        <div data-gid="20975717">
          <Select
            style={{ backgroundColor: '#ffffff', width: 150 }}
            value={value}
            renderValue={() => value}
            onChange={(e): Promise<void> => handleChange(e.target.value, value)}
            data-gid="32856553"
          >
            {userActionEnum.map((action) => (
              <MenuItem key={action} value={action} data-gid="54820895">
                {action.includes('_') ? action.split('_').join(' ') : action}
              </MenuItem>
            ))}
          </Select>
          <br data-gid="34094288" />
          <div style={{ maxWidth: 150 }} data-gid="84431194">
            {displayImmutableRoleRule && !removalConfirmation && (
              <Text
                size="2"
                color="error"
                style={{
                  position: 'relative',

                  top: -10,
                }}
                data-gid="25091335"
              >
                There must be at least one owner
              </Text>
            )}
            {displayCannotRemoveExtractionForOnlyOneOwner &&
              !removalConfirmation && (
                <Text
                  size="2"
                  color="error"
                  style={{
                    position: 'relative',

                    top: -10,
                  }}
                  data-gid="25091335"
                >
                  You cannot remove access to an extraction for which there is
                  only one owner
                </Text>
              )}
          </div>
        </div>
      </>
      {removalConfirmation && (
        <div style={{ marginBottom: 20 }} data-gid="42212032">
          <Text size="2" weight={300} align="left" data-gid="67271234">
            Are you sure?
          </Text>
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            data-gid="75672485"
          >
            <Button
              style={{ marginRight: 5 }}
              onClick={() => confirmRemoval(true)}
              data-gid="30637421"
            >
              Yes
            </Button>
            <Button onClick={() => confirmRemoval(false)} data-gid="45784969">
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
