/* eslint-disable react/require-default-props */
// Copyright (C) 2020 Grayshift, LLC - All Rights Reserved
// This source code is proprietary and confidential.
// Unauthorized copying of this file via any medium is strictly prohibited.

import {
  ConditionalWrapper,
  ContentRegion,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as DefaultTableRow,
  TableSortLabel,
  Text,
} from '@grayshift/cairn';
import { TableProps } from '@grayshift/cairn/dist/components/Table';
import { makeStyles } from '@material-ui/core/styles';
import { TableRowProps } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface DataTableProps<T extends {} = {}>
  extends Pick<TableProps, 'size'> {
  data: T[];
  columnDefinitions: {
    heading: React.ReactNode;
    content?: (item: T) => React.ReactNode;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    id: string;
    sortable?: boolean;
  }[];
  title?: string;
  headerAction?: React.ReactNode;
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc';
  onSortHandler?: (columnId: string) => void;
  totalRecordCount?: number;
  page?: number;
  recordsPerPage?: number;
  pageChangeHandler?: (page: number) => void;
  noRecordsMessage?: string;
  loading?: boolean;
  hoverable?: boolean;
  selectedRowId?: string;
  slots?: {
    TableRow: React.FC<TableRowProps & { row?: T }>;
  };
}
const useStyles = makeStyles({
  tableContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tableHeading: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 16,
    height: 60,
  },
});

export const DataTable = <T extends { id: string } = { id: string }>(
  props: DataTableProps<T>,
): React.ReactElement => {
  const classes = useStyles(props);
  const {
    size = 'medium',
    data = [],
    columnDefinitions,
    title,
    headerAction,
    sortColumn,
    sortDirection,
    onSortHandler,
    totalRecordCount,
    page,
    recordsPerPage,
    pageChangeHandler,
    noRecordsMessage = 'No Records',
    loading = false,
    hoverable,
    selectedRowId,
    slots: { TableRow = DefaultTableRow } = { TableRow: DefaultTableRow },
  } = props;

  return (
    <div className={classes.tableContainer} data-gid="75459511">
      <div data-gid="34461165">
        {(title || headerAction) && (
          <ContentRegion
            padding={3}
            paddingTop={2}
            paddingBottom={2}
            flex
            justify="space-between"
            align="center"
            data-gid="97407001"
          >
            <Text heading="h2" size="4" weight={500} data-gid="75836816">
              {title}
            </Text>
            {headerAction}
          </ContentRegion>
        )}
        <Divider data-gid="18837228" />
        <TableContainer data-gid="92980190">
          <Table size={size} data-gid="30916453">
            <TableHead data-gid="46937137">
              <DefaultTableRow data-gid="71286061">
                {columnDefinitions.map((columnDef) => (
                  <TableCell
                    align={columnDef.align || 'left'}
                    key={`column-${columnDef.id}`}
                    data-gid="31614742"
                  >
                    <ConditionalWrapper
                      condition={!!columnDef.sortable}
                      wrapper={(children) => (
                        <TableSortLabel
                          active={
                            sortColumn &&
                            sortDirection &&
                            sortColumn === columnDef.id
                              ? true
                              : undefined
                          }
                          direction={sortDirection}
                          onClick={() => {
                            if (onSortHandler) {
                              onSortHandler(columnDef.id);
                            }
                          }}
                          data-gid="52386159"
                        >
                          {children}
                        </TableSortLabel>
                      )}
                      data-gid="24657207"
                    >
                      {typeof columnDef.heading === 'string' ? (
                        <Text
                          size="1"
                          color="textSecondary"
                          weight={500}
                          allCaps
                          data-gid="36665010"
                        >
                          {columnDef.heading}
                        </Text>
                      ) : (
                        columnDef.heading
                      )}
                    </ConditionalWrapper>
                  </TableCell>
                ))}
              </DefaultTableRow>
            </TableHead>
            <TableBody data-gid="65238235">
              {loading && (
                <TableRow data-gid="89109343">
                  <TableCell
                    align="center"
                    colSpan={columnDefinitions.length || 1}
                    data-gid="75881670"
                  >
                    Loading
                  </TableCell>
                </TableRow>
              )}
              {!loading && data.length === 0 && (
                <TableRow data-gid="70846415">
                  <TableCell
                    align="center"
                    colSpan={columnDefinitions.length || 1}
                    data-gid="54455086"
                  >
                    {noRecordsMessage}
                  </TableCell>
                </TableRow>
              )}
              {data.map((row) => (
                <TableRow
                  key={`data-row-${row.id}`}
                  hover={hoverable}
                  selected={selectedRowId === row.id}
                  row={row}
                  data-gid="68221911"
                >
                  {columnDefinitions.map((columnDef) =>
                    columnDef.content ? (
                      <TableCell
                        align={columnDef.align || 'left'}
                        key={`data-${columnDef.id}-${row.id}`}
                        data-gid="73160651"
                      >
                        {columnDef.content(row)}
                      </TableCell>
                    ) : null,
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!!totalRecordCount && page && recordsPerPage && pageChangeHandler && (
        <div data-gid="85509631">
          <Pagination
            currentPage={page}
            itemsPerPage={recordsPerPage}
            totalItems={totalRecordCount}
            onPageChange={pageChangeHandler}
            data-gid="28402049"
          />
        </div>
      )}
    </div>
  );
};

// export function DataTable<T extends { id: string } = { id: string }>(
//   props: DataTableProps<T>,
// ) {}
