import { gql } from '@apollo/client';

export const dismissInviteMutation = gql`
  mutation DismissInvite($inviteCode: String!) {
    dismissInvite(input: { code: $inviteCode }) {
      status
    }
  }
`;

export const acceptInviteMutation = gql`
  mutation AcceptInvite($inviteCode: String!) {
    acceptInvite(input: { code: $inviteCode }) {
      status
      extractionAccess {
        id
        user {
          id
          firstName
          lastName
          email
          accountId
        }
        extractionId
        type
      }
    }
  }
`;
