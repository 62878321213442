import { gql } from '@apollo/client';

export const updateUserHasAcceptedTos = gql`
  mutation updateUserHasAcceptedTos($input: UpdateUserTosInput!) {
    updateUserHasAcceptedTos(input: $input) {
      user {
        id
        firstName
        lastName
        email
        accountId
        hasAcceptedTos
      }
    }
  }
`;

export const updateUserMarketingOptIn = gql`
  mutation updateUserMarketingOptIn($input: UpdateUserMarketingOptInInput!) {
    updateUserMarketingOptIn(input: $input) {
      user {
        id
        marketingOptIn
      }
    }
  }
`;

export const tryProMutation = gql`
  mutation ActivateProTrial {
    activateTrial {
      id
      firstName
      lastName
      email
      trialActiveUntil
    }
  }
`;
