import { gql } from '@apollo/client';

import { CoreSourceFileFields, CoreTaggedByFields } from '../fragments';

export const memosQuery = gql`
  ${CoreTaggedByFields}
  query allMemosByExtractionId(
    $extractionId: UUID!
    $searchTerm: String
    $timeframe: TimeFrame
  ) {
    allMemosByExtractionId(
      extractionId: $extractionId
      searchTerm: $searchTerm
      timeframe: $timeframe
    ) {
      id
      title
      author
      noteBody
      modifiedAt
      extractionId
      appBundleId
      taggedAt
      taggedBy {
        ...CoreTaggedByFields
      }
      app {
        id
        bundleId
        displayName
        version
        iconUrl
      }
    }
  }
`;

export const memoByIdQuery = gql`
  ${CoreSourceFileFields}
  ${CoreTaggedByFields}
  query memoById($memoId: ID!, $extractionId: UUID!) {
    memoById(id: $memoId, extractionId: $extractionId) {
      id
      title
      author
      noteBody
      modifiedAt
      extractionId
      appBundleId
      taggedAt
      taggedBy {
        ...CoreTaggedByFields
      }
      app {
        id
        bundleId
        displayName
        version
        iconUrl
      }
      sourceFiles {
        ...CoreSourceFileFields
      }
    }
  }
`;
