import { BarChart, Colors, makeStyles, Text } from '@grayshift/cairn';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilValue } from 'recoil';

import { getTopContactsByExtractionId } from './graphql/generated/getTopContactsByExtractionId';
import { topContactsQuery } from './graphql/queries';
import { timeFrameStateAtom } from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    height: (props: { topContainerExpanded: boolean }) =>
      props.topContainerExpanded ? 345 : 'auto',
  },
  loadingContainer: {
    display: 'flex',
    height: 345,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    padding: 30,
    marginBottom: 10,
  },
  topContactsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '15px 20px',
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
  },
  topContactsHeader: { display: 'flex', justifyContent: 'space-between' },
  collapseButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  noDataContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topContactsSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 280,
  },
  legend: {
    alignSelf: 'flex-end',
    display: 'flex',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
  },
  legendColorIcon: {
    width: 15,
    height: 15,
    border: `solid 1px ${Colors.lightDivider}`,
    marginRight: 5,
  },
});

const barColors = [
  '#115f9a',
  '#1984c5',
  '#22a7f0',
  '#48b5c4',
  '#76c68f',
  '#a6d75b',
  '#c9e52f',
  '#d0ee11',
  '#d0f400',
];

const ContactsBarChart: React.FC = () => {
  const [currentExtractionId] = useCurrentExtractionId();
  const [topContainerExpanded, setTopContainerExpanded] = useState(true);
  const classes = useStyles({ topContainerExpanded });
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { loading, data } =
    useQueryWithErrorBoundary<getTopContactsByExtractionId>(topContactsQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        extractionId: currentExtractionId,
        timeframe:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
      },
    });

  if (!data && loading && topContainerExpanded) {
    return (
      <div className={classes.loadingContainer} data-gid="27970276">
        <ScaleLoader loading color={Colors.blue} data-gid="35813061" />
      </div>
    );
  }

  const edges = data?.contactsByExtractionId?.edges || [];

  const platforms: Array<string> = [];

  const barData = edges?.reduce((accum, nextEdge) => {
    const entry: Entry = {
      id: nextEdge.node.id,
      name: nextEdge.node.metacontact
        ? nextEdge.node.metacontact.primaryDisplayName
        : nextEdge.node.primaryDisplayName,
    };

    entry.messageCounts = nextEdge.node.platformMessageCounts.reduce(
      (acc, platformMessageCount) => {
        if (platformMessageCount.platform === '__total') {
          entry.totalCount = platformMessageCount.count;
          return acc;
        }

        if (!platforms.includes(platformMessageCount.platform)) {
          platforms.push(platformMessageCount.platform);
        }

        acc[platformMessageCount.platform] = platformMessageCount.count;
        return acc;
      },
      {} as { [key: string]: number },
    );
    accum.push(entry);
    return accum;
  }, [] as Array<Entry>);

  const emptyMessageCountObj = platforms.reduce(
    (acc, platform: string) => {
      acc[platform] = 0;
      return acc;
    },
    {} as { [key: string]: number },
  );

  const zeroFilledBarData = barData.map((columnData) => ({
    ...columnData,
    messageCounts: {
      ...emptyMessageCountObj,
      ...columnData.messageCounts,
    },
  }));

  const sortedBarData = zeroFilledBarData.sort((a, b) =>
    a.totalCount && b.totalCount ? b.totalCount - a.totalCount : 0,
  );

  /* colors shape
    {
      'messageCounts|Facebook': '#eff3ff',
      'messageCounts|Twitter': '#bdd7e7',
      'messageCounts|WeChat': '#6baed6',
      'messageCounts|SMS': '#2171b5',
    }
  */
  const barColorsMap = platforms.reduce(
    (acc, p, i) => {
      acc[`messageCounts|${p}`] = barColors[i % barColors.length];
      return acc;
    },
    {} as { [key: string]: string },
  );

  return (
    <div className={classes.rootContainer} data-gid="52401025">
      <div className={classes.topContactsContainer} data-gid="95069002">
        <div className={classes.topContactsHeader} data-gid="67797325">
          <Text heading="h2" size="4" weight={500} data-gid="25214063">
            Top Contacts
          </Text>
          <div
            className={classes.collapseButton}
            onClick={() => setTopContainerExpanded(!topContainerExpanded)}
            role="presentation"
            data-gid="42904662"
          >
            <Text data-gid="80585106">
              {topContainerExpanded ? 'Collapse' : 'Expand'}
            </Text>
            {topContainerExpanded ? (
              <KeyboardArrowUp data-gid="80093785" />
            ) : (
              <KeyboardArrowDown data-gid="40850492" />
            )}
          </div>
        </div>
        {topContainerExpanded && sortedBarData.length === 0 && (
          <div className={classes.noDataContainer} data-gid="92688990">
            <Text data-gid="80356428">No data to show</Text>
          </div>
        )}
        {topContainerExpanded && sortedBarData && sortedBarData.length > 0 && (
          <div className={classes.topContactsSubContainer} data-gid="26921188">
            <div className={classes.legend} data-gid="63278417">
              {platforms.map((p, i) => (
                <div className={classes.legendItem} key={p} data-gid="26466891">
                  <div
                    style={{
                      backgroundColor: `${barColors[i % barColors.length]}`,
                    }}
                    className={classes.legendColorIcon}
                    data-gid="43339256"
                  />
                  <Text size="3" data-gid="42374865">
                    {p}
                  </Text>
                </div>
              ))}
            </div>
            <BarChart
              name="bar-chart"
              data={zeroFilledBarData}
              colors={barColorsMap}
              stackSeries={platforms}
              labelKey="name"
              series={['messageCounts']}
              spacing={0.5}
              seriesSpacing={5}
              xAxisTooltip
              yAxisLabelFormat={String}
              xAxisLabelFormat={(text) => {
                if (text.length <= 10) return text;
                // eslint-disable-next-line unicorn/prefer-spread
                return text.slice(0, 10).concat('...');
              }}
              tooltipFormat={(tooltipData) => {
                const label =
                  tooltipData.seriesKeys?.length > 0
                    ? tooltipData.seriesKeys.at(-1)
                    : tooltipData.label;
                return (
                  <>
                    <div style={{ fontWeight: 'bold' }} data-gid="20036703">
                      {label}
                    </div>
                    <div data-gid="56153503">{tooltipData.value}</div>
                  </>
                );
              }}
              data-gid="82648508"
            />
          </div>
        )}
      </div>
    </div>
  );
};

type Entry = {
  id: string;
  name: string | null;
  messageCounts?: { [key: string]: number };
  totalCount?: number;
};

export default ContactsBarChart;
