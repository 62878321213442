/* eslint-disable @typescript-eslint/no-floating-promises */
import { useLazyQuery } from '@apollo/client';
import { Colors, makeStyles, Modal, Text } from '@grayshift/cairn';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';

import { AccountUserManagementEditModal } from './AccountUserManagementEditModal';
import { accountInfo_accountInfo } from './graphql/generated/accountInfo';
import { accountUsers_accountUsers } from './graphql/generated/accountUsers';
import {
  extractionsByAccountIdAndUserId,
  extractionsByAccountIdAndUserId_extractionsByAccountIdAndUserId,
} from './graphql/generated/extractionsByAccountIdAndUserId';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';
import { allExtractionsByAccountIdAndUserId } from './lib/allExtractionsByAccountIdAndUserId';
import { UserManagementExtractionAccess } from './UserManagementExtractionAccess';

const useStyles = makeStyles({
  emptySubtable: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accessWrapper: { marginRight: 20, marginTop: 20 },
  loader: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

interface UserManagementUserExtractionsTableProps {
  key: string;
  user: accountUsers_accountUsers;
  accountInfo: accountInfo_accountInfo | null;
  refetchAndUpdateTable: () => void;
}

export const UserManagementUserExtractionsTable: React.FC<
  UserManagementUserExtractionsTableProps
> = ({ user, accountInfo, refetchAndUpdateTable }) => {
  const classes = useStyles();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentlyExpandedIds, setCurrentlyExpandedIds] = useState<{
    [key: string]: string;
  }>({});

  const [
    getAllExtractionsByAccountIdAndUserId,
    { loading: subtableLoading, data: subtableData },
  ] = useLazyQuery<extractionsByAccountIdAndUserId>(
    allExtractionsByAccountIdAndUserId,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userId: selectedUserId,
        skip: !selectedUserId,
      },
    },
  );

  useEffect((): void => {
    if (selectedUserId) {
      getAllExtractionsByAccountIdAndUserId();
    }
  }, [
    selectedUserId,
    currentlyExpandedIds,
    getAllExtractionsByAccountIdAndUserId,
  ]);

  const extractionsForUserFound =
    subtableData &&
    subtableData?.extractionsByAccountIdAndUserId &&
    subtableData?.extractionsByAccountIdAndUserId.length > 0;

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <Modal open={modalOpen} data-gid="23139560">
        <AccountUserManagementEditModal
          user={user}
          accountInfo={accountInfo}
          handleClose={handleClose}
          refetchAndUpdateTable={refetchAndUpdateTable}
          data-gid="74177850"
        />
      </Modal>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} data-gid="76363449">
        <TableCell style={{ width: 0 }} data-gid="76779925">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              const currentlyOpen = { ...currentlyExpandedIds };
              if (currentlyOpen[user.id]) {
                delete currentlyOpen[user.id];
                setCurrentlyExpandedIds({ ...currentlyOpen });
              } else {
                currentlyOpen[user.id] = user.id;
                setCurrentlyExpandedIds({ ...currentlyOpen });
                setSelectedUserId(user.id);
              }
            }}
            data-gid="76959736"
          >
            {currentlyExpandedIds[user.id] ? (
              <KeyboardArrowUpIcon data-gid="63913587" />
            ) : (
              <KeyboardArrowDownIcon data-gid="55882754" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left" data-gid="97214192">
          {user.firstName}
        </TableCell>
        <TableCell align="left" data-gid="41318815">
          {user.lastName}
        </TableCell>
        <TableCell align="left" data-gid="86632486">
          {user.email}
        </TableCell>
        <TableCell align="left" data-gid="17925204">
          {user.isExternalUser
            ? 'External User'
            : LowerAllButFirstLetter(user.role)}
        </TableCell>
        <TableCell align="left" data-gid="69083511">
          {user.isEntitled ? (
            <Text data-gid="85017292">Pro</Text>
          ) : user.isExternalEntitled ? (
            <Text data-gid="36252457">Pro (External)</Text>
          ) : (
            <Text data-gid="88756197">Free</Text>
          )}
        </TableCell>
        <TableCell align="left" data-gid="77347109">
          <EditIcon
            style={{ cursor: 'pointer', color: Colors.blue }}
            onClick={() => setModalOpen(!modalOpen)}
            data-gid="27921164"
          />
        </TableCell>
      </TableRow>
      <TableRow data-gid="75198213">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#F5F5F7',
          }}
          colSpan={7}
          data-gid="29980249"
        >
          <Collapse
            in={!!currentlyExpandedIds[user.id]}
            timeout="auto"
            unmountOnExit
            data-gid="62169878"
          >
            {extractionsForUserFound && !subtableLoading && (
              <Box sx={{ margin: 1 }} data-gid="56321826">
                <Table
                  style={{
                    margin: 30,
                    marginLeft: 50,
                    width: '90%',
                    border: '2px solid #FFFFFF',
                    backgroundColor: '#FFFFFF',
                    boxShadow:
                      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    borderRadius: 4,
                  }}
                  size="small"
                  aria-label="purchases"
                  data-gid="99095676"
                >
                  <TableHead data-gid="74952027">
                    <TableRow data-gid="32723685">
                      <TableCell align="left" data-gid="17179639">
                        Evidence ID
                      </TableCell>
                      <TableCell align="left" data-gid="28362310">
                        Shared By
                      </TableCell>
                      <TableCell align="left" data-gid="75513852">
                        Role
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody data-gid="74306521">
                    {subtableData?.extractionsByAccountIdAndUserId?.map(
                      (
                        extraction: extractionsByAccountIdAndUserId_extractionsByAccountIdAndUserId | null,
                      ) =>
                        extraction && (
                          <TableRow data-gid="77310107">
                            <TableCell
                              component="th"
                              scope="row"
                              data-gid="86224676"
                            >
                              {extraction?.evidenceId}
                            </TableCell>
                            <TableCell data-gid="10563868">
                              {extraction.sharedBy || 'N/A'}
                            </TableCell>
                            <TableCell data-gid="11009302">
                              <div
                                className={classes.accessWrapper}
                                data-gid="53131904"
                              >
                                <UserManagementExtractionAccess
                                  accessType={extraction.type}
                                  userId={extraction.userId}
                                  extractionIdInQuestion={
                                    extraction.extractionId
                                  }
                                  refetchExtractionAccessById={
                                    getAllExtractionsByAccountIdAndUserId
                                  }
                                  data-gid="43134030"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ),
                    )}
                  </TableBody>
                </Table>
              </Box>
            )}
            {subtableLoading && (
              <div className={classes.emptySubtable} data-gid="76493695">
                <div className={classes.loader} data-gid="37449594">
                  <ScaleLoader loading color="#1665D8" data-gid="38984922" />
                </div>
              </div>
            )}
            {!subtableLoading &&
              subtableData?.extractionsByAccountIdAndUserId?.length === 0 && (
                <div className={classes.emptySubtable} data-gid="10100358">
                  <Text data-gid="55587523">No Extractions Found</Text>
                </div>
              )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
