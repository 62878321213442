import {
  Button,
  Checkbox,
  makeStyles,
  MenuItem,
  Select,
} from '@grayshift/cairn';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { appsByCallType } from './graphql/generated/appsByCallType';
import getCallTypes from './graphql/queries/getCallTypes';
import {
  appFilterStateAtom,
  currentGlobalSearchTextAtom,
  timeFrameStateAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  timeFilterContainer: {
    display: 'flex',
    cursor: 'pointer',
    textAlign: 'right',
    minWidth: 'fit-content',
    paddingTop: 5,
  },
  searchBarAndBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 500,
  },
  callsFilterForm: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-10px',
  },
  searchContainer: {
    height: 57,
    width: 380,
    margin: '0px 15px 0px 0px',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
  appFilterContainer: {
    padding: '0 25px 0 0',
    width: 250,
    marginTop: 5,
  },
});

type Props = {
  downloadData: (dataType: 'csv' | 'xml') => Promise<void>;
};

const CallsFilters: React.FC<Props> = ({ downloadData }) => {
  const [currentExtractionId] = useCurrentExtractionId();
  const classes = useStyles();

  const [allPossibleAppNames, setAllPossibleAppNames] = useState<string[]>([]);

  const [showExportOptions, setShowExportOptions] = useState(false);

  const [appFilterState, setAppFilterState] =
    useRecoilState(appFilterStateAtom);

  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { data } = useQueryWithErrorBoundary<appsByCallType>(getCallTypes, {
    fetchPolicy: 'cache-and-network',
    variables: {
      extractionId: currentExtractionId,
      timeframe:
        globalTimeframe?.start && globalTimeframe?.end
          ? {
              startDate: globalTimeframe?.start.toISOString(),
              endDate: globalTimeframe?.end.toISOString(),
            }
          : null,
      searchTerm: globalSearchTextState.currentGlobalSearchText,
    },
  });

  useEffect(() => {
    setAppFilterState({
      ...appFilterState,
      callAppFilter: data?.appsByCallType.map(
        ({ appDisplayName }) => appDisplayName ?? '',
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, globalSearchTextState.currentGlobalSearchText, globalTimeframe]);

  useEffect(() => {
    if (data?.appsByCallType) {
      const allApps = data.appsByCallType.map((a) => a.appDisplayName || '');
      setAllPossibleAppNames(allApps);
    }
  }, [data]);

  return (
    <>
      <div className={classes.rootContainer} data-gid="60147701">
        <div className={classes.headerContainer} data-gid="62008270">
          <div className={classes.timeFilterContainer} data-gid="80807161">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: 3,
                width: 180,
              }}
              data-gid="36820993"
            >
              <Button
                onClick={() => setShowExportOptions(!showExportOptions)}
                style={{ borderRadius: 0, width: 180 }}
                data-gid="69294489"
              >
                Export All Calls
              </Button>
            </div>
            {showExportOptions && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  top: 113,
                  width: 180,
                  zIndex: 3,
                  backgroundColor: 'white',
                }}
                data-gid="24063592"
              >
                {/* XML is for the sake of penlink and the integration is not clear so we will bring this back when reqs are fully understood */}
                {/* <Button
                  onClick={() => downloadData('xml')}
                  style={{ borderRadius: 0 }}
                  data-gid="55985292"
                >
                  XML
                </Button> */}
                <Button
                  onClick={() => downloadData('csv')}
                  style={{ borderRadius: 0 }}
                  data-gid="69294489"
                >
                  CSV
                </Button>
              </div>
            )}
          </div>
          <div className={classes.appFilterContainer} data-gid="64391401">
            <Select
              labelString="Filter By App"
              labelId="appLabel"
              value={appFilterState?.callAppFilter ?? []}
              multiple
              renderValue={(selected) => {
                if (Array.isArray(selected) && selected.length === 0) {
                  return 'No filters selected.';
                }
                return (selected as Array<string>)?.join(', ');
              }}
              onChange={(
                // Override type since MUI thinks its a string - https://github.com/mui/material-ui/issues/13782
                e: React.ChangeEvent<
                  HTMLSelectElement & { value: Array<string> }
                >,
              ) => {
                const appNames = e.target.value;
                if (appNames.includes('Select All Apps')) {
                  setAppFilterState({
                    ...appFilterState,
                    callAppFilter: allPossibleAppNames,
                  });
                } else if (appNames.includes('Deselect All Apps')) {
                  setAppFilterState({
                    ...appFilterState,
                    callAppFilter: [],
                  });
                } else {
                  setAppFilterState({
                    ...appFilterState,
                    callAppFilter: appNames,
                  });
                }
              }}
              data-gid="61644159"
            >
              <MenuItem
                key="selectAll"
                value="Select All Apps"
                data-gid="38071852"
              >
                <Checkbox
                  checked={
                    allPossibleAppNames.length ===
                    appFilterState.callAppFilter?.length
                  }
                  data-gid="29825115"
                />
                Select All Apps
              </MenuItem>
              <MenuItem
                key="deselectAllApps"
                value="Deselect All Apps"
                data-gid="94020075"
              >
                <Checkbox
                  checked={appFilterState.callAppFilter?.length === 0}
                  data-gid="94422616"
                />
                Deselect All Apps
              </MenuItem>
              {data?.appsByCallType?.map((type) => (
                <MenuItem
                  key={type.appDisplayName}
                  value={type?.appDisplayName || ''}
                  data-gid="92434916"
                >
                  <Checkbox
                    checked={
                      !isNil(type) &&
                      !isNil(type.appDisplayName) &&
                      appFilterState?.callAppFilter &&
                      appFilterState?.callAppFilter.includes(
                        type.appDisplayName,
                      )
                    }
                    data-gid="83280081"
                  />
                  {type?.appDisplayName} ({type?.hits})
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallsFilters;
