/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import { memo, Ref, SetStateAction, useMemo } from 'react';
import { RecyclerListViewState } from 'recyclerlistview/dist/web/core/RecyclerListView';
import {
  BaseLayoutProvider,
  DataProvider,
  Dimension,
  Layout,
  LayoutManager,
  RecyclerListView,
  RecyclerListViewProps,
  WrapGridLayoutManager,
} from 'recyclerlistview/web';

import { TimelineCard } from './TimelineCard';
import { TimelineEvent } from './types';

// Implement new LayoutManager in order to get items to be full width
// https://github.com/Flipkart/recyclerlistview/issues/564
class GSLayoutManager extends WrapGridLayoutManager {
  // eslint-disable-next-line class-methods-use-this
  public getStyleOverridesForIndex(): Record<string, unknown> | undefined {
    return { width: '100%' };
  }
}

class LayoutProvider extends BaseLayoutProvider {
  private _getLayoutTypeForIndex: (index: number) => string | number;

  private _setLayoutForType: (
    type: string | number,
    dim: Dimension,
    index: number,
  ) => void;

  private _tempDim: Dimension;

  private _lastLayoutManager: GSLayoutManager | undefined;

  constructor(
    getLayoutTypeForIndex: (index: number) => string | number,
    setLayoutForType: (
      type: string | number,
      dim: Dimension,
      index: number,
    ) => void,
  ) {
    super();
    this._getLayoutTypeForIndex = getLayoutTypeForIndex;
    this._setLayoutForType = setLayoutForType;
    this._tempDim = { height: 0, width: 0 };
  }

  public newLayoutManager(
    renderWindowSize: Dimension,
    isHorizontal?: boolean,
    cachedLayouts?: Layout[],
  ): LayoutManager {
    this._lastLayoutManager = new GSLayoutManager(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this,
      renderWindowSize,
      isHorizontal,
      cachedLayouts,
    );
    return this._lastLayoutManager;
  }

  // Provide a type for index, something which identifies the template of view about to load
  public getLayoutTypeForIndex(index: number): string | number {
    return this._getLayoutTypeForIndex(index);
  }

  // Given a type and dimension set the dimension values on given dimension object
  // You can also get index here if you add an extra argument but we don't recommend using it.
  public setComputedLayout(
    type: string | number,
    dimension: Dimension,
    index: number,
  ): void {
    return this._setLayoutForType(type, dimension, index);
  }

  public checkDimensionDiscrepancy(
    dimension: Dimension,
    type: string | number,
    index: number,
  ): boolean {
    const dimension1 = dimension;
    this.setComputedLayout(type, this._tempDim, index);
    const dimension2 = this._tempDim;

    return (
      dimension1.height !== dimension2.height ||
      dimension1.width !== dimension2.width
    );
  }
}

export const TimelineListView: React.FC<{
  number: number;
  data: Array<TimelineEvent>;
  onHover: (eventId: string) => void;
  onClick: (event: TimelineEvent) => void;
  scrollToRef: Ref<
    RecyclerListView<RecyclerListViewProps, RecyclerListViewState>
  >;
  selectedEventId: string | null;
  totalInRangeClues?: number;
  totalFetchedClues?: number;
  setTotalFetchedClues?: React.Dispatch<SetStateAction<number>>;
}> = memo(
  ({
    data,
    onClick,
    onHover,
    selectedEventId,
    scrollToRef,
    totalInRangeClues,
    totalFetchedClues,
    setTotalFetchedClues,
  }) => {
    // Create the data provider and provide method which takes in two rows of data and return if those two are different or not.
    // THIS IS VERY IMPORTANT, FORGET PERFORMANCE IF THIS IS MESSED UP
    const dp = new DataProvider((r1: TimelineEvent, r2: TimelineEvent) => {
      if (selectedEventId === r1.data.id) {
        return true;
      }

      return r1.data.id !== r2.data.id;
    });

    const dataProvider = dp.cloneWithRows(data);

    // Create the layout provider
    // First method: Given an index return the type of item e.g ListItemType1, ListItemType2 in case you have variety of items in your list/grid
    // Second: Given a type and object set the exact height and width for that type on given object, if you're using non deterministic rendering provide close estimates
    // If you need data based check you can access your data provider here
    // You'll need data in most cases, we don't provide it by default to enable things like data virtualization in the future
    // NOTE: For complex lists LayoutProvider will also be complex it would then make sense to move it to a different file
    const layoutProvider = useMemo(
      () =>
        new LayoutProvider(
          // eslint-disable-next-line lodash/prefer-noop
          (index) => {
            const timelineData = dataProvider.getDataForIndex(
              index,
            ) as TimelineEvent;

            if (
              timelineData?.data?.textContent &&
              timelineData?.data?.textContent.length < 36
            ) {
              return 'ShortMessage';
            }

            if (
              timelineData?.data?.textContent &&
              timelineData?.data?.textContent.length < 72
            ) {
              return 'MediumMessage';
            }

            if (timelineData) {
              return timelineData.type;
            }
            return 'Media';
          },
          // eslint-disable-next-line lodash/prefer-noop
          (layoutType, dimensions) => {
            switch (layoutType) {
              case 'ShortMessage':
                // eslint-disable-next-line no-param-reassign
                dimensions.height = 91;
                // eslint-disable-next-line no-param-reassign
                dimensions.width = 305;
                break;

              case 'Messages':
                // eslint-disable-next-line no-param-reassign
                dimensions.height = 141;
                // eslint-disable-next-line no-param-reassign
                dimensions.width = 305;
                break;

              case 'MediumMessage':
                // eslint-disable-next-line no-param-reassign
                dimensions.height = 116;
                // eslint-disable-next-line no-param-reassign
                dimensions.width = 305;
                break;

              default:
                // eslint-disable-next-line no-param-reassign
                dimensions.height = 200;
                // eslint-disable-next-line no-param-reassign
                dimensions.width = 305;
            }
          },
        ),
      // Layout provider can only be instantiated once. Otherwise is creates some scrolling jankiness
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const rowRenderer = (
      _type: string | number,
      d: TimelineEvent,
    ): JSX.Element | Array<JSX.Element> | null => (
      <TimelineCard
        event={d}
        showPulse={selectedEventId === d.data.id}
        onHover={() => onHover(d.data.id)}
        onClick={() => onClick(d)}
        data-gid="63541537"
      />
    );

    return (
      <RecyclerListView
        ref={scrollToRef}
        dataProvider={dataProvider}
        layoutProvider={layoutProvider}
        rowRenderer={rowRenderer}
        onEndReached={() => {
          if (
            !data ||
            !totalFetchedClues ||
            !totalInRangeClues ||
            !setTotalFetchedClues
          )
            return;
          if (data && totalFetchedClues <= totalInRangeClues) {
            setTotalFetchedClues(totalFetchedClues + 40); // TODO: make optional to satisfy TS errors
          }
        }}
        canChangeSize
        forceNonDeterministicRendering
        style={{
          width: '100%',
          display: 'flex',
          position: 'relative',
        }}
        data-gid="21978033"
      />
    );
  },
);
