import { gql } from '@apollo/client';

import { CoreSourceFileFields } from '../fragments';

export const CoreBrowserHistoryFields = gql`
  fragment CoreBrowserHistoryFields on BrowserHistory {
    id
  }
`;

export const getAllBrowserHistoriesByExtractionId = gql`
  query browserHistoryByExtractionId(
    $extractionId: UUID!
    $searchTerm: String!
    $page: Int
    $pageSize: Int
    $sortField: BrowserHistorySortField!
    $orderByDir: Sort!
    $timeframe: TimeFrame
  ) {
    browserHistoryByExtractionId(
      extractionId: $extractionId
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      orderByDir: $orderByDir
      timeframe: $timeframe
    ) {
      edges {
        page
        pageSize
        node {
          id
          url
          timestamp
          taggedAt
          appBundleId
          title
          visitCount
          app {
            id
            displayName
            iconUrl
          }
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;

export const getBrowserHistoryById = gql`
  ${CoreSourceFileFields}
  query browserHistoryById($browserHistoryId: ID!, $extractionId: UUID!) {
    browserHistoryById(id: $browserHistoryId, extractionId: $extractionId) {
      id
      url
      timestamp
      appBundleId
      title
      visitCount
      taggedAt
      taggedBy {
        id
        email
        firstName
        lastName
      }
      app {
        id
        displayName
        iconUrl
      }
      sourceFiles {
        ...CoreSourceFileFields
      }
    }
  }
`;
