import { makeStyles, Text } from '@grayshift/cairn';
import { Skeleton } from '@mui/material';

import { randomInteger } from './lib/randomInteger';

const useStyles = makeStyles({
  centeredFlexRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const DetailTextSkeleton: React.FC<{
  randomWidth?: boolean;
  minWidth?: number;
  maxWidth?: number;
}> = ({ randomWidth = false, minWidth = 50, maxWidth = 70 }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      width: `${randomWidth ? randomInteger(minWidth, maxWidth) : 100}%`,
      marginTop: '16px',
    }}
    data-gid="63660636"
  >
    <Skeleton
      variant="text"
      sx={{ fontSize: '1rem', flex: 1, marginRight: '8px' }}
      data-gid="26258881"
    />
    <Skeleton
      variant="text"
      sx={{ fontSize: '1rem', flex: 1 }}
      data-gid="54350173"
    />
  </div>
);

export const DetailText: React.FC<{
  title: string;
  text?: string | null | React.ReactElement;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  loading?: boolean;
}> = ({ title, text, titleStyle, textStyle, style }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.centeredFlexRow}
      style={{
        width: '100%',
        marginTop: '16px',
        ...style,
      }}
      data-gid="85377701"
    >
      <Text
        color="textSecondary"
        style={{
          ...titleStyle,
          maxWidth: '110px',
          overflowWrap: 'break-word',
          hyphens: 'auto',
        }}
        data-gid="67723995"
      >
        {title}
      </Text>
      <Text
        weight={500}
        style={{ marginLeft: '15px', wordBreak: 'break-all', ...textStyle }}
        align="left"
        data-gid="31718911"
      >
        {text}
      </Text>
    </div>
  );
};
