import { Button, makeStyles, Text } from '@grayshift/cairn';
import { ReactElement } from 'react';
import { useRecoilState } from 'recoil';

import { extractionSelectionModalIsOpenAtom } from './lib/atoms';

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacer: {
    height: 15,
  },
});

function SelectedExtractionPlaceholder(): ReactElement {
  const classes = useStyles();
  const [, setExtractionSelectionModalIsOpen] = useRecoilState(
    extractionSelectionModalIsOpenAtom,
  );
  const text = 'Select an extraction to get started.';
  return (
    <div className={classes.rootContainer} data-gid="83739829">
      <Text size="4" data-gid="59272521">
        {text}
      </Text>
      <div className={classes.spacer} data-gid="40091081" />
      <Button
        onClick={() => setExtractionSelectionModalIsOpen(true)}
        data-gid="29213617"
      >
        Select An Extraction
      </Button>
    </div>
  );
}

export default SelectedExtractionPlaceholder;
