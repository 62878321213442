import { useMutation } from '@apollo/client';
import { Button, makeStyles, Text } from '@grayshift/cairn';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ClueDetailsSlideoutContentWrapper from './ClueDetailsSlideoutContentWrapper';
import ClueDetailsSlideoutMetadataSection from './ClueDetailsSlideoutMetadataSection';
import ClueDetailsSlideoutMetadataSubsection from './ClueDetailsSlideoutMetadataSubsection';
import ClueDetailsSlideoutMetadataTextItem from './ClueDetailsSlideoutMetadataTextItem';
import { browserHistoryById } from './graphql/generated/browserHistoryById';
import {
  ClueTypes,
  ExtractionAccessTypeEnum,
} from './graphql/generated/globalTypes';
import {
  removeClueTagMutation,
  tagBrowserHistoryClueMutation,
} from './graphql/mutations';
import { getBrowserHistoryById } from './graphql/queries/browserHistory';
import { currentExtractionAccessTypeAtom } from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { allTaggedCluesQuery } from './lib/queryExtractionDashboard';
import { Notes } from './Note';
import { ReadOnlyTooltip } from './ReadOnlyTooltip';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  metadataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  tagBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
});

interface BrowserHistoryProps {
  clueId: string;
}

const BrowserHistoryDetails: React.FC<BrowserHistoryProps> = ({ clueId }) => {
  const classes = useStyles();
  const location = useLocation();
  const [currentExtractionId] = useCurrentExtractionId();
  const [sourcefileSectionCollapsed, setSourcefileSectionCollapsed] =
    useState(true);
  const [currentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );
  const [tagBrowserHistoryClue] = useMutation(tagBrowserHistoryClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  const { data, loading } = useQueryWithErrorBoundary<browserHistoryById>(
    getBrowserHistoryById,
    {
      variables: {
        browserHistoryId: clueId,
        extractionId: currentExtractionId,
      },
    },
  );

  const isTagged = data?.browserHistoryById?.taggedAt;
  const allSourceFiles = data?.browserHistoryById?.sourceFiles;
  const onDashboard = location.pathname === '/dashboard';

  return (
    <ClueDetailsSlideoutContentWrapper
      loading={loading && !data}
      data-gid="74123620"
    >
      <div className={classes.metadataContainer} data-gid="94761800">
        <ClueDetailsSlideoutMetadataSection title="Actions" data-gid="83557791">
          <div className={classes.tagBtnContainer} data-gid="97695478">
            <ReadOnlyTooltip
              verb="tag"
              bypass={
                currentExtractionAccessType !==
                ExtractionAccessTypeEnum.READ_ONLY
              }
              data-gid="78703368"
            >
              <Button
                color={isTagged ? 'primary' : 'default'}
                disabled={
                  currentExtractionAccessType ===
                  ExtractionAccessTypeEnum.READ_ONLY
                }
                fullWidth
                onClick={() => {
                  if (!isTagged) {
                    tagBrowserHistoryClue({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                        },
                      },
                      refetchQueries: onDashboard
                        ? [getBrowserHistoryById, allTaggedCluesQuery]
                        : [getBrowserHistoryById],
                    }).catch((tagError: Error) => tagError);
                  }
                  if (isTagged) {
                    removeClueTag({
                      variables: {
                        input: {
                          clueId,
                          extractionId: currentExtractionId,
                          clueType: 'BROWSER_HISTORY',
                        },
                      },
                      refetchQueries: onDashboard
                        ? [getBrowserHistoryById, allTaggedCluesQuery]
                        : [getBrowserHistoryById],
                    }).catch((removeTagError: Error) => removeTagError);
                  }
                }}
                data-gid="88796720"
              >
                {isTagged ? 'Untag (Currently Tagged)' : 'Tag As Important'}
              </Button>
            </ReadOnlyTooltip>
            {isTagged && (
              <Text size="2" data-gid="61610202">
                You can view all tagged clues on the Dashboard
              </Text>
            )}
          </div>
        </ClueDetailsSlideoutMetadataSection>
        <Notes
          clueId={clueId}
          clueType={ClueTypes.BROWSER_HISTORY}
          data-gid="39506512"
        />
        <ClueDetailsSlideoutMetadataSection
          title="Basic Details"
          data-gid="76716505"
        >
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Clue ID:"
            rightText={clueId.toString()}
            data-gid="35746524"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Date/ Time:"
            rightText={
              data?.browserHistoryById?.timestamp
                ? `${DateTime.fromISO(
                    data?.browserHistoryById?.timestamp,
                  ).toLocaleString(DateTime.DATE_MED)} ${DateTime.fromISO(
                    data?.browserHistoryById?.timestamp,
                  ).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`
                : 'null'
            }
            data-gid="73367458"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Title:"
            rightText={data?.browserHistoryById?.title ?? ''}
            data-gid="52661752"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="URL:"
            rightText={data?.browserHistoryById?.url}
            data-gid="67207177"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Browser:"
            rightText={
              data?.browserHistoryById?.app?.displayName ??
              data?.browserHistoryById?.appBundleId
            }
            data-gid="73202558"
          />
          <ClueDetailsSlideoutMetadataTextItem
            leftText="Number of Visits:"
            rightText={data?.browserHistoryById?.visitCount ?? ''}
            data-gid="45190297"
          />
        </ClueDetailsSlideoutMetadataSection>
        {allSourceFiles && allSourceFiles.length > 0 && (
          <ClueDetailsSlideoutMetadataSection
            title="Source Files"
            collapsed={sourcefileSectionCollapsed}
            onClickCollapse={() =>
              setSourcefileSectionCollapsed(!sourcefileSectionCollapsed)
            }
            data-gid="41732298"
          >
            {allSourceFiles.map((sf) => (
              <ClueDetailsSlideoutMetadataSubsection
                title={sf.name}
                data-gid="40848079"
              >
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="File Path:"
                  rightText={`${sf.enclosingDirectory}/${sf.name}`}
                  data-gid="74647789"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="SHA256:"
                  rightText={`${sf.checksum}`}
                  data-gid="87213343"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Changed:"
                  rightText={`${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.ctime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="11523584"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Modified:"
                  rightText={`${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.mtime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="93685567"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Last Accessed:"
                  rightText={`${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.TIME_WITH_SHORT_OFFSET,
                  )} ${DateTime.fromMillis(sf.atime).toLocaleString(
                    DateTime.DATE_MED,
                  )}`}
                  data-gid="12763575"
                />
                <ClueDetailsSlideoutMetadataTextItem
                  leftText="Size:"
                  rightText={`${sf.sizeBytes}`}
                  data-gid="43791558"
                />
              </ClueDetailsSlideoutMetadataSubsection>
            ))}
          </ClueDetailsSlideoutMetadataSection>
        )}
      </div>
    </ClueDetailsSlideoutContentWrapper>
  );
};

export default BrowserHistoryDetails;
