/* eslint-disable react/jsx-one-expression-per-line */
import { Colors, makeStyles } from '@grayshift/cairn';
import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { publicStaticFileURL } from './lib/publicFile';

const useStyles = makeStyles({
  rootContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  topSection: {
    display: 'flex',
    backgroundColor: '#000E2B',
    color: 'white',
    padding: '20px 70px',
    backgroundImage: `url("${publicStaticFileURL(
      'splashscreen/banner-bg-graphic-logo.png',
    )}")`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  textInTopSection: {
    width: 600,
  },
  preTitle: {
    margin: 0,
    marginBottom: -20,
    fontSize: 20,
    fontFamily: 'rift',
  },
  bigTitle: {
    color: 'white',
    fontSize: 80,
    fontFamily: 'rift',
    margin: 0,
  },
  postTitle: {
    fontSize: 35,
    fontFamily: 'rift',
    margin: 0,
    marginTop: -20,
  },
  textAboveButton: {
    fontSize: 40,
    fontFamily: 'rift',
    marginTop: 15,
    marginBottom: 10,
  },
  tryBtn: {
    cursor: 'pointer',
    backgroundColor: '#6ABF46',
    fontSize: 16,
    fontWeight: 600,
    padding: '12px 25px',
    borderRadius: 30,
    border: 'none',
    '&:hover': {
      background: '#77DD55',
    },
  },
  disclaimerTezt: {
    marginTop: 5,
    marginBottom: 0,
    fontSize: 12,
  },
  productToursBanner: {
    display: 'flex',
    backgroundColor: '#EAF6FA',
    width: '100%',
    padding: '7px 0',
    justifyContent: 'center',
  },
  productToursBtn: {
    fontWeight: 600,
    color: Colors.blue,
    margin: '0px 5px',
    textDecoration: 'underline',
  },
  bigAdvantagesSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 70px 20px 70px',
  },
  sectionTitle: {
    fontSize: 35,
    fontFamily: 'rift',
    margin: 0,
    marginBottom: 10,
  },
  bigAdvantagesItemsContainer: {
    display: 'flex',
  },
  bigAdvantageItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    padding: '0px 30px',
  },
  bigAdvantageItemTitle: {
    marginTop: 10,
    marginBottom: 0,
  },
  bigAdvantageItemDesc: {
    textAlign: 'center',
    margin: 0,
    width: 200,
  },
  artifactCategoriesSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 70px',
    backgroundColor: '#EFF0F1',
  },
  artifactItemsContainer: {
    display: 'flex',
  },
  artifactItemsColumn: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  artifactItemsColumnSubContainer: {
    display: 'flex',
    paddingRight: 10,
  },
  artifactIconsColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    justifyContent: 'space-between',
  },
  artifactTextColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    justifyContent: 'space-between',
  },
  artifactItemText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    marginTop: 0,
    marginBottom: 0,
    height: 30,
  },
  artifactItemTitle: {
    fontWeight: 'bold',
    marginRight: 3,
  },
  finePrintSection: {
    display: 'flex',
    height: 30,
    alignItems: 'center',
    padding: '5px 70px',
    fontSize: 12,
  },
});

function useRedirectPath(): string | null {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  return params.get('redirectPath');
}

const SampleComponent: React.FC = () => {
  const classes = useStyles();
  const [clickedRedirect, setClickedRedirect] = useState(false);
  const redirectPath = useRedirectPath();

  return (
    <div className={classes.rootContainer} data-gid="70394125">
      <div className={classes.topSection} data-gid="48723570">
        <div className={classes.textInTopSection} data-gid="44507529">
          <h5 className={classes.preTitle} data-gid="97471513">
            FROM THE LEADER IN MOBILE FORENSICS EXTRACTIONS
          </h5>
          <h1 className={classes.bigTitle} data-gid="46946613">
            Welcome To ArtifactIQ
          </h1>
          <h3 className={classes.postTitle} data-gid="97829002">
            Early Access
          </h3>
          <p data-gid="32332272">
            ArtifactIQ by Grayshift is a cloud-based digital analysis tool that
            offers incredible speed and ability to collaborate. You can review
            and share evidence in less than 5 minutes from the time you start a
            GrayKey extraction. When every second counts, count on ArtifactIQ to
            deliver - fast.{' '}
          </p>
          <h2 className={classes.textAboveButton} data-gid="15378996">
            Protect And Serve. Faster.
          </h2>
          <button
            className={classes.tryBtn}
            type="button"
            onClick={() => setClickedRedirect(true)}
            data-gid="35176441"
          >
            Try ArtifactIQ By Grayshift
            {clickedRedirect && (
              <Redirect to={redirectPath ?? '/'} data-gid="17204964" />
            )}
          </button>
          <p className={classes.disclaimerTezt} data-gid="90054301">
            I understand that if I click “Try ArtifactIQ by Grayshift”, my
            mobile device extraction will be sent to ArtifactIQ by Grayshift,
            which is hosted on AWS in the US.*
          </p>
        </div>
      </div>
      <div className={classes.productToursBanner} data-gid="90045593">
        View our{' '}
        <a
          href="https://www.grayshift.com/artifactiq-product-tour/"
          className={classes.productToursBtn}
          data-gid="72079016"
        >
          Product Tours
        </a>{' '}
        to learn more about how ArtifactIQ by Grayshift can help your
        investigations.
      </div>
      <div className={classes.bigAdvantagesSection} data-gid="78885216">
        <h2 className={classes.sectionTitle} data-gid="82304423">
          Why ArtifactIQ
        </h2>
        <div
          className={classes.bigAdvantagesItemsContainer}
          data-gid="81261505"
        >
          <div className={classes.bigAdvantageItem} data-gid="47910003">
            <img
              height="80px"
              width="80px"
              src={publicStaticFileURL('/splashscreen/icon-lg-speed.png')}
              alt="answers-faster"
              data-gid="37343135"
            />
            <h3 className={classes.bigAdvantageItemTitle} data-gid="21955281">
              Answers, Faster
            </h3>
            <p className={classes.bigAdvantageItemDesc} data-gid="12566567">
              Native GrayKey integration means the investigation starts minutes
              after your extraction starts, not hours.
            </p>
          </div>
          <div className={classes.bigAdvantageItem} data-gid="10471201">
            <img
              height="80px"
              width="80px"
              src={publicStaticFileURL('/splashscreen/icon-lg-collab.png')}
              alt="answers-faster"
              data-gid="85917778"
            />
            <h3 className={classes.bigAdvantageItemTitle} data-gid="11518353">
              True Collaboration
            </h3>
            <p className={classes.bigAdvantageItemDesc} data-gid="37745677">
              Sharing with investigative staff over the cloud is fast and
              secure, which saves you time, money, and resources.
            </p>
          </div>
          <div className={classes.bigAdvantageItem} data-gid="94327392">
            <img
              height="80px"
              width="80px"
              src={publicStaticFileURL('/splashscreen/icon-lg-learn.png')}
              alt="answers-faster"
              data-gid="77374559"
            />
            <h3 className={classes.bigAdvantageItemTitle} data-gid="80159577">
              Easy to Learn
            </h3>
            <p className={classes.bigAdvantageItemDesc} data-gid="79066235">
              ArtifactIQ’s intuitive user interface means you spend less time
              learning and more time investigating.
            </p>
          </div>
          <div className={classes.bigAdvantageItem} data-gid="77302672">
            <img
              height="80px"
              width="80px"
              src={publicStaticFileURL('/splashscreen/icon-lg-resources.png')}
              alt="answers-faster"
              data-gid="55881598"
            />
            <h3 className={classes.bigAdvantageItemTitle} data-gid="41203075">
              Maximized Resources
            </h3>
            <p className={classes.bigAdvantageItemDesc} data-gid="51558421">
              No headaches over storage devices or the cost of updating and
              maintaining on-site premises.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.artifactCategoriesSection} data-gid="71552324">
        <h2 className={classes.sectionTitle} data-gid="53787476">
          Artifact Categories
        </h2>
        <div className={classes.artifactItemsContainer} data-gid="39578612">
          <div className={classes.artifactItemsColumn} data-gid="74645471">
            <div
              className={classes.artifactItemsColumnSubContainer}
              data-gid="25491299"
            >
              <div className={classes.artifactIconsColumn} data-gid="51385186">
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL(
                    '/splashscreen/icon-sm-timeline.png',
                  )}
                  alt="answers-faster"
                  data-gid="56520735"
                />
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL(
                    '/splashscreen/icon-sm-messages.png',
                  )}
                  alt="answers-faster"
                  data-gid="25606077"
                />
              </div>
              <div className={classes.artifactTextColumn} data-gid="29155764">
                <div className={classes.artifactItemText} data-gid="69683074">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="33930377"
                  >
                    Timeline:
                  </span>{' '}
                  Every artifact in chronological order
                </div>
                <div className={classes.artifactItemText} data-gid="56178717">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="22060197"
                  >
                    Messages:
                  </span>{' '}
                  SMS & popular apps supported
                </div>
              </div>
            </div>
          </div>
          <div className={classes.artifactItemsColumn} data-gid="49816757">
            <div
              className={classes.artifactItemsColumnSubContainer}
              data-gid="88965549"
            >
              <div className={classes.artifactIconsColumn} data-gid="75730634">
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL('/splashscreen/icon-sm-calls.png')}
                  alt="answers-faster"
                  data-gid="15505473"
                />
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL('/splashscreen/icon-sm-media.png')}
                  alt="answers-faster"
                  data-gid="66546363"
                />
              </div>
              <div className={classes.artifactTextColumn} data-gid="41451968">
                <div className={classes.artifactItemText} data-gid="99462668">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="72029582"
                  >
                    Calls:
                  </span>{' '}
                  Incoming, outgoing, & missed
                </div>
                <div className={classes.artifactItemText} data-gid="43404854">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="50256573"
                  >
                    Media:
                  </span>{' '}
                  Images on device & attachments
                </div>
              </div>
            </div>
          </div>
          <div className={classes.artifactItemsColumn} data-gid="98558214">
            <div
              className={classes.artifactItemsColumnSubContainer}
              data-gid="34758385"
            >
              <div className={classes.artifactIconsColumn} data-gid="90717704">
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL(
                    '/splashscreen/icon-sm-locations.png',
                  )}
                  alt="answers-faster"
                  data-gid="99196081"
                />
                <img
                  height="30px"
                  width="30px"
                  src={publicStaticFileURL(
                    '/splashscreen/icon-sm-contacts.png',
                  )}
                  alt="answers-faster"
                  data-gid="32470561"
                />
              </div>
              <div className={classes.artifactTextColumn} data-gid="47578742">
                <div className={classes.artifactItemText} data-gid="62837208">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="38815225"
                  >
                    Locations:
                  </span>{' '}
                  Map of device & event locations
                </div>
                <div className={classes.artifactItemText} data-gid="61232578">
                  <span
                    className={classes.artifactItemTitle}
                    data-gid="29968678"
                  >
                    Contacts:
                  </span>{' '}
                  Across calls & supported apps
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.finePrintSection} data-gid="48128435">
        * Additional datacenters will be supported for other jurisdictions in
        the future.
      </div>
    </div>
  );
};

export default SampleComponent;
