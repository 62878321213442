import { gql } from '@apollo/client';

export const CoreTaggedByFields = gql`
  fragment CoreTaggedByFields on User {
    id
    firstName
    lastName
    email
    trialActiveUntil
  }
`;
