import { Button, CardContent, makeStyles, Text } from '@grayshift/cairn';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles({
  errorBoundary: {
    margin: 'auto',
    width: '100%',
    height: '35%',
    textAlign: 'center',
  },
});

export const ErrorFallback: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <CardContent flexDirection="column" data-gid="26205906">
      <div className={classes.errorBoundary} data-gid="32031174">
        <Sentry.ErrorBoundary showDialog>
          <Text data-gid="61142425">
            We are sorry, there seems to be a problem
          </Text>
          <br data-gid="27815453" />
          <Button onClick={() => Sentry.showReportDialog()} data-gid="20000152">
            Send To Engineering
          </Button>
        </Sentry.ErrorBoundary>
      </div>
    </CardContent>
  );
};
