/* eslint-disable pii/no-email */
import { gql, useMutation } from '@apollo/client';
import { Colors, makeStyles, Text } from '@grayshift/cairn';
import { CallMade, CallReceived, PhoneMissed } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilState, useRecoilValue } from 'recoil';
import xmlbuilder from 'xmlbuilder';

import { ActionBar } from './ActionBar';
import CallsFilters from './CallsFilters';
import { DataTable } from './DataTable';
import { callById } from './graphql/generated/callById';
import {
  callsByExtractionId,
  callsByExtractionId_callsByExtractionId_edges_node,
} from './graphql/generated/callsByExtractionId';
import { ClueTypes } from './graphql/generated/globalTypes';
import {
  removeClueTagMutation,
  tagCallClueMutation,
} from './graphql/mutations';
import { callByIdQuery, callsQuery } from './graphql/queries/calls';
import {
  appFilterStateAtom,
  clueDetailsSlideoutDataAtom,
  ClueType,
  currentGlobalSearchTextAtom,
  timeFrameStateAtom,
  userInfoAtom,
} from './lib/atoms';
import determineCallStatus from './lib/determineCallStatus';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  rootContainer: {
    padding: 20,
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  searchContainer: {
    height: 57,
    width: 280,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
  },
  spacer: {
    height: 10,
  },
  searchBtn: {
    display: 'flex',
    marginLeft: 10,
    marginTop: 8,
  },
  cellContainer: {
    cursor: 'pointer',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewDetailsButtonContainer: {
    marginRight: 50,
  },
  alternateContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    padding: '10px 0',
    alignItems: 'center',
    paddingTop: 5,
  },
  mostRecentCommContainer: {
    cursor: 'pointer',
  },
  statusWord: {
    marginLeft: 3,
  },
  callStatus: {
    display: 'flex',
    justifyContent: 'center',
  },
  appContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

type StatusProps = {
  originated: boolean;
  answered: boolean;
};

const DownloadCallLogDataAuditLogMutation = gql`
  mutation DownloadCallLogDataEvent(
    $input: CreateExtractionAuditLogEventInput!
  ) {
    createExtractionAuditLogEvent(input: $input) {
      auditLog {
        id
      }
    }
  }
`;

const CallStatus = ({ originated, answered }: StatusProps): JSX.Element => {
  const classes = useStyles();
  const callStatusLabel = determineCallStatus(originated, answered);

  return (
    <div className={classes.callStatus} data-gid="59534885">
      {callStatusLabel === 'Incoming' && <CallReceived data-gid="52787390" />}
      {callStatusLabel === 'Missed' && <PhoneMissed data-gid="41421098" />}
      {callStatusLabel === 'Outgoing' && <CallMade data-gid="86713419" />}
      <div className={classes.statusWord} data-gid="42817973">
        {callStatusLabel}
      </div>
    </div>
  );
};

const CallsTable: React.FC = () => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const [userInfo] = useRecoilState(userInfoAtom);
  const { setClue } = useQueryParams();
  const [clueDetailsSlideoutData] = useRecoilState(clueDetailsSlideoutDataAtom);

  const [createAuditLogEvent] = useMutation(
    DownloadCallLogDataAuditLogMutation,
  );

  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);

  const [appFilterState] = useRecoilState(appFilterStateAtom);

  const { globalTimeframe } = timeFrameState || {};
  const { slideoutOpen } = clueDetailsSlideoutData;

  const [currentPage, setCurrentPage] = useState(1);

  const { loading, data, fetchMore } =
    useQueryWithErrorBoundary<callsByExtractionId>(callsQuery, {
      variables: {
        extractionId: currentExtractionId,
        timeFrame:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
        searchTerm:
          globalSearchTextState.currentGlobalSearchText.length > 0
            ? globalSearchTextState.currentGlobalSearchText
            : null,
        appNames: appFilterState?.callAppFilter || [null],
        page: currentPage,
        pageSize: 50,
      },
    });

  const [tagCallClue] = useMutation(tagCallClueMutation);
  const [removeClueTag] = useMutation(removeClueTagMutation);

  const createCsvAndDownload = async (
    allData: callsByExtractionId_callsByExtractionId_edges_node[],
  ): Promise<void> => {
    const csvPoints: string[][] = [
      ['DATE/TIME', 'IDENTIFIER', 'STATUS', 'APP', 'DURATION'],
    ];
    allData.forEach((call) => {
      if (call) {
        const {
          timestamp,
          displayName,
          appBundleId,
          duration,
          originated,
          answered,
        } = call;
        const callStatusLabel = determineCallStatus(originated, answered);
        const csvPoint = [
          timestamp,
          displayName,
          callStatusLabel,
          appBundleId,
          duration ? duration.toString() : '',
        ];
        csvPoints.push(csvPoint);
      }
    });
    if (csvPoints.length > 0) {
      const element = document.createElement('a');
      const file = new Blob([csvPoints.join('\n') || '']);
      if (file) {
        // eslint-disable-next-line scanjs-rules/assign_to_href
        element.href = URL.createObjectURL(file);
        element.download = 'ArtifactIQ_Call_CSV.csv';
        document.body.append(element);
        element.click();

        await createAuditLogEvent({
          variables: {
            input: {
              action: 'CALL_LOG_EXPORT',
              data: {
                exportType: 'csv',
                userId: userInfo.id,
                email: userInfo.email,
              },
              extractionId: currentExtractionId,
            },
          },
        });
      }
    }
  };

  const createXmlAndDownload = async (
    allData: callsByExtractionId_callsByExtractionId_edges_node[],
  ): Promise<void> => {
    const xml = xmlbuilder.create('call_log');
    allData.forEach(
      (call: callsByExtractionId_callsByExtractionId_edges_node) => {
        const callXml = xml.ele('call');

        Object.entries(call).forEach(
          (e: [string, callsByExtractionId_callsByExtractionId_edges_node]) => {
            const key = e[0];
            const value = e[1];
            if (key !== 'contact') {
              callXml.ele(key, value);
            }
          },
        );
      },
    );

    const xmlString = xml.end({ pretty: true });
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    // eslint-disable-next-line scanjs-rules/assign_to_href
    link.href = url;
    link.download = 'data.xml';
    link.click();

    await createAuditLogEvent({
      variables: {
        input: { action: 'CALL_LOG_EXPORT', data: { exportType: 'XML' } },
      },
    });
  };

  const downloadData = async (type: 'xml' | 'csv'): Promise<void> => {
    let scopedCurrentPage = currentPage;
    let accumulatedData: callsByExtractionId_callsByExtractionId_edges_node[] =
      [];
    if (data?.callsByExtractionId?.pageInfo?.totalPages) {
      while (
        scopedCurrentPage <= data?.callsByExtractionId?.pageInfo?.totalPages
      ) {
        // eslint-disable-next-line no-await-in-loop
        const pageOfData = await fetchMore({
          variables: {
            page: scopedCurrentPage,
          },
        }).catch(() => null);

        if (pageOfData) {
          const nodes =
            pageOfData?.data.callsByExtractionId?.edges.map(
              (edge) => edge.node,
            ) || [];
          accumulatedData = [...accumulatedData, ...nodes];
        }

        scopedCurrentPage += 1;
      }
    }

    if (accumulatedData.length > 0 && type === 'csv') {
      await createCsvAndDownload(accumulatedData);
    }

    if (accumulatedData.length > 0 && type === 'xml') {
      await createXmlAndDownload(accumulatedData);
    }
  };

  useEffect(() => {
    // Reset Page # when App Filter Changes.
    setCurrentPage(1);
  }, [
    appFilterState?.callAppFilter,
    globalTimeframe?.end,
    globalTimeframe?.start,
    globalSearchTextState?.currentGlobalSearchText,
  ]);

  const edges = data?.callsByExtractionId?.edges || [];
  const totalRows = data?.callsByExtractionId?.pageInfo.totalEdges;

  function setClueDetails(id: string): void {
    setClue({
      slideoutOpen: true,
      clueId: id,
      clueType: ClueType.CALL,
    });
  }

  const [selectedRow, setSelectedRow] = useState<string | undefined>();

  const onClickRow = (id: string): void => {
    setClueDetails(id);
    setSelectedRow(id);
  };

  return (
    <div className={classes.rootContainer} data-gid="55465631">
      <Text heading="h2" size="5" weight={500} data-gid="12217691">
        Calls
      </Text>
      <CallsFilters downloadData={downloadData} data-gid="49939263" />
      <div className={classes.spacer} data-gid="49866863" />
      {loading && (
        <div className={classes.loadingContainer} data-gid="95960684">
          <ScaleLoader loading color={Colors.blue} data-gid="82804397" />
        </div>
      )}
      <div className={classes.tableContainer} data-gid="48120656">
        <DataTable
          data={edges.map((edge) => edge.node)}
          columnDefinitions={[
            {
              id: 'Date/Time',
              heading: 'Date/Time',
              align: 'left',
              content: (row) => (
                <div
                  onClick={() => onClickRow(row.id)}
                  role="presentation"
                  className={classes.cellContainer}
                  key={`call-timestamp-${row.id}`}
                  data-gid="68975716"
                >
                  <Text data-gid="29867501">
                    {`${DateTime.fromISO(row.timestamp).toLocaleString(
                      DateTime.DATE_MED,
                    )} ${DateTime.fromISO(row.timestamp).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )}
                   `}
                  </Text>
                </div>
              ),
            },
            {
              id: 'Identifier',
              heading: 'Identifier',
              align: 'left',
              sortable: true,
              content: (row) => (
                <div
                  className={classes.nameContainer}
                  onClick={() => {
                    onClickRow(row.id);
                  }}
                  role="presentation"
                  key={`call-contact-${row.id}`}
                  data-gid="79736204"
                >
                  <Text data-gid="67451805">
                    {row.displayName.length >= 25
                      ? `${row.displayName.slice(0, 22)}...`
                      : row.contact?.metacontact
                      ? row.contact.metacontact.primaryDisplayName
                      : row.displayName}
                  </Text>
                </div>
              ),
            },
            {
              id: 'Status',
              heading: 'Status',
              align: 'center',
              content: (row) => (
                <div
                  onClick={() => onClickRow(row.id)}
                  role="presentation"
                  className={classes.cellContainer}
                  key={`call-source-${row.id}`}
                  data-gid="45414922"
                >
                  <CallStatus
                    originated={row.originated}
                    answered={row.answered}
                    data-gid="48598432"
                  />
                </div>
              ),
            },
            {
              id: 'App',
              heading: 'App',
              align: 'center',
              content: (row) => (
                <div
                  onClick={() => onClickRow(row.id)}
                  role="presentation"
                  className={`${classes.cellContainer} ${classes.appContainer}`}
                  key={`call-duration-${row.id}`}
                  data-gid="14699645"
                >
                  {row.app?.iconUrl ? (
                    <Tooltip
                      title={row.app?.displayName ?? row.appBundleId}
                      placement="right"
                      data-gid="70707694"
                    >
                      <img
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: '#1665D8',
                          color: 'white',
                          borderRadius: '100%',
                          height: 24,
                          width: 24,
                        }}
                        src={row.app?.iconUrl ?? undefined}
                        alt="Call Icon"
                        data-gid="78123003"
                      />
                    </Tooltip>
                  ) : (
                    <Text data-gid="64304560">
                      {row.app?.displayName ?? row.appBundleId}
                    </Text>
                  )}
                </div>
              ),
            },
            {
              id: 'Duration',
              heading: 'Duration',
              align: 'center',
              content: (row) => (
                <div
                  onClick={() => onClickRow(row.id)}
                  role="presentation"
                  className={classes.cellContainer}
                  key={`call-duration-${row.id}`}
                  data-gid="16471624"
                >
                  <Text data-gid="85602584">
                    {!row?.duration && row?.duration !== 0
                      ? 'Unknown'
                      : new Date(1000 * row.duration)
                          .toISOString()
                          .slice(11, 19)}
                  </Text>
                </div>
              ),
            },
            {
              id: 'actions',
              heading: 'Actions',
              align: 'center',
              content: (row) => (
                <ActionBar
                  onTag={async (tagged) => {
                    if (tagged && row?.id) {
                      await removeClueTag({
                        variables: {
                          input: {
                            clueId: row.id,
                            extractionId: currentExtractionId,
                            clueType: ClueTypes.CALL,
                          },
                        },
                        optimisticResponse: {
                          removeClueTag: {
                            clueId: row.id,
                            __typename: 'RemoveClueTagPayload',
                          },
                        },
                        update: (
                          proxy,
                          {
                            data: {
                              removeClueTag: { clueId },
                            },
                          },
                        ) => {
                          const rqData = proxy.readQuery<
                            callById,
                            { callId: string }
                          >({
                            query: callByIdQuery,
                            variables: { callId: clueId as string },
                          });

                          if (rqData?.callById) {
                            proxy.writeQuery({
                              query: callByIdQuery,
                              data: {
                                callById: {
                                  ...rqData.callById,
                                  taggedAt: null,
                                  taggedBy: null,
                                },
                              },
                              variables: { callId: clueId as string },
                            });
                          }
                        },
                      });
                    } else if (row?.id) {
                      await tagCallClue({
                        variables: {
                          input: {
                            clueId: row.id,
                            extractionId: currentExtractionId,
                          },
                        },
                        // update(proxy, data) {
                        //   const callData = proxy.readQuery({
                        //     query: callByIdQuery,
                        //     variables: { callId: row.id },
                        //   });
                        // },
                        optimisticResponse: {
                          tagCallClue: {
                            call: {
                              id: row.id,
                              taggedAt: '2024-01-09T04:27:31.549Z',
                              taggedBy: {
                                id: userInfo.id,
                                firstName: userInfo.firstName,
                                lastName: userInfo.lastName,
                                email: userInfo.email,
                                __typename: 'User',
                              },
                              __typename: 'Call',
                            },
                            __typename: 'TagCallCluePayload',
                          },
                        },
                      });
                    }
                  }}
                  position="relative"
                  isTagged={!!row?.taggedAt}
                  data-gid="59534885"
                />
              ),
            },
          ]}
          page={currentPage}
          pageChangeHandler={(nextPageNum) => {
            setCurrentPage(nextPageNum);
            fetchMore({
              variables: {
                page: nextPageNum,
              },
            }).catch(() => null);
          }}
          totalRecordCount={totalRows}
          hoverable
          selectedRowId={slideoutOpen ? selectedRow : undefined}
          recordsPerPage={50}
          size="small"
          noRecordsMessage="No Calls Found"
          data-gid="98908141"
        />
      </div>
    </div>
  );
};

export default CallsTable;
