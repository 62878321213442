import { Tooltip } from '@mui/material';
import { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

import { AppDisplayNames, getIconSource } from '../IconMap';
import { publicStaticFileURL } from '../lib/publicFile';

export const AppIcon: React.FC<{
  iconUrl: string;
  displayName: string | undefined;
  height: ImgHTMLAttributes<HTMLImageElement>['height'];
  width: ImgHTMLAttributes<HTMLImageElement>['width'];
}> = ({ iconUrl, height, width, displayName }) => {
  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <div data-gid="40081380">
      <Tooltip
        title={displayName ?? ''}
        ref={tipRef}
        PopperProps={{
          sx: { display: inView ? 'block' : 'none' },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        }}
        data-gid="62115362"
      >
        <img
          style={iconUrl === 'default' ? {} : { borderRadius: '100%' }}
          height={height}
          width={width}
          src={
            iconUrl === 'default'
              ? publicStaticFileURL('/genericmessage100blue.png')
              : iconUrl
          }
          alt="messageAppIcon"
          data-gid="57998540"
        />
      </Tooltip>
    </div>
  );
};

export const StaticAppIcon: React.FC<{
  displayName: string;
  height: ImgHTMLAttributes<HTMLImageElement>['height'];
  width: ImgHTMLAttributes<HTMLImageElement>['width'];
}> = ({ displayName, height, width }) => (
  <div data-gid="40081380">
    <img
      height={height}
      width={width}
      src={getIconSource(displayName as AppDisplayNames, true)}
      alt="messageAppIcon"
      data-gid="57998540"
    />
  </div>
);
