import { useMutation } from '@apollo/client';
import {
  Checkbox,
  Colors,
  makeStyles,
  MenuItem,
  Select,
  Text,
} from '@grayshift/cairn';
import { Tooltip } from '@mui/material';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import max from 'lodash/max';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ActionBar } from './ActionBar';
import { DataTable } from './DataTable';
import {
  appByContactType,
  appByContactType_appsByContactType,
} from './graphql/generated/appByContactType';
import { contactByIdAndExtractionId } from './graphql/generated/contactByIdAndExtractionId';
import {
  getAllContactsByExtractionId,
  getAllContactsByExtractionId_contactsByExtractionId_edges_node,
  getAllContactsByExtractionId_contactsByExtractionId_edges_node_attributes,
} from './graphql/generated/getAllContactsByExtractionId';
import { ClueTypes } from './graphql/generated/globalTypes';
import { RemoveClueTag } from './graphql/generated/RemoveClueTag';
import { TagContactClue } from './graphql/generated/TagContactClue';
import {
  removeClueTagMutation,
  tagContactClueMutation,
} from './graphql/mutations';
import {
  allContactsQuery,
  contactByIdAndExtractionIdQuery,
  getContactTypes,
} from './graphql/queries';
import {
  appFilterStateAtom,
  clueDetailsSlideoutDataAtom,
  ClueType,
  contactSortStateAtom,
  currentGlobalSearchTextAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { useUser } from './useUser';

const useStyles = makeStyles({
  rootContainer: {
    padding: 20,
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  filterContainer: {
    display: 'flex',
  },
  searchContainer: {
    height: 57,
    width: 380,
  },
  filterByAppContainer: {
    marginTop: 8,
    marginLeft: 'auto',
    width: 300,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
  },
  searchBtn: {
    display: 'flex',
    marginLeft: 10,
    marginTop: 8,
  },
  cellContainer: {
    cursor: 'pointer',
  },
  appContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-25px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewDetailsButtonContainer: {
    marginRight: 50,
  },
  alternateContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    padding: '10px 0',
    alignItems: 'center',
    paddingTop: 5,
  },
  commCountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mostRecentCommContainer: {
    cursor: 'pointer',
  },
  clearSearch: {
    position: 'relative',
    left: -45,
    cursor: 'pointer',
    paddingTop: '5px',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
});

type ApplicationMapType = {
  [key: string]: string;
};

const ApplicationMap: ApplicationMapType = {
  Messenger: 'Facebook Messenger',
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const ContactsTable: React.FC = () => {
  const classes = useStyles();
  const { user } = useUser();
  const [currentExtractionId] = useCurrentExtractionId();
  const [clueDetailsSlideoutData] = useRecoilState(clueDetailsSlideoutDataAtom);

  const [tagContactClue] = useMutation<TagContactClue>(tagContactClueMutation);
  const [removeClueTag] = useMutation<RemoveClueTag>(removeClueTagMutation);

  const [allPossibleAppNames, setAllPossibleAppNames] = useState<string[]>([]);

  const { slideoutOpen } = clueDetailsSlideoutData;

  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);

  const [appFilterState, setAppFilterState] =
    useRecoilState(appFilterStateAtom);
  const [contactSortState, setContactSortState] =
    useRecoilState(contactSortStateAtom);

  const { params, setPageNumber, setClue } = useQueryParams();

  const sortMap: ApplicationMapType = {
    Name: 'primaryDisplayName',
    App: 'app',
    'most-recent-comm': 'timestamp',
    'communication-count': 'contactVolume',
  };

  const { loading, data, fetchMore } =
    useQueryWithErrorBoundary<getAllContactsByExtractionId>(allContactsQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        extractionId: currentExtractionId,
        displayName: globalSearchTextState.currentGlobalSearchText,
        appNames: appFilterState?.contactAppFilter,
        sortField: sortMap[contactSortState?.sortField || 'Name'],
        ascending: contactSortState?.ascending,
        page: params.page ?? 1,

        pageSize: 50,
      },
    });

  const { data: contactTypeData } = useQueryWithErrorBoundary<appByContactType>(
    getContactTypes,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        extractionId: currentExtractionId,
        searchTerm: globalSearchTextState.currentGlobalSearchText,
      },
    },
  );

  const appsByContactType = useMemo(
    () =>
      filter(
        contactTypeData?.appsByContactType,
        (type) => !!type?.appDisplayName,
      ),
    [contactTypeData],
  );

  useEffect(() => {
    setAppFilterState({
      ...appFilterState,
      contactAppFilter: appsByContactType.map(
        ({ appDisplayName }) => appDisplayName,
      ) as Array<string>,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactTypeData, globalSearchTextState.currentGlobalSearchText]);

  useEffect(() => {
    if (contactTypeData?.appsByContactType) {
      const allApps = contactTypeData?.appsByContactType.map(
        (a) => a.appDisplayName || '',
      );
      setAllPossibleAppNames(allApps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const edges = data?.contactsByExtractionId?.edges || [];
  const totalRows = data?.contactsByExtractionId?.pageInfo.totalEdges;

  function setClueDetails(id: string): void {
    setClue({ clueId: id, clueType: ClueType.CONTACT, slideoutOpen: true });
  }

  const [selectedRow, setSelectedRow] = useState<string | undefined>();

  const onClickRow = (id: string): void => {
    setClueDetails(id);
    setSelectedRow(id);
  };

  return (
    <div className={classes.rootContainer} data-gid="84678399">
      <Text heading="h2" size="4" weight={500} data-gid="79937930">
        Contacts
      </Text>
      <div className={classes.filterContainer} data-gid="72709169">
        <div className={classes.filterByAppContainer} data-gid="38241662">
          <Select
            multiple
            labelString={
              appsByContactType && appsByContactType.length > 0
                ? 'Filter By App'
                : 'No Apps To Filter'
            }
            renderValue={(selected) => (selected as Array<string>).join(', ')}
            labelId="appLabel"
            value={appFilterState?.contactAppFilter ?? []}
            onChange={(
              // Override type since MUI thinks its a string - https://github.com/mui/material-ui/issues/13782
              e: React.ChangeEvent<
                HTMLSelectElement & { value: Array<string> }
              >,
            ) => {
              const appNames = e.target.value;
              if (appNames.includes('Select All Apps')) {
                setAppFilterState({
                  ...appFilterState,
                  contactAppFilter: allPossibleAppNames,
                });
              } else if (appNames.includes('Deselect All Apps')) {
                setAppFilterState({
                  ...appFilterState,
                  contactAppFilter: [],
                });
              } else {
                setAppFilterState({
                  ...appFilterState,
                  contactAppFilter: appNames,
                });
              }
              setPageNumber(1);
            }}
            disabled={!appsByContactType || appsByContactType.length === 0}
            data-gid="96390449"
          >
            <MenuItem
              key="selectAll"
              value="Select All Apps"
              data-gid="38071852"
            >
              <Checkbox
                checked={
                  allPossibleAppNames.length ===
                  appFilterState.contactAppFilter?.length
                }
                data-gid="25745465"
              />
              Select All Apps
            </MenuItem>
            <MenuItem
              key="deselectAllApps"
              value="Deselect All Apps"
              data-gid="94020075"
            >
              <Checkbox
                checked={appFilterState.contactAppFilter?.length === 0}
                data-gid="79138474"
              />
              Deselect All Apps
            </MenuItem>
            {appsByContactType?.map(
              (type: appByContactType_appsByContactType | null) => (
                <MenuItem
                  key={type?.appDisplayName}
                  value={type?.appDisplayName || ''}
                  data-gid="99274938"
                >
                  <Checkbox
                    checked={
                      !isNil(type) &&
                      !isNil(type.appDisplayName) &&
                      appFilterState?.contactAppFilter?.includes(
                        type.appDisplayName,
                      )
                    }
                    data-gid="54199086"
                  />
                  {`${type?.appDisplayName || ''} (${type?.hits || ''})`}
                </MenuItem>
              ),
            )}
          </Select>
        </div>
      </div>
      {!data && loading ? (
        <div className={classes.loadingContainer} data-gid="87423587">
          <ScaleLoader loading color={Colors.blue} data-gid="77166506" />
        </div>
      ) : (
        <div className={classes.tableContainer} data-gid="48161934">
          <DataTable
            data={edges.map((edge) => edge.node)}
            size="small"
            columnDefinitions={[
              {
                id: 'Name',
                heading: 'Name',
                align: 'left',
                sortable: true,
                content: (row) => (
                  <div
                    className={classes.nameContainer}
                    onClick={() => {
                      onClickRow(row.id);
                    }}
                    role="presentation"
                    key={`contact-name-${row.id}`}
                    data-gid="31913114"
                  >
                    <Text size="2" data-gid="83928374">
                      {row.primaryDisplayName}
                    </Text>
                  </div>
                ),
              },
              {
                id: 'App',
                heading: 'App',
                align: 'center',
                sortable: true,
                content: (row) => (
                  <div
                    onClick={() => onClickRow(row.id)}
                    role="presentation"
                    className={`${classes.cellContainer} ${classes.appContainer}`}
                    key={`contact-table-source-${row.id}`}
                    data-gid="11291032"
                  >
                    {row.app?.iconUrl ? (
                      <Tooltip
                        title={row.app?.displayName ?? row.appBundleId}
                        placement="right"
                        data-gid="12841915"
                      >
                        <img
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#1665D8',
                            color: 'white',
                            borderRadius: '100%',
                            height: 24,
                            width: 24,
                          }}
                          src={row.app?.iconUrl ?? undefined}
                          alt="Call Icon"
                          data-gid="78123003"
                        />
                      </Tooltip>
                    ) : row.app?.displayName ? (
                      ApplicationMap[row.app.displayName] || row.app.displayName
                    ) : (
                      row.appBundleId
                    )}
                  </div>
                ),
              },
              {
                id: 'additional-info',
                heading: 'Additional Info',
                align: 'center',
                content: (
                  row: getAllContactsByExtractionId_contactsByExtractionId_edges_node,
                ) => (
                  <div
                    onClick={() => onClickRow(row.id)}
                    className={classes.cellContainer}
                    key={`contact-table-source-${row.id}`}
                    role="presentation"
                    data-gid="31067323"
                  >
                    {row?.attributes?.map(
                      (
                        attribute: getAllContactsByExtractionId_contactsByExtractionId_edges_node_attributes,
                      ) => {
                        if ('number' in attribute && attribute.number) {
                          return (
                            <div
                              key={`contact-table-info-${row.id}`}
                              data-gid="39649604"
                            >
                              Phone Number: {attribute?.number}
                            </div>
                          );
                        }

                        return <></>;
                      },
                    )}
                  </div>
                ),
              },
              {
                id: 'communication-count',
                heading: 'Communication Count',
                align: 'center',
                sortable: true,
                content: (row) => {
                  const callCounts = find(row?.platformCallCounts, [
                    'platform',
                    '__total',
                  ])?.count;

                  const messageCounts = find(row?.platformMessageCounts, [
                    'platform',
                    '__total',
                  ])?.count;

                  if (messageCounts || callCounts || row.proxyApp?.iconUrl) {
                    return (
                      <div
                        className={classes.commCountContainer}
                        data-gid="13320064"
                      >
                        <div
                          data-gid="56674781"
                          className={classes.cellContainer}
                          onClick={() => onClickRow(row.id)}
                          role="presentation"
                        >
                          {' '}
                          {(callCounts ?? 0) + (messageCounts ?? 0)}{' '}
                        </div>
                        {row.proxyApp?.iconUrl ? (
                          <Tooltip
                            placement="top"
                            title={`${
                              row.app?.displayName ?? row.appBundleId
                            } routed this activity through the ${
                              row.proxyApp.displayName
                            } app.`}
                            data-gid="57031737"
                          >
                            <img
                              style={{
                                marginLeft: '5px',
                                marginBottom: '8px',
                                background: '#1665D8',
                                color: 'white',
                                borderRadius: '100%',
                                height: 12,
                                width: 12,
                              }}
                              src={row.proxyApp.iconUrl}
                              alt="proxy app icon"
                              data-gid="49174519"
                            />
                          </Tooltip>
                        ) : (
                          // Empty div is here for spacing
                          <div
                            style={{ width: 12, marginLeft: '5px' }}
                            data-gid="86941764"
                          />
                        )}
                      </div>
                    );
                  }
                  return <> </>;
                },
              },
              {
                id: 'most-recent-comm',
                heading: 'Most Recent Comm',
                align: 'center',
                sortable: true,
                content: (row) => {
                  const mostRecentCommunication = max([
                    row?.mostRecentMessageSent?.timestamp,
                    row?.mostRecentCallSent?.timestamp,
                  ]);

                  return (
                    <div
                      onClick={() => onClickRow(row.id)}
                      role="presentation"
                      className={classes.mostRecentCommContainer}
                      key={`most-recent-message-sent-${row.id}`}
                      data-gid="69682009"
                      style={{ minWidth: '150px' }}
                    >
                      <div data-gid="28883494">
                        {mostRecentCommunication ? (
                          <>
                            {`${DateTime.fromISO(
                              mostRecentCommunication,
                            ).toLocaleString(DateTime.DATE_MED)}`}
                            <br data-gid="54428341" />
                            {`${DateTime.fromISO(
                              mostRecentCommunication,
                            ).toLocaleString(
                              DateTime.TIME_WITH_SHORT_OFFSET,
                            )} `}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                },
              },
              {
                id: 'actions',
                heading: 'Actions',
                align: 'center',
                content: (row) => (
                  <ActionBar
                    position="relative"
                    isTagged={!!row?.taggedAt}
                    onTag={async (tagged) => {
                      if (tagged && row?.id) {
                        await removeClueTag({
                          variables: {
                            input: {
                              clueId: row.id,
                              extractionId: currentExtractionId,
                              clueType: ClueTypes.CALL,
                            },
                          },
                          optimisticResponse: {
                            removeClueTag: {
                              clueId: row.id,
                              __typename: 'RemoveClueTagPayload',
                            },
                          },
                          update: (proxy) => {
                            const rqData = proxy.readQuery<
                              contactByIdAndExtractionId,
                              { contactById: string; extractionId: string }
                            >({
                              query: contactByIdAndExtractionIdQuery,
                              variables: {
                                contactById: row.id,
                                extractionId: currentExtractionId as string,
                              },
                            });

                            if (rqData?.contactByIdAndExtractionId) {
                              proxy.writeQuery({
                                query: contactByIdAndExtractionIdQuery,
                                data: {
                                  contactByIdAndExtractionId: {
                                    ...rqData.contactByIdAndExtractionId,
                                    taggedAt: null,
                                    taggedBy: null,
                                  },
                                },
                                variables: {
                                  contactById: row.id,
                                  extractionId: currentExtractionId,
                                },
                              });
                            }
                          },
                        });
                      } else if (row?.id) {
                        await tagContactClue({
                          variables: {
                            input: {
                              clueId: row.id,
                              extractionId: currentExtractionId,
                            },
                          },
                          optimisticResponse: {
                            tagContactClue: {
                              contact: {
                                id: row.id,
                                primaryDisplayName: '',
                                taggedAt: DateTime.now().toISO(),
                                taggedBy: {
                                  id: user?.id ?? '',
                                  firstName: user?.firstName ?? '',
                                  lastName: user?.lastName ?? '',
                                  email: user?.email ?? '',
                                  __typename: 'User',
                                },
                                __typename: 'Contact',
                              },
                              __typename: 'TagContactCluePayload',
                            },
                          },
                        });
                      }
                    }}
                    data-gid="12618338"
                  />
                ),
              },
            ]}
            page={params.page ?? 1}
            pageChangeHandler={(nextPageNum) => {
              setPageNumber(nextPageNum);
              fetchMore({
                variables: {
                  page: nextPageNum,
                  sortField: 'primaryDisplayName',
                  ascending: contactSortState?.ascending,
                },
              }).catch(() => null);
            }}
            totalRecordCount={totalRows}
            recordsPerPage={50}
            noRecordsMessage="No Contacts Found"
            sortColumn={contactSortState?.sortField}
            sortDirection={contactSortState?.ascending ? 'asc' : 'desc'}
            hoverable
            selectedRowId={slideoutOpen ? selectedRow : undefined}
            onSortHandler={(e) => {
              setContactSortState({
                sortField: e,
                ascending: !contactSortState?.ascending,
              });
            }}
            data-gid="10831536"
          />
        </div>
      )}
    </div>
  );
};

export default ContactsTable;
