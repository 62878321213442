import { Colors, makeStyles, Text } from '@grayshift/cairn';
import { Close } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilState } from 'recoil';

import { clueDetailsSlideoutDataAtom } from './lib/atoms';
import { useQueryParams } from './useQueryParams';

const CONTAINER_WIDTH = 400;

const useStyles = makeStyles({
  fakeSpaceContainer: {
    width: (slideoutOpen) => (slideoutOpen ? CONTAINER_WIDTH : 0),
    transition: 'all 0.25s',
  },
  rootContainer: {
    position: 'absolute',
    right: (slideoutOpen) => (slideoutOpen ? 0 : -CONTAINER_WIDTH),
    width: CONTAINER_WIDTH,
    height: '95vh',
    background: Colors.white,
    borderLeft: `solid 1px ${Colors.divider}`,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 30,
    transition: 'all 0.25s',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    zIndex: 2,
  },
  headerContainer: {
    backgroundColor: 'white',
    position: 'sticky',
    paddingTop: 30,
    paddingBottom: 10,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 3,
  },
  closeIconContainer: {
    position: 'absolute',
    paddingTop: 30,
    top: 3,
    left: 0,
    cursor: 'pointer',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface ClueDetailsSlideoutContentWrapperProps {
  loading: boolean;
  children: ReactElement;
}

const ClueDetailsSlideoutContentWrapper: React.FC<
  ClueDetailsSlideoutContentWrapperProps
> = ({ loading, children }) => {
  const { setClue } = useQueryParams();
  const [clueDetailsSlideOutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const { clueId, clueType, slideoutOpen, onCloseCallback, onOpenCallback } =
    clueDetailsSlideOutData;
  const classes = useStyles(slideoutOpen);

  return (
    <div
      className={classes.fakeSpaceContainer}
      data-gid="26078518"
      onTransitionEnd={() => onOpenCallback && onOpenCallback()}
    >
      <div
        className={classes.rootContainer}
        data-gid="62022025"
        id="rootScrollContainer"
      >
        <div className={classes.headerContainer} data-gid="23873990">
          <div
            className={classes.closeIconContainer}
            onClick={() => {
              setClue({ clueId: null, clueType: null, slideoutOpen: false });
              setClueDetailsSlideoutData({
                ...clueDetailsSlideOutData,
                slideoutOpen: false,
                clueId,
                clueType,
              });
              if (onCloseCallback) {
                onCloseCallback();
              }
            }}
            role="presentation"
            data-gid="68738511"
          >
            <Close data-gid="78670357" />
          </div>
          <Text size="6" data-gid="96978583">
            {clueType} Details
          </Text>
        </div>
        {loading && (
          <div className={classes.loadingContainer} data-gid="58854417">
            <ScaleLoader loading color={Colors.blue} data-gid="66156829" />
          </div>
        )}
        {!loading && children}
      </div>
    </div>
  );
};

export default ClueDetailsSlideoutContentWrapper;
