import { gql } from '@apollo/client';

export const notesByClueQuery = gql`
  query NotesByClueQuery(
    $clueId: UUID!
    $extractionId: UUID!
    $clueType: ClueTypes!
  ) {
    notesByClue(
      clueId: $clueId
      extractionId: $extractionId
      clueType: $clueType
    ) {
      id
      text
      recordCreatedAt
      recordUpdatedAt
      extractionId
      createdBy {
        id
        firstName
        lastName
      }
      isOwner
      clue {
        ... on Call {
          id
          displayName
          timestamp
        }
        ... on Memo {
          id
          modifiedAt
        }
        ... on Image {
          id
          timestamp
        }
        ... on Video {
          id
          timestamp
        }
        ... on Contact {
          id
          primaryDisplayName
        }
        ... on DefaultAppMessage {
          id
        }
        ... on DefaultLocation {
          id
          timestamp
          latitude
          longitude
        }
      }
    }
  }
`;
