/* eslint-disable unicorn/no-nested-ternary, sonarjs/cognitive-complexity */
import { Colors, makeStyles } from '@grayshift/cairn';
import { IosShare } from '@mui/icons-material';
import { Typography } from '@mui/material';
import noop from 'lodash/noop';
import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  extractionSelectionModalIsOpenAtom,
  extractionShareModalControllerAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useExtraction } from './useExtraction';

const useStyles = makeStyles({
  extractionSelection: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  extractionInfoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  extractionNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  },
  extractionNameTextContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '230px',
  },
  infoIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
  popperBackground: {
    alignSelf: 'flex-end',
    background: 'white',
    border: 'solid 1px #D2D6E1',
    borderRadius: 5,
    zIndex: 1,
    padding: 10,
  },
  extractionProgressContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    marginBottom: 10,
  },
  extractionProgressBarContainer: {
    height: '10px',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '265px',
    borderRadius: 10,
    border: `1px solid ${Colors.mystic}`,
  },
  progressText: {
    display: 'flex',
    alignItems: 'left',
    paddingTop: '2px',
    paddingLeft: '3px',
  },
  archiveBanner: {
    paddingTop: '10px',
  },
  selectIconContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: '4px',
  },
  extractionActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    color: Colors.shark,
    paddingRight: 30,
  },
});

const ExtractionSelection: React.FC = memo(() => {
  const classes = useStyles();
  const location = useLocation();

  const { data } = useExtraction();
  const [currentExtractionId] = useCurrentExtractionId();
  const [, setExtractionShareModalController] = useRecoilState(
    extractionShareModalControllerAtom,
  );

  const [extractionSelectionModalIsOpen, setExtractionSelectionModalIsOpen] =
    useRecoilState(extractionSelectionModalIsOpenAtom);

  useEffect(() => {
    if (
      location.pathname.includes('/audit-log') ||
      location.pathname.includes('/user-management')
    ) {
      return;
    }
    if (!currentExtractionId && extractionSelectionModalIsOpen === false) {
      setExtractionSelectionModalIsOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const extraction = data?.extractionById;

  const openShareModal = (): void => {
    if (!currentExtractionId) return;
    if (extraction) {
      setExtractionShareModalController({
        isOpen: true,
        extraction,
        shareModalWasEntry: true,
      });
    }
  };

  return (
    <>
      <div
        className={classes.extractionSelection}
        style={extraction?.isArchived ? { marginTop: '15px' } : {}}
        data-gid="99793448"
      >
        {/* PSA: This code to be reintroduced once archived decision have been made system wide */}
        {/* {extraction?.isArchived ? (
        <div className={classes.archiveBanner} data-gid="40543218">
          <Text weight={500} size="2" data-gid="32039011">
            This extraction has been archived
          </Text>
        </div>
      ) : (
        ''
      )} */}
        <div className={classes.extractionActionsContainer} data-gid="93025496">
          <div
            className={classes.infoIconContainer}
            style={
              currentExtractionId
                ? { cursor: 'pointer' }
                : { color: Colors.textSecondary }
            }
            onClick={openShareModal}
            role="presentation"
            onKeyDown={noop}
            data-gid="60337844"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                height: '30px',
                borderRight: '1px solid #6B6C6F',
                paddingRight: '1.5em',
                marginLeft: '.5rem',
              }}
              data-gid="27142775"
            >
              <IosShare
                fontSize="small"
                data-gid="81046603"
                style={{ color: 'white' }}
              />
              <Typography
                fontSize={14}
                style={{
                  marginLeft: '.15vw',
                  // marginTop: '.20vh',
                  color: currentExtractionId ? 'white' : Colors.mystic,
                  width: '8em',
                }}
                data-gid="99658256"
              >
                Share Extraction
              </Typography>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
});

export { ExtractionSelection };
