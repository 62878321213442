import { Colors } from '@grayshift/cairn';
import { Badge, makeStyles, withStyles } from '@material-ui/core';
import { Link } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { extractionByIdQuery_extractionById } from '../graphql/generated/extractionByIdQuery';
import { getReviewLink as getReviewLinkType } from '../graphql/generated/getReviewLink';
import { getReviewLink } from '../graphql/queries/reviewLink';
import {
  reviewButtonStatusAtom,
  sendToReviewModalControllerAtom,
} from '../lib/atoms';
import useCurrentExtractionId from '../lib/hookUseCurrentExtractionId';
import { useQueryWithErrorBoundary } from '../useQueryWithErrorBoundary';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 10,
    left: -14,
    position: 'relative',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 10,
  },
}))(Badge);

const useStyles = makeStyles({
  reviewLinkContainer: {
    cursor: 'pointer',
  },
});

interface Props {
  extraction: extractionByIdQuery_extractionById | undefined | null;
}

const ReviewLink: React.FC<Props> = ({ extraction }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();

  const [, setSendToReviewModalController] = useRecoilState(
    sendToReviewModalControllerAtom,
  );
  const [reviewButtonStatus, setReviewButtonStatus] = useRecoilState(
    reviewButtonStatusAtom,
  );

  const { data: url, refetch: reviewLinkRefetch } =
    useQueryWithErrorBoundary<getReviewLinkType>(getReviewLink, {
      fetchPolicy: 'cache-and-network',
      variables: {
        extractionId: currentExtractionId,
      },
      skip: !currentExtractionId,
    });

  useEffect(() => {
    if (extraction?.isReviewEnabled) {
      setReviewButtonStatus('open');
      if (!url?.getReviewLink) {
        // eslint-disable-next-line no-void
        void reviewLinkRefetch();
      }
    } else if (extraction?.isReviewEnabled === false) {
      setReviewButtonStatus('send');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraction?.isReviewEnabled]);

  const urlLink = url?.getReviewLink;

  return (
    <div data-gid="77075731">
      {reviewButtonStatus === 'open' && urlLink ? (
        <div
          className={classes.reviewLinkContainer}
          style={{ display: 'flex', alignItems: 'center' }}
          role="presentation"
          onClick={() => {
            if (!urlLink) return;
            window.open(urlLink.link, '_blank')?.focus();
          }}
          data-gid="39753067"
        >
          <Link
            href={urlLink?.link}
            fontSize="small"
            style={{ color: 'white' }}
            data-gid="98079357"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '.35vw',
              marginRight: '1rem',
              height: '30px',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              borderRight: '1px solid #6B6C6F',
              paddingRight: '1.5em',
            }}
            data-gid="97580910"
          >
            <Typography
              style={{
                paddingRight: '.5em',
                color: urlLink ? 'white' : Colors.mystic,
              }}
              fontSize={14}
              data-gid="66772752"
            >
              Open in Review
            </Typography>
            <StyledBadge
              badgeContent="Beta"
              color="primary"
              data-gid="90359183"
            />
          </div>
        </div>
      ) : reviewButtonStatus === 'send' ? (
        <div
          className={classes.reviewLinkContainer}
          style={{ display: 'flex', alignItems: 'center' }}
          role="presentation"
          onClick={() => {
            setSendToReviewModalController({ isOpen: true, isReplay: true });
          }}
          data-gid="94625449"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '.35vw',
              marginRight: '1rem',
              height: '30px',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              borderRight: '1px solid #6B6C6F',
              paddingRight: '1.5em',
            }}
            data-gid="14366900"
          >
            <Typography
              style={{
                paddingRight: '.5em',
                color: Colors.mystic,
              }}
              fontSize={14}
              data-gid="36329515"
            >
              Send to Review
            </Typography>
            <StyledBadge
              badgeContent="Beta"
              color="primary"
              data-gid="25926114"
            />
          </div>
        </div>
      ) : (
        <div data-gid="15649509" />
      )}
    </div>
  );
};

export default ReviewLink;
