import { makeStyles } from '@grayshift/cairn';
import { ReactElement, Suspense } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

import CallsTable from './CallsTable';
import useDocumentTitle from './lib/useDocumentTitle';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
});

function Calls(): ReactElement {
  const classes = useStyles();
  useDocumentTitle('Calls');

  return (
    <Suspense
      fallback={() => <ScaleLoader data-gid="97760376" />}
      data-gid="85456443"
    >
      <div className={classes.outerWrapper} data-gid="20725499">
        <div className={classes.rootContainer} data-gid="95727841">
          <CallsTable data-gid="80832549" />
        </div>
      </div>
    </Suspense>
  );
}

export default Calls;
