/* eslint-disable no-underscore-dangle */
import {
  getUserInfo,
  getUserInfo_getUserInfo,
} from './graphql/generated/getUserInfo';
import { UserInfo } from './graphql/queries/user';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

export type ArtifactIQUser = {
  user: getUserInfo_getUserInfo | undefined;
  loading: boolean;
};

export const useUser = (): ArtifactIQUser => {
  const { data: userInfo, loading: userLoading } =
    useQueryWithErrorBoundary<getUserInfo>(UserInfo);
  return { user: userInfo?.getUserInfo, loading: userLoading };
};
