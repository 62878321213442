import { gql } from '@apollo/client';

import { CoreImageFields, CoreSourceFileFields } from '../fragments';

export const allMessagesTimelineQuery = gql`
  query getAllMessages(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $timeframe: TimeFrame
  ) {
    allMessagesByExtractionId(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeframe
    ) {
      edges {
        node {
          id
          timestamp
          textContent
          sentByOwner
          thread {
            threadDisplayName
            id
          }
          sender {
            primaryDisplayName
            metacontact {
              primaryDisplayName
            }
          }
          app {
            displayName
          }
          appBundleId
          media {
            ...images
          }
        }
      }
    }
  }
  fragment images on Image {
    id
    thumbnailUrl
  }
`;

export const getAllMessageThreads = gql`
  ${CoreImageFields}
  query getAllMessageThreads(
    $extractionId: UUID!
    $displayName: String
    $appNames: [String!]!
    $timeFrame: TimeFrame
    $page: Int
    $pageSize: Int
  ) {
    allMessageThreads(
      extractionId: $extractionId
      displayName: $displayName
      appNames: $appNames
      timeFrame: $timeFrame
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          id
          threadDisplayName
          latestMessage {
            textContent
            timestamp
            id
            media {
              ...CoreImageFields
            }
          }
          app {
            id
            bundleId
            displayName
            iconUrl
          }
          appBundleId
          participants {
            primaryDisplayName
            isDeviceOwner
            metacontact {
              primaryDisplayName
            }
          }
        }
      }
    }
  }
`;

export const messageByIdQuery = gql`
  ${CoreSourceFileFields}
  ${CoreImageFields}
  query messageById($messageById: ID!) {
    messageById(id: $messageById) {
      id
      textContent
      sentByOwner
      sender {
        primaryDisplayName
        metacontact {
          primaryDisplayName
        }
        isDeviceOwner
        sourceFiles {
          ...CoreSourceFileFields
        }
      }
      thread {
        id
        threadDisplayName
        sourceFiles {
          ...CoreSourceFileFields
        }
      }
      app {
        displayName
      }
      appBundleId
      senderId
      timestamp
      sourceFiles {
        ...CoreSourceFileFields
      }
      meta {
        key
        value
      }
      media {
        ...CoreImageFields
      }
      taggedAt
      taggedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const getMessageThreadSearchContent = gql`
  query allMessageThreadsByTextContent(
    $extractionId: UUID!
    $displayName: String!
    $page: Int
    $pageSize: Int
    $appNames: [String!]
  ) {
    allMessageThreads(
      extractionId: $extractionId
      displayName: $displayName
      page: $page
      pageSize: $pageSize
      appNames: $appNames
    ) {
      edges {
        node {
          id
          threadDisplayName
          app {
            id
            displayName
            iconUrl
          }
          appBundleId
        }
      }
    }
  }
`;

export const getMessageThreadById = gql`
  ${CoreImageFields}
  query getMessageThreadById($messageThreadId: UUID!, $extractionId: UUID!) {
    messageThreadById(
      messageThreadId: $messageThreadId
      extractionId: $extractionId
    ) {
      id
      threadDisplayName
      latestMessage {
        textContent
        timestamp
        id
        media {
          ...CoreImageFields
        }
      }
      app {
        id
        bundleId
        displayName
        iconUrl
      }
      appBundleId
      participants {
        primaryDisplayName
        isDeviceOwner
        metacontact {
          primaryDisplayName
        }
      }
    }
  }
`;

export const getMessageSearchContent = gql`
  ${CoreImageFields}
  query messagesByTextContent(
    $extractionId: UUID!
    $textContent: String!
    $timeframe: TimeFrame
    $sources: [String]
  ) {
    messagesByTextContent(
      extractionId: $extractionId
      textContent: $textContent
      timeframe: $timeframe
      sources: $sources
    ) {
      edges {
        node {
          id
          timestamp
          textContent
          thread {
            id
          }
          app {
            displayName
            iconUrl
          }
          appBundleId
          sender {
            metacontact {
              primaryDisplayName
            }
            primaryDisplayName
          }
          media {
            ...CoreImageFields
          }
        }
      }
    }
  }
`;

export const getAllMessagesByMessageThreadIdQuery = gql`
  query getAllMessagesByMessageThreadId(
    $messageThreadId: UUID!
    $sources: [String]
    $timeframe: TimeFrame
    $hideBiome: Boolean
    $extractionId: UUID
    $page: Int
    $pageSize: Int
  ) {
    allMessagesByThreadId(
      messageThreadId: $messageThreadId
      sources: $sources
      timeframe: $timeframe
      hideBiome: $hideBiome
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          id
          taggedAt
          textContent
          timestamp
          sentByOwner
          thread {
            id
            threadDisplayName
          }
          sender {
            id
            primaryDisplayName
            metacontact {
              primaryDisplayName
            }
            isDeviceOwner
          }
          media {
            ...ExpandedImageFields
          }
          meta {
            key
            value
          }
          app {
            id
            bundleId
            displayName
          }
          appBundleId
        }
      }
    }
  }

  fragment ExpandedImageFields on Image {
    id
    thumbnailUrl
    # The URL of a full-size browser-renderable version of the image.
    previewUrl
    # URL pointing to original image file. Might not render in browser.
    originalUrl
    thumbnailFileId
    containsNudity
    containsCSAM
    containsCSAMConfidence
    containsNudityConfidence
    ncmecMatch
    thumbnailFile {
      id
    }
    previewFileId
    previewFile {
      id
    }
    originalFileId
    originalFile {
      id
    }
    labels
    app {
      id
      displayName
      bundleId
    }
    appBundleId
    extractionId
    timestamp
    imageAnnotations {
      annotation
    }
  }
`;
