import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { extractionByIdQuery } from './graphql/generated/extractionByIdQuery';
import { ExtractionAccessTypeEnum } from './graphql/generated/globalTypes';
import {
  currentExtractionAccessTypeAtom,
  currentGlobalSearchTextAtom,
  extractionAccessAtom,
  timeFrameStateAtom,
  userInfoAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import queryExtractionById from './lib/queryExtractionById';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

export const useExtraction = (): {
  data: extractionByIdQuery | undefined;
  loading: boolean;
  isLicensedUser: boolean;
  pollInterval: number | undefined;
  extractionAccessType: ExtractionAccessTypeEnum | null;
  readOnlyMode: boolean;
  setPollInterval: (pollInterval: number) => void;
  // eslint-disable-next-line sonarjs/cognitive-complexity
} => {
  const [currentExtractionId] = useCurrentExtractionId();
  const [pollInterval, setPollInterval] = useState<number>(5000);
  const [pageLoaded] = useState(new Date().toISOString());

  const extractionAccess = useRecoilValue(extractionAccessAtom);
  const [extractionAccessType, setCurrentExtractionAccessType] = useRecoilState(
    currentExtractionAccessTypeAtom,
  );

  const [timeFrameState] = useRecoilState(timeFrameStateAtom);
  const globalSearchTextState = useRecoilValue(currentGlobalSearchTextAtom);
  const { globalTimeframe } = timeFrameState || {};

  const queryVariables = {
    variables: {
      extractionId: currentExtractionId,
      timeframe:
        globalTimeframe?.start && globalTimeframe?.end
          ? {
              startDate: globalTimeframe?.start.toISOString(),
              endDate: globalTimeframe?.end.toISOString(),
            }
          : null,
      searchText: globalSearchTextState.currentGlobalSearchText,
    },
    pollInterval: extractionAccess ? pollInterval : 0,
    skip: !currentExtractionId,
  };

  const isEntitledAccessState = useRecoilValue(userInfoAtom);
  const { entitledAccounts, hasActiveTrial } = isEntitledAccessState;
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;

  const { loading, data } = useQueryWithErrorBoundary<extractionByIdQuery>(
    queryExtractionById,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      ...queryVariables,
    },
  );

  useEffect(() => {
    if (!data || !data.extractionById) return;
    const { state, aggregationsCompletedAt } = data.extractionById;

    switch (state) {
      case 'IN_PROGRESS':
        if (pollInterval !== 5000) setPollInterval(5000);
        break;

      case 'STALLED':
        if (pollInterval !== 300_000) setPollInterval(300_000);
        break;

      case 'COMPLETE':
        if (!aggregationsCompletedAt) setPollInterval(5000);
        else setPollInterval(0);
        break;

      case 'FAILED':
        setPollInterval(0);
        break;

      default:
    }
  }, [data, pollInterval]);

  useEffect(() => {
    if (!data || !data.extractionById) return;
    const { accessType } = data.extractionById;
    setCurrentExtractionAccessType(accessType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageLoaded]);

  return {
    loading,
    data,
    isLicensedUser: !!(data?.extractionById && entitledAccess),
    extractionAccessType,
    readOnlyMode: extractionAccessType === ExtractionAccessTypeEnum.READ_ONLY,
    pollInterval,
    setPollInterval,
  };
};
