// interface IconMapType {
//   [index: string]: string;
// }
import isNil from 'lodash/isNil';

import { publicStaticFileURL } from './lib/publicFile';

export type AppDisplayNames =
  | 'Snapchat'
  // eslint-disable-next-line security/detect-bidi-characters
  | '‎WhatsApp'
  | 'WhatsApp'
  | 'iOS Messages'
  | 'Messenger'
  | 'iOS Biome Messages'
  | 'Facebook Messenger'
  | 'Instagram'
  | 'Android Messages'
  | 'TextNow'
  | 'com.enflick.android.TextNow' // TODO COMEBACK AND FIX THIS
  | 'Signal'
  | 'Kik'
  | 'Discord'
  | 'Messages'
  | 'Phone'
  | 'Telegram'
  | 'TikTok'
  | 'Twitter'
  | 'Viber';

type IconMapType = Record<AppDisplayNames, string>;

const iconMap: IconMapType = {
  Snapchat: publicStaticFileURL('/icon-snapchat.png'),
  // eslint-disable-next-line security/detect-bidi-characters
  '‎WhatsApp': publicStaticFileURL('/icon-whatsapp.png'),
  WhatsApp: publicStaticFileURL('/icon-whatsapp.png'),
  'iOS Messages': publicStaticFileURL('/icon-iOSmessages.png'),
  Messenger: publicStaticFileURL('/icon-messenger.png'),
  'iOS Biome Messages': publicStaticFileURL('/icon-iOSmessages.png'),
  'Facebook Messenger': publicStaticFileURL('/icon-messenger.png'),
  Instagram: publicStaticFileURL('/icon-instagram.png'),
  'Android Messages': publicStaticFileURL('/icon-google.png'),
  Signal: publicStaticFileURL('/icon-signal.png'),
  TextNow: publicStaticFileURL('/icon-textnow.png'),
  'com.enflick.android.TextNow': publicStaticFileURL('/icon-textnow.png'), // TODO COMEBACK AND FIX THIS
  Kik: publicStaticFileURL('/icon-kik.png'),
  Discord: publicStaticFileURL('/icon-discord.png'),
  Messages: publicStaticFileURL('/icon-messages.png'),
  Phone: publicStaticFileURL('/icon-phone.png'),
  Telegram: publicStaticFileURL('/icon-telegram.png'),
  TikTok: publicStaticFileURL('/icon-tiktok.png'),
  Twitter: publicStaticFileURL('/icon-twitter.png'),
  Viber: publicStaticFileURL('/icon-viber.png'),
};

export const getIconSource = (
  appDisplayName: AppDisplayNames,
  isActive: boolean,
): string => {
  const iconSrc = iconMap[appDisplayName];

  const defaultIconSrc = isActive
    ? publicStaticFileURL('/genericmessage100blue.png')
    : publicStaticFileURL('/genericmessage100.png');

  return !isNil(iconSrc) ? iconSrc : defaultIconSrc;
};
