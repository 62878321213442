/* eslint-disable sonarjs/no-duplicate-string */
import { useQuery } from '@apollo/client';
import { Colors, DataTable, makeStyles, Text } from '@grayshift/cairn';
import { Chip } from '@mui/material';
import React, { useState } from 'react';

import {
  ExtractionAuditLogs,
  ExtractionAuditLogs_extractionAuditLogs_edges_node,
} from './graphql/generated/ExtractionAuditLogs';
import {
  GlobalAuditLogs,
  GlobalAuditLogs_globalAuditLogs_edges_node,
} from './graphql/generated/GlobalAuditLogs';
import {
  EXTRACTION_AUDIT_LOG_QUERY,
  GLOBAL_AUDIT_LOG_QUERY,
} from './graphql/queries/auditLog';
import { Layout } from './Layout';
import { getPrettyDateShortOffset } from './lib/getPrettyDate';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // overflowY: 'scroll',
    marginBottom: 30,
    padding: 30,
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeading: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
});

type GlobalAuditLogOrderByFields = keyof Omit<
  GlobalAuditLogs_globalAuditLogs_edges_node,
  '__typename' | 'recordUpdatedAt' | 'data'
>;

export const GlobalAuditLog: React.FC = () => {
  const [extractionId] = useExtractionId();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] =
    useState<GlobalAuditLogOrderByFields>('recordCreatedAt');
  const [orderByDir, setOrderByDir] = useState<'asc' | 'desc'>('desc');

  const classes = useStyles();

  const { data, loading } = useQuery<GlobalAuditLogs>(GLOBAL_AUDIT_LOG_QUERY, {
    variables: {
      extractionId,
      pageSize: 10,
      page: currentPage,
      orderBy: { [orderBy]: orderByDir },
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div data-gid="93799512" className={classes.container}>
      <div className={classes.tableHeading} data-gid="13558716">
        <Text heading="h2" size="4" weight={500} data-gid="15413466">
          Account Audit Logs
        </Text>
      </div>
      <DataTable
        data={data?.globalAuditLogs?.edges.map(({ node }) => node) ?? []}
        columnDefinitions={[
          {
            id: 'timestamp',
            heading: 'Date/Time',
            align: 'left',
            sortable: false,
            content: (item) => (
              <div data-gid="83737688">
                {getPrettyDateShortOffset(item?.recordCreatedAt ?? '')}
              </div>
            ),
          },
          {
            id: 'actor',
            heading: 'User',
            align: 'left',
            sortable: false,
            content: (item) => (
              <div data-gid="29941029">{item.actor?.email}</div>
            ),
          },
          {
            id: 'action',
            heading: 'Action',
            align: 'left',
            sortable: false,
            content: (item) => (
              <div data-gid="67543300">
                <Chip
                  label={item.action}
                  style={{ fontSize: 12 }}
                  data-gid="96945903"
                />
              </div>
            ),
          },
          {
            id: 'role',
            heading: 'Role',
            align: 'left',
            sortable: false,
            content: (item) => (
              <div data-gid="80769081">{item.actor?.role}</div>
            ),
          },
        ]}
        page={currentPage}
        pageChangeHandler={(page) => setCurrentPage(page)}
        totalRecordCount={data?.globalAuditLogs?.pageInfo?.totalEdges ?? 0}
        recordsPerPage={10}
        sortColumn={orderBy}
        sortDirection="desc"
        onSortHandler={(sort) => {
          if (orderBy === sort) {
            setOrderByDir(orderByDir === 'asc' ? 'desc' : 'asc');
          }
          setOrderBy(sort as GlobalAuditLogOrderByFields);
        }}
        loading={loading && !data}
        hoverable
        size="small"
        noRecordsMessage="No Global Audit Logs Found"
      />
    </div>
  );
};

type AuditLogOrderByFields = keyof Omit<
  ExtractionAuditLogs_extractionAuditLogs_edges_node,
  '__typename' | 'recordUpdatedAt' | 'data'
>;

export const AuditLog: React.FC = () => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] =
    useState<AuditLogOrderByFields>('recordCreatedAt');
  const [orderByDir, setOrderByDir] = useState<'asc' | 'desc'>('desc');
  const { data, loading } = useQuery<ExtractionAuditLogs>(
    EXTRACTION_AUDIT_LOG_QUERY,
    {
      variables: {
        pageSize: 10,
        page: currentPage,
        orderBy: { [orderBy]: orderByDir },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <Layout data-gid="86423565">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          width: '100%',
        }}
        data-gid="27801824"
      >
        <div className={classes.paper} data-gid="69800817">
          <GlobalAuditLog data-gid="61778228" />
        </div>
        <div className={classes.paper} data-gid="69800817">
          <div data-gid="34996670" className={classes.container}>
            <div className={classes.tableHeading} data-gid="52018238">
              <Text heading="h2" size="4" weight={500} data-gid="15413466">
                Extraction Audit Logs
              </Text>
            </div>
            <DataTable
              data={
                data?.extractionAuditLogs?.edges.map(({ node }) => node) ?? []
              }
              columnDefinitions={[
                {
                  id: 'timestamp',
                  heading: 'Date/Time',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="50705095">
                      {getPrettyDateShortOffset(item?.recordCreatedAt ?? '')}
                    </div>
                  ),
                },
                {
                  id: 'actor',
                  heading: 'User',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="42993402">{item.actor?.email}</div>
                  ),
                },
                {
                  id: 'role',
                  heading: 'Role',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="82993987">{item.actor?.role}</div>
                  ),
                },
                {
                  id: 'action',
                  heading: 'Action',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="39747540">
                      <Chip
                        label={item.action}
                        style={{ fontSize: 12 }}
                        data-gid="96945903"
                      />
                    </div>
                  ),
                },
                {
                  id: 'evidenceId',
                  heading: 'Evidence ID',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="24364106">{item.extraction.evidenceId}</div>
                  ),
                },
                {
                  id: 'extractionId',
                  heading: 'Extraction ID',
                  align: 'left',
                  sortable: false,
                  content: (item) => (
                    <div data-gid="24364106">{item.extraction.id}</div>
                  ),
                },
              ]}
              page={currentPage}
              pageChangeHandler={(page) => setCurrentPage(page)}
              sortColumn={orderBy}
              sortDirection={orderByDir}
              onSortHandler={(sort) => {
                if (orderBy === sort) {
                  setOrderByDir(orderByDir === 'asc' ? 'desc' : 'asc');
                }
                setOrderBy(sort as AuditLogOrderByFields);
              }}
              totalRecordCount={
                data?.extractionAuditLogs?.pageInfo?.totalEdges ?? 0
              }
              recordsPerPage={10}
              hoverable
              size="small"
              noRecordsMessage="No Extraction Audit Logs Found"
              loading={loading && !data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AuditLog;
