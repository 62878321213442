import { Colors, makeStyles } from '@grayshift/cairn';
import { Close } from '@mui/icons-material';
import { MouseEventHandler } from 'react';

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 900,
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  emptyModalContentContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  bottomButtonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  extractionSearch: {
    display: 'flex',
    paddingTop: 10,
    width: 380,
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'pointer',
    top: 8,
    color: '#9EA0A5',
  },
});

export const CloseModalButton: React.FC<{
  onClick: MouseEventHandler<SVGSVGElement> | undefined;
}> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Close
      onClick={onClick}
      className={classes.closeIcon}
      data-gid="44720668"
    />
  );
};

export const ModalContent: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalContentContainer} data-gid="15351737">
      {children}
    </div>
  );
};

export const BottomModal: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.bottomButtonContainer} data-gid="42433459">
      {children}
    </div>
  );
};
