import { useMutation } from '@apollo/client';
import { Button, MenuItem, Select, Text } from '@grayshift/cairn';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { ExtractionAccessTypeEnum } from './graphql/generated/globalTypes';
import { RemoveExtractionAccess } from './graphql/generated/RemoveExtractionAccess';
import { UpdateExtractionAccessTypeByUserId } from './graphql/generated/UpdateExtractionAccessTypeByUserId';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';
import { userInfoAtom } from './lib/atoms';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import queryUsersWithExtractionAccess from './lib/queryUsersWithExtractionAccess';
import { removeExtractionAccess } from './lib/removeExtractionAccess';
import { updateExtractionAccessType } from './lib/updateExtractionAccessType';

interface ExtractionAccessManagerSelectProps {
  accessType: string;
  userId: string | null;
  extractionIdInQuestion: string | null;
}

const ExtractionAccessManagerSelect = ({
  accessType,
  userId,
  extractionIdInQuestion,
}: ExtractionAccessManagerSelectProps): React.ReactElement => {
  const [extractionId] = useExtractionId();
  const [userInfo] = useRecoilState(userInfoAtom);
  const [value, setValue] = useState<string>(accessType);
  const userActionEnum = [
    ...Object.keys(ExtractionAccessTypeEnum),
    'REMOVE',
  ].map((type) => LowerAllButFirstLetter(type));
  const [removalConfirmation, setRemovalConfirmation] = useState(false);
  const [displayImmutableRoleRule, setDisplayImmutableRoleRule] =
    useState<boolean>(false);

  const isLoggedInUser = userId === userInfo?.id;

  const [fireUpdateUserExtractionAccess] =
    useMutation<UpdateExtractionAccessTypeByUserId>(
      updateExtractionAccessType,
      {
        refetchQueries: [
          {
            query: queryUsersWithExtractionAccess,
            variables: { extractionId },
          },
          'usersWithExtractionAccess',
        ],
      },
    );

  const [fireRemoveExtractionAccess] = useMutation<RemoveExtractionAccess>(
    removeExtractionAccess,
    {
      refetchQueries: [
        {
          query: queryUsersWithExtractionAccess,
          variables: { extractionId },
        },
        'usersWithExtractionAccess',
      ],
    },
  );

  const confirmRemoval = async (confirmed: boolean): Promise<void> => {
    if (confirmed) {
      await fireRemoveExtractionAccess({
        variables: {
          userId,
          extractionId: extractionIdInQuestion || extractionId,
        },
      });
    } else {
      setValue(accessType);
      setRemovalConfirmation(false);
      setDisplayImmutableRoleRule(false);
    }
  };

  const handleChange = async (newValue: string): Promise<void> => {
    if (newValue === 'Remove') {
      setValue(newValue);
      setRemovalConfirmation(true);
    } else {
      setRemovalConfirmation(false);
      const { data } = await fireUpdateUserExtractionAccess({
        variables: {
          type: newValue.toUpperCase(),
          userId,
          extractionId: extractionIdInQuestion || extractionId,
        },
      });
      if (data?.updateExtractionAccessType === null) {
        setDisplayImmutableRoleRule(true);
      }
      const { type } = data?.updateExtractionAccessType || {};
      if (type) {
        setValue(type);
      }
    }
  };

  return (
    <>
      {!isLoggedInUser && (
        <>
          <div data-gid="52706665">
            <Select
              style={{ backgroundColor: '#ffffff', width: 150 }}
              value={value}
              renderValue={
                value.includes('_')
                  ? () => LowerAllButFirstLetter(value.split('_').join(' '))
                  : () => LowerAllButFirstLetter(value)
              }
              onChange={(e): Promise<void> => handleChange(e.target.value)}
              data-gid="32856553"
            >
              {userActionEnum.map((action) => (
                <MenuItem key={action} value={action} data-gid="54820895">
                  {action.includes('_') ? action.split('_').join(' ') : action}
                </MenuItem>
              ))}
            </Select>
            <br data-gid="48232049" />
            {displayImmutableRoleRule && !removalConfirmation && (
              <Text
                size="2"
                color="error"
                style={{
                  position: 'relative',

                  top: -10,
                }}
                data-gid="15813623"
              >
                There must be at least one owner
              </Text>
            )}
          </div>
        </>
      )}
      {removalConfirmation && (
        <div style={{ marginBottom: 20 }} data-gid="42212032">
          <Text size="2" weight={300} align="left" data-gid="67271234">
            Are you sure?
          </Text>
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            data-gid="75672485"
          >
            <Button
              style={{ marginRight: 5 }}
              onClick={() => confirmRemoval(true)}
              data-gid="30637421"
            >
              Yes
            </Button>
            <Button onClick={() => confirmRemoval(false)} data-gid="45784969">
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExtractionAccessManagerSelect;
