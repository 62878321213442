import { Button, makeStyles, Text } from '@grayshift/cairn';
import { DateTime } from 'luxon';
import { useState } from 'react';

import ExtractionAccessManagerSelect from './ExtractionAccessManagerSelect';
import ExtractionAccessRequestSelect from './ExtractionAccessRequestSelect';
import { usersRequestingExtractionAccess } from './graphql/generated/usersRequestingExtractionAccess';
import { usersWithExtractionAccess } from './graphql/generated/usersWithExtractionAccess';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';

const useStyles = makeStyles({
  userCardContainer: {
    marginTop: '20px',
    maxHeight: '31vh',
    overflowY: 'auto',
    scrollPaddingBottom: '0',
    paddingTop: '10px',
  },
  userCard: {
    alignItems: 'center',
    backgroundColor: '#F3F3F4',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '0 1vw',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1vh 0',
  },
  actionsDropdown: {
    width: '25%',
    marginTop: 20,
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '14px 0',
  },
  inputStack: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  toggleView: {
    display: 'flex',
    flexDirection: 'row',
    width: '20vh',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '65px',
    padding: '10px',
  },
});

const ExtractionAccessManager: React.FC<{
  handleClose: () => void;
  usersListData: usersWithExtractionAccess | undefined;
  requestingUsersData: usersRequestingExtractionAccess | undefined;
  extractionId: string;
}> = ({ handleClose, usersListData, requestingUsersData, extractionId }) => {
  const classes = useStyles();
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const totalUsersWithAccess = usersListData?.usersWithExtractionAccess.length;
  const totalRequestingUsers =
    requestingUsersData?.usersRequestingExtractionAccess.length;

  return (
    <div data-gid="11381384">
      <Text size="6" weight={500} align="center" data-gid="95563035">
        Sharing Settings
      </Text>
      <div className={classes.flexRow} data-gid="19196599">
        <div
          className={classes.toggleView}
          role="presentation"
          style={showActiveUsers ? { borderBottom: '1px solid #1665D8' } : {}}
          onClick={() => setShowActiveUsers(true)}
          data-gid="30539963"
        >
          <Text
            size="2"
            color={showActiveUsers ? 'primary' : 'initial'}
            data-gid="38289542"
          >
            Active Users ({totalUsersWithAccess})
          </Text>
        </div>
        <div
          className={classes.toggleView}
          role="presentation"
          style={!showActiveUsers ? { borderBottom: '1px solid #1665D8' } : {}}
          onClick={() => setShowActiveUsers(false)}
          data-gid="30889787"
        >
          <Text
            size="2"
            color={!showActiveUsers ? 'primary' : 'initial'}
            data-gid="62168740"
          >
            Access Requests ({totalRequestingUsers})
          </Text>
        </div>
      </div>
      <div className={classes.userCardContainer} data-gid="12125671">
        {showActiveUsers &&
          usersListData?.usersWithExtractionAccess.map((user) => (
            <div key={user.id} className={classes.userCard} data-gid="89225835">
              <div className={classes.userInfo} data-gid="56780915">
                <Text size="2" weight={300} align="left" data-gid="22942219">
                  {LowerAllButFirstLetter(user.accessType)}
                </Text>
                <Text size="4" weight={700} align="left" data-gid="22942219">
                  {user.email}
                </Text>
                {user.firstName && (
                  <Text size="2" weight={300} align="left" data-gid="86104971">
                    {user.firstName} {user.lastName}
                  </Text>
                )}
                {user.sharedByDateTime && (
                  <Text size="1" weight={300} align="left" data-gid="90741849">
                    {user.sharedByUserFirstName && user.sharedByUserLastName
                      ? `Shared By: ${user.sharedByUserFirstName}
                  ${user.sharedByUserLastName} on `
                      : `Shared On: `}
                    {DateTime.fromISO(user.sharedByDateTime).toLocaleString(
                      DateTime.DATE_SHORT,
                    )}{' '}
                    at{' '}
                    {DateTime.fromISO(user.sharedByDateTime).toLocaleString(
                      DateTime.TIME_WITH_SHORT_OFFSET,
                    )}
                  </Text>
                )}
              </div>
              <div className={classes.actionsDropdown} data-gid="74947457">
                <ExtractionAccessManagerSelect
                  extractionIdInQuestion={extractionId}
                  accessType={user.accessType}
                  userId={user.id}
                  data-gid="21151702"
                />
              </div>
            </div>
          ))}
        {!showActiveUsers &&
          requestingUsersData?.usersRequestingExtractionAccess.map(
            (accessRequest) => (
              <div
                key={accessRequest.id}
                className={classes.userCard}
                data-gid="89225835"
              >
                <div className={classes.userInfo} data-gid="56780915">
                  <Text size="4" weight={700} align="left" data-gid="22942219">
                    {accessRequest.email}
                  </Text>
                  {accessRequest.firstName && (
                    <Text
                      size="2"
                      weight={300}
                      align="left"
                      data-gid="86104971"
                    >
                      {accessRequest.firstName} {accessRequest.lastName}
                    </Text>
                  )}
                  {accessRequest.recordCreatedAt && (
                    <Text
                      size="1"
                      weight={300}
                      align="left"
                      data-gid="90741849"
                    >
                      {`
                    Requested on ${DateTime.fromISO(
                      accessRequest.recordCreatedAt,
                    ).toLocaleString(DateTime.DATE_SHORT)} at
                    ${DateTime.fromISO(
                      accessRequest.recordCreatedAt,
                    ).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
                    `}
                    </Text>
                  )}
                </div>
                <div data-gid="30238099">
                  <ExtractionAccessRequestSelect
                    requestingUserId={accessRequest.id}
                    extractionIdInQuestion={extractionId}
                    data-gid="34058931"
                  />
                </div>
              </div>
            ),
          )}
        {!showActiveUsers && !totalRequestingUsers && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-gid="68589807"
          >
            <Text size="1" data-gid="91731554">
              No Pending Access Requests
            </Text>
          </div>
        )}
      </div>
      <div className={classes.closeButton} data-gid="45477454">
        <Button color="primary" onClick={handleClose} data-gid="13575220">
          Close
        </Button>
      </div>
    </div>
  );
};

export default ExtractionAccessManager;
