import { Skeleton } from '@mui/material';

export const WidgetSkeleton: React.FC = () => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    }}
    data-gid="81566368"
  >
    <div
      style={{
        padding: '1rem 1rem 1rem 0',
      }}
      data-gid="61842577"
    >
      <Skeleton variant="circular" width={40} height={40} data-gid="37325359" />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingRight: '0.5rem',
        justifyContent: 'space-between',
      }}
      data-gid="84974179"
    >
      <Skeleton variant="text" sx={{ fontSize: '16px' }} data-gid="17934452" />
      <Skeleton variant="text" sx={{ fontSize: '16px' }} data-gid="22609696" />
      <Skeleton variant="text" sx={{ fontSize: '14px' }} data-gid="81600829" />
    </div>
  </div>
);
