import { Card, Colors, makeStyles, Text } from '@grayshift/cairn';
import { Skeleton } from '@mui/material';

import { DetailText, DetailTextSkeleton } from './DetailText';
import { deviceInformationByExtractionId } from './graphql/generated/deviceInformationByExtractionId';
import { deviceInformationQuery } from './graphql/queries/deviceInfo';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';
import { publicStaticFileURL } from './lib/publicFile';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    padding: '16px',
    backgroundColor: Colors.white,
    marginBottom: '16px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    padding: '16px',
    backgroundColor: Colors.white,
    marginBottom: '16px',
    '&:not(:first-child)': {
      marginLeft: '16px',
    },
  },
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    width: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  widgets: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '192px',
  },
  collapseButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  deviceInfo: {
    overflow: 'hidden',
    overflowY: 'auto',
    paddingLeft: '15px',
  },
  loadingContainer: {
    display: 'flex',
    height: 400,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 30,
    marginBottom: 30,
  },
  centeredFlex: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  taggedClues: {
    width: '320px',
    height: 'calc(100% - 50px)',
  },
  centeredFlexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  flex: {
    display: 'flex',
  },
});

export const ExtractionDeviceInfoWidget: React.FC = () => {
  const classes = useStyles();
  const [currentExtractionId] = useExtractionId();
  const { pollInterval } = useExtraction();

  const { data: deviceData, loading } =
    useQueryWithErrorBoundary<deviceInformationByExtractionId>(
      deviceInformationQuery,
      {
        variables: { extractionId: currentExtractionId },
        skip: !currentExtractionId,
        pollInterval,
      },
    );

  const deviceInfoAttributes = deviceData?.deviceInformationByExtractionId;

  const deviceInformationDetails = deviceInfoAttributes?.map((value) => {
    const { attributeData, displayName, id } = value;
    return (
      <DetailText
        key={id}
        loading={loading}
        title={`${displayName}:`}
        text={attributeData}
        titleStyle={{ textAlign: 'right', flex: 1 }}
        textStyle={{ flex: 1 }}
        style={{ justifyContent: 'center' }}
        data-gid="99482088"
      />
    );
  });

  return (
    <Card
      fillColor="white"
      style={{
        flex: 1,
        marginLeft: '16px',
        padding: '16px',
        overflow: 'scroll',
      }}
      data-gid="68771025"
    >
      <>
        <div
          style={{ display: 'flex', marginLeft: '-5px' }}
          data-gid="87451542"
        >
          <img
            src={publicStaticFileURL('/iphone-placeholder.svg')}
            alt="phone"
            style={{ width: '56px' }}
            data-gid="45510099"
          />
          <div style={{ marginLeft: '8px' }} data-gid="60642242">
            <Text
              color="textSecondary"
              style={{ minWidth: '112px' }}
              data-gid="97520276"
            >
              Device Information
            </Text>
            {loading || !deviceData?.extractionById?.target.extra.model ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem' }}
                data-gid="89089053"
              />
            ) : (
              <Text heading="h2" size="4" weight={500} data-gid="37493513">
                {deviceData?.extractionById?.target.extra.model}
              </Text>
            )}
          </div>
        </div>
        <div className={classes.deviceInfo} data-gid="18032214">
          {loading || !deviceInfoAttributes ? (
            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              data-gid="96635913"
            >
              <DetailTextSkeleton randomWidth data-gid="21581351" />
              <DetailTextSkeleton randomWidth data-gid="32263278" />
              <DetailTextSkeleton randomWidth data-gid="12577539" />
              <DetailTextSkeleton randomWidth data-gid="53284901" />
              <DetailTextSkeleton randomWidth data-gid="63057859" />
              <DetailTextSkeleton randomWidth data-gid="54463403" />
              <DetailTextSkeleton randomWidth data-gid="89707999" />
              <DetailTextSkeleton randomWidth data-gid="83428135" />
            </div>
          ) : (
            deviceInformationDetails
          )}
        </div>
      </>
    </Card>
  );
};
