import { Button, Colors, makeStyles, Text } from '@grayshift/cairn';
import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useRecoilState, useRecoilValue } from 'recoil';

import { memoById } from './graphql/generated/memoById';
import { memoByIdQuery } from './graphql/queries/memos';
import {
  clueDetailsSlideoutDataAtom,
  ClueType,
  currentMemoIdAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useMemoBody } from './useMemoBody';
import { useQueryParams } from './useQueryParams';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  memosContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    width: '300px',
    flex: 1,
  },
  memoGroupContainer: {
    borderLeft: 'solid 1px #d1d1d1',
    margin: '10px 0',
    paddingLeft: 15,
    position: 'relative',
  },
  noMemosText: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacer: {
    marginTop: 10,
  },
  memosHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
    background: Colors.white,
    borderBottom: `1px solid ${Colors.divider}`,
    top: 0,
    position: 'sticky',
    padding: '10px',
    justifyContent: 'space-between',
  },
  memoDetailsHeader: {
    padding: '3px 15px',
  },
  memoContent: {
    padding: '18px',
    paddingLeft: '25px',
    overflow: 'auto',
    '& p': {
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  headerText: {
    fontSize: 22,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingTop: '3px',
    paddingLeft: '15px',
  },
  clueProgressBar: {
    textAlign: 'right',
    paddingRight: '25px',
  },
  noInfo: {
    color: `${Colors.textSecondary}`,
    fontStyle: 'italic',
  },
});

const MemoThread = (): ReactElement => {
  const classes = useStyles();
  const currentMemoState = useRecoilValue(currentMemoIdAtom);
  const [clueDetailsSlideoutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );
  const { setClue } = useQueryParams();
  const { currentMemoId } = currentMemoState || {};

  const { slideoutOpen } = clueDetailsSlideoutData;
  const [currentExtractionId] = useCurrentExtractionId();

  const {
    loading,
    data,
    error: memoError,
  } = useQueryWithErrorBoundary<memoById>(memoByIdQuery, {
    variables: {
      memoId: currentMemoId,
      extractionId: currentExtractionId,
    },
  });

  if (loading) {
    return (
      <div className={classes.loadingContainer} data-gid="93697443">
        <ScaleLoader loading color={Colors.blue} data-gid="14708915" />
      </div>
    );
  }

  if (memoError) {
    return <div data-gid="87598624"> </div>;
  }

  const memo = data?.memoById;
  // Can be removed once old aggs with non-parsed JSON and HTML content are removed
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formattedNote = useMemoBody(memo?.noteBody);

  return (
    <div className={classes.memosContainer} data-gid="54391971">
      <div className={classes.memosHeader} data-gid="69209290">
        <div className={classes.headerText} data-gid="98956568">
          {memo?.title ?? (
            <div className={classes.noInfo} data-gid="70436370">
              No title
            </div>
          )}
          {memo?.modifiedAt ? (
            <Text size="2" data-gid="62188729">
              {`${DateTime.fromISO(memo.modifiedAt).toLocaleString(
                DateTime.DATE_MED,
              )}
                ${DateTime.fromISO(memo.modifiedAt).toLocaleString(
                  DateTime.TIME_WITH_SHORT_OFFSET,
                )} `}
            </Text>
          ) : (
            <div className={classes.noInfo} data-gid="55694392">
              <Text size="2" data-gid="28256242">
                No Date
              </Text>
            </div>
          )}
        </div>
        {slideoutOpen ? null : (
          <div className={classes.memoDetailsHeader} data-gid="18638465">
            <Button
              onClick={() => {
                setClue({ clueId: memo?.id || null, clueType: ClueType.MEMO });
                setClueDetailsSlideoutData({
                  slideoutOpen: true,
                  clueId: memo?.id || '',
                  clueType: ClueType.MEMO,
                });
              }}
              data-gid="10379522"
            >
              Open Memo Details
            </Button>
          </div>
        )}
      </div>
      <div className={classes.memoContent} data-gid="59726923">
        {memo?.noteBody ? (
          <pre data-gid="92625060">
            <Text data-gid="55688056">
              {formattedNote.replace(/\uFFFC/, '')}
            </Text>
          </pre>
        ) : (
          <div className={classes.noInfo} data-gid="18945704">
            <Text data-gid="63724335">No content to display</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemoThread;
