import { Text } from '@grayshift/cairn';

import { publicStaticFileURL } from '../lib/publicFile';

const ReviewInformationBullets: React.FC = () => (
  <div data-gid="99293098">
    <img
      src={publicStaticFileURL('/MF_Review_wm1line_color_rgb.png')}
      alt="Magnet Artifact IQ Logo"
      style={{ height: '40px', marginBottom: '15px' }}
      data-gid="27421627"
    />
    <Text
      size="3"
      style={{ marginBottom: '1rem', width: '95%' }}
      data-gid="97587015"
    >
      We&apos;re excited to bring you the power of Magnet Review! Using Artifact
      IQ&apos;s fast and secure data transfer, you can now send your Graykey
      extractions to REVIEW and:
    </Text>
    <Text size="2" data-gid="72843102">
      • Share and collaborate in real time
    </Text>
    <Text size="2" data-gid="73356598">
      • Review multiple evidence sources per case
    </Text>
    <Text size="2" data-gid="35989546">
      {/* eslint-disable-next-line no-irregular-whitespace */}
      • Use powerful tools to search, filter, and sort
    </Text>
    <Text size="2" data-gid="32941048">
      • Tag and comment with a robust system
    </Text>
    <Text size="2" data-gid="75163030">
      {/* eslint-disable-next-line no-irregular-whitespace */}
      • Report on all evidence together
    </Text>
  </div>
);

export default ReviewInformationBullets;
