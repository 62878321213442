import { makeStyles, Text } from '@grayshift/cairn';
import React from 'react';

const useStyles = makeStyles({
  metadataSubsection: {
    marginTop: 25,
  },
});
interface metadataSubsection {
  title: string;
}
const ClueDetailsSlideoutMetadataSubsection: React.FC<metadataSubsection> = ({
  title,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.metadataSubsection} data-gid="64636107">
      <Text data-gid="85505104">{title}</Text>
      {children}
    </div>
  );
};

export default ClueDetailsSlideoutMetadataSubsection;
