import { selectorFamily, waitForAny } from 'recoil';

import {
  getAllMessagesByMessageThreadId,
  getAllMessagesByMessageThreadId_allMessagesByThreadId_edges,
} from '../graphql/generated/getAllMessagesByMessageThreadId';
import makeSerializable from './makeSerializable';

const URL = `${process.env.REACT_APP_ZEUS_API_URL as string}/graphql`;
const PAGE_SIZE = 100;

interface FetchAllPagesInput {
  token: string;
  messageThreadId: string;
  sources: Array<string>;
  hideBiome: boolean;
  timeframe: {
    startDate: Date;
    endDate: Date;
  } | null;
  extractionId: string | null;
  toJSON: () => string;
}

const queryPage = selectorFamily({
  key: 'MessagesByThreadIdQuery',
  get:
    ({
      token,
      messageThreadId,
      timeframe,
      hideBiome,
      extractionId,
      sources,
      page,
      toJSON, // eslint-disable-line @typescript-eslint/no-unused-vars -- this is used internal to recoil
    }: FetchAllPagesInput & { page: number }) =>
    async () => {
      const locationResponse = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
      query getAllMessagesByMessageThreadId(
          $messageThreadId: UUID!
          $sources: [String]
          $timeframe: TimeFrame
          $hideBiome: Boolean
          $extractionId: UUID
          $page: Int
          $pageSize: Int
        ) {
          allMessagesByThreadId(
            messageThreadId: $messageThreadId
            sources: $sources
            timeframe: $timeframe
            hideBiome: $hideBiome
            extractionId: $extractionId
            page: $page
            pageSize: $pageSize
          ) {
            edges {
              node {
                id
                textContent
                timestamp
                sentByOwner
                thread {
                  id
                  threadDisplayName
                }
                sender {
                  id
                  primaryDisplayName
                  metacontact {
                    primaryDisplayName
                  }
                  isDeviceOwner
                }
                media {
                  ...ExpandedImageFields
                }
                meta {
                  key
                  value
                }
                app {
                  id
                  bundleId
                  displayName
                }
                appBundleId
              }
            }
          }
        }

        fragment ExpandedImageFields on Image {
          id
          thumbnailUrl
          # The URL of a full-size browser-renderable version of the image.
          previewUrl
          # URL pointing to original image file. Might not render in browser.
          originalUrl
          thumbnailFileId
          containsNudity
          containsCSAM
          containsCSAMConfidence
          containsNudityConfidence
          ncmecMatch
          thumbnailFile {
            id
          }
          previewFileId
          previewFile {
            id
          }
          originalFileId
          originalFile {
            id
          }
          labels
          app {
            id
            displayName
            bundleId
          }
          appBundleId
          extractionId
          timestamp
          imageAnnotations {
            annotation
          }
        }
      `,
          variables: {
            page,
            messageThreadId,
            hideBiome,
            timeframe,
            extractionId,
            pageSize: PAGE_SIZE,
            sources,
          },
        }),
      });

      const js = (await locationResponse.json()) as {
        data: getAllMessagesByMessageThreadId;
      };

      return js.data.allMessagesByThreadId?.edges ?? [];
    },
});

interface GetMessagePageInfoInput {
  token: string;
  messageThreadId: string;
  sources: Array<string>;
  timeframe: {
    startDate: Date;
    endDate: Date;
  } | null;
  hideBiome: boolean;
  extractionId: string | null;
  toJSON: () => string;
}

const messagePageInfo = selectorFamily({
  key: 'getMessagePageInfo',
  get:
    ({
      token,
      messageThreadId,
      sources,
      timeframe,
      hideBiome,
      extractionId,
      toJSON, // eslint-disable-line @typescript-eslint/no-unused-vars -- this is used internal to recoil
    }: GetMessagePageInfoInput) =>
    async () => {
      const pageInfoResponse = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: `
          query getAllMessagesByMessageThreadId(
              $messageThreadId: UUID!
              $sources: [String]
              $timeframe: TimeFrame
              $hideBiome: Boolean
              $extractionId: UUID
              $page: Int
              $pageSize: Int
            ) {
              allMessagesByThreadId(
                messageThreadId: $messageThreadId
                sources: $sources
                timeframe: $timeframe
                hideBiome: $hideBiome
                extractionId: $extractionId
                page: $page
                pageSize: $pageSize
              ) {
                pageInfo {
                  totalEdges
                }
                __typename
              }
            }
          `,
          variables: {
            messageThreadId,
            sources,
            timeframe,
            hideBiome,
            extractionId,
            pageSize: PAGE_SIZE,
          },
        }),
      });
      const pageInfoJson = (await pageInfoResponse.json()) as {
        data: getAllMessagesByMessageThreadId;
      };

      const totalEdges: number =
        pageInfoJson?.data?.allMessagesByThreadId?.pageInfo.totalEdges || 0;

      return totalEdges;
    },
});

interface MessagesQueryInput {
  token: string;
  messageThreadId: string;
  sources: Array<string>;
  timeframe: {
    startDate: Date;
    endDate: Date;
  } | null;
  hideBiome: boolean;
  extractionId: string | null;
  toJSON: () => string;
}

export const messagesQuery = selectorFamily({
  key: 'AllMessagesByTheadId',
  get:
    ({
      token,
      messageThreadId,
      sources,
      timeframe,
      hideBiome,
      extractionId,
      toJSON, // eslint-disable-line @typescript-eslint/no-unused-vars -- this is used internal to recoil
    }: MessagesQueryInput) =>
    async ({ get }) => {
      const totalEdges = get(
        messagePageInfo({
          token,
          messageThreadId,
          sources,
          timeframe,
          hideBiome,
          extractionId,
          toJSON,
        }),
      );

      const messagePages = [];
      const numberOfPages = Math.ceil(totalEdges / PAGE_SIZE);

      for (let i = 1; i <= numberOfPages; i += 1) {
        messagePages.push(
          queryPage(
            makeSerializable({
              page: i,
              token,
              messageThreadId,
              sources,
              hideBiome,
              extractionId,
              timeframe,
            }),
          ),
        );
      }

      const pageLoadables = get(waitForAny(messagePages));

      const result = pageLoadables
        .filter(({ state }) => state === 'hasValue')
        .flatMap(
          ({ contents }) =>
            contents as getAllMessagesByMessageThreadId_allMessagesByThreadId_edges,
        );

      return {
        edges: result,
        totalEdges,
      };
    },
});
