import { Button, Colors, makeStyles, Modal, Text } from '@grayshift/cairn';
import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';

import ExtractionSelectionModalCard from './ExtractionSelectionModalCard';
import { extractionByIdQuery } from './graphql/generated/extractionByIdQuery';
import { Layout } from './Layout';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import queryExtractionById from './lib/queryExtractionById';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

const useStyles = makeStyles({
  emptyPlaceholderContent: {
    display: 'flex',
    flex: '1',
  },
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 900,
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  modalLoadingContainer: {
    justifyContent: 'center',
  },
  extractionCardContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '30px 0',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
  },
});

/* 
important to note that this component doesn't actually really do anything.
It's just there to indicate to the user that the extraction they just selected
in the GK UI is now here in columbo
*/
function ExtractionImport(): ReactElement {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(true);
  const history = useHistory();
  const [currentExtractionId] = useCurrentExtractionId();

  const { loading, data } = useQueryWithErrorBoundary<extractionByIdQuery>(
    queryExtractionById,
    {
      variables: { extractionId: currentExtractionId },
    },
  );

  const extraction = data?.extractionById;

  const letsGo = "Let's Go";

  return (
    <Layout data-gid="78039228">
      <div className={classes.emptyPlaceholderContent} data-gid="60658410">
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            history.push(`/dashboard${history.location.search}`);
          }}
          data-gid="75116985"
        >
          {loading && !data ? (
            <div
              className={`${classes.modalContentContainer} ${classes.modalLoadingContainer}`}
              data-gid="21460124"
            >
              <ScaleLoader loading color={Colors.blue} data-gid="59883831" />
            </div>
          ) : (
            <div className={classes.modalContentContainer} data-gid="81156005">
              <Text size="6" data-gid="37124954">
                Import Extraction From GrayKey
              </Text>
              <div
                className={classes.extractionCardContainer}
                data-gid="31043536"
              >
                <ExtractionSelectionModalCard
                  selected={false}
                  extraction={extraction}
                  onClick={() => null}
                  data-gid="65954698"
                />
              </div>
              <div className={classes.btnContainer} data-gid="86836141">
                <Button
                  color="primary"
                  onClick={() => {
                    setModalOpen(false);
                    history.push(`/dashboard${history.location.search}`);
                  }}
                  data-gid="97914740"
                >
                  {letsGo}
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </Layout>
  );
}

export default ExtractionImport;
