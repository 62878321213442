/* eslint-disable max-len */
import { DateTime, DateTimeFormatOptions } from 'luxon';

const getPrettyDate = (
  dateStr: string,
  formatOpts?: DateTimeFormatOptions,
): string => DateTime.fromISO(dateStr).toLocaleString(formatOpts);

export default getPrettyDate;

export const getPrettyDateShortOffset = (
  dateStr: string,
): string => `${DateTime.fromISO(dateStr).toLocaleString()}
    ${DateTime.fromISO(dateStr).toLocaleString(
      DateTime.TIME_WITH_SHORT_OFFSET,
    )} `;
