import { Colors, makeStyles } from '@grayshift/cairn';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CalendarMonth } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  currentGlobalFiltersActiveAtom,
  timeFrameStateAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import { useQueryParams } from './useQueryParams';

const useStyles = makeStyles({
  rootWithNoLabel: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .MuiInputBase-root:focus-within': {
      borderColor: 'transparent',
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      color: 'black',
    },
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .MuiInputBase-root': {
      border: '1px solid white',
      borderRadius: '4px',
    },
    '& .MuiInputBase-root:focus-within': {
      borderColor: 'transparent',
    },
  },
  shareLock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: '7px 5px 5px 5px',
  },
});

const GlobalTimeFrameContainer: React.FC = () => {
  const classes = useStyles();

  const { clearSearchParams } = useQueryParams();
  const [showTimeframeInputs, setShowTimeframeInputs] = useState(false);
  const [revisingTimeframe, setRevisingTimeframe] = useState(false);
  const [timeframeApplied, setTimeframeApplied] = useState(false);
  const [timeframesValid, setTimeframesValid] = useState(false);
  const [startPopUpOpen, setStartPopUpOpen] = useState(false);
  const [endPopUpOpen, setEndPopUpOpen] = useState(false);

  const [timeFrameState, setTimeFrameState] =
    useRecoilState(timeFrameStateAtom);
  const [currentGlobalFiltersActive, setCurrentGlobalFiltersActive] =
    useRecoilState(currentGlobalFiltersActiveAtom);

  const [currentExtractionId] = useCurrentExtractionId();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [localStartTimeframe, setLocalStartTimeframe] =
    /* eslint-disable-next-line  @typescript-eslint/no-redundant-type-constituents */
    useState<MaterialUiPickersDate | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [localEndTimeframe, setLocalEndTimeframe] =
    /* eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents */
    useState<MaterialUiPickersDate | null>(null);

  useEffect(() => {
    if (
      localStartTimeframe &&
      localEndTimeframe &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !localStartTimeframe?.invalid &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !localEndTimeframe?.invalid
    ) {
      setTimeframesValid(true);
    } else {
      setTimeframesValid(false);
    }
  }, [localStartTimeframe, localEndTimeframe]);

  const clearTimeFrameState = (): void => {
    setLocalStartTimeframe(null);
    setLocalEndTimeframe(null);
    setTimeFrameState({
      globalTimeframe: { start: null, end: null },
    });
    setShowTimeframeInputs(false);
    setRevisingTimeframe(false);
    setTimeframeApplied(false);
    setTimeframesValid(false);
    setCurrentGlobalFiltersActive({
      timeframeActive: false,
      searchActive: currentGlobalFiltersActive.searchActive,
    });
    clearSearchParams();
  };

  const handleDivKeyPress = (event: { key: string }): void => {
    if (event.key === 'Enter') {
      clearTimeFrameState();
    }
  };

  const applyTimeFrame = (): void => {
    if (timeframesValid) {
      const { ts: startTs } = localStartTimeframe as { ts: number };
      const startJsDate = new Date(startTs);

      const { ts: endTs } = localEndTimeframe as { ts: number };
      const endJsDate = new Date(endTs);
      setTimeFrameState({
        globalTimeframe: {
          start: startJsDate,
          end: endJsDate,
        },
      });
      setShowTimeframeInputs(false);
      setTimeframeApplied(true);
      setRevisingTimeframe(false);
      setCurrentGlobalFiltersActive({
        timeframeActive: true,
        searchActive: currentGlobalFiltersActive.searchActive,
      });
      clearSearchParams();
    }
  };

  return (
    <div data-gid="27457111">
      {!showTimeframeInputs &&
        !revisingTimeframe &&
        !timeframeApplied &&
        !timeFrameState?.globalTimeframe.start &&
        !timeFrameState?.globalTimeframe.end &&
        currentExtractionId && (
          <div className={classes.shareLock} data-gid="32713998">
            <div
              onClick={() => setShowTimeframeInputs(!showTimeframeInputs)}
              role="presentation"
              onKeyDown={noop}
              style={{
                display: 'flex',
                color: Colors.shark,
                cursor: 'pointer',
              }}
              data-gid="68986511"
            >
              <CalendarMonth
                style={{ color: '#1665d8' }}
                fontSize="small"
                data-gid="18181200"
              />
              <Typography
                style={{
                  color: '#1665d8',
                  marginLeft: '.25vw',
                  marginTop: '.20vh',
                }}
                fontSize={12}
                data-gid="27582647"
              >
                Set Timeframe
              </Typography>
            </div>
          </div>
        )}
      {(showTimeframeInputs || revisingTimeframe) && (
        <div
          className={classes.shareLock}
          data-gid="52072468"
          style={{ alignItems: 'center' }}
        >
          <DateTimePicker
            label="Start"
            className={
              localStartTimeframe || startPopUpOpen
                ? classes.rootWithNoLabel
                : classes.root
            }
            onOpen={() => setStartPopUpOpen(true)}
            onClose={() => setStartPopUpOpen(false)}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={localStartTimeframe}
            sx={{
              width: 250,
              marginRight: 1,
              color: '#1665d8',
              minHeight: 0,
              svg: { color: '#1665d8' },
              input: {
                color: 'black',
              },
            }}
            onChange={(newTimeframe: MaterialUiPickersDate) => {
              setLocalStartTimeframe(newTimeframe);
            }}
            data-gid="53414274"
          />

          <DateTimePicker
            label="End"
            className={
              localEndTimeframe || endPopUpOpen
                ? classes.rootWithNoLabel
                : classes.root
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={localEndTimeframe}
            onOpen={() => setEndPopUpOpen(true)}
            onClose={() => setEndPopUpOpen(false)}
            sx={{
              width: 250,
              marginRight: 1,
              color: '#1665d8',
              minHeight: 0,
              svg: { color: '#1665d8' },
              input: {
                color: 'black',
              },
            }}
            onChange={(newTimeframe: MaterialUiPickersDate) => {
              setLocalEndTimeframe(newTimeframe);
            }}
            data-gid="65355312"
          />

          <CheckCircleIcon
            onClick={applyTimeFrame}
            onKeyDown={handleDivKeyPress}
            style={{
              fontSize: 30,
              color: timeframesValid ? '#34AA44' : '#DDDDDD',
              cursor:
                localStartTimeframe && localEndTimeframe ? 'pointer' : 'auto',
              marginBottom: 6,
              marginLeft: 6,
            }}
            data-gid="72444297"
          />
          <CancelIcon
            onClick={clearTimeFrameState}
            onKeyDown={handleDivKeyPress}
            style={{
              fontSize: 30,
              color: '#D2122E',
              cursor: 'pointer',
              marginBottom: 6,
              marginLeft: 6,
              marginRight: 6,
            }}
            data-gid="72444297"
          />
        </div>
      )}
      {timeFrameState?.globalTimeframe.start &&
        timeFrameState.globalTimeframe.end &&
        !revisingTimeframe && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-gid="32713998"
          >
            <div
              style={{
                height: 60,
                paddingRight: 10,
                paddingLeft: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              data-gid="59666003"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                data-gid="58803975"
              >
                <EventAvailableIcon
                  style={{ fontSize: 30, color: '#1765D8', marginRight: 10 }}
                  data-gid="23014058"
                />
                <div data-gid="82653793">
                  <Typography
                    fontFamily="Roboto"
                    fontSize={16}
                    sx={{ color: '#1765D8', marginRight: 1 }}
                    data-gid="49036864"
                  >
                    {timeFrameState?.globalTimeframe?.start?.toLocaleString()}
                  </Typography>
                  <Typography
                    fontFamily="Roboto"
                    fontSize={16}
                    sx={{ color: '#1765D8', marginRight: 1 }}
                    data-gid="99422288"
                  >
                    {timeFrameState?.globalTimeframe?.end?.toLocaleString()}
                  </Typography>
                </div>
                <ArrowDropDownIcon
                  style={{
                    fontSize: 30,
                    color: '#1765D8',
                    marginRight: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setRevisingTimeframe(true);
                  }}
                  data-gid="80484529"
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default GlobalTimeFrameContainer;
