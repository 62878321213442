// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

export function useQueryWithErrorBoundary<
  TData = ExplicitAny,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  options: QueryHookOptions<TData, TVariables> = Object.create(null),
): QueryResult<TData, TVariables> {
  // Any 500 hundred error
  // const fiveHundredRegex = /500/g;

  // // Server turning over, timeout
  // const failedToFetch = /Failed to fetch/g;

  // // Internal Server Errors
  // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const gqlErrorCode = queryResult.error?.graphQLErrors[0]?.extensions?.code;

  // PSA: This exposed a bad pattern, will fix pattern before uncommenting this so UI is still usable for images
  // if (
  //   queryResult.error?.message.match(fiveHundredRegex) ||
  //   queryResult.error?.message.match(failedToFetch) ||
  //   gqlErrorCode === 'INTERNAL_SERVER_ERROR'
  // ) {
  //   // eslint-disable-next-line @typescript-eslint/no-throw-literal
  //   throw queryResult.error;
  // }

  return useQuery<TData, TVariables>(query, options);
}
