/* eslint-disable pii/no-email */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-template-literals, @typescript-eslint/restrict-template-expressions */
import { gql } from '@apollo/client';
import { Colors, makeStyles, Text, TextBox } from '@grayshift/cairn';
import { Button, InputAdornment } from '@material-ui/core';
import { HighlightOff } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AddUserModal } from './AddUserModal';
import { accountInfo } from './graphql/generated/accountInfo';
import {
  accountUsers,
  accountUsers_accountUsers,
} from './graphql/generated/accountUsers';
import { AccountUsersSortField } from './graphql/generated/globalTypes';
import {
  pendingUsers,
  pendingUsers_pendingUsers,
} from './graphql/generated/pendingUsers';
import { Layout } from './Layout';
import { extractionAccessAtom, userInfoAtom } from './lib/atoms';
import useDocumentTitle from './lib/useDocumentTitle';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { UserManagementPendingUserTable } from './UserManagementPendingUserTable';
import { UserManagementUserExtractionsTable } from './UserManagementUserExtractionsTable';

const AccountInfo = gql`
  query accountInfo {
    accountInfo {
      usedEntitlements
      availableEntitlements
      totalUsers
    }
  }
`;

const AccountUsers = gql`
  query accountUsers(
    $sortField: AccountUsersSortField!
    $ascending: Boolean!
    $searchTerm: String!
  ) {
    accountUsers(
      sortField: $sortField
      ascending: $ascending
      searchTerm: $searchTerm
    ) {
      id
      firstName
      lastName
      email
      role
      isEntitled
      isExternalUser
      isExternalEntitled
    }
  }
`;

const PendingUsers = gql`
  query pendingUsers(
    $sortField: PendingUsersSortField!
    $ascending: Boolean!
    $searchTerm: String!
  ) {
    pendingUsers(
      sortField: $sortField
      ascending: $ascending
      searchTerm: $searchTerm
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
  rootContainer: {
    backgroundColor: Colors.white,
    border: `solid 1px ${Colors.mystic}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addUserBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '7px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  spacer: {
    height: 10,
  },
  overprovisioned: {
    color: 'red',
  },
  adornedEnd: {
    paddingRight: '7px',
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    width: '75vw',
  },
  userFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  userFilterElement: {
    display: 'flex',
    flexDirection: 'row',
    width: '20vh',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '10px',
  },
  userFilterSearchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  emptyTable: {
    marginTop: '20px',
    textAlign: 'center',
  },
});

export const UserManagement = (): React.ReactElement => {
  const classes = useStyles();
  const [userInfo] = useRecoilState(userInfoAtom);
  const extractionAccess = useRecoilValue(extractionAccessAtom);
  const [sortField, setSortField] = useState(AccountUsersSortField.firstName);
  const [ascending, setAscending] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isActiveUserFilter, setIsActiveUserFilter] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  useDocumentTitle('User Management');

  const {
    loading: tableLoading,
    data: tableData,
    refetch: tableDataRefetch,
  } = useQueryWithErrorBoundary<accountInfo>(AccountInfo, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: userInfo?.accountId,
      skip: !userInfo?.accountId,
    },
  });

  const {
    loading: accountUsersLoading,
    data: accountUsersData,
    refetch: accountUsersDataRefetch,
  } = useQueryWithErrorBoundary<accountUsers>(AccountUsers, {
    fetchPolicy: 'no-cache',
    variables: {
      sortField,
      ascending,
      searchTerm,
      accountId: userInfo?.accountId,
      skip: !userInfo?.accountId,
    },
  });

  const {
    loading: pendingUsersLoading,
    data: pendingUsersData,
    refetch: pendingUsersDataRefetch,
  } = useQueryWithErrorBoundary<pendingUsers>(PendingUsers, {
    fetchPolicy: 'no-cache',
    variables: {
      sortField,
      ascending,
      searchTerm,
      accountId: userInfo?.accountId,
      skip: !userInfo?.accountId,
    },
  });

  const refetchAndUpdateTable = async (): Promise<void> => {
    await tableDataRefetch();
    await accountUsersDataRefetch();
    await pendingUsersDataRefetch();
  };

  const { availableEntitlements, totalUsers, usedEntitlements } =
    tableData?.accountInfo || {};

  const summary = `${totalUsers} Total ${
    totalUsers === 1 ? 'User' : 'Users'
  } | ${usedEntitlements} ${
    usedEntitlements === 1 ? `License` : `Licenses`
  } Used of ${availableEntitlements} Available`;

  const checkSortField = (field: AccountUsersSortField): boolean =>
    ascending || sortField !== field;

  const handleSort = (newSortField: AccountUsersSortField): void => {
    if (sortField === newSortField) {
      setAscending(!ascending);
    } else {
      setSortField(newSortField);
      setAscending(true);
    }
  };

  const handleClose = (): void => {
    setShowAddUserModal(false);
  };

  return (
    <Layout disableLinks={!extractionAccess} data-gid="29094918">
      <div className={classes.outerWrapper} data-gid="68334102">
        <div className={classes.rootContainer} data-gid="10364947">
          <div style={{ padding: 20 }} data-gid="35087680">
            <div className={classes.headerContainer} data-gid="42436124">
              <Text heading="h2" size="4" weight={500} data-gid="15413466">
                User Management
              </Text>
              <div
                className={classes.addUserBtn}
                role="presentation"
                onClick={() => setShowAddUserModal(true)}
                data-gid="37282167"
              >
                <PersonAddIcon
                  style={{ fontSize: '25px', color: '#1665D8' }}
                  data-gid="85423414"
                />
                <Text weight={500} size="4" color="primary" data-gid="51286738">
                  Add User
                </Text>
              </div>
            </div>
            {tableData && !tableLoading && summary && (
              <div
                className={
                  usedEntitlements &&
                  availableEntitlements &&
                  usedEntitlements > availableEntitlements
                    ? classes.overprovisioned
                    : ''
                }
                data-gid="27715441"
              >
                <Text data-gid="66771337">{summary}</Text>
              </div>
            )}
          </div>
          <div className={classes.spacer} data-gid="73282948" />
          <div className={classes.searchContainer} data-gid="40408560">
            <div
              className={classes.userFilterSearchContainer}
              data-gid="70322315"
            >
              <div className={classes.userFilterContainer} data-gid="98307926">
                <div
                  className={classes.userFilterElement}
                  role="presentation"
                  style={
                    isActiveUserFilter
                      ? { borderBottom: '1px solid #1665D8' }
                      : {}
                  }
                  onClick={() => {
                    setIsActiveUserFilter(true);
                  }}
                  data-gid="96860221"
                >
                  <Button data-gid="25581382">
                    <Text
                      heading="h2"
                      size="2"
                      color={isActiveUserFilter ? 'primary' : 'initial'}
                      data-gid="39032150"
                    >
                      Active Users ({totalUsers})
                    </Text>
                  </Button>
                </div>
                <div
                  className={classes.userFilterElement}
                  role="presentation"
                  onClick={() => {
                    setIsActiveUserFilter(false);
                  }}
                  style={
                    isActiveUserFilter
                      ? {}
                      : { borderBottom: '1px solid #1665D8' }
                  }
                  data-gid="97349317"
                >
                  <Button data-gid="80607193">
                    <Text
                      heading="h2"
                      size="2"
                      color={!isActiveUserFilter ? 'primary' : 'initial'}
                      data-gid="56917604"
                    >
                      Pending Users (
                      {pendingUsersData?.pendingUsers
                        ? pendingUsersData.pendingUsers.length
                        : 0}
                      )
                    </Text>
                  </Button>
                </div>
              </div>
              <TextBox
                label="Search Users"
                value={searchTerm}
                style={{ width: 400, margin: 0, minHeight: '47px' }}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                data-gid="14160295"
                InputProps={{
                  endAdornment: searchTerm && (
                    <InputAdornment position="end" data-gid="97011049">
                      <IconButton
                        aria-label="search users"
                        onClick={() => {
                          setSearchTerm('');
                        }}
                        data-gid="28765500"
                      >
                        <HighlightOff data-gid="94151422" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: {
                    adornedEnd: classes.adornedEnd,
                  },
                }}
              />
            </div>
            {tableLoading ||
              accountUsersLoading ||
              (pendingUsersLoading && !isActiveUserFilter && (
                <div className={classes.loader} data-gid="21696246">
                  {' '}
                  <ScaleLoader
                    loading
                    color="#1665D8"
                    data-gid="86576723"
                  />{' '}
                </div>
              ))}
          </div>
          {!isActiveUserFilter ? (
            <Alert severity="info" data-gid="11181977">
              {`These users have been invited to the account, but they haven't
              accepted the invite or registered their email address yet.`}
            </Alert>
          ) : null}
          {tableData && !tableLoading && (
            <TableContainer data-gid="93569516">
              <Table aria-label="collapsible table" data-gid="88407058">
                <TableHead data-gid="24470840">
                  <TableRow data-gid="44626562">
                    <TableCell data-gid="94003928" />
                    <TableCell data-gid="66569174">
                      <TableSortLabel
                        active={sortField === AccountUsersSortField.firstName}
                        direction={
                          checkSortField(AccountUsersSortField.firstName)
                            ? 'asc'
                            : 'desc'
                        }
                        onClick={() =>
                          handleSort(AccountUsersSortField.firstName)
                        }
                        data-gid="52453604"
                      >
                        First Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" data-gid="82791070">
                      <TableSortLabel
                        active={sortField === AccountUsersSortField.lastName}
                        direction={
                          checkSortField(AccountUsersSortField.lastName)
                            ? 'asc'
                            : 'desc'
                        }
                        onClick={() =>
                          handleSort(AccountUsersSortField.lastName)
                        }
                        data-gid="35823935"
                      >
                        Last Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" data-gid="57484003">
                      <TableSortLabel
                        active={sortField === AccountUsersSortField.email}
                        direction={
                          checkSortField(AccountUsersSortField.email)
                            ? 'asc'
                            : 'desc'
                        }
                        onClick={() => handleSort(AccountUsersSortField.email)}
                        data-gid="35823935"
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    {isActiveUserFilter && (
                      <TableCell align="left" data-gid="29576343">
                        <TableSortLabel
                          active={
                            sortField === AccountUsersSortField.accountUserRole
                          }
                          direction={
                            checkSortField(
                              AccountUsersSortField.accountUserRole,
                            )
                              ? 'asc'
                              : 'desc'
                          }
                          onClick={() =>
                            handleSort(AccountUsersSortField.accountUserRole)
                          }
                          data-gid="95875039"
                        >
                          Account Role
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {isActiveUserFilter && (
                      <TableCell align="left" data-gid="83330761">
                        <TableSortLabel
                          active={
                            sortField === AccountUsersSortField.isEntitled
                          }
                          direction={
                            checkSortField(AccountUsersSortField.isEntitled)
                              ? 'asc'
                              : 'desc'
                          }
                          onClick={() =>
                            handleSort(AccountUsersSortField.isEntitled)
                          }
                          data-gid="21768617"
                        >
                          License
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell align="center" data-gid="83330761" />
                  </TableRow>
                </TableHead>
                <TableBody data-gid="29226101">
                  {isActiveUserFilter
                    ? accountUsersData?.accountUsers?.map(
                        (user: accountUsers_accountUsers) => (
                          <UserManagementUserExtractionsTable
                            key={user.id}
                            user={user}
                            accountInfo={tableData?.accountInfo}
                            refetchAndUpdateTable={refetchAndUpdateTable}
                            data-gid="23174521"
                          />
                        ),
                      )
                    : pendingUsersData?.pendingUsers?.map(
                        (pendingUser: pendingUsers_pendingUsers) => (
                          <UserManagementPendingUserTable
                            key={pendingUser.id}
                            pendingUser={pendingUser}
                            data-gid="10515162"
                          />
                        ),
                      )}
                </TableBody>
              </Table>
              {isActiveUserFilter &&
                accountUsersData?.accountUsers &&
                accountUsersData?.accountUsers?.length < 1 && (
                  <div className={classes.emptyTable} data-gid="26166838">
                    No Active Users
                  </div>
                )}
              {!isActiveUserFilter &&
                pendingUsersData?.pendingUsers &&
                pendingUsersData?.pendingUsers?.length < 1 && (
                  <div className={classes.emptyTable} data-gid="89201902">
                    No Pending Users
                  </div>
                )}
            </TableContainer>
          )}
        </div>
      </div>
      {showAddUserModal && (
        <AddUserModal
          refetchAndUpdateTable={refetchAndUpdateTable}
          onClose={handleClose}
          data-gid="23084187"
        />
      )}
    </Layout>
  );
};
