/* eslint-disable no-underscore-dangle */

import { Loadable } from 'recoil';

import {
  getAllTaggedClues_allTaggedClues_edges,
  getAllTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_media,
  getAllTaggedClues_allTaggedClues_edges_node_Video,
} from '../graphql/generated/getAllTaggedClues';
import {
  inRangeTimelineClues,
  inRangeTimelineClues_inRangeTimelineClues_edgeNodes_Video,
} from '../graphql/generated/inRangeTimelineClues';
import { reportTaggedClues_allTaggedClues_edges_node_Image } from '../graphql/generated/reportTaggedClues';
import { timelineBrowserHistory_browserHistoryTimestamps_edgeNodes } from '../graphql/generated/timelineBrowserHistory';
import { timelineCalls_callTimestamps_edgeNodes } from '../graphql/generated/timelineCalls';
import { timelineImages_imageTimestamps_edgeNodes } from '../graphql/generated/timelineImages';
import { timelineLocations_locationTimestamps_edgeNodes } from '../graphql/generated/timelineLocations';
import { timelineMemos_memoTimestamps_edgeNodes } from '../graphql/generated/timelineMemos';
import { timelineMessages_messageTimestamps_edgeNodes } from '../graphql/generated/timelineMessages';
import { timelineVideos_videoTimestamps_edgeNodes } from '../graphql/generated/timelineVideos';
import { TimelineEvents, TimelineEventTypes } from '../types';

export function generateTaggedClueEvents(
  data: getAllTaggedClues_allTaggedClues_edges[] | undefined,
): TimelineEvents {
  const events: TimelineEvents = {};
  // eslint-disable-next-line sonarjs/cognitive-complexity
  data?.forEach(({ node }) => {
    if (node.__typename === 'Contact') {
      const {
        id: contactId,
        primaryDisplayName,
        mostRecentMessageSent,
        app,
        appBundleId,
      } = node;
      const appDisplayName = app?.displayName ?? appBundleId;
      const mostRecentMessageTimeStamp = mostRecentMessageSent?.timestamp;
      events[contactId] = {
        entityIds: ['Contact'],
        time: new Date(),
        data: {
          id: contactId,
          primaryDisplayName,
          timestamp: mostRecentMessageTimeStamp,
          appDisplayName,
          iconUrl: app?.iconUrl ?? '',
        },
        type: 'Contact',
      };
    }

    if (node.__typename === 'DefaultAppMessage') {
      const {
        id: messageId,
        textContent,
        timestamp: messageTimestamp,
        sentByOwner,
        sender,
        media,
        app,
        appBundleId,
      } = node;
      const imageUrls =
        (media.length > 0 &&
          media.map(
            (
              attachment: getAllTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_media,
            ) => {
              if (attachment.__typename === 'Image') {
                return attachment.thumbnailUrl;
              }
              return null;
            },
          )) ||
        [];

      events[messageId] = {
        entityIds: ['Messages'],
        time: new Date(),
        data: {
          id: messageId,
          textContent,
          timestamp: messageTimestamp,
          sentByOwner,
          sender: sender.primaryDisplayName ?? '',
          thumbnails: imageUrls,
          displayName: app?.displayName ?? appBundleId,
          iconUrl: app?.iconUrl ?? '',
        },
        type: 'Messages',
      };
    }

    if (node.__typename === 'DefaultLocation') {
      const { id: locationId, longitude, latitude, app, appBundleId } = node;
      events[locationId] = {
        entityIds: ['Location'],
        time: new Date(),
        data: {
          id: locationId,
          longitude,
          latitude,
          displayName: app?.displayName ?? appBundleId,
          iconUrl: app?.iconUrl ?? '',
        },
        type: 'Location',
      };
    }

    if (node.__typename === 'Image' || node.__typename === 'Video') {
      const {
        id: mediaId,
        thumbnailUrl,
        timestamp,
        ncmecMatch,
        containsCSAM,
        containsNudity,
        containsNudityConfidence,
        __typename: mediaType,
        app,
        appBundleId,
      } = node;
      const { originalUrl, durationSeconds, videoAnnotations } =
        node as getAllTaggedClues_allTaggedClues_edges_node_Video;
      const { imageAnnotations } =
        node as unknown as reportTaggedClues_allTaggedClues_edges_node_Image;
      const isExplicit =
        mediaType === 'Video'
          ? videoAnnotations && videoAnnotations.length > 0
          : imageAnnotations && imageAnnotations.length > 0;
      events[mediaId] = {
        entityIds: ['Media'],
        time: new Date(),
        data: {
          id: mediaId,
          mediaUrl: thumbnailUrl,
          originalUrl,
          timestamp,
          displayName: app?.displayName ?? appBundleId,
          iconUrl: app?.iconUrl ?? '',
          mediaType,
          durationSeconds,
          imageContext: {
            ncmecMatch,
            containsCSAM,
            containsNudity,
            containsNudityConfidence: containsNudityConfidence || 0,
            isExplicit,
          },
        },
        type: 'Media',
      };
    }

    if (node.__typename === 'Call') {
      const {
        id: callId,
        displayName,
        timestamp,
        contact,
        duration,
        originated,
        answered,
        app,
        appBundleId,
      } = node;
      events[callId] = {
        entityIds: ['Calls'],
        time: new Date(),
        data: {
          id: callId,
          callDisplayName: displayName,
          callDuration: duration,
          callContactDisplayName: contact?.primaryDisplayName,
          callOriginated: originated,
          callAnswered: answered,
          timestamp,
          iconUrl: app?.iconUrl ?? '',
          appDisplayName: app?.displayName ?? appBundleId,
        },
        type: 'Calls',
      };
    }

    if (node.__typename === 'Memo') {
      const {
        id: memoId,
        title,
        modifiedAt,
        noteBody,
        app,
        appBundleId,
      } = node;
      events[memoId] = {
        entityIds: ['Memos'],
        time: new Date(),
        data: {
          id: memoId,
          memoTitle: title,
          memoNoteBody: noteBody,
          timestamp: modifiedAt,
          appDisplayName: app?.displayName ?? appBundleId,
          iconUrl: app?.iconUrl ?? '',
        },
        type: 'Memos',
      };
    }

    if (node.__typename === 'InstalledApp') {
      const {
        id: installedAppById,
        installedAppDisplayName,
        version,
        bundleId,
        iconUrl,
      } = node;
      events[installedAppById] = {
        entityIds: ['Installed Apps'],
        time: new Date(),
        data: {
          id: installedAppById,
          displayName: installedAppDisplayName,
          version,
          bundleId,
          iconUrl,
        },
        type: 'InstalledApps',
      };
    }

    if (node.__typename === 'BrowserHistory') {
      const {
        id: browserHistoryById,
        url,
        timestamp,
        app,
        appBundleId,
        title,
        visitCount,
      } = node;
      events[browserHistoryById] = {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        entityIds: ['BrowserHistory'],
        time: new Date(),
        data: {
          id: browserHistoryById,
          url,
          timestamp,
          appDisplayName: app?.displayName ?? appBundleId,
          title,
          visitCount,
          iconUrl: app?.iconUrl,
        },
        type: 'BrowserHistory',
      };
    }
  });

  return events;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function generateTimelineEvents(
  locations:
    | Loadable<{
        edges: timelineLocations_locationTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  messages:
    | Loadable<{
        edges: timelineMessages_messageTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  calls:
    | Loadable<{
        edges: timelineCalls_callTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  memos:
    | Loadable<{
        edges: timelineMemos_memoTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  images:
    | Loadable<{
        edges: timelineImages_imageTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  videos:
    | Loadable<{
        edges: timelineVideos_videoTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  browserHistory:
    | Loadable<{
        edges: timelineBrowserHistory_browserHistoryTimestamps_edgeNodes[];
        totalEdges: number;
      }>
    | undefined,
  includedEventTypes: Array<TimelineEventTypes>,
  // data: fetchAllLocationsMessagesImagesAndCalls | undefined,
): TimelineEvents {
  const events: TimelineEvents = {};
  if (
    locations &&
    locations.state === 'hasValue' &&
    includedEventTypes.includes('Location')
  ) {
    Object.values(locations.contents.edges).forEach((value) => {
      const location = value;
      const { id, timestamp } = location;
      if (timestamp) {
        events[id] = {
          entityIds: ['Location'],
          time: new Date(timestamp),
          data: {
            id,
          },
          type: 'Location',
        };
      }
    });
  }

  if (
    messages &&
    messages.state === 'hasValue' &&
    includedEventTypes.includes('Messages')
  ) {
    Object.values(messages.contents.edges).forEach((value) => {
      const message = value;
      const { id, timestamp } = message;

      events[id] = {
        entityIds: ['Messages'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'Messages',
      };
    });
  }

  if (
    images &&
    images.state === 'hasValue' &&
    includedEventTypes.includes('Media')
  ) {
    Object.values(images.contents.edges).forEach((value) => {
      const mediaEvent = value;
      const { id, timestamp } = mediaEvent;
      events[id] = {
        entityIds: ['Media'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'Media',
      };
    });
  }

  if (
    videos &&
    videos.state === 'hasValue' &&
    includedEventTypes.includes('Media')
  ) {
    Object.values(videos.contents.edges).forEach((value) => {
      const mediaEvent = value;
      const { id, timestamp } = mediaEvent;
      events[id] = {
        entityIds: ['Media'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'Media',
      };
    });
  }

  if (
    calls &&
    calls.state === 'hasValue' &&
    includedEventTypes.includes('Calls')
  ) {
    Object.values(calls.contents.edges).forEach((value) => {
      const call = value;
      const { id, timestamp } = call;
      events[id] = {
        entityIds: ['Calls'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'Calls',
      };
    });
  }

  if (
    memos &&
    memos.state === 'hasValue' &&
    includedEventTypes.includes('Memos')
  ) {
    Object.values(memos.contents.edges).forEach((value) => {
      const memo = value;
      const { id, timestamp } = memo;
      events[id] = {
        entityIds: ['Memos'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'Memos',
      };
    });
  }

  if (
    browserHistory &&
    browserHistory.state === 'hasValue' &&
    includedEventTypes.includes('BrowserHistory')
  ) {
    Object.values(browserHistory.contents.edges).forEach((value) => {
      const browserHistorySingular = value;
      const { id, timestamp } = browserHistorySingular;
      events[id] = {
        entityIds: ['BrowserHistory'],
        time: new Date(timestamp),
        data: {
          id,
        },
        type: 'BrowserHistory',
      };
    });
  }

  return events;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function generateInRangeEvents(
  inRangeClues: inRangeTimelineClues | undefined,
): TimelineEvents {
  const events: TimelineEvents = {};

  const clues = inRangeClues?.inRangeTimelineClues?.edgeNodes;

  if (!clues) {
    return events;
  }

  Object.values(clues).forEach((clue) => {
    const clueType = clue?.__typename;
    if (clue && clueType === 'DefaultLocation') {
      const location = clue;
      const { id, timestamp, longitude, latitude, app } = location || {};
      if (timestamp) {
        events[id] = {
          entityIds: ['Location'],
          time: new Date(timestamp),
          data: {
            id,
            longitude,
            latitude,
            timestamp,
            iconUrl: app?.iconUrl,
            appDisplayName: app?.displayName ?? '',
          },
          type: 'Location',
        };
      }
    }

    if (clue && clueType === 'Call') {
      const call = clue;
      const {
        id,
        displayName,
        timestamp,
        duration,
        contact,
        originated,
        answered,
        appBundleId,
        app,
      } = call || {};
      events[id] = {
        entityIds: ['Calls'],
        time: new Date(timestamp),
        data: {
          id,
          callDisplayName: displayName,
          callDuration: duration,
          callContactDisplayName: contact?.primaryDisplayName,
          callOriginated: originated,
          callAnswered: answered,
          timestamp,
          iconUrl: app?.iconUrl,
          appDisplayName: app?.displayName ?? appBundleId,
        },
        type: 'Calls',
      };
    }

    if (clue && clueType === 'Memo') {
      const memo = clue;
      const { id, title, modifiedAt, noteBody, app, appBundleId } = memo || {};
      events[id] = {
        entityIds: ['Memos'],
        time: new Date(modifiedAt ?? ''),
        data: {
          id,
          memoTitle: title,
          memoNoteBody: noteBody,
          timestamp: modifiedAt,
          iconUrl: app?.iconUrl,
          appDisplayName: app?.displayName ?? appBundleId,
        },
        type: 'Memos',
      };
    }

    if (clue && clueType === 'BrowserHistory') {
      const browserHistory = clue;
      const { id, title, url, visitCount, timestamp, app, appBundleId } =
        browserHistory || {};
      events[id] = {
        entityIds: ['BrowserHistory'],
        time: new Date(timestamp ?? ''),
        data: {
          id,
          title,
          url,
          timestamp,
          visitCount,
          iconUrl: app?.iconUrl,
          appDisplayName: app?.displayName ?? appBundleId,
        },
        type: 'BrowserHistory',
      };
    }

    if (clue && clueType === 'DefaultAppMessage') {
      const message = clue;

      const {
        id,
        textContent,
        sender,
        thread,
        media: messageMedia,
        timestamp,
        app,
        sentByOwner,
        appBundleId,
      } = message || {};
      const { primaryDisplayName } = sender || {};
      const { id: threadId, threadDisplayName } = thread || {};

      const imageUrls =
        (messageMedia &&
          messageMedia.length > 0 &&
          // eslint-disable-next-line sonarjs/no-identical-functions
          messageMedia.map((attachment) => {
            if (attachment.__typename === 'Image') {
              return attachment.thumbnailUrl;
            }
            return null;
          })) ||
        [];

      events[id] = {
        entityIds: ['Messages'],
        time: new Date(timestamp),
        data: {
          id,
          textContent: textContent || undefined,
          sender: primaryDisplayName || undefined,
          threadDisplayName: threadDisplayName || undefined,
          sentByOwner,
          threadId: threadId || undefined,
          timestamp,
          thumbnails: imageUrls,
          displayName: app?.displayName ?? appBundleId,
          iconUrl: app?.iconUrl,
        },
        type: 'Messages',
      };
    }
    if (clue && (clueType === 'Image' || clueType === 'Video')) {
      const mediaEvent = clue;

      const {
        id,
        thumbnailUrl,
        timestamp,
        app,
        ncmecMatch,
        containsCSAM,
        containsCSAMConfidence,
        containsNudity,
        containsNudityConfidence,
        __typename: mediaType,
      } = mediaEvent || {};

      const { originalUrl, durationSeconds, videoAnnotations } =
        mediaEvent as unknown as inRangeTimelineClues_inRangeTimelineClues_edgeNodes_Video;
      const { imageAnnotations } =
        mediaEvent as unknown as reportTaggedClues_allTaggedClues_edges_node_Image;

      const isExplicit =
        mediaType === 'Video'
          ? videoAnnotations && videoAnnotations.length > 0
          : imageAnnotations && imageAnnotations.length > 0;
      events[id] = {
        entityIds: ['Media'],
        time: new Date(timestamp),
        data: {
          id,
          mediaUrl: thumbnailUrl || undefined,
          timestamp,
          mediaType,
          iconUrl: app?.iconUrl,
          appDisplayName: app?.displayName ?? '',
          originalUrl,
          durationSeconds,
          imageContext: {
            ncmecMatch,
            containsCSAM,
            containsCSAMConfidence: containsCSAMConfidence ?? 0,
            containsNudity,
            containsNudityConfidence: containsNudityConfidence ?? 0,
            isExplicit,
          },
        },
        type: 'Media',
      };
    }
  });
  return events;
}
