import { gql } from '@apollo/client';

export const CoreImageFields = gql`
  fragment CoreImageFields on Image {
    id
    thumbnailUrl
    previewUrl
    imageAnnotations {
      id
      annotation
      annotatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
