import { gql } from '@apollo/client';

export const insertVideoAnnotationMutation = gql`
  mutation insertVideoAnnotation(
    $extractionId: UUID!
    $annotation: VideoAnnotationEnum!
    $userId: UUID!
    $videoId: UUID!
  ) {
    insertVideoAnnotation(
      extractionId: $extractionId
      annotation: $annotation
      userId: $userId
      videoId: $videoId
    ) {
      id
      videoAnnotations {
        videoId
        annotation
      }
      thumbnailUrl
    }
  }
`;

export const removeVideoAnnotationMutation = gql`
  mutation removeVideoAnnotation(
    $videoId: UUID!
    $extractionId: UUID!
    $annotation: VideoAnnotationEnum!
  ) {
    removeVideoAnnotation(
      videoId: $videoId
      extractionId: $extractionId
      annotation: $annotation
    ) {
      id
      videoAnnotations {
        videoId
        annotation
      }
      thumbnailUrl
    }
  }
`;
