import { gql } from '@apollo/client';

const ExtractionAccessTypeByUserId = gql`
  query extractionAccessTypeByUserId($extractionId: UUID!, $userId: UUID!) {
    extractionAccessTypeByUserId(extractionId: $extractionId, userId: $userId) {
      accessType
    }
  }
`;

export default ExtractionAccessTypeByUserId;
