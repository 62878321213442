import { gql } from '@apollo/client';

export const deviceInformationQuery = gql`
  query deviceInformationByExtractionId($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      target {
        hardwareId
        extra {
          model
          serial
        }
      }
    }
    deviceInformationByExtractionId(extractionId: $extractionId) {
      id
      attributeType
      attributeData
      displayName
    }
  }
`;
