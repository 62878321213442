import { useMutation } from '@apollo/client';
import { Chip, Colors, makeStyles, Text } from '@grayshift/cairn';
import {
  CheckCircleOutline,
  GpsFixed,
  HighlightOff,
  IosShare,
  Restore,
} from '@mui/icons-material';
import { Alert } from '@mui/material';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ExtractionProgressBar from './ExtractionProgressBar';
import { extractionProgressText } from './extractionText';
import { allExtractions_allExtractions_edges_node } from './graphql/generated/allExtractions';
import {
  archiveExtractionMutation,
  reactivateExtractionMutation,
} from './graphql/mutations/extraction';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';
import {
  extractionSelectionModalIsOpenAtom,
  extractionShareModalControllerAtom,
  userInfoAtom,
} from './lib/atoms';
import { isISOTimestampExpired } from './lib/date';
import { getPrettyDateShortOffset } from './lib/getPrettyDate';

const useStyles = makeStyles({
  extractionCard: ({
    entitledAccess,
    expired,
    selected,
    isInShareModal,
    disabled,
  }: {
    entitledAccess: boolean;
    expired: boolean;
    selected: boolean;
    isInShareModal: boolean;
    disabled: boolean;
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: selected ? Colors.blue : '#f5f5f5',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: entitledAccess ? 5 : 0,
    borderTopRightRadius: entitledAccess ? 5 : 0,
    marginBottom: 15,
    padding: 20,
    cursor: isInShareModal || disabled ? 'auto' : 'pointer',
    '&:hover': {
      background: isInShareModal
        ? ''
        : selected
        ? ''
        : disabled
        ? ''
        : Colors.divider,
    },
    color:
      !entitledAccess && expired
        ? '#9EA0A5'
        : selected
        ? Colors.white
        : Colors.black,
  }),
  extractionCardTopContent: {
    display: 'flex',
  },
  selectedExtractionIdCard: {
    background: Colors.blue,
    color: Colors.white,
  },
  extractionLeftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1.2,
  },
  extractionRightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  extractionButtonList: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  buttonItem: {
    color: Colors.blue,
    display: 'flex',
    alignItems: 'center',
  },
  buttonItemWhenSelected: {
    color: Colors.white,
  },
  archiveWord: {
    marginLeft: 3,
  },
  archiveWordSelected: {
    marginLeft: 3,
  },
  extractionButtonsWithConfirm: {
    display: 'flex',
    flexDirection: 'column',
  },
  confirmContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '7px',
  },
  confirmYes: {
    color: Colors.blue,
    padding: '0px 15px',
  },
  confirmYesWhenSelected: {
    color: Colors.white,
    padding: '0px 15px',
  },
  confirmCancel: {
    color: Colors.red,
    padding: '0px 0px 0px 15px',
  },
  extractionProgressContainer: {
    paddingTop: '5px',
  },
  extractionProgressBarContainer: {
    height: '10px',
    width: '310px',
    borderRadius: 10,
    backgroundColor: 'white',
    border: `1px solid ${Colors.mystic}`,
  },
  selectedExtractionProgressBarContainer: {
    height: '10px',
    width: '310px',
    borderRadius: 10,
    backgroundColor: 'white',
    border: '1px solid white',
  },
  progressText: {
    display: 'flex',
    alignItems: 'left',
    paddingTop: '2px',
    paddingLeft: '2px',
  },
});

interface ExtractionSelectionModalCardProps {
  extraction: allExtractions_allExtractions_edges_node | null | undefined;
  selected?: boolean;
  onClick?: (eid: string) => void;
  isInvite?: boolean;
  isInShareModal?: boolean;
  disabled?: boolean;
}

const ExtractionStatus: React.FC<{
  extraction: allExtractions_allExtractions_edges_node | null | undefined;
}> = ({ extraction }) => {
  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;

  if (entitledAccess) {
    return null;
  }
  const resetsAvailable =
    extraction?.expirationResetCount !== null &&
    extraction?.expirationResetCount !== undefined &&
    extraction.expirationResetCount <= 3
      ? 3 - extraction.expirationResetCount
      : 0;

  if (
    extraction &&
    extraction?.expiresAt &&
    DateTime.fromISO(extraction?.expiresAt) < DateTime.now()
  ) {
    return (
      <Alert
        severity="error"
        icon={false}
        data-gid="70196841"
        style={{
          border: '1px solid #D73619',
          color: 'black',
          // backgroundColor: '#ff9800',
          justifyContent: 'flex-end',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '0px',
          borderBottomLeftRadius: '0px',
          fontSize: '14px',
          fontWeight: '500',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        sx={{ '& .MuiAlert-message': { padding: '2px 0px' } }}
      >
        EXPIRED | {resetsAvailable} Reactivation(s) Available
      </Alert>
    );
  }

  if (extraction?.isArchived) {
    return (
      <Alert
        severity="error"
        icon={false}
        data-gid="70196841"
        style={{
          border: '1px solid #D73619',
          color: 'black',
          // backgroundColor: '#ff9800',
          justifyContent: 'flex-end',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '0px',
          borderBottomLeftRadius: '0px',
          fontSize: '14px',
          fontWeight: '500',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        sx={{ '& .MuiAlert-message': { padding: '2px 0px' } }}
      >
        ARCHIVED
      </Alert>
    );
  }

  const expiresAt = extraction?.expiresAt || '';

  const timeLeftDays = DateTime.fromISO(expiresAt).diffNow().as('days') > 1;
  const timeLeft = timeLeftDays ? 'day' : 'hour';

  const timeLeftText = `${Math.round(
    DateTime.fromISO(expiresAt).diffNow().as(timeLeft),
  )} ${
    timeLeftDays ? 'Day' : 'Hour'
  }(s) Until Expiration | ${resetsAvailable} Reactivation(s) available`;

  return (
    <Alert
      severity="warning"
      icon={false}
      data-gid="70196841"
      style={{
        border: '1px solid #F6AB2F',
        padding: '4px 15px',
        color: 'black',
        // backgroundColor: '#ff9800',
        justifyContent: 'flex-end',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '14px',
        fontWeight: '500',
        paddingTop: '0px',
        paddingBottom: '0px',
      }}
      sx={{ '& .MuiAlert-message': { padding: '2px 0px' } }}
    >
      {timeLeftText}
    </Alert>
  );
};

const ExtractionActions: React.FC<{
  selected: boolean;
  isInvite?: boolean;
  extraction: allExtractions_allExtractions_edges_node | null | undefined;
  isInShareModal: boolean;
  disabled: boolean;
}> = ({
  selected,
  extraction,
  isInvite = false,
  isInShareModal,
  disabled = false,
}) => {
  const [reactivateExtraction] = useMutation(reactivateExtractionMutation, {
    variables: { input: { extractionId: extraction?.id } },
  });
  const [, setExtractionSelectionModalIsOpen] = useRecoilState(
    extractionSelectionModalIsOpenAtom,
  );
  const [, setExtractionShareModalController] = useRecoilState(
    extractionShareModalControllerAtom,
  );
  const [reactivateProcessing, setReactivateProcessing] = useState(false);

  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;

  const reactivate = async (): Promise<void> => {
    if (extraction?.id && process.env.REACT_APP_ENV === 'production') {
      window.aptrinsic('track', 'Reactivate', {
        extractionId: extraction.id,
      });
    }
    if (!reactivateProcessing) {
      setReactivateProcessing(true);
      const res = await reactivateExtraction();
      if (res.errors) setReactivateProcessing(false);
    }
  };

  const switchToShareModal = (): void => {
    if (extraction) {
      setExtractionShareModalController({
        isOpen: true,
        extraction,
        shareModalWasEntry: false,
      });
      setExtractionSelectionModalIsOpen(false);
    }
  };

  const shouldShowShareIcon =
    ((extraction?.expiresAt &&
      DateTime.fromISO(extraction.expiresAt) > DateTime.local()) ||
      entitledAccess) &&
    !isInShareModal &&
    !disabled;

  const reactivateColor = reactivateProcessing ? 'gray' : Colors.blue;

  return (
    <>
      {isInvite && (
        <>
          <div
            role="presentation"
            onClick={() => reactivate()}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#D73619',
              fontWeight: '500',
            }}
            data-gid="44541094"
          >
            <HighlightOff
              style={{ marginRight: '4px', color: '#D73619' }}
              data-gid="84850402"
            />
            Dismiss
          </div>
          <div
            role="presentation"
            onClick={() => reactivate()}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: Colors.blue,
              fontWeight: '500',
            }}
            data-gid="90088232"
          >
            <CheckCircleOutline
              style={{ marginRight: '4px', color: Colors.blue }}
              data-gid="60249750"
            />
            Accept
          </div>
        </>
      )}
      {shouldShowShareIcon && (
        <div
          style={{
            color: selected ? 'white' : Colors.blue,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => switchToShareModal()}
          onKeyDown={noop}
          role="button"
          tabIndex={0}
          data-gid="74893760"
        >
          <IosShare fontSize="medium" data-gid="81046603" />
          <Text
            style={{
              fontWeight: '500',
              textAlign: 'left',
              margin: '0 4px 0 .1vw',
              paddingTop: '5px',
            }}
            size="2"
            data-gid="99658256"
          >
            Share
          </Text>
        </div>
      )}
      {!entitledAccess &&
        extraction?.expiresAt &&
        extraction?.expirationResetCount >= 3 &&
        DateTime.fromISO(extraction.expiresAt) < DateTime.local() && (
          <div style={{ color: Colors.black }} data-gid="65305552">
            To reactivate,{' '}
            <a
              href="https://www.magnetforensics.com/resources/artifact-iq-upgrade/"
              data-gid="56807398"
              rel="noopener noreferrer"
              target="_blank"
            >
              upgrade to Pro.
            </a>
          </div>
        )}
      {!entitledAccess &&
        extraction?.expiresAt &&
        extraction?.expirationResetCount < 3 &&
        DateTime.fromISO(extraction.expiresAt) < DateTime.local() && (
          <div
            role="presentation"
            onClick={() => reactivate()}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: reactivateColor,
              fontWeight: '500',
            }}
            data-gid="90088232"
          >
            <Restore
              style={{ marginRight: '4px', color: reactivateColor }}
              data-gid="52003427"
            />
            Reactivate and Open
          </div>
        )}
    </>
  );
};

const ExtractionSelectionModalCard: React.FC<
  ExtractionSelectionModalCardProps
> = ({
  extraction,
  selected = false,
  onClick,
  isInvite,
  isInShareModal = false,
  disabled = false,
}) => {
  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const entitledAccess = entitledAccounts.length > 0 || hasActiveTrial;

  const classes = useStyles({
    entitledAccess: entitledAccess ?? false,
    expired: isISOTimestampExpired(extraction?.expiresAt ?? ''),
    selected,
    isInShareModal,
    disabled,
  });

  const [confirmContainerVisible, setConfirmContainerVisible] = useState(false);
  const [archiveExtraction] = useMutation(archiveExtractionMutation);
  const isComplete =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    extraction?.state === 'COMPLETE' && extraction?.aggregationsCompletedAt;
  const width = isComplete ? '100%' : `${extraction?.percentComplete || ''}%`;
  const state = extraction?.state;
  const extractionCompletedAt = extraction?.aggregationsCompletedAt
    ? getPrettyDateShortOffset(extraction?.aggregationsCompletedAt)
    : '';
  const lastRealActivityAt = extraction?.lastRealActivityAt
    ? getPrettyDateShortOffset(extraction?.lastRealActivityAt)
    : '';

  const extractionText = extractionProgressText(
    state,
    extractionCompletedAt,
    lastRealActivityAt,
    extraction?.percentComplete,
  );

  const extractionAccessType = extraction?.accessType;

  return (
    <>
      <ExtractionStatus extraction={extraction} data-gid="13098262" />
      <div
        className={classes.extractionCard}
        role="presentation"
        onClick={() => {
          if (extraction?.id && onClick) onClick(extraction.id);
        }}
        data-gid="14241224"
        data-extraction-id={extraction?.id}
      >
        <div className={classes.extractionCardTopContent} data-gid="43434993">
          <div className={classes.extractionLeftSide} data-gid="95724737">
            <Text size="2" weight={500} data-gid="59571032">
              Evidence ID
            </Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              data-gid="94689176"
            >
              <Text
                size="5"
                weight={500}
                data-gid="54714731"
                style={{ marginRight: 5 }}
              >
                {extraction?.evidenceId}
              </Text>
              {extractionAccessType && (
                <Chip
                  style={{ height: 25, borderRadius: 20, fontWeight: 400 }}
                  label={
                    extractionAccessType.includes('_')
                      ? LowerAllButFirstLetter(
                          extractionAccessType.split('_').join(' '),
                        )
                      : LowerAllButFirstLetter(extractionAccessType)
                  }
                  data-gid="26253922"
                />
              )}
              {extraction?.sharedTimeframe && (
                <Chip
                  style={{
                    height: 25,
                    borderRadius: 20,
                    fontWeight: 400,
                    marginLeft: 10,
                  }}
                  label="Scoped"
                  icon={<GpsFixed fontSize="small" data-gid="92462522" />}
                  data-gid="26253922"
                />
              )}
            </div>
            {extraction && (
              <div
                className={classes.extractionProgressContainer}
                data-gid="14350339"
              >
                <div
                  className={
                    selected
                      ? classes.selectedExtractionProgressBarContainer
                      : classes.extractionProgressBarContainer
                  }
                  data-gid="36105964"
                >
                  <ExtractionProgressBar
                    state={state}
                    width={width}
                    complete={!!extractionCompletedAt}
                    data-gid="69087787"
                  />
                </div>
                <div className={classes.progressText} data-gid="23752498">
                  <Text size="2" data-gid="32962904">
                    {extractionText}{' '}
                  </Text>
                </div>
              </div>
            )}
          </div>
          <div className={classes.extractionRightSide} data-gid="34822086">
            <Text size="3" data-gid="92990185">
              Phone: {extraction?.target.extra.model}
            </Text>
            <Text size="3" data-gid="87973795">
              Extraction Started:{' '}
              {DateTime.fromISO(
                extraction?.extractionStartedAt || '',
              ).toLocaleString(DateTime.DATE_MED)}{' '}
              {DateTime.fromISO(
                extraction?.extractionStartedAt || '',
              ).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
            </Text>
            <Text size="3" data-gid="26417545">
              Examiner Name: {extraction?.examinerName}
            </Text>
          </div>
        </div>
        <div
          className={classes.extractionButtonsWithConfirm}
          data-gid="17093207"
        >
          <div className={classes.extractionButtonList} data-gid="38487628">
            {/* Archive disabled for now
        <div
          role="presentation"
          className={`${classes.buttonItem}  ${
            selected ? classes.buttonItemWhenSelected : ''
          }`}
          onClick={() => {
            setConfirmContainerVisible(true);
          }}
          data-gid="93845882"
        >
          <Archive data-gid="62806968" />
          <div className={classes.archiveWord} data-gid="89009826">
            Archive
          </div>
        </div> */}
            <ExtractionActions
              selected={selected}
              disabled={disabled}
              extraction={extraction}
              isInvite={isInvite}
              isInShareModal={isInShareModal}
              data-gid="47194716"
            />
          </div>
          {confirmContainerVisible && (
            <div className={classes.confirmContainer} data-gid="59562995">
              Are you sure? Archived extractions are removed from view and
              can&apos;t be accessed from this list.
              <div
                role="presentation"
                className={`${classes.confirmYes}  ${
                  selected ? classes.confirmYesWhenSelected : ''
                }`}
                onClick={() => {
                  if (extraction?.id) {
                    archiveExtraction({
                      variables: {
                        input: { id: extraction.id, archive: true },
                      },
                    }).catch(() => setConfirmContainerVisible(false));
                  }
                  setConfirmContainerVisible(false);
                }}
                data-gid="89760501"
              >
                Yes
              </div>
              |
              <div
                role="presentation"
                className={classes.confirmCancel}
                onClick={() => {
                  setConfirmContainerVisible(false);
                }}
                data-gid="88435899"
              >
                Cancel
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExtractionSelectionModalCard;
