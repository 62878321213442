import { gql } from '@apollo/client';

export const UserInfo = gql`
  query getUserInfo {
    getUserInfo {
      id
      firstName
      lastName
      email
      accountId
      hasAcceptedTos
      role
      trialActiveUntil
      hasActiveTrial
      eligibleForTrial
      entitledAccounts {
        id
      }
      marketingOptIn
    }
  }
`;

export const getUserZendeskTokenQuery = gql`
  query getUserZendeskToken {
    getUserZendeskToken {
      coreToken
      chatToken
    }
  }
`;
