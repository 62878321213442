/* eslint-disable no-secrets/no-secrets */
import { gql } from '@apollo/client';

export const archiveExtractionMutation = gql`
  mutation updateExtractionArchive($input: ArchiveExtractionInput!) {
    updateExtractionArchive(input: $input) {
      isArchived
      id
    }
  }
`;

export const reactivateExtractionMutation = gql`
  mutation reactivateExtraction($input: ReactivateExtractionInput!) {
    reactivateExtraction(input: $input) {
      extraction {
        id
        expiresAt
      }
    }
  }
`;

export const updateMaskCsamMutation = gql`
  mutation updateExtractionMaskCsam(
    $input: UpdateExtractionMaskCsamInput!
    $action: GlobalAuditLogEventEnum!
  ) {
    updateExtractionMaskCsam(input: $input) {
      extraction {
        id
        maskCsam
      }
    }
    createAuditLogEvent(input: { action: $action, data: {} }) {
      auditLog {
        id
      }
    }
  }
`;

export const updateExtractionReviewInfo = gql`
  mutation updateExtractionReviewInfo(
    $input: UpdateExtractionReviewInfoInput!
  ) {
    updateExtractionReviewInfo(input: $input) {
      extraction {
        id
      }
    }
  }
`;

export const createCaseAndUpdateExtractionReviewInfo = gql`
  mutation createCaseAndUpdateExtractionReviewInfo(
    $input: CreateCaseAndUpdateExtractionReviewInfoInput!
  ) {
    createCaseAndUpdateExtractionReviewInfo(input: $input) {
      extraction {
        id
      }
    }
  }
`;
