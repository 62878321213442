import { gql } from '@apollo/client';

import { CoreSourceFileFields } from '../fragments';

export const getContactTypes = gql`
  query appByContactType($extractionId: UUID!, $searchTerm: String) {
    appsByContactType(extractionId: $extractionId, searchTerm: $searchTerm) {
      appDisplayName
      hits
    }
  }
`;

export const allContactsQuery = gql`
  query getAllContactsByExtractionId(
    $extractionId: UUID!
    $displayName: String
    $appNames: [String!]
    $sortField: AllContactsSortField!
    $ascending: Boolean!
    $page: Int
    $pageSize: Int
  ) {
    contactsByExtractionId(
      extractionId: $extractionId
      displayName: $displayName
      appNames: $appNames
      sortField: $sortField
      ascending: $ascending
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        totalEdges
      }
      edges {
        node {
          id
          metacontact {
            primaryDisplayName
          }
          app {
            id
            displayName
            iconUrl
          }
          appBundleId
          primaryDisplayName
          platformMessageCounts {
            count
            platform
          }
          platformCallCounts {
            count
            platform
          }
          taggedAt
          mostRecentMessageSent {
            timestamp
            app {
              displayName
            }
            appBundleId
          }
          mostRecentCallSent {
            id
            timestamp
          }
          attributes {
            ... on NameAttribute {
              key
              value
            }
            ... on PhoneNumberAttribute {
              number
            }
            ... on SocialHandleAttribute {
              userName
            }
          }
          proxyApp {
            id
            displayName
            iconUrl
          }
        }
      }
    }
  }
`;

export const topContactsQuery = gql`
  query getTopContactsByExtractionId(
    $extractionId: UUID!
    $timeframe: TimeFrame
  ) {
    contactsByExtractionId(
      extractionId: $extractionId
      page: 1
      pageSize: 9
      sortField: topContacts
      ascending: true
      timeframe: $timeframe
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          id
          primaryDisplayName
          metacontact {
            primaryDisplayName
          }
          platformMessageCounts {
            count
            platform
          }
          platformCallCounts {
            count
            platform
          }
        }
      }
    }
  }
`;

export const contactByIdAndExtractionIdQuery = gql`
  ${CoreSourceFileFields}
  query contactByIdAndExtractionId($contactById: ID!, $extractionId: ID!) {
    contactByIdAndExtractionId(id: $contactById, extractionId: $extractionId) {
      id
      isDeviceOwner
      primaryDisplayName
      metacontact {
        primaryDisplayName
      }
      taggedAt
      taggedBy {
        id
        firstName
        lastName
        email
      }
      platformMessageCounts {
        count
        platform
      }
      platformCallCounts {
        count
        platform
      }
      mostRecentMessageSent {
        id
        timestamp
        textContent
        app {
          displayName
        }
        appBundleId
        thread {
          id
          threadDisplayName
        }
        sender {
          primaryDisplayName
        }
        sourceFiles {
          ...CoreSourceFileFields
        }
      }
      mostRecentCallSent {
        id
        timestamp
        duration
        app {
          displayName
        }
        appBundleId
        sourceFiles {
          ...CoreSourceFileFields
        }
      }
      attributes {
        sourceFiles {
          ...CoreSourceFileFields
        }
        ... on PhoneNumberAttribute {
          number
        }
        ... on SocialHandleAttribute {
          userName
        }
        ... on MiscellaneousAttribute {
          key
          value
        }
      }
      sourceFiles {
        ...CoreSourceFileFields
      }
    }
  }
`;
