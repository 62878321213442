import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Colors,
  makeStyles,
  Modal,
  Text,
} from '@grayshift/cairn';
import { Badge, withStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';

import ExtractionSelectionModalCard from '../ExtractionSelectionModalCard';
import { extractionByIdQuery_extractionById } from '../graphql/generated/extractionByIdQuery';
import { hasReviewUser } from '../graphql/generated/hasReviewUser';
import { replayToReview } from '../graphql/generated/replayToReview';
import {
  createCaseAndUpdateExtractionReviewInfo,
  updateExtractionReviewInfo,
} from '../graphql/mutations/extraction';
import {
  getHasReviewUser,
  sendReplayToReview,
} from '../graphql/queries/reviewLink';
import { ErrorMessageBanner } from '../helpers/ErrorMessageBanner';
import {
  isReviewEnabledAtom,
  reviewButtonStatusAtom,
  sendToReviewModalControllerAtom,
  userInfoAtom,
} from '../lib/atoms';
import useCurrentExtractionId from '../lib/hookUseCurrentExtractionId';
import ReviewInformationBullets from './ReviewInformationBullets';
import SendToReview from './SendToReview';
import SignUpToReview from './SignUptoReview';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 10,
    left: -14,
    position: 'relative',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 10,
  },
}))(Badge);

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 1100,
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  reviewDecisionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '1rem',
  },
});

interface Props {
  extraction: extractionByIdQuery_extractionById | undefined | null;
}

const ReviewModal: React.FC<Props> = ({ extraction }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();

  const [sendToReviewDecision, setSendToReviewDecision] =
    useState<boolean>(false);
  const [selectedCase, setSelectedCase] = useState<string | null>();
  const [newCaseName, setNewCaseName] = useState<string | null>(null);
  const [caseTypeEnum, setCaseTypeEnum] = useState<string>('create');
  const [dontSendLoading, setDontSendLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [waitingForReviewSignup, setWaitingForReviewSignup] =
    useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [sendToReviewModalController, setSendToReviewModalController] =
    useRecoilState(sendToReviewModalControllerAtom);
  const [, setIsReviewEnabled] = useRecoilState(isReviewEnabledAtom);
  const [userInfo] = useRecoilState(userInfoAtom);
  const [, setReviewButtonStatus] = useRecoilState(reviewButtonStatusAtom);

  const { data, refetch: refetchReviewUser } =
    useQuery<hasReviewUser>(getHasReviewUser);

  const [replayReview] = useMutation<replayToReview>(sendReplayToReview);
  const [updateReviewInfo] = useMutation(updateExtractionReviewInfo);
  const [createCaseAndUpdateReviewInfo] = useMutation(
    createCaseAndUpdateExtractionReviewInfo,
  );

  const hasReviewUserBool = data?.hasReviewUser.hasReviewUser;

  if (!extraction || hasReviewUserBool === undefined)
    return <div data-gid="19187167" />;
  const deniedRequest = async (): Promise<void> => {
    setDontSendLoading(true);
    const res = await updateReviewInfo({
      variables: {
        input: {
          extractionId: currentExtractionId,
          reviewCaseId: null,
          isReviewEnabled: false,
          sendToReviewDecision: sendToReviewDecision ? false : null,
          accountId: userInfo.accountId,
        },
      },
    });
    if (res.data) {
      setDontSendLoading(false);
      setIsReviewEnabled(false);
      setSendToReviewModalController({ isOpen: false, isReplay: false });
    }
  };

  const associateCase = async (): Promise<void> => {
    setSendLoading(true);
    const res = await updateReviewInfo({
      variables: {
        input: {
          extractionId: currentExtractionId,
          reviewCaseId: selectedCase,
          isReviewEnabled: true,
          sendToReviewDecision: sendToReviewDecision ? true : null,
          accountId: userInfo.accountId,
        },
      },
    });
    if (sendToReviewModalController.isReplay) {
      await replayReview({
        variables: {
          extractionId: currentExtractionId,
        },
      });
    }
    if (res.data) {
      setSendLoading(false);
      setIsReviewEnabled(true);
      setSendToReviewModalController({ isOpen: false, isReplay: false });
      setReviewButtonStatus('open');
    }
  };

  const createCase = async (): Promise<void> => {
    setSendLoading(true);
    try {
      const res = await createCaseAndUpdateReviewInfo({
        variables: {
          input: {
            extractionId: currentExtractionId,
            reviewCaseName: newCaseName,
            isReviewEnabled: true,
            sendToReviewDecision: sendToReviewDecision ? true : null,
            accountId: userInfo.accountId,
          },
        },
      });
      if (sendToReviewModalController.isReplay) {
        await replayReview({
          variables: {
            extractionId: currentExtractionId,
          },
        });
      }
      if (res.data) {
        setSendLoading(false);
        setIsReviewEnabled(true);
        setSendToReviewModalController({ isOpen: false, isReplay: false });
        setReviewButtonStatus('open');
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : String(error);
      if (message === 'Payment required') {
        setErrorMsg(
          // The email in this line is not PII
          /* eslint-disable pii/no-email */
          "Cannot create additional case. You've reached the maximum number of Magnet Review cases for your license. To upload additional cases, contact your Sales Representative or email us at sales@magnetforensics.com, or delete one of your existing cases.",
          /* eslint-enable pii/no-email */
        );
      } else {
        setErrorMsg('Something went wrong, please try again later.');
      }
      setSendLoading(false);
    }
  };

  return (
    <div data-gid="55214174">
      <Modal
        open={sendToReviewModalController.isOpen}
        disableBackdropClick
        data-gid="63834774"
      >
        <div className={classes.modalContentContainer} data-gid="32408044">
          <div data-gid="25275100">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}
              data-gid="26191757"
            >
              <Text
                size="6"
                weight={500}
                align="center"
                style={{ margin: '0 5px 15px 0' }}
                data-gid="27001806"
              >
                Send Extraction to Magnet REVIEW
              </Text>
              <StyledBadge
                badgeContent="Beta"
                color="primary"
                data-gid="78300562"
              />
            </div>
            {errorMsg.length > 0 && (
              <div style={{ marginBottom: '1rem' }} data-gid="84954193">
                <ErrorMessageBanner text={errorMsg} data-gid="69099350" />
              </div>
            )}
            <div style={{ display: 'flex' }} data-gid="99605999">
              <div
                style={{ width: '60%', marginRight: '2rem' }}
                data-gid="98925170"
              >
                <ReviewInformationBullets data-gid="11996443" />
                <SignUpToReview
                  hasReviewUser={hasReviewUserBool}
                  userInfo={userInfo}
                  refetchReviewUser={refetchReviewUser}
                  waitingForReviewSignup={waitingForReviewSignup}
                  setWaitingForReviewSignup={setWaitingForReviewSignup}
                  data-gid="37981938"
                />
              </div>
              <div data-gid="27532318">
                <ExtractionSelectionModalCard
                  extraction={extraction}
                  key={extraction?.id}
                  disabled
                  data-gid="30029581"
                />
                <SendToReview
                  newCaseName={newCaseName}
                  setNewCaseName={setNewCaseName}
                  setSelectedCase={setSelectedCase}
                  caseTypeEnum={caseTypeEnum}
                  setCaseTypeEnum={setCaseTypeEnum}
                  hasReviewUser={hasReviewUserBool}
                  data-gid="35742535"
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    margin: '1rem 0',
                  }}
                  data-gid="89638740"
                >
                  <Button
                    onClick={deniedRequest}
                    disabled={dontSendLoading}
                    color="default"
                    style={{
                      color: '#1e6ee3',
                      marginRight: '10px',
                      cursor: 'pointer',
                      width: '12rem',
                    }}
                    data-gid="10656965"
                  >
                    {dontSendLoading ? (
                      <ScaleLoader
                        loading
                        color={Colors.white}
                        data-gid="57883331"
                      />
                    ) : (
                      <p data-gid="28996884">Don&apos;t Send to REVIEW</p>
                    )}
                  </Button>
                  <Tooltip
                    title={
                      !hasReviewUserBool
                        ? 'You must have a Review account matching your email to send an extraction to Review'
                        : ''
                    }
                    placement="top"
                    data-gid="75699909"
                  >
                    <div data-gid="85158417">
                      <Button
                        style={{ cursor: 'pointer', width: '10rem' }}
                        color="primary"
                        disabled={
                          (!selectedCase && !newCaseName) ||
                          !hasReviewUserBool ||
                          sendLoading
                        }
                        onClick={() =>
                          caseTypeEnum === 'associate'
                            ? associateCase()
                            : createCase()
                        }
                        data-gid="21857185"
                      >
                        {sendLoading ? (
                          <ScaleLoader
                            loading
                            color={Colors.white}
                            data-gid="76213119"
                          />
                        ) : (
                          <p data-gid="90434185">Send to REVIEW</p>
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
                <div
                  className={classes.reviewDecisionContainer}
                  data-gid="34905656"
                >
                  <Checkbox
                    checked={sendToReviewDecision}
                    onChange={() =>
                      setSendToReviewDecision(!sendToReviewDecision)
                    }
                    data-gid="11380612"
                  />
                  <Text
                    size="2"
                    weight={500}
                    align="center"
                    data-gid="33821878"
                  >
                    Apply this choice for extractions from this GrayKey for 30
                    Days.
                  </Text>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  data-gid="12148709"
                >
                  <Text size="1" weight={500} align="right" data-gid="73323488">
                    If sending to REVIEW, future extractions uploaded from this
                    GrayKey will be new cases in REVIEW under the ownership of{' '}
                    {userInfo.email}.
                  </Text>
                </div>
                {/* <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  data-gid="12148709"
                >
                  <Text
                    size="1"
                    weight={500}
                    align="center"
                    data-gid="73323488"
                  >
                    Automatic sending will be canceled for free users that have
                    hit their case limit.
                  </Text>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewModal;
