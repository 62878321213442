/* eslint-disable react/require-default-props */
import { makeStyles } from '@grayshift/cairn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import noop from 'lodash/noop';
import React, { ComponentType, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import captureException from './lib/captureException';
import { Taggable } from './Taggable';
import { useExtraction } from './useExtraction';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    position: 'relative',
    borderColor: theme.palette.divider,
    borderWidth: '1px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  taggableAbsolute: (props: { top?: number; right?: number }) => ({
    background: theme.palette.background.default,
    borderColor: theme.palette.divider,
    borderRadius: '4px',
    position: 'absolute',
    top: -theme.spacing(props.top ?? 1),
    right: theme.spacing(props.right ?? 1),
  }),
}));

interface ActionBarProps {
  position: 'absolute' | 'relative';
  isTagged: boolean;
  enableActionBar?: boolean;
  borderWhenTagged?: boolean;
  top?: number;
  right?: number;
  onTag?: (tagged: boolean) => Promise<void>;
  onTagError?: <T extends Error>(error: T) => Promise<void>;
  as?: ComponentType | keyof JSX.IntrinsicElements;
  cellAs?: ComponentType | keyof JSX.IntrinsicElements;
  className?: string;
  onClick?: () => void;
  role?: string;
}

export const ActionBar: React.FC<ActionBarProps> = ({
  position,
  children,
  isTagged,
  enableActionBar = false,
  top = 1,
  right = -1,
  onTag = async () => {},
  onTagError = async () => {},
  as: Wrapper = 'div',
  cellAs: Cell = 'div',
  borderWhenTagged = false,
  ...props
}) => {
  const classes = useStyles({ top, right });
  const [showActionBar, setShowActionBar] = useState(false);
  const [clueIsTagged, setClueIsTagged] = useState(isTagged);
  const [hovered, setHovered] = useState(false);
  const { readOnlyMode } = useExtraction();

  const handleMouseEnter = (): void => {
    setHovered(true);
  };

  const handleMouseExit = (): void => {
    setHovered(false);
  };

  const handleTag = useCallback(async () => {
    setClueIsTagged(!clueIsTagged);
    if (onTag) {
      try {
        await onTag(clueIsTagged);
      } catch (error) {
        if (onTagError) {
          await onTagError<Error>(error as Error);
        } else {
          captureException(error);
        }
      }
    }
  }, [clueIsTagged, onTag, onTagError]);

  useHotkeys(
    't',
    (e) => {
      e.preventDefault();
      handleTag().then(noop).catch(noop);
      // setClueIsTagged(!clueIsTagged);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
    },
    { enabled: hovered && !readOnlyMode },
    [clueIsTagged, showActionBar],
  );

  return (
    <Wrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      style={{
        position: 'relative',
        width: '100%',
        padding: 7,
      }}
      {...props}
      data-gid="43475322"
    >
      {children}
      <Cell
        className={`${classes.actionBar} ${
          position === 'absolute' ? classes.taggableAbsolute : ''
        }`}
        data-gid="99026566"
      >
        <Stack direction="row" data-gid="67798469">
          {enableActionBar && showActionBar && (
            <>
              <Taggable isTagged={false} data-gid="50333548" />
              <Taggable isTagged data-gid="28267355" />
              <Taggable isTagged={false} data-gid="40437290" />
            </>
          )}
          {enableActionBar && (
            <IconButton
              onClick={() => setShowActionBar(!showActionBar)}
              data-gid="75714033"
            >
              <MoreHorizIcon data-gid="47875489" />
            </IconButton>
          )}
          {(hovered || clueIsTagged || position === 'relative') && (
            <Taggable
              borderWhenTagged={borderWhenTagged}
              hovered={hovered}
              isTagged={clueIsTagged}
              onToggle={handleTag}
              data-gid="68374743"
            />
          )}
        </Stack>
      </Cell>
    </Wrapper>
  );
};

export const ActionBarTableRow: React.FC<ActionBarProps> = React.memo(
  ({ children, ...props }) => (
    <ActionBar
      as={TableRow}
      cellAs={({ children: cellChildren }) => (
        <TableCell align="center" data-gid="80646647">
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            data-gid="37597428"
          >
            {cellChildren}
          </div>
        </TableCell>
      )}
      {...props}
      data-gid="32642290"
    >
      {children}
    </ActionBar>
  ),
);
