import { ExtractionState } from './graphql/generated/globalTypes';

interface ProgressProps {
  state: ExtractionState | undefined;
  width: string | undefined;
  complete: boolean;
}
export default function ExtractionProgressBar({
  state,
  width,
  complete,
}: ProgressProps): JSX.Element {
  const progressBarStyle = {
    width: width === '%' ? '0%' : width,
    borderRadius: 10,
    height: '100%',
    backgroundColor: complete
      ? '#50c878'
      : state === ExtractionState.FAILED ||
        state === ExtractionState.STALLED ||
        state === ExtractionState.ABORTED
      ? '#F08080'
      : '#0e8beb',
  };
  return <div style={progressBarStyle} data-gid="26501272" />;
}
