import { makeStyles } from '@grayshift/cairn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React from 'react';

import { renderedMediaContext } from './lib/determineMediaRenderContext';
import { publicStaticFileURL } from './lib/publicFile';

const border = '1px solid black';

const useStyles = makeStyles({
  warningText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '15px',
    textAlign: 'center',
    alignItems: 'center',
    color: 'black',
    border,
    textShadow: '0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white',
    background: 'white',
    overflow: 'hidden',
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    background:
      'linear-gradient(to left top, #ffffff, #f6f6f9, #ecedf2, #e2e4ec, #d8dce6)',
    border: 'solid 1px #D2D6E1',
    borderRadius: '10px',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    minHeight: '130px',
    maxWidth: '380px',
    justifyContent: 'center',
    overflow: 'hidden',
    textAlign: 'center',
  },
  textDivider: {
    borderTop: border,
  },
  warningImg: {
    height: '35px',
    textShadow: '0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white',
  },
});

const notVisible = '/not_visible.png';

const ExplicitImagePlaceholder: React.FC<{
  mediaContext: renderedMediaContext;
  containsNudityConfidence: number;
}> = ({ mediaContext, containsNudityConfidence }) => {
  const classes = useStyles();
  return (
    <>
      {mediaContext === renderedMediaContext.NCMEC_MATCH && (
        <div className={classes.warningText} data-gid="69987008">
          <img
            className={classes.warningImg}
            alt=""
            src={publicStaticFileURL(notVisible)}
            data-gid="17923933"
          />
          <h4 data-gid="62335748">CSAM Detected</h4>
          <h5 data-gid="50488100">Matches Known CSAM Hash Set</h5>
          <h5 className={classes.textDivider} data-gid="12645836">
            Unmask CSAM to view
          </h5>
        </div>
      )}
      {mediaContext === renderedMediaContext.AI_CSAM_MATCH && (
        <div className={classes.warningText} data-gid="62026284">
          <img
            className={classes.warningImg}
            alt=""
            src={publicStaticFileURL(notVisible)}
            data-gid="68726656"
          />
          <h4 data-gid="15161479">Explicit Content</h4>
          <h6 data-gid="84452050">May be Sensitive or Disturbing</h6>
          <h5 className={classes.textDivider} data-gid="68064723">
            Unmask CSAM to view
          </h5>
        </div>
      )}
      {mediaContext === renderedMediaContext.AI_NUDITY_MATCH && (
        <div className={classes.warningText} data-gid="10941629">
          <img
            className={classes.warningImg}
            alt=""
            src={publicStaticFileURL(notVisible)}
            data-gid="20959181"
          />
          <h3 data-gid="51755769">
            Nudity Detected
            <h5 data-gid="53148804">
              Confidence:{' '}
              {Number(
                containsNudityConfidence
                  ? containsNudityConfidence * 100
                  : null,
              ).toFixed(0)}
              %
            </h5>
          </h3>
          <h5 className={classes.textDivider} data-gid="93048957">
            Click to view
          </h5>
        </div>
      )}
      {mediaContext === renderedMediaContext.FLAGGED_EXPLICIT && (
        <div className={classes.warningContainer} data-gid="34240950">
          <VisibilityOffIcon fontSize="large" data-gid="22478593" />
          <h2
            style={{ fontWeight: 'bold', marginTop: '10px', padding: '7px' }}
            data-gid="91820698"
          >
            Flagged as explicit and hidden
          </h2>
        </div>
      )}
    </>
  );
};

export default ExplicitImagePlaceholder;
