import { gql, useMutation } from '@apollo/client';
import { Button, Colors, makeStyles, Modal, Text } from '@grayshift/cairn';
import { CircularProgress } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { Alert, TextField } from '@mui/material';
import { FormEvent, ReactElement, useState } from 'react';

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'grid',
    flexDirection: 'column',
    width: 750,
    height: '45%',
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 20,
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'pointer',
    top: 8,
    color: '#9EA0A5',
  },

  inputStack: {
    display: 'flex',
    gap: '15px',
    width: '100%',
  },
  inputField: {
    height: 70,
    minHeight: 'auto',
    margin: 0,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '7px',
  },
});

interface ModalProps {
  onClose: () => void;
  refetchAndUpdateTable: () => void;
}

const addUser = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      ... on User {
        id
      }
      ... on PendingUser {
        id
      }
    }
  }
`;

export const AddUserModal = ({
  onClose,
  refetchAndUpdateTable,
}: ModalProps): ReactElement<ModalProps> => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const { email, firstName, lastName } = formValues;
  const [userCreated, setUserCreated] = useState('');

  const [addUserMutation, { loading, error }] = useMutation(addUser, {
    variables: {
      input: {
        firstName,
        lastName,
        email,
      },
    },
  });

  const handleAddUser = async (
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    const response = await addUserMutation();
    if (!response?.errors) {
      refetchAndUpdateTable();
      setUserCreated(
        `Success! If the new user doesn't have a log in already, we've sent an email to ${email} with any steps they need to follow to complete the process. You can add another user below or close to continue.`,
      );
      setFormValues({
        email: '',
        firstName: '',
        lastName: '',
      });
    }
  };

  return (
    <Modal open data-gid="98545328" onClose={onClose}>
      <div
        className={classes.modalContentContainer}
        role="presentation"
        data-gid="44802882"
      >
        <div
          style={{ margin: 'auto', verticalAlign: 'middle' }}
          data-gid="40586216"
        >
          <Text
            heading="h2"
            size="6"
            weight={500}
            align="center"
            style={{ padding: 20 }}
            data-gid="49653864"
          >
            Add User
          </Text>
          {error && (
            <Alert
              severity="error"
              style={{ marginBottom: 15 }}
              data-gid="27184710"
            >
              An error occurred while trying to add a new user. Please try again
              later.
            </Alert>
          )}
          {userCreated && (
            <Alert
              severity="success"
              style={{ marginBottom: 15 }}
              data-gid="62777910"
            >
              {userCreated}
            </Alert>
          )}
          <form onSubmit={handleAddUser} data-gid="81110917">
            <div className={classes.inputStack} data-gid="15982997">
              <TextField
                value={firstName || ''}
                label="First Name"
                type="firstName"
                className={classes.inputField}
                onChange={(e) => {
                  setFormValues({ ...formValues, firstName: e.target.value });
                }}
                data-gid="17388892"
              />{' '}
              <TextField
                value={lastName || ''}
                label="Last Name"
                type="lastName"
                className={classes.inputField}
                onChange={(e) => {
                  setFormValues({ ...formValues, lastName: e.target.value });
                }}
                data-gid="30014363"
              />{' '}
              <TextField
                value={email || ''}
                label="Email"
                type="email"
                className={classes.inputField}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value });
                }}
                data-gid="86723730"
              />
            </div>
            <Text
              size="3"
              color="textSecondary"
              style={{ marginBottom: 10 }}
              data-gid="93843485"
            >
              <span style={{ fontWeight: 500 }} data-gid="26778529">
                Note:
              </span>{' '}
              {`If the user you're adding does not have a log in, they will be marked as "Pending" and asked to register. You can then edit their permissions in User Management.`}
            </Text>
            <div style={{ textAlign: 'right' }} data-gid="28318885">
              <Button
                type="submit"
                color="primary"
                disabled={!firstName || !lastName || !email}
                data-gid="15460235"
              >
                {loading ? (
                  <div className={classes.loadingContainer} data-gid="73776251">
                    <CircularProgress
                      size={15}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        color: Colors.white,
                        marginBottom: '2px',
                      }}
                      data-gid="65430381"
                    />
                    <span data-gid="14606963">Adding User...</span>
                  </div>
                ) : (
                  `Add User`
                )}
              </Button>
            </div>
          </form>
          <Close
            onClick={onClose}
            className={classes.closeIcon}
            data-gid="10451246"
          />
        </div>
      </div>
    </Modal>
  );
};
