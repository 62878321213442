import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Colors,
  makeStyles,
  MenuItem,
  Select,
  Text,
} from '@grayshift/cairn';
import { Close } from '@mui/icons-material';
import { Switch } from '@mui/material';
import React, { useState } from 'react';

import { accountInfo_accountInfo } from './graphql/generated/accountInfo';
import { accountUsers_accountUsers } from './graphql/generated/accountUsers';
import { AccountUserRole } from './graphql/generated/globalTypes';
import { ErrorMessageBanner } from './helpers/ErrorMessageBanner';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 700,
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  licenseRolesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1.5rem 0',
  },
  licenseSwitchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  overprovisioned: {
    color: Colors.red,
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionsDropdown: {
    paddingTop: '1.75rem',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'pointer',
    top: 8,
    color: '#9EA0A5',
  },
});

const upsertAccountUser = gql`
  mutation upsertAccountUser($input: upsertAccountUserInput!) {
    upsertAccountUser(input: $input) {
      id
      role
      isEntitled
    }
  }
`;

export const AccountUserManagementEditModal: React.FC<{
  user: accountUsers_accountUsers;
  accountInfo: accountInfo_accountInfo | null;
  handleClose: () => void;
  refetchAndUpdateTable: () => void;
}> = ({ user, accountInfo, refetchAndUpdateTable, handleClose }) => {
  const classes = useStyles();
  const [isEntitled, setIsEntitled] = useState(user.isEntitled);
  const [submitError, setSubmitError] = useState('');
  const [accountUserRole, setAccountUserRole] = useState<string>(
    LowerAllButFirstLetter(user.role),
  );

  const [updatedUsedEntitlements, setUpdatedUsedEntitlements] = useState(
    accountInfo?.usedEntitlements,
  );

  const accountUserRoleEnum = Object.keys(AccountUserRole)
    .map((role) => LowerAllButFirstLetter(role))
    .sort((a) => {
      if (a === LowerAllButFirstLetter(user.role)) {
        return -1;
      }
      return 1;
    });

  const handleSelectChange = (newValue: string): void => {
    setAccountUserRole(newValue);
  };

  const [upsertAccountUserMutation] = useMutation(upsertAccountUser, {
    variables: {
      input: {
        userId: user.id,
        role: accountUserRole.toUpperCase(),
        isEntitled,
      },
    },
    onError: (e) => {
      setSubmitError(e.message);
      handleSelectChange(LowerAllButFirstLetter(user.role));
      setIsEntitled(user.isEntitled);
      setUpdatedUsedEntitlements(accountInfo?.usedEntitlements);
    },
  });

  if (!accountInfo || updatedUsedEntitlements === undefined) {
    return (
      <div className={classes.modalContentContainer} data-gid="45114570">
        <ErrorMessageBanner
          text="We're temporarily unable to process this request. Please try again."
          data-gid="96588427"
        />
        <Close
          onClick={handleClose}
          className={classes.closeIcon}
          data-gid="44720668"
        />
      </div>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setUpdatedUsedEntitlements(updatedUsedEntitlements + 1);
    } else {
      setAccountUserRole(LowerAllButFirstLetter(AccountUserRole.USER));
      setUpdatedUsedEntitlements(updatedUsedEntitlements - 1);
    }
    setIsEntitled(e.target.checked);
  };

  const handleSubmit = async (): Promise<void> => {
    const response = await upsertAccountUserMutation();
    if (!response?.errors) {
      refetchAndUpdateTable();
      handleClose();
    }
  };

  const checkSubmitDisabled = (): boolean => {
    // Disabled if nothing has changed
    if (
      isEntitled === user.isEntitled &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      accountUserRole.toUpperCase() === user.role
    ) {
      return true;
    }
    // Disabled if overprovisioned and attempting to add license
    return !!(
      updatedUsedEntitlements > accountInfo.availableEntitlements &&
      !user.isEntitled &&
      isEntitled
    );
  };

  return (
    <div className={classes.modalContentContainer} data-gid="45114570">
      <Text size="6" weight={500} align="center" data-gid="35249123">
        {user.firstName} {user.lastName}
      </Text>
      <Text size="4" weight={300} align="center" data-gid="17204930">
        {user.email}
      </Text>
      {submitError.length > 0 && (
        <div style={{ marginTop: '15px', padding: '10px' }} data-gid="11399204">
          <ErrorMessageBanner text={submitError} data-gid="55934422" />
        </div>
      )}
      <div className={classes.licenseRolesContainer} data-gid="91706810">
        <div data-gid="58322253">
          <Text size="6" weight={400} align="left" data-gid="94110647">
            License
          </Text>
          <Text size="3" weight={300} align="left" data-gid="61582183">
            Determine whether this user has a Pro license for{' '}
            <br data-gid="72843236" />
            your account. Pro users have access to more features.
          </Text>
        </div>
        <div data-gid="35901912">
          <div className={classes.licenseSwitchContainer} data-gid="48658911">
            <Switch
              checked={isEntitled}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'is entitled checkbox' }}
              data-gid="70149712"
            />
          </div>
          <div
            className={
              updatedUsedEntitlements > accountInfo.availableEntitlements
                ? classes.overprovisioned
                : ''
            }
            data-gid="55061877"
          >
            <Text size="2" weight={300} align="left" data-gid="24195815">
              {updatedUsedEntitlements}{' '}
              {updatedUsedEntitlements === 1 ? 'License' : 'licenses'} used of{' '}
              {accountInfo.availableEntitlements} Available
            </Text>
          </div>
        </div>
      </div>
      <div className={classes.licenseRolesContainer} data-gid="28587941">
        <div data-gid="23636964">
          <Text size="6" weight={400} align="left" data-gid="47664184">
            Account Role
          </Text>
          <Text size="3" weight={300} align="left" data-gid="78822206">
            Set this user&rsquo;s role within your account.{' '}
            <br data-gid="91501472" />
            <strong data-gid="81349759">Note:</strong> Admins must have a Pro
            license.
          </Text>
        </div>
        <div className={classes.actionsDropdown} data-gid="23963519">
          {user?.isExternalUser && (
            <Text size="4" weight={300} align="left" data-gid="72720787">
              External User
            </Text>
          )}
          {!user?.isExternalUser && (
            <Select
              style={{ backgroundColor: '#ffffff', width: 125 }}
              value={accountUserRole}
              renderValue={() => accountUserRole}
              disabled={!isEntitled}
              onChange={(e) => handleSelectChange(e.target.value)}
              data-gid="32856553"
            >
              {accountUserRoleEnum.map((action) => (
                <MenuItem key={action} value={action} data-gid="54820895">
                  {action}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </div>
      <div className={classes.submitButtonContainer} data-gid="15463119">
        <Button
          color="primary"
          disabled={checkSubmitDisabled()}
          onClick={handleSubmit}
          data-gid="72633599"
        >
          Submit
        </Button>
      </div>
      <Close
        onClick={handleClose}
        className={classes.closeIcon}
        data-gid="44720668"
      />
    </div>
  );
};
