/* eslint-disable no-console */
import { useApolloClient } from '@apollo/client';
import { useResetRecoilState } from 'recoil';

import {
  appFilterStateAtom,
  contactSortStateAtom,
  currentMemoIdAtom,
  currentMessageThreadIdAtom,
  mediaCategoryFilterAtom,
  textSearchAtom,
  timeFrameStateAtom,
} from './lib/atoms';

type ResetAppStateHookProps = {
  beforeRecoilResetHook?: () => Promise<void>;
};

export const useResetAppState = ({
  beforeRecoilResetHook,
}: ResetAppStateHookProps): {
  resetRecoilState: () => Promise<void>;
  resetApolloCache: () => Promise<void>;
} => {
  const apolloClient = useApolloClient();

  const resetAppFilters = useResetRecoilState(appFilterStateAtom);
  const resetTextSearches = useResetRecoilState(textSearchAtom);
  const resetTimeFrameFilters = useResetRecoilState(timeFrameStateAtom);
  const resetCurrentMessageThread = useResetRecoilState(
    currentMessageThreadIdAtom,
  );
  const resetCurrentMemo = useResetRecoilState(currentMemoIdAtom);
  const resetContactSortState = useResetRecoilState(contactSortStateAtom);
  const resetMediaCategoryFilter = useResetRecoilState(mediaCategoryFilterAtom);

  const resetApolloCache = async (): Promise<void> => {
    await apolloClient.clearStore();
  };

  const resetRecoilState = async (): Promise<void> => {
    if (beforeRecoilResetHook) {
      await beforeRecoilResetHook();
    }

    resetAppFilters();
    resetTextSearches();
    resetTimeFrameFilters();
    resetCurrentMessageThread();
    resetCurrentMemo();
    resetContactSortState();
    resetMediaCategoryFilter();
  };

  return { resetRecoilState, resetApolloCache };
};
