import { gql } from '@apollo/client';

export const tagImageClueMutation = gql`
  mutation tagImageClue($input: TagImageClueInput!) {
    tagImageClue(input: $input) {
      image {
        id
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const tagLocationClueMutation = gql`
  mutation tagLocationClue($input: TagLocationClueInput!) {
    tagLocationClue(input: $input) {
      location {
        id
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const removeClueTagMutation = gql`
  mutation RemoveClueTag($input: RemoveClueTagInput!) {
    removeClueTag(input: $input) {
      clueId
    }
  }
`;

export const removeMessagesClueTagsMutation = gql`
  mutation removeMessagesClueTags($input: RemoveMessagesClueTagsInput!) {
    removeMessagesClueTags(input: $input) {
      messages {
        id
        taggedAt
      }
    }
  }
`;

export const tagContactClueMutation = gql`
  mutation TagContactClue($input: TagContactClueInput!) {
    tagContactClue(input: $input) {
      contact {
        id
        primaryDisplayName
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const tagMessagesClueMutation = gql`
  mutation TagMessagesClueM($input: TagMessagesClueInput!) {
    tagMessagesClue(input: $input) {
      messages {
        id
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const tagCallClueMutation = gql`
  mutation tagCallClue($input: TagCallClueInput!) {
    tagCallClue(input: $input) {
      call {
        id
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const tagMemoClueMutation = gql`
  mutation tagMemoClue($input: TagMemoClueInput!) {
    tagMemoClue(input: $input) {
      memo {
        id
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const tagMediaClueMutation = gql`
  mutation tagMediaClue($input: TagMediaClueInput!) {
    tagMediaClue(input: $input) {
      media {
        ... on Image {
          id
          taggedAt
          taggedBy {
            id
            firstName
            lastName
            email
          }
        }
        ... on Video {
          id
          taggedAt
          taggedBy {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const tagInstalledAppClueMutation = gql`
  mutation tagInstalledAppClue($input: TagInstalledAppClueInput!) {
    tagInstalledAppClue(input: $input) {
      installedApp {
        id
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

// eslint-disable-next-line no-secrets/no-secrets
export const tagBrowserHistoryClueMutation = gql`
  mutation TagBrowserHistoryClue($input: TagBrowserHistoryClueInput!) {
    tagBrowserHistoryClue(input: $input) {
      browserHistory {
        id
        taggedAt
        taggedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
