import { TimelineEventTypes } from '../types';
import { ClueType } from './atoms';

export const eventTypeToClueType = (
  eventType: TimelineEventTypes,
): ClueType => {
  switch (eventType) {
    case 'Location':
      return ClueType.LOCATION;

    case 'Messages':
      return ClueType.MESSAGE;

    case 'Media':
      return ClueType.MEDIA;

    case 'Memos':
      return ClueType.MEMO;

    case 'Contact':
      return ClueType.CONTACT;

    case 'Calls':
      return ClueType.CALL;

    case 'InstalledApps':
      return ClueType.INSTALLED_APP;

    case 'BrowserHistory':
      return ClueType.BROWSER_HISTORY;

    default:
      return ClueType.MEMO;
  }
};
