type note = {
  document: {
    note: {
      noteText: string;
    };
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useMemoBody = (noteBody: string | null | undefined) => {
  let formattedNoteText: string | null | undefined;
  if (noteBody) {
    try {
      const result = JSON.parse(noteBody) as note;
      formattedNoteText = result.document.note.noteText;
    } catch (error) {
      const temp = document.createElement('div');
      // eslint-disable-next-line no-unsanitized/property, @typescript-eslint/no-unsafe-assignment, xss/no-mixed-html
      temp.innerHTML = noteBody;
      formattedNoteText = temp.textContent || '';
    }
  }

  return formattedNoteText?.length ? formattedNoteText : 'No memo body';
};
