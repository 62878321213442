import { DateTime } from 'luxon';

import { ExtractionState } from './graphql/generated/globalTypes';

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export function extractionProgressText(
  state: ExtractionState | undefined,
  completedAt: string,
  lastRealActivityAt: string,
  percentComplete: number | undefined,
): string {
  if (state === ExtractionState.COMPLETE && completedAt) {
    return `Extraction Upload Complete | ${completedAt}`;
  }
  if (state === ExtractionState.COMPLETE && !completedAt) {
    return `Processing Clues ...`;
  }
  if (state === ExtractionState.FAILED || state === ExtractionState.STALLED) {
    return `Extraction Upload Failed: ${percentComplete?.toFixed(0) || ''}%
      | ${lastRealActivityAt}`;
  }
  if (state === ExtractionState.ABORTED) {
    return `Extraction Upload Aborted: ${percentComplete?.toFixed(0) || ''}%
      | ${lastRealActivityAt}`;
  }
  if (state === ExtractionState.ABORTED_USER) {
    return `Extraction Upload Aborted: ${percentComplete?.toFixed(0) || ''}%
    | ${lastRealActivityAt}`;
  }
  if (state === ExtractionState.ABORTED_ERROR) {
    return `Extraction Upload Errored: ${percentComplete?.toFixed(0) || ''}%
    | ${lastRealActivityAt}`;
  }
  if (state === ExtractionState.ABORTED_FAILURE) {
    return `Extraction Upload Failed: ${percentComplete?.toFixed(0) || ''}%
    | ${lastRealActivityAt}`;
  }
  if (state === ExtractionState.ABORTED_DISCONNECT) {
    return `Extraction Upload Disconnected: ${
      percentComplete?.toFixed(0) || ''
    }%
    | ${lastRealActivityAt}`;
  }

  return `Extraction Upload Progress: ${percentComplete?.toFixed(0) || ''}%`;
}

export function generateTimeLeft(
  expiresAt: string | undefined,
  isExpired: boolean | string | undefined,
): string {
  if (!expiresAt || isExpired) return 'This extraction has expired.';

  const timeLeftDays = DateTime.fromISO(expiresAt).diffNow().as('days') > 1;
  const timeLeft = timeLeftDays ? 'day' : 'hour';

  return `This extraction expires in ${Math.round(
    DateTime.fromISO(expiresAt).diffNow().as(timeLeft),
  )} ${timeLeft}(s)`;
}
