/* eslint-disable sonarjs/cognitive-complexity */
import { Card, CardContent, makeStyles, Text } from '@grayshift/cairn';
import { TextProps } from '@grayshift/cairn/dist/components/Text';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PersonIcon from '@mui/icons-material/Person';
import find from 'lodash/find';
import max from 'lodash/max';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { LatestMessageWidgetQuery } from './graphql/generated/LatestMessageWidgetQuery';
import { MostContactedWidgetQuery } from './graphql/generated/MostContactedWidgetQuery';
import { RecentLocationWidgetQuery } from './graphql/generated/RecentLocationWidgetQuery';
import { AppIcon } from './helpers/AppIcons';
import {
  clueDetailsSlideoutDataAtom,
  ClueType,
  timeFrameStateAtom,
} from './lib/atoms';
import useCurrentExtractionId from './lib/hookUseCurrentExtractionId';
import {
  latestMessageWidgetQuery,
  mostContactedWidgetQuery,
  recentLocationWidgetQuery,
} from './lib/queryExtractionDashboard';
import { useExtraction } from './useExtraction';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';
import { WidgetSkeleton } from './WidgetSkeleton';

const useStyles = makeStyles({
  cardContent: {
    display: 'flex',
    height: '100%',
    '&:hover': {
      background: '#f7f7f7',
    },
    borderRadius: 10,
    padding: '5px',
    alignItems: 'center',
    overflow: 'hidden',
  },
  selectedCardContent: {
    display: 'flex',
    height: '100%',
    background: '#FFEFD5',
    '&:hover': {
      background: '#FFEFD5',
    },
    borderRadius: 10,
    padding: '5px',
    alignItems: 'center',
    overflow: 'hidden',
  },
  icon: {
    display: 'flex',
    padding: '1rem 1rem 1rem 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '0%',
    marginBottom: '0.5rem',
  },
  latestMessageDisplayName: {
    display: 'flex',
  },
});

// eslint-disable-next-line react/require-default-props
const Timestamp: React.FC<{ timestamp?: string } & TextProps> = ({
  timestamp,
  ...props
}) => (
  <Text color="textSecondary" {...props} data-gid="11029333">
    {timestamp && DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED)}{' '}
    {timestamp &&
      DateTime.fromISO(timestamp).toLocaleString(
        DateTime.TIME_WITH_SHORT_OFFSET,
      )}
  </Text>
);

const RecentLocationWidget: React.FC<{
  selectedWidgetClueId: null | string;
  setSelectedWidgetClueId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ selectedWidgetClueId, setSelectedWidgetClueId }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const { pollInterval } = useExtraction();
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { data, loading } =
    useQueryWithErrorBoundary<RecentLocationWidgetQuery>(
      recentLocationWidgetQuery,
      {
        variables: {
          extractionId: currentExtractionId,
          timeframe:
            globalTimeframe?.start && globalTimeframe?.end
              ? {
                  startDate: globalTimeframe?.start.toISOString(),
                  endDate: globalTimeframe?.end.toISOString(),
                }
              : null,
        },
        pollInterval,
      },
    );

  const [clueDetailsSlideOutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );

  const recentLocation = data?.extractionById?.recentLocation;
  const { clueId, slideoutOpen, clueType } = clueDetailsSlideOutData;
  const selectedWidget = selectedWidgetClueId === clueId && slideoutOpen;

  const selectedWidgetLocation =
    selectedWidget && clueType === ClueType.LOCATION;

  return (
    <Card
      onClick={() => {
        if (recentLocation?.id) {
          setSelectedWidgetClueId(recentLocation?.id);
          setClueDetailsSlideoutData({
            slideoutOpen: true,
            clueId: recentLocation.id,
            clueType: ClueType.LOCATION,
          });
        }
      }}
      style={{
        flex: '1 1 0%',
        margin: '.5rem 0 .5rem .5rem',
        cursor: !loading && !recentLocation ? 'auto' : 'pointer',
      }}
      role="presentation"
      data-gid="79428334"
    >
      <CardContent
        style={{ padding: '0.75rem' }}
        flexDirection="column"
        data-gid="14535096"
      >
        <Text weight={500} data-gid="70745354">
          Most Recent Location
        </Text>
        <div
          className={
            selectedWidgetLocation
              ? classes.selectedCardContent
              : classes.cardContent
          }
          data-gid="46636397"
        >
          {loading && !recentLocation && <WidgetSkeleton data-gid="85156300" />}
          {!loading && !recentLocation && (
            <Text color="textSecondary" data-gid="67364756">
              No location available within timeframe.
            </Text>
          )}
          {!loading && recentLocation && (
            <>
              <div
                className={classes.icon}
                style={{ textAlign: 'center' }}
                data-gid="18998326"
              >
                <i
                  className="fas fa-map-marker-alt"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#6ABF46',
                    borderRadius: '100%',
                    fontSize: '2.5rem',
                    width: 40,
                  }}
                  data-gid="27508325"
                />
              </div>
              <div data-gid="59085751">
                <Text weight={500} color="primary" data-gid="25351704">
                  {recentLocation.latitude} {recentLocation.longitude}
                </Text>
                {recentLocation.timestamp && (
                  <Timestamp
                    size="2"
                    timestamp={recentLocation.timestamp}
                    color={
                      selectedWidgetLocation ? 'textPrimary' : 'textSecondary'
                    }
                    data-gid="63786457"
                  />
                )}
                <Text
                  color={
                    selectedWidgetLocation ? 'textPrimary' : 'textSecondary'
                  }
                  data-gid="37168899"
                >
                  {recentLocation.app?.displayName ??
                    recentLocation.appBundleId}
                </Text>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const MostContactedWidget: React.FC<{
  selectedWidgetClueId: null | string;
  setSelectedWidgetClueId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ selectedWidgetClueId, setSelectedWidgetClueId }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const { pollInterval } = useExtraction();
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { data, loading } = useQueryWithErrorBoundary<MostContactedWidgetQuery>(
    mostContactedWidgetQuery,
    {
      variables: {
        extractionId: currentExtractionId,
        timeframe:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
      },
      pollInterval,
    },
  );

  const [clueDetailsSlideOutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );

  const mostContacted = data?.extractionById?.mostContacted;
  const { clueId, slideoutOpen, clueType } = clueDetailsSlideOutData;
  const selectedWidget = selectedWidgetClueId === clueId && slideoutOpen;

  const selectedWidgetContact = selectedWidget && clueType === ClueType.CONTACT;

  const calls = find(mostContacted?.platformCallCounts, ['platform', '__total'])
    ?.count;
  const messages = find(mostContacted?.platformMessageCounts, [
    'platform',
    '__total',
  ])?.count;

  const mostRecentCommunication = max([
    mostContacted?.mostRecentCallSent?.timestamp,
    mostContacted?.mostRecentMessageSent?.timestamp,
  ]);

  return (
    <Card
      onClick={() => {
        if (mostContacted && mostContacted.id) {
          setSelectedWidgetClueId(mostContacted.id);
          setClueDetailsSlideoutData({
            slideoutOpen: true,
            clueId: mostContacted.id,
            clueType: ClueType.CONTACT,
          });
        }
      }}
      role="presentation"
      style={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        margin: '.5rem',
        cursor: !loading && !mostContacted ? 'auto' : 'pointer',
      }}
      data-gid="45578124"
    >
      <CardContent
        style={{ padding: '0.75rem' }}
        flexDirection="column"
        data-gid="43828076"
      >
        <Text weight={500} data-gid="44424740">
          Most Contacted
        </Text>
        <div
          className={
            selectedWidgetContact
              ? classes.selectedCardContent
              : classes.cardContent
          }
          data-gid="41606339"
        >
          {loading && !mostContacted && <WidgetSkeleton data-gid="62335775" />}{' '}
          {!loading && !mostContacted && (
            <Text color="textSecondary" data-gid="67364756">
              No contact available within timeframe.
            </Text>
          )}
          {!loading && mostContacted && (
            <>
              <div className={classes.icon} data-gid="32791153">
                <PersonIcon
                  style={{ fontSize: '40px', color: '#1665D8' }}
                  data-gid="74955061"
                />
              </div>
              <div data-gid="48677973">
                <Text color="primary" weight={500} data-gid="34115016">
                  {mostContacted.primaryDisplayName}
                </Text>
                <Text
                  color={
                    selectedWidgetContact ? 'textPrimary' : 'textSecondary'
                  }
                  data-gid="68816246"
                >
                  {(calls ?? 0) + (messages ?? 0)} Total Communications
                </Text>
                {mostRecentCommunication && (
                  <Timestamp
                    size="2"
                    color={
                      selectedWidgetContact ? 'textPrimary' : 'textSecondary'
                    }
                    timestamp={mostRecentCommunication}
                    data-gid="46075169"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const LatestMessageWidget: React.FC<{
  selectedWidgetClueId: null | string;
  setSelectedWidgetClueId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ selectedWidgetClueId, setSelectedWidgetClueId }) => {
  const classes = useStyles();
  const [currentExtractionId] = useCurrentExtractionId();
  const { pollInterval } = useExtraction();
  const timeFrameState = useRecoilValue(timeFrameStateAtom);
  const { globalTimeframe } = timeFrameState || {};

  const { data, loading } = useQueryWithErrorBoundary<LatestMessageWidgetQuery>(
    latestMessageWidgetQuery,
    {
      variables: {
        extractionId: currentExtractionId,
        timeframe:
          globalTimeframe?.start && globalTimeframe?.end
            ? {
                startDate: globalTimeframe?.start.toISOString(),
                endDate: globalTimeframe?.end.toISOString(),
              }
            : null,
      },
      pollInterval,
    },
  );

  const [clueDetailsSlideOutData, setClueDetailsSlideoutData] = useRecoilState(
    clueDetailsSlideoutDataAtom,
  );

  const { latestMessage, mostContacted } = data?.extractionById ?? {};
  const { sender, sentByOwner } = latestMessage ?? {};

  const senderThumbnail = latestMessage?.sender.avatarImages[0] ?? null;
  const { clueId, slideoutOpen, clueType } = clueDetailsSlideOutData;
  const selectedWidget = selectedWidgetClueId === clueId && slideoutOpen;

  const selectedWidgetMessage = selectedWidget && clueType === ClueType.MESSAGE;

  return (
    <Card
      onClick={() => {
        if (latestMessage && latestMessage?.id) {
          setSelectedWidgetClueId(latestMessage?.id);
          setClueDetailsSlideoutData({
            slideoutOpen: true,
            clueId: latestMessage.id,
            clueType: ClueType.MESSAGE,
          });
        }
      }}
      role="presentation"
      style={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        margin: '.5rem .5rem .5rem 0',
        cursor: !loading && !latestMessage ? 'auto' : 'pointer',
      }}
      data-gid="62821000"
    >
      <CardContent
        style={{ padding: '0.75rem' }}
        flexDirection="column"
        data-gid="26179650"
      >
        <Text weight={500} data-gid="69876883">
          Latest Message
        </Text>
        <div
          className={
            selectedWidgetMessage
              ? classes.selectedCardContent
              : classes.cardContent
          }
          data-gid="23814928"
        >
          {!latestMessage && loading && <WidgetSkeleton data-gid="46683319" />}
          {!loading && !latestMessage && (
            <Text color="textSecondary" data-gid="67364756">
              No messages available within timeframe.
            </Text>
          )}
          {!loading && latestMessage && (
            <>
              {latestMessage.sender.avatarImages.length > 0 ? (
                <div className={classes.icon} data-gid="56186899">
                  {senderThumbnail?.thumbnailUrl && (
                    <img
                      src={senderThumbnail.thumbnailUrl}
                      height="50px"
                      width="50px"
                      style={{ borderRadius: '100%' }}
                      alt="avatar"
                      data-gid="50787202"
                    />
                  )}
                </div>
              ) : (
                <div className={classes.icon} data-gid="18230275">
                  <AppIcon
                    iconUrl={latestMessage.app?.iconUrl ?? 'default'}
                    height="40px"
                    width="40px"
                    displayName=""
                  />
                </div>
              )}

              <div data-gid="37345504">
                <div
                  className={classes.latestMessageDisplayName}
                  data-gid="45228919"
                >
                  <Text color="primary" weight={500} data-gid="79645928">
                    {latestMessage.sender.primaryDisplayName}
                  </Text>
                  {sender && sentByOwner ? (
                    <CallMadeIcon
                      sx={{
                        margin: '6px 0 0 4px',
                        fontSize: '12px',
                        color: selectedWidgetMessage ? undefined : '#9EA0A5',
                      }}
                      data-gid="60992464"
                    />
                  ) : (
                    <CallReceivedIcon
                      sx={{
                        margin: '6px 0 0 4px',
                        fontSize: '12px',
                        color: selectedWidgetMessage ? undefined : '#9EA0A5',
                      }}
                      data-gid="26457430"
                    />
                  )}
                </div>
                <Text
                  color={
                    selectedWidgetMessage ? 'textPrimary' : 'textSecondary'
                  }
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                  data-gid="90316243"
                >
                  {latestMessage.textContent}
                </Text>
                {mostContacted?.mostRecentMessageSent?.timestamp && (
                  <Timestamp
                    size="2"
                    color={
                      selectedWidgetMessage ? 'textPrimary' : 'textSecondary'
                    }
                    timestamp={latestMessage.timestamp}
                    data-gid="82759510"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export const ExtractionDashboardWidgets: React.FC = () => {
  const classes = useStyles();

  const [selectedWidgetClueId, setSelectedWidgetClueId] = useState<
    null | string
  >(null);

  return (
    <div className={classes.wrapper} data-gid="13793290">
      <LatestMessageWidget
        selectedWidgetClueId={selectedWidgetClueId}
        setSelectedWidgetClueId={setSelectedWidgetClueId}
        data-gid="85214356"
      />
      <MostContactedWidget
        selectedWidgetClueId={selectedWidgetClueId}
        setSelectedWidgetClueId={setSelectedWidgetClueId}
        data-gid="50612054"
      />
      <RecentLocationWidget
        selectedWidgetClueId={selectedWidgetClueId}
        setSelectedWidgetClueId={setSelectedWidgetClueId}
      />
    </div>
  );
};
