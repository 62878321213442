/* eslint-disable react/require-default-props, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, sonarjs/no-duplicate-string, security/detect-unsafe-regex */

import { Colors, makeStyles } from '@grayshift/cairn';
import { CircularProgress } from '@material-ui/core';
import { Apps } from '@mui/icons-material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { Tooltip, Typography } from '@mui/material';
import classnames from 'classnames';
import timeFormat from 'hh-mm-ss';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';
import React, { RefObject, useRef, useState } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { useInView } from 'react-intersection-observer';
import { Marker, StaticMap } from 'react-map-gl';
import { useRecoilState } from 'recoil';

import { getDomain } from './BrowserHistoryUrlColumn';
import ExplicitImagePlaceholder from './ExplicitImagePlaceholder';
import {
  reportTaggedClues_allTaggedClues_edges_node_BrowserHistory_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_Call_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_Contact_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_media_Image_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_DefaultLocation_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_Image_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_InstalledApp_taggedBy,
  reportTaggedClues_allTaggedClues_edges_node_Memo_taggedBy,
} from './graphql/generated/reportTaggedClues';
import { StaticAppIcon } from './helpers/AppIcons';
import { clueDetailsSlideoutDataAtom } from './lib/atoms';
import determineCallStatus from './lib/determineCallStatus';
import determineMediaRenderContext, {
  renderedMediaContext,
} from './lib/determineMediaRenderContext';
import { publicStaticFileURL } from './lib/publicFile';
import { ReportEvent, ReportEventData, TimelineEvent } from './types';
import { useExtraction } from './useExtraction';
import { useMemoBody } from './useMemoBody';

const useStyles = makeStyles({
  '@keyframes pulseAnimation': {
    '100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
  pulse: {
    height: 24,
    width: 24,
    background: 'linear-gradient(#1665D8, #407ed7)',
    position: 'relative',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: Colors.blue,
      borderRadius: '50%',
      zIndex: 1,
      opacity: 0.7,
      animation: '$pulseAnimation 2s 1s ease-out infinite',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: Colors.blue,
      borderRadius: '50%',
      zIndex: 1,
      opacity: 0.7,
      animation: '$pulseAnimation 2s ease-out infinite',
    },
  },

  mapbox: {
    borderRadius: 10,
    overflow: 'hidden',
  },

  inRangeItem: {
    borderRadius: 10,
    marginTop: 4,
    width: '100%',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
    '&:first-child': {
      marginTop: 0,
    },
    height: ({ fullHeight = true }: { fullHeight?: boolean }) =>
      fullHeight ? '100%' : 'auto',
  },

  selectedInRangeItem: {
    borderRadius: 10,
    marginTop: 4,
    background: '#FFEFD5',
    '&:hover': {
      background: '#FFEFD5',
    },

    '&:first-child': {
      marginTop: 0,
    },
    height: '100%',
    width: '100%',
  },
  chatBubble: {
    padding: '7px 14px',
    // wordBreak: 'break-word',
    borderRadius: 15,
    background: Colors.lightDivider,
    width: '100%',
  },
  sentByOwner: {
    color: Colors.white,
    background: '#0e8beb',
  },
  text: {
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  mediaItemsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  flyoutImg: {
    borderRadius: 10,
  },
  attachedImage: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {},
  avatars: {
    display: 'flex',
    listStyleType: 'none',
    marginLeft: 5,
    padding: 0,
    flexDirection: 'row',
  },
  memoWrap: {
    wordBreak: 'break-word',
    '& p': {
      display: '-webkit-box',
      lineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxOrient: 'vertical',
      wordWrap: 'break-word',
    },
  },
  loadingOrNoDataContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoHoverContainer: {
    height: 144,
    width: '100%',
    borderRadius: 10,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  videoDuration: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    padding: '1px',
    borderRadius: '5px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#D3D3D3',
  },
});

type BaseProps = React.ComponentPropsWithRef<'div'> & {
  event: TimelineEvent;
  icon?: React.ReactNode;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  ref?: RefObject<HTMLDivElement>;
  hideIcon?: boolean;
  showPulse?: boolean;
  selectedClue?: boolean;
  fullHeight?: boolean;
  showTaggedBy?: boolean;
};

const BaseCard: React.FC<BaseProps> = ({
  event: { data },
  icon: Icon,
  children,
  onClick,
  hideIcon,
  ...props
}) => {
  const classes = useStyles({ fullHeight: props.fullHeight });

  const [clueDetailsSlideoutData] = useRecoilState(clueDetailsSlideoutDataAtom);

  const { clueId, slideoutOpen } = clueDetailsSlideoutData;

  const selectedClue = clueId === data.id && slideoutOpen;

  return (
    <div
      role="listitem"
      style={{
        padding: '1rem 1rem 1rem 0.75rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      key={data.id}
      className={
        selectedClue ? classes.selectedInRangeItem : classes.inRangeItem
      }
      onClick={onClick}
      {...props}
      data-gid="91300850"
    >
      {hideIcon ? null : (
        <div className={classes.icon} data-gid="26208860">
          {Icon || null}
        </div>
      )}
      <div
        style={{
          paddingLeft: '1rem',
          width: '100%',
          overflow: 'hidden',
          display: 'inline-grid',
        }}
        data-gid="36217858"
      >
        {children}
      </div>
    </div>
  );
};

const CardFooter: React.FC<{
  timestamp?: string;
  selectedClue?: boolean;
  taggedBy?:
    | null
    | reportTaggedClues_allTaggedClues_edges_node_Call_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_Memo_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_Contact_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_media_Image_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_DefaultAppMessage_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_DefaultLocation_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_Image_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_InstalledApp_taggedBy
    | reportTaggedClues_allTaggedClues_edges_node_BrowserHistory_taggedBy;
}> = ({ timestamp, selectedClue, taggedBy }) => (
  <div data-gid="81804081" style={{ width: '100%' }}>
    {timestamp && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
        data-gid="43344227"
      >
        <Typography
          color={selectedClue ? undefined : '#9EA0A5'}
          style={{ paddingTop: 5 }}
          fontSize={12}
          data-gid="30491081"
        >
          {DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED)}
        </Typography>
        <Typography
          color={selectedClue ? undefined : '#9EA0A5'}
          style={{ paddingTop: 5 }}
          fontSize={12}
          data-gid="76507956"
        >
          {DateTime.fromISO(timestamp).toLocaleString(
            DateTime.TIME_WITH_SHORT_OFFSET,
          )}
        </Typography>
      </div>
    )}
    {taggedBy && (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-gid="99735989"
      >
        <Typography
          color={selectedClue ? undefined : '#9EA0A5'}
          style={{ paddingTop: 5 }}
          fontSize={12}
          data-gid="76507956"
        >
          Tagged By:
        </Typography>
        <Typography
          color={selectedClue ? undefined : '#9EA0A5'}
          style={{ paddingTop: 5 }}
          fontSize={12}
          data-gid="76507956"
        >
          {taggedBy.firstName} {taggedBy.lastName}
        </Typography>
      </div>
    )}
  </div>
);

const MessageCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;
  const containsAttachments =
    data && data.thumbnails && data.thumbnails?.length > 0;
  const numberOfAttachments = data.thumbnails?.length || 0;
  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="96284250"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.displayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="18814836"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Message Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <div data-gid="32662629">
              <StaticAppIcon
                displayName={data.displayName ?? 'default'}
                height="24px"
                width="24px"
              />
            </div>
          )}
        </div>
      }
      data-gid="36836432"
    >
      <div className={classes.content} data-gid="12155132">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          data-gid="33373816"
        >
          <Typography
            color={selectedClue ? undefined : '#9EA0A5'}
            fontSize={12}
            data-gid="76822727"
          >
            {data.sender}
          </Typography>
          {data.sender && data.sentByOwner ? (
            <CallMadeIcon
              sx={{
                marginLeft: '4px',
                fontSize: '14px',
                color: selectedClue ? undefined : '#9EA0A5',
              }}
              data-gid="96110010"
            />
          ) : (
            <CallReceivedIcon
              sx={{
                marginLeft: '4px',
                fontSize: '14px',
                color: selectedClue ? undefined : '#9EA0A5',
              }}
              data-gid="81478633"
            />
          )}
        </div>
        <div
          className={classnames(classes.chatBubble, {
            [classes.sentByOwner]: data.sentByOwner,
          })}
          data-gid="60580713"
        >
          <Typography
            className={classes.text}
            fontSize={14}
            data-gid="65729766"
          >
            {data.textContent?.replace(/\uFFFC/, '')}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            data-gid="44573410"
          >
            {containsAttachments && (
              <>
                <Typography
                  color={data.sentByOwner ? 'white' : '#9EA0A5'}
                  fontSize={12}
                  style={{ marginTop: 2 }}
                  data-gid="71111824"
                >
                  {data.thumbnails?.length === 1
                    ? 'Attachment (1)'
                    : `Attachments (${numberOfAttachments})`}
                </Typography>
                <div className={classes.avatars} data-gid="65922401">
                  {data?.thumbnails?.map((attachedImage) => (
                    <div
                      style={{
                        backgroundImage: `url(${attachedImage ?? ''})`,
                        height: 20,
                        width: 20,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        borderRadius: 10,
                        marginRight: 5,
                      }}
                      data-gid="68677094"
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {data.timestamp && (
        <CardFooter
          selectedClue={selectedClue}
          timestamp={data.timestamp}
          data-gid="85084578"
          taggedBy={(data as ReportEventData).taggedBy}
        />
      )}
    </BaseCard>
  );
};

const LocationCard: React.FC<BaseProps> = (props) => {
  // Allows us to hide resource intensive renders such as Mapbox
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const { event, showPulse, selectedClue, fullHeight, ...rest } = props;
  const classes = useStyles({ fullHeight });
  const tipRef = useRef(null);
  const [tooltipInView, setTooltipInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting
      ? setTooltipInView(true)
      : setTooltipInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const tooltipRef = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (tooltipRef) observer.observe(tooltipRef);
    return () => {
      if (tooltipRef) observer.unobserve(tooltipRef);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(rest, 'showPulse')}
      event={event}
      // ref={scrollToRef}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="13392272"
        >
          {event.data.iconUrl ? (
            <Tooltip
              title={event.data.displayName || event.data.appDisplayName || ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: tooltipInView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="31312865"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={event.data.iconUrl ?? undefined}
                alt="Location Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <i
              className="fas fa-map-marker-alt"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: Colors.blue,
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="70494969"
            />
          )}
        </div>
      }
      data-gid="46885052"
    >
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-gid="70122466"
      >
        {inView && event.data.latitude && event.data.longitude ? (
          <StaticMap
            key={event.data.id}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={process.env.REACT_APP_MAPBOX_SATELLITE_STYLE}
            latitude={parseFloat(event.data.latitude)}
            longitude={parseFloat(event.data.longitude)}
            zoom={16}
            width="100%"
            height="144px"
            className={classes.mapbox}
            attributionControl={false}
            reuseMaps
            data-gid="11774485"
          >
            <Marker
              latitude={parseFloat(event.data.latitude)}
              longitude={parseFloat(event.data.longitude)}
              offsetTop={-20}
              data-gid="57332450"
            >
              <img
                height="10%"
                width="10%"
                src={publicStaticFileURL('/marker.png')}
                alt="map-marker"
                data-gid="63685723"
              />
            </Marker>
          </StaticMap>
        ) : (
          <div style={{ height: '144px', width: '100%' }} data-gid="62861721" />
        )}
        {event.data.timestamp && (
          <CardFooter
            selectedClue={selectedClue}
            timestamp={event.data.timestamp}
            taggedBy={(event.data as ReportEventData).taggedBy ?? null}
            data-gid="55984859"
          />
        )}
      </div>
    </BaseCard>
  );
};

const MediaCard: React.FC<BaseProps> = (props) => {
  const { event, showPulse, selectedClue, fullHeight, ...rest } = props;
  const classes = useStyles({ fullHeight });
  const { data: extraction } = useExtraction();
  const maskCsam = extraction?.extractionById?.maskCsam;
  const {
    mediaType,
    mediaUrl,
    timestamp,
    originalUrl,
    iconUrl,
    displayName,
    appDisplayName,
    imageContext,
    durationSeconds,
  } = event.data;
  const {
    ncmecMatch = false,
    containsCSAM = false,
    containsNudity = false,
    containsNudityConfidence,
    isExplicit = false,
  } = imageContext || {};
  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  const mediaImage = (
    <div
      style={{
        backgroundImage: `url(${mediaUrl ?? ''})`,
        height: 144,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: 10,
      }}
      data-gid="68278033"
    />
  );

  const mediaContext = determineMediaRenderContext(
    ncmecMatch,
    containsCSAM,
    containsNudity,
    maskCsam || false,
    isExplicit,
  );

  const showMedia = mediaContext === renderedMediaContext.ACTUAL_IMAGE;
  return (
    <BaseCard
      {...omit(rest, 'showPulse')}
      event={event}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="30647960"
        >
          {iconUrl ? (
            <Tooltip
              title={appDisplayName || displayName || ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="93675198"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={iconUrl ?? undefined}
                alt="Media Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <i
              className={
                mediaType === 'Image' ? 'far fa-images' : 'fas fa-film'
              }
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: Colors.blue,
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="42998603"
            />
          )}
        </div>
      }
      data-gid="87162419"
    >
      <div className={classes.mediaItemsContainer} data-gid="65597643">
        {mediaType === 'Image' && showMedia && mediaImage}
        {mediaType === 'Video' && showMedia && (
          <HoverVideoPlayer
            className={classes.videoHoverContainer}
            videoClassName={classes.videoHoverContainer}
            loadingOverlayWrapperClassName={classes.loadingOrNoDataContainer}
            videoSrc={originalUrl || ''}
            pausedOverlay={
              <div data-gid="75086070">
                <div className={classes.videoDuration} data-gid="96274253">
                  {durationSeconds && (
                    <Typography fontSize={13} data-gid="98337799">
                      {timeFormat.fromS(
                        parseInt(durationSeconds.toFixed(2), 10),
                        'mm:ss',
                      )}
                    </Typography>
                  )}
                </div>
                {mediaImage}
              </div>
            }
            preload="none"
            unloadVideoOnPaused
            loadingOverlay={
              originalUrl ? (
                <CircularProgress
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: 'center',
                  }}
                  data-gid="88222329"
                />
              ) : undefined
            }
            data-gid="17332544"
          />
        )}
        {!showMedia && (
          <div className={classes.videoHoverContainer} data-gid="81234116">
            <ExplicitImagePlaceholder
              mediaContext={mediaContext}
              containsNudityConfidence={containsNudityConfidence || 0}
              data-gid="27430954"
            />
          </div>
        )}
        {timestamp && (
          <CardFooter
            selectedClue={selectedClue}
            timestamp={timestamp}
            taggedBy={(event.data as ReportEventData).taggedBy ?? null}
            data-gid="82204873"
          />
        )}
      </div>
    </BaseCard>
  );
};

const ContactCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;
  const tipRef = useRef(null);
  const color = selectedClue ? undefined : '#9EA0A5';

  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="25869641"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.appDisplayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="39830675"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Contact Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <i
              className="fas fa-user"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: Colors.blue,
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="90008641"
            />
          )}
        </div>
      }
      data-gid="24484757"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        data-gid="76458835"
      >
        <div data-gid="35156771">
          <Typography color={color} fontSize={12} data-gid="71824470">
            Contact
          </Typography>
          <Typography
            style={{ inlineSize: 300, overflowWrap: 'break-word' }}
            data-gid="51373477"
          >
            {data.primaryDisplayName}
          </Typography>
        </div>
        <div style={{ marginTop: 5 }} data-gid="97054646">
          <Typography color={color} fontSize={12} data-gid="64128288">
            App
          </Typography>
          <Typography
            style={{ inlineSize: 300, overflowWrap: 'break-word' }}
            data-gid="20718980"
          >
            {data.appDisplayName}
          </Typography>
        </div>
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="50469351"
      >
        <Typography color={color} fontSize={12} data-gid="71940101">
          Most Recently Sent Message
        </Typography>
        {data.timestamp && (
          <div data-gid="95604290">
            <Typography data-gid="28234841">
              {`${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.DATE_MED,
              )} ${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET,
              )}`}
            </Typography>
          </div>
        )}
      </div>
      <CardFooter
        selectedClue={selectedClue}
        taggedBy={(data as ReportEventData).taggedBy ?? null}
        data-gid="82204873"
      />
    </BaseCard>
  );
};

const CallCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;

  const color = selectedClue ? undefined : '#9EA0A5';

  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      // ref={scrollToRef}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="47474650"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.appDisplayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="66050788"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Call Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <i
              className="fas fa-phone"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: Colors.blue,
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="97728666"
            />
          )}
        </div>
      }
      data-gid="82288773"
    >
      {' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        data-gid="65466784"
      >
        <div data-gid="77883755">
          <Typography color={color} fontSize={12} data-gid="89272727">
            Call Status
          </Typography>
          <Typography
            style={{ inlineSize: 300, overflowWrap: 'break-word' }}
            data-gid="36890018"
          >
            {data?.callOriginated !== undefined &&
            data?.callAnswered !== undefined
              ? determineCallStatus(data?.callOriginated, data?.callAnswered)
              : ''}
          </Typography>
        </div>
        <div data-gid="19045864">
          <Typography color={color} fontSize={12} data-gid="85720353">
            Phone Number
          </Typography>
          <Typography
            style={{ inlineSize: 300, overflowWrap: 'break-word' }}
            data-gid="81469100"
          >
            {data.callContactDisplayName && data.callDisplayName
              ? `${data.callDisplayName.toString()} (${data.callContactDisplayName.toString()})`
              : data.callDisplayName}
          </Typography>
        </div>
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="51258601"
      >
        <Typography color={color} fontSize={12} data-gid="30717463">
          Duration
        </Typography>
        {data.timestamp && (
          <div data-gid="96846169">
            <Typography data-gid="52910652">
              {data.callDuration || data.callDuration === 0
                ? new Date(1000 * data.callDuration).toISOString().slice(11, 19)
                : 'Unknown'}
            </Typography>
          </div>
        )}
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="40461824"
      >
        <Typography color={color} fontSize={12} data-gid="98356909">
          Call Time
        </Typography>
        {data.timestamp && (
          <div data-gid="94528018">
            <Typography data-gid="90971093">
              {`${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.DATE_MED,
              )} ${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET,
              )}`}
            </Typography>
          </div>
        )}
      </div>
      <CardFooter
        selectedClue={selectedClue}
        taggedBy={(data as ReportEventData).taggedBy ?? null}
        data-gid="82204873"
      />
    </BaseCard>
  );
};

const MemoCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;

  const color = selectedClue ? undefined : '#9EA0A5';

  const formattedMemoBody = useMemoBody(data?.memoNoteBody);

  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      icon={
        <div
          className={showPulse ? classes.pulse : undefined}
          data-gid="47474650"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.appDisplayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="98764930"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Memo Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <i
              className="fas fa-clipboard"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: Colors.blue,
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="97728666"
            />
          )}
        </div>
      }
      data-gid="82288773"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        data-gid="65466784"
        className={classes.memoWrap}
      >
        <div data-gid="77883755">
          <Typography color={color} fontSize={12} data-gid="89272727">
            Memo
          </Typography>
          <Typography
            style={{ inlineSize: 200, overflowWrap: 'break-word' }}
            data-gid="51373477"
          >
            {data.memoTitle ?? 'No memo title'}
          </Typography>
        </div>
        <div data-gid="19045864">
          <Typography color={color} fontSize={12} data-gid="85720353">
            Memo Body
          </Typography>
          <Typography
            style={{ inlineSize: 200, overflowWrap: 'break-word' }}
            data-gid="81469100"
          >
            {formattedMemoBody.replace(/\uFFFC/, '')}
          </Typography>
        </div>
      </div>
      <CardFooter
        selectedClue={selectedClue}
        timestamp={data.timestamp ?? ''}
        taggedBy={(data as ReportEventData).taggedBy ?? null}
        data-gid="82204873"
      />
    </BaseCard>
  );
};

const InstalledAppCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;

  const color = selectedClue ? undefined : '#9EA0A5';

  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      icon={
        <div
          className={showPulse ? classes.attachedImage : undefined}
          data-gid="36040935"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.displayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="24320520"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Installed App Icon"
                data-gid="20832898"
              />
            </Tooltip>
          ) : (
            <Apps
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#1665D8',
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
              data-gid="31078551"
            />
          )}
        </div>
      }
      data-gid="10198010"
    >
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="93007307"
      >
        <Typography color={color} fontSize={12} data-gid="45314786">
          Installed App Name
        </Typography>
        <Typography data-gid="79217561">{data.displayName ?? 'N/A'}</Typography>
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="33254407"
      >
        <Typography color={color} fontSize={12} data-gid="12826346">
          Version
        </Typography>
        <Typography data-gid="93371646">{data.version}</Typography>
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="76872935"
      >
        <Typography color={color} fontSize={12} data-gid="90761117">
          Bundle Id
        </Typography>
        <Typography data-gid="49485240">{data.bundleId}</Typography>
      </div>
      <CardFooter
        selectedClue={selectedClue}
        taggedBy={(data as ReportEventData).taggedBy ?? null}
        data-gid="89153237"
      />
    </BaseCard>
  );
};

const BrowserHistoryCard: React.FC<BaseProps> = ({ fullHeight, ...props }) => {
  const classes = useStyles({ fullHeight });
  const {
    event: { data },
    hideIcon = false,
    showPulse = false,
    selectedClue = false,
  } = props;

  const color = selectedClue ? undefined : '#9EA0A5';

  const tipRef = useRef(null);
  const [inView, setInView] = useState(false);

  const cb = (entries: IntersectionObserverEntry[]): void => {
    const [entry] = entries;
    return entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);

    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  return (
    <BaseCard
      {...omit(props, 'showPulse')}
      hideIcon={hideIcon}
      icon={
        <div
          className={showPulse ? classes.attachedImage : undefined}
          data-gid="80518638"
        >
          {data.iconUrl ? (
            <Tooltip
              title={data.appDisplayName ?? ''}
              ref={tipRef}
              PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
              data-gid="50215504"
            >
              <img
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#1665D8',
                  color: 'white',
                  borderRadius: '100%',
                  height: 24,
                  width: 24,
                  zIndex: 2,
                }}
                src={data.iconUrl ?? undefined}
                alt="Browser History Icon"
                data-gid="78123003"
              />
            </Tooltip>
          ) : (
            <Apps
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#1665D8',
                color: 'white',
                borderRadius: '100%',
                height: 24,
                width: 24,
                zIndex: 2,
              }}
            />
          )}
        </div>
      }
      data-gid="10198010"
    >
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="33875901"
      >
        <Typography color={color} fontSize={12} data-gid="21749809">
          Browser History Title
        </Typography>
        <Typography data-gid="68602541">{data.title ?? 'N/A'}</Typography>
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="65744329"
      >
        {data.url && (
          <Tooltip title={data.url} placement="top" data-gid="26378899">
            <div data-gid="92851176">
              <Typography color={color} fontSize={12} data-gid="68688561">
                URL
              </Typography>
              <Typography data-gid="24007905">{getDomain(data.url)}</Typography>
            </div>
          </Tooltip>
        )}
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="40307629"
      >
        <Typography color={color} fontSize={12} data-gid="34080048">
          Date/ Time
        </Typography>
        {data.timestamp && (
          <div data-gid="86073796">
            <Typography data-gid="32464086">
              {' '}
              {`${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.DATE_MED,
              )} ${DateTime.fromISO(data.timestamp).toLocaleString(
                DateTime.TIME_WITH_SHORT_OFFSET,
              )} `}
            </Typography>
          </div>
        )}
      </div>
      <div
        style={{ marginTop: 5, inlineSize: 300, overflowWrap: 'break-word' }}
        data-gid="93253193"
      >
        <Typography color={color} fontSize={12} data-gid="58578585">
          Visit Count
        </Typography>
        <Typography data-gid="41771004">{data.visitCount}</Typography>
      </div>
      <CardFooter
        selectedClue={selectedClue}
        taggedBy={(data as ReportEventData).taggedBy ?? null}
        data-gid="81872323"
      />
    </BaseCard>
  );
};

export const TimelineCard: React.FC<{
  event: TimelineEvent | ReportEvent;
  showPulse: boolean;
  selectedEventId?: string;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  onHover: (eventId: string, e: React.SyntheticEvent<HTMLDivElement>) => void;
  fullHeight?: boolean;
  showTaggedBy?: boolean;
}> = (props) => {
  const { event, onHover, ...rest } = props;

  const [clueDetailsSlideoutData] = useRecoilState(clueDetailsSlideoutDataAtom);

  const { clueId, slideoutOpen } = clueDetailsSlideoutData;

  const selectedClue = clueId === event.data.id && slideoutOpen;

  if (event.type === 'Messages') {
    return (
      <MessageCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
        data-gid="77993449"
      />
    );
  }

  if (event.type === 'Location') {
    return (
      <LocationCard
        {...rest}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        event={event}
        selectedClue={selectedClue}
      />
    );
  }
  if (event.type === 'Media') {
    return (
      <MediaCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
        data-gid="51880658"
      />
    );
  }

  if (event.type === 'Contact') {
    return (
      <ContactCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
        data-gid="98668769"
      />
    );
  }

  if (event.type === 'Calls') {
    return (
      <CallCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
        data-gid="72074387"
      />
    );
  }

  if (event.type === 'Memos') {
    return (
      <>
        <MemoCard
          {...rest}
          event={event}
          key={event.data.id}
          onMouseEnter={(e) => onHover(event.data.id, e)}
          selectedClue={selectedClue}
          data-gid="72074387"
        />
      </>
    );
  }

  if (event.type === 'InstalledApps') {
    return (
      <InstalledAppCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
      />
    );
  }

  if (event.type === 'BrowserHistory') {
    return (
      <BrowserHistoryCard
        {...rest}
        event={event}
        key={event.data.id}
        onMouseEnter={(e) => onHover(event.data.id, e)}
        selectedClue={selectedClue}
        data-gid="62160511"
      />
    );
  }

  return null;
};
