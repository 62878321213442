import { useMutation } from '@apollo/client';
import { Button as CairnButton, makeStyles, Text } from '@grayshift/cairn';
import { CalendarMonth } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ExtractionAccessTypeEnum } from './graphql/generated/globalTypes';
import { extractionAccessSharedTimeframeAtom, userInfoAtom } from './lib/atoms';
import {
  UpdateAccessRequestStatustMutation,
  UsersRequestingExtractionAccess,
} from './lib/extractionAccessRequest';
import queryUsersWithExtractionAccess from './lib/queryUsersWithExtractionAccess';

const useStyles = makeStyles({
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sharedTimeframeAlert: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F6AB2F',
  },
});

// Material UI Pickers Date is an any
type MaterialUiPickersDateCustomType = (DateTime & { invalid: boolean }) | null;

type TimeFrame = {
  startDate: string;
  endDate: string;
};

interface ExtractionAccessRequestSelectProps {
  requestingUserId: string;
  extractionIdInQuestion: string;
}

const ExtractionAccessRequestSelect = ({
  requestingUserId,
  extractionIdInQuestion,
}: ExtractionAccessRequestSelectProps): React.ReactElement => {
  const classes = useStyles();

  const [extractionAccessType, setExtractionAccessType] = useState<string>('');
  const [accessAction, setAccessAction] = useState<string>('');
  const [timeframeError, setTimeframeError] = useState<null | string>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showTimeframeInputs, setShowTimeframeInputs] =
    useState<boolean>(false);
  const [localStartTimeframe, setLocalStartTimeframe] =
    useState<MaterialUiPickersDateCustomType>(null);
  const [localEndTimeframe, setLocalEndTimeframe] =
    useState<MaterialUiPickersDateCustomType>(null);
  const [validatedTimeframe, setValidatedTimeframe] = useState<
    TimeFrame | undefined
  >();

  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const [extractionAccessSharedTimeframe] = useRecoilState(
    extractionAccessSharedTimeframeAtom,
  );

  const {
    startDate: timebasedLimitScopedStart,
    endDate: timebasedLimitScopedEnd,
  } = extractionAccessSharedTimeframe?.extractionAccessSharedTimeframe || {};

  const isAnEntitledProUser = entitledAccounts.length > 0 || hasActiveTrial;

  const [updateExtractionAccessStatus] = useMutation(
    UpdateAccessRequestStatustMutation,
    {
      refetchQueries: [
        UsersRequestingExtractionAccess,
        queryUsersWithExtractionAccess,
      ],
    },
  );

  const validateSelectTimeframes = (): void => {
    if (
      localStartTimeframe &&
      localEndTimeframe &&
      (localStartTimeframe?.invalid || localEndTimeframe?.invalid)
    ) {
      setTimeframeError('Invalid timeframe');
      setConfirmModalOpen(false);
      setAccessAction('');
      return;
    }

    if (
      localStartTimeframe &&
      localEndTimeframe &&
      !localStartTimeframe?.invalid &&
      !localEndTimeframe?.invalid
    ) {
      const { ts: startTs } = localStartTimeframe as unknown as {
        ts: number;
      };
      const { ts: endTs } = localEndTimeframe as unknown as { ts: number };

      const utcStart = DateTime.fromMillis(startTs, { zone: 'utc' });
      const utcEnd = DateTime.fromMillis(endTs, { zone: 'utc' });

      const utcStartString: string = utcStart.toUTC().toISO();
      const utcEndString: string = utcEnd.toUTC().toISO();

      setValidatedTimeframe({
        startDate: utcStartString,
        endDate: utcEndString,
      });
    }
  };

  const clearTimeInput = (): void => {
    setLocalStartTimeframe(null);
    setLocalEndTimeframe(null);
    setShowTimeframeInputs(!showTimeframeInputs);
    setTimeframeError(null);
  };

  const clearAllInput = (): void => {
    setAccessAction('');
    setExtractionAccessType('');
    setLocalStartTimeframe(null);
    setLocalEndTimeframe(null);
    setShowTimeframeInputs(false);
    setTimeframeError(null);
    setConfirmModalOpen(false);
  };

  return (
    <div style={{ padding: '8px' }} data-gid="21141865">
      <div className={classes.selectForm} data-gid="26750474">
        <form data-gid="64656149">
          <div style={{ paddingRight: '30px' }} data-gid="73145498">
            <FormControl style={{ minWidth: 120 }} data-gid="23867901">
              <InputLabel id="accessRequest" data-gid="98373867">
                Extraction Access Type
              </InputLabel>
              <Select
                labelId="accessRequest"
                style={{ minHeight: 0, width: 250 }}
                value={extractionAccessType}
                label="Extraction Access Type"
                onChange={(e) => {
                  setExtractionAccessType(e.target.value);
                }}
                data-gid="54726253"
              >
                <MenuItem
                  value={ExtractionAccessTypeEnum.OWNER}
                  data-gid="17437179"
                >
                  Owner
                </MenuItem>
                <MenuItem
                  value={ExtractionAccessTypeEnum.EDITOR}
                  data-gid="10579157"
                >
                  Editor
                </MenuItem>
                <MenuItem
                  value={ExtractionAccessTypeEnum.READ_ONLY}
                  data-gid="19414747"
                >
                  Read Only
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </form>
        <Tooltip
          placement="bottom"
          disableHoverListener={
            (!timebasedLimitScopedStart || !timebasedLimitScopedEnd) &&
            isAnEntitledProUser
          }
          title={
            <div data-gid="45198929">
              {isAnEntitledProUser &&
                timebasedLimitScopedStart &&
                timebasedLimitScopedEnd &&
                `This extraction has a specific time scope applied.`}
              {!isAnEntitledProUser &&
                `This is a pro feature to allow for sharing extractions with a specific time scope.`}
            </div>
          }
          data-gid="62237378"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              paddingRight: '30px',
            }}
            data-gid="28520711"
          >
            <CairnButton
              variant="text"
              data-gid="94991338"
              disabled={
                !!(timebasedLimitScopedStart && timebasedLimitScopedEnd) ||
                !isAnEntitledProUser
              }
              onClick={() => {
                clearTimeInput();
                setConfirmModalOpen(false);
              }}
            >
              <CalendarMonth
                style={{
                  color:
                    !!(timebasedLimitScopedStart && timebasedLimitScopedEnd) ||
                    !isAnEntitledProUser
                      ? 'gray'
                      : 'black',
                }}
                fontSize="medium"
                data-gid="18181200"
              />
              <Typography
                style={{
                  marginLeft: '.25vw',
                  marginTop: '.20vh',
                }}
                fontSize={16}
                data-gid="27582647"
              >
                {showTimeframeInputs ? `Remove Time Scope` : `Add Time Scope`}
              </Typography>
            </CairnButton>
          </div>
        </Tooltip>

        <div className={classes.selectActions} data-gid="69752744">
          <Button
            disabled={
              !extractionAccessType ||
              (showTimeframeInputs &&
                (!localStartTimeframe || !localEndTimeframe)) ||
              !!timeframeError
            }
            onClick={() => {
              if (localStartTimeframe && localEndTimeframe) {
                validateSelectTimeframes();
              }
              setAccessAction('GRANTED');
              setConfirmModalOpen(true);
            }}
            data-gid="10259255"
          >
            <Tooltip
              title="Grant Access"
              placement="bottom"
              data-gid="96776385"
            >
              <CheckIcon data-gid="87486209" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => {
              setAccessAction('DENIED');
              setTimeframeError(null);
              setConfirmModalOpen(true);
              setShowTimeframeInputs(false);
            }}
            data-gid="16841824"
          >
            <Tooltip title="Deny Access" placement="bottom" data-gid="26908076">
              <CloseIcon color="error" data-gid="45400859" />
            </Tooltip>
          </Button>
        </div>
        {accessAction && confirmModalOpen && !timeframeError && (
          <div className={classes.confirmAction} data-gid="90911524">
            <Text size="2" weight={300} align="left" data-gid="29633498">
              {accessAction === 'GRANTED' ? 'Grant Access?' : 'Deny Access?'}
            </Text>
            <Button
              onClick={async () => {
                await updateExtractionAccessStatus({
                  variables: {
                    input: {
                      extractionId: extractionIdInQuestion,
                      userId: requestingUserId,
                      status: accessAction,
                      accessType: extractionAccessType || null,
                      sharedTimeframe: validatedTimeframe,
                    },
                  },
                });
              }}
              data-gid="27374222"
            >
              <Text size="1" data-gid="14838222">
                Confirm
              </Text>
            </Button>
            <Button
              onClick={() => {
                clearAllInput();
              }}
              data-gid="91725640"
            >
              <Text size="1" data-gid="89746462">
                Cancel
              </Text>
            </Button>
          </div>
        )}
      </div>
      {showTimeframeInputs && (
        <div
          style={{
            paddingTop: '8px',
            display: 'flex',
            flexDirection: 'row',
          }}
          data-gid="71980855"
        >
          <DateTimePicker
            label="Start"
            className=""
            value={localStartTimeframe}
            sx={{
              width: 250,
              marginRight: 1,
              minHeight: 0,
            }}
            onChange={(newTimeframe) => {
              setTimeframeError(null);
              setLocalStartTimeframe(newTimeframe);
              setConfirmModalOpen(false);
            }}
            data-gid="53414274"
          />
          <DateTimePicker
            label="End"
            className=""
            value={localEndTimeframe}
            sx={{
              width: 250,
              marginRight: 1,
              minHeight: 0,
            }}
            onChange={(newTimeframe) => {
              setTimeframeError(null);
              setLocalEndTimeframe(newTimeframe);
              setConfirmModalOpen(false);
            }}
            data-gid="53414274"
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-gid="21102119"
          >
            {localStartTimeframe?.toLocaleString() &&
              localEndTimeframe?.toLocaleString() &&
              'Timeframe Set'}
            {localStartTimeframe?.toLocaleString() &&
              localEndTimeframe?.toLocaleString() && (
                <Tooltip
                  placement="bottom"
                  title={
                    <div data-gid="45198929">
                      {`Timeframes adjust to the viewer's timezone if necessary.`}
                      <br data-gid="79782421" />
                      {`Start:   ${localStartTimeframe.toLocaleString(
                        DateTime.DATE_MED,
                      )} ${localStartTimeframe.toLocaleString(
                        DateTime.TIME_WITH_SHORT_OFFSET,
                      )} 
  `}
                      <br data-gid="10105925" />
                      {`End:  ${localEndTimeframe.toLocaleString(
                        DateTime.DATE_MED,
                      )} ${localEndTimeframe.toLocaleString(
                        DateTime.TIME_WITH_SHORT_OFFSET,
                      )} 
   `}
                    </div>
                  }
                  data-gid="62237378"
                >
                  <InfoIcon
                    style={{
                      marginLeft: '10px',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}
                    data-gid="61700844"
                  />
                </Tooltip>
              )}
          </div>
          {timeframeError && (
            <Alert
              severity="error"
              className={classes.sharedTimeframeAlert}
              style={{
                borderRadius: '0px',
              }}
              data-gid="26344365"
            >
              {timeframeError}
            </Alert>
          )}
        </div>
      )}
    </div>
  );
};

export default ExtractionAccessRequestSelect;
