import { useMutation } from '@apollo/client';
import { Button, makeStyles } from '@grayshift/cairn';
import { Restore } from '@mui/icons-material';

import { reactivateExtractionMutation } from './graphql/mutations/extraction';
import { Layout } from './Layout';
import { useExtractionId } from './lib/hookUseCurrentExtractionId';

const useStyles = makeStyles({
  centeredFlex: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  selectExtraction: {
    paddingTop: 10,
  },
});

export const ExtractionExpired: React.FC = () => {
  const classes = useStyles();
  const [extractionId] = useExtractionId();

  const [reactivateExtraction] = useMutation(reactivateExtractionMutation, {
    variables: { input: { extractionId } },
  });

  const reactivate = (): void => {
    if (extractionId && process.env.REACT_APP_ENV === 'production')
      window.aptrinsic('track', 'Reactivate', {
        extractionId,
      });
    // eslint-disable-next-line no-void
    void reactivateExtraction();
  };

  return (
    <Layout data-gid="43924239" disableLinks>
      <div className={classes.centeredFlex} data-gid="24905042">
        Extraction has expired. Please re-activate the extraction using the
        button below.
        <Button
          color="primary"
          role="presentation"
          onClick={() => reactivate()}
          style={{ marginTop: '8px' }}
          data-gid="50067355"
        >
          <Restore style={{ marginRight: '4px' }} data-gid="15383669" />
          Reactivate and Open
        </Button>
      </div>
    </Layout>
  );
};
