import { gql } from '@apollo/client';

export const ImageFrag = gql`
  fragment ImageFrag on Image {
    id
    originalUrl
    thumbnailUrl
    previewUrl
    containsNudity
    containsCSAM
    containsCSAMConfidence
    containsNudityConfidence
    ncmecMatch
    imageAnnotations {
      id
      annotation
      annotatedBy {
        id
        firstName
        lastName
        email
      }
    }
    sourceFiles {
      id
      name
      checksum
      extension
      enclosingDirectory
      sizeBytes
      atime
      mtime
      ctime
    }
    storedFileChecksum
  }
`;

export const VideoFrag = gql`
  fragment VideoFrag on Video {
    id
    originalUrl
    thumbnailUrl
    sourceFiles {
      id
      name
      checksum
      extension
      enclosingDirectory
      sizeBytes
      atime
      mtime
      ctime
    }
  }
`;
