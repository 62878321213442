import { gql } from '@apollo/client';

import { ImageFrag, VideoFrag } from '../fragments';

export const allLocationQuery = gql`
  query getAllLocations(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $sources: [String]
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    allLocations(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      sources: $sources
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          id
          latitude
          longitude
          timestamp
          source
        }
      }
      pageInfo {
        totalEdges
      }
    }
  }
`;

export const getLocationSources = gql`
  query locationSources(
    $extractionId: UUID!
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    locationSources(
      extractionId: $extractionId
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      source
      hits
    }
  }
`;

export const allLocationTimelineQuery = gql`
  query allLocationsTimeline(
    $extractionId: UUID!
    $page: Int
    $pageSize: Int
    $timeframe: TimeFrame
    $searchTerm: String
  ) {
    allLocations(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
      timeframe: $timeframe
      searchTerm: $searchTerm
    ) {
      pageInfo {
        totalEdges
        totalPages
      }
      edges {
        node {
          id
          latitude
          longitude
          timestamp
        }
      }
    }
  }
`;

export const getPaginatedLocationsByIdsQuery = gql`
  ${ImageFrag}
  ${VideoFrag}
  query paginatedLocationsByIds(
    $extractionId: UUID!
    $ids: [ID!]!
    $page: Int
    $pageSize: Int
  ) {
    paginatedLocationsByIds(
      extractionId: $extractionId
      ids: $ids
      page: $page
      pageSize: $pageSize
    ) {
      edges {
        page
        pageSize
        node {
          id
          latitude
          longitude
          app {
            id
          }
          appBundleId
          meta {
            key
            value
          }
          media {
            ...ImageFrag
            ...VideoFrag
          }
          source
          timestamp
          sourceFiles {
            id
            name
            checksum
            extension
            enclosingDirectory
            sizeBytes
            atime
            mtime
            ctime
          }
          taggedAt
          taggedBy {
            id
            firstName
            lastName
            email
          }
        }
      }
      pageInfo {
        totalPages
        totalEdges
      }
    }
  }
`;
