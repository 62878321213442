import { gql } from '@apollo/client';

export const recentLocationWidgetQuery = gql`
  query RecentLocationWidgetQuery($extractionId: UUID!, $timeframe: TimeFrame) {
    extractionById(extractionId: $extractionId, timeframe: $timeframe) {
      id
      recentLocation {
        id
        timestamp
        latitude
        longitude
        app {
          id
          displayName
        }
        appBundleId
      }
    }
  }
`;

export const mostContactedWidgetQuery = gql`
  query MostContactedWidgetQuery($extractionId: UUID!, $timeframe: TimeFrame) {
    extractionById(extractionId: $extractionId, timeframe: $timeframe) {
      id
      mostContacted {
        id
        primaryDisplayName
        isDeviceOwner
        mostRecentCallSent {
          id
          timestamp
        }
        mostRecentMessageSent {
          id
          timestamp
          sender {
            id
            primaryDisplayName
            app {
              id
              displayName
            }
            appBundleId
          }
          textContent
        }
        attributes {
          id
        }
        platformMessageCounts {
          count
          platform
        }
        platformCallCounts {
          count
          platform
        }
      }
    }
  }
`;

export const latestMessageWidgetQuery = gql`
  query LatestMessageWidgetQuery($extractionId: UUID!, $timeframe: TimeFrame) {
    extractionById(extractionId: $extractionId, timeframe: $timeframe) {
      id
      latestMessage {
        id
        timestamp
        app {
          id
          displayName
          iconUrl
        }
        appBundleId
        appBundleId
        sentByOwner
        textContent
        sender {
          id
          primaryDisplayName
          metacontact {
            primaryDisplayName
          }
          avatarImages {
            id
            thumbnailUrl
          }
        }
      }
      mostContacted {
        id
        mostRecentMessageSent {
          id
          timestamp
        }
      }
    }
  }
`;

export const extractionInfoWidgetQuery = gql`
  query ExtractionInfoWidgetQuery($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      evidenceId
      examinerName
      extractionStartedAt
      aggregationsCompletedAt
      lastRealActivityAt
      state
      lockState
      progress
      recordUpdatedAt
      timeToFirstClue
      expiresAt
    }
  }
`;

export const deviceOwnerWidgetQuery = gql`
  query DeviceOwnerWidgetQuery($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      owner {
        name
        contacts {
          id
          primaryDisplayName
          isDeviceOwner
          attributes {
            ... on NameAttribute {
              id
              key
              value
            }
            ... on EmailAddressAttribute {
              emailAddress
            }
            ... on PhoneNumberAttribute {
              id
              number
            }
            ... on SocialHandleAttribute {
              id
              handle
            }
            ... on StreetAddressAttribute {
              city
              id
            }
            ... on MiscellaneousAttribute {
              id
              value
            }
          }
          app {
            id
            displayName
            version
          }
          appBundleId
        }
      }
      target {
        hardwareId
        extra {
          model
          serial
        }
      }
      targetMeta {
        os
      }
    }
  }
`;

export const extractionDashboardQuery = gql`
  query extractionDashboard($extractionId: UUID!) {
    extractionById(extractionId: $extractionId) {
      id
      evidenceId
      examinerName
      extractionStartedAt
      aggregationsCompletedAt
      lastRealActivityAt
      state
      lockState
      progress
      recordUpdatedAt
      timeToFirstClue
      expiresAt

      owner {
        name
        contacts {
          id
          primaryDisplayName
          isDeviceOwner
          attributes {
            ... on NameAttribute {
              id
              key
              value
            }
            ... on EmailAddressAttribute {
              emailAddress
            }
            ... on PhoneNumberAttribute {
              id
              number
            }
            ... on SocialHandleAttribute {
              id
              handle
            }
            ... on StreetAddressAttribute {
              city
              id
            }
            ... on MiscellaneousAttribute {
              id
              value
            }
          }
          app {
            id
            displayName
            version
          }
          appBundleId
        }
      }
      target {
        hardwareId
        extra {
          model
          serial
        }
      }
      targetMeta {
        os
      }
    }
  }
`;

export const allTaggedCluesQuery = gql`
  query getAllTaggedClues($extractionId: UUID!, $page: Int, $pageSize: Int) {
    allTaggedClues(
      extractionId: $extractionId
      page: $page
      pageSize: $pageSize
    ) {
      edges {
        node {
          ...ContactData
          ...MessageData
          ...LocationData
          ...CallData
          ...MemoData
          ... on Video {
            ...VideoData
          }
          ... on Image {
            ...ImageData
          }
          ...InstalledAppData
          ...BrowserHistoryData
        }
      }
    }
  }

  fragment ContactData on Contact {
    id
    primaryDisplayName
    taggedAt
    mostRecentMessageSent {
      timestamp
    }
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
  }
  fragment MessageData on DefaultAppMessage {
    id
    textContent
    sender {
      primaryDisplayName
    }
    timestamp
    sentByOwner
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
    media {
      ...ImageData
    }
    taggedAt
  }
  fragment LocationData on DefaultLocation {
    id
    latitude
    longitude
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
    source
  }
  fragment ImageData on Image {
    id
    thumbnailUrl
    timestamp
    containsNudity
    containsCSAM
    containsCSAMConfidence
    containsNudityConfidence
    ncmecMatch
    imageAnnotations {
      annotation
    }
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
    __typename
  }
  fragment CallData on Call {
    id
    displayName
    timestamp
    contact {
      primaryDisplayName
      id
    }
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
    duration
    originated
    answered
  }
  fragment MemoData on Memo {
    id
    title
    modifiedAt
    author
    noteBody
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
  }
  fragment VideoData on Video {
    id
    thumbnailUrl
    originalUrl
    timestamp
    containsNudity
    containsCSAM
    containsCSAMConfidence
    containsNudityConfidence
    ncmecMatch
    durationSeconds
    videoAnnotations {
      annotation
    }
    app {
      id
      displayName
      iconUrl
    }
    appBundleId
    __typename
  }
  fragment InstalledAppData on InstalledApp {
    id
    installedAppDisplayName: displayName
    version
    bundleId
    iconUrl
  }
  fragment BrowserHistoryData on BrowserHistory {
    id
    url
    timestamp
    appBundleId
    title
    visitCount
    app {
      id
      displayName
      iconUrl
    }
  }
`;
