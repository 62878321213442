/* eslint-disable sonarjs/no-duplicate-string */
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Colors,
  makeStyles,
  Modal,
  Text,
  Tooltip,
} from '@grayshift/cairn';
import {
  AddCircleOutline,
  ArrowBack,
  CalendarMonth,
  Close,
  HighlightOffOutlined,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ExtractionAccessManager from './ExtractionAccessManager';
import ExtractionSelectionModalCard from './ExtractionSelectionModalCard';
import { allExtractions_allExtractions_edges_node } from './graphql/generated/allExtractions';
import { createInviteWithExtractionAccess } from './graphql/generated/createInviteWithExtractionAccess';
import { extractionAccessSharedTimeframe } from './graphql/generated/extractionAccessSharedTimeframe';
import { extractionAccessTypeByUserId } from './graphql/generated/extractionAccessTypeByUserId';
import { extractionByIdQuery_extractionById } from './graphql/generated/extractionByIdQuery';
import { ExtractionAccessTypeEnum } from './graphql/generated/globalTypes';
import { usersRequestingExtractionAccess } from './graphql/generated/usersRequestingExtractionAccess';
import { usersWithExtractionAccess } from './graphql/generated/usersWithExtractionAccess';
import { extractionAccessSharedTimeframeQuery } from './graphql/queries';
import { LowerAllButFirstLetter } from './helpers/LowerAllButFirstLetter';
import {
  extractionSelectionModalIsOpenAtom,
  extractionShareModalControllerAtom,
  userInfoAtom,
} from './lib/atoms';
import { UsersRequestingExtractionAccess } from './lib/extractionAccessRequest';
import queryExtractionAccessByUserId from './lib/extractionAccessTypeByUserId';
import queryUsersWithExtractionAccess from './lib/queryUsersWithExtractionAccess';
import { useQueryWithErrorBoundary } from './useQueryWithErrorBoundary';

// Material UI Pickers Date is an any
type MaterialUiPickersDateCustomType = (DateTime & { invalid: boolean }) | null;

const useStyles = makeStyles({
  modalContentContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    width: 1100,
    background: Colors.white,
    border: `solid 1px ${Colors.divider}`,
    borderRadius: 5,
    padding: 30,
    outline: 'none',
  },
  extractionSearch: {
    paddingTop: 10,
    width: '100%',
  },
  arrowBackIcon: {
    position: 'absolute',
    left: 8,
    cursor: 'pointer',
    top: 8,
    color: Colors.blue,
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    cursor: 'pointer',
    top: 8,
    color: '#9EA0A5',
  },
  inviteForm: {
    display: 'flex',
    width: '100%',
    marginBottom: '14px',
    justifyContent: 'space-between',
  },
  inputStack: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  pillContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '14px',
    width: '100%',
    flexWrap: 'wrap',
  },
  pill: {
    border: '1px solid #E2E5ED',
    borderRadius: '16px',
    padding: '4px 12px',
    width: 'auto',
    marginRight: '7px',
    marginBottom: '7px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  inviteLinkContainer: {
    display: 'flex',
    backgroundColor: '#E2E5ED',
    borderRadius: '7px',
    padding: '14px',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '14px',
  },
  settingsButtonContainer: {
    marginTop: '14px',
  },
  sharedTimeframeAlert: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F6AB2F',
  },
});

const reformatPresentation = (accessType: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (accessType === ExtractionAccessTypeEnum.READ_ONLY) {
    return accessType
      .split('_')
      .map((word: string) => LowerAllButFirstLetter(word))
      .join(' ');
  }

  return LowerAllButFirstLetter(accessType);
};

const createInviteMutation = gql`
  mutation createInviteWithExtractionAccess(
    $invitations: [InvitationInput!]!
    $extractionId: UUID!
  ) {
    createInviteWithExtractionAccess(
      input: { invitations: $invitations, extractionId: $extractionId }
    ) {
      invites {
        email
        code
      }
    }
  }
`;

/* eslint-disable sonarjs/cognitive-complexity  */
const ExtractionShareModal: React.FC = () => {
  const classes = useStyles();

  const [totalInvitations, setTotalInvitations] = useState<
    {
      email: string;
      accessType: string;
      sharedTimeframe: { startDate: string; endDate: string } | undefined;
    }[]
  >([]);
  const [invitationsSent, setInvitationsSent] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);

  const [email, setEmail] = useState<string | null>();
  const [extractionAccessInviteChoice, setExtractionAccessInviteChoice] =
    useState<string>('');
  const [isAccessSettings, setIsAccessSettings] = useState(false);

  const [timeframeError, setTimeframeError] = useState<null | string>();

  const [showTimeframeInputs, setShowTimeframeInputs] =
    useState<boolean>(false);

  const [localStartTimeframe, setLocalStartTimeframe] =
    useState<MaterialUiPickersDateCustomType>(null);

  const [localEndTimeframe, setLocalEndTimeframe] =
    useState<MaterialUiPickersDateCustomType>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEndPopUpOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setStartPopUpOpen] = useState<boolean>(false);

  const [userInfo] = useRecoilState(userInfoAtom);

  const { entitledAccounts, hasActiveTrial } = useRecoilValue(userInfoAtom);
  const isAnEntitledProUser = entitledAccounts.length > 0 || hasActiveTrial;

  const [, setExtractionSelectionModalIsOpen] = useRecoilState(
    extractionSelectionModalIsOpenAtom,
  );

  const addInvite = (validatedTimeframe?: {
    startDate: string;
    endDate: string;
  }): void => {
    if (email && extractionAccessInviteChoice) {
      setTotalInvitations(
        uniq([
          ...totalInvitations,
          {
            email,
            accessType: extractionAccessInviteChoice,
            sharedTimeframe: validatedTimeframe || undefined,
          },
        ]),
      );
    }
    setEmail(null);
    setExtractionAccessInviteChoice('');
    setShowTimeframeInputs(false);
    setLocalStartTimeframe(null);
    setLocalEndTimeframe(null);
  };

  const [extractionShareModalController, setExtractionShareModalController] =
    useRecoilState(extractionShareModalControllerAtom);

  const { isOpen, extraction, shareModalWasEntry } =
    extractionShareModalController;

  const extractionId = extraction.id;
  const { data: loggedInUserAccessType, loading: accessTypeLoading } =
    useQueryWithErrorBoundary<extractionAccessTypeByUserId>(
      queryExtractionAccessByUserId,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          extractionId,
          userId: userInfo?.id,
        },
        skip: !extractionId || !isOpen || !userInfo?.id,
      },
    );

  const accessTypeForLoggedInUser =
    loggedInUserAccessType?.extractionAccessTypeByUserId?.accessType;

  const { data: usersListData } =
    useQueryWithErrorBoundary<usersWithExtractionAccess>(
      queryUsersWithExtractionAccess,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          extractionId,
          page: 1,
          pageSize: 100_000,
        },
        skip: !extractionId || !isOpen,
      },
    );

  const { data: usersRequestingAccessData } =
    useQueryWithErrorBoundary<usersRequestingExtractionAccess>(
      UsersRequestingExtractionAccess,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          extractionId,
        },
        skip: !extractionId || !isOpen,
      },
    );

  const { data: sharedTimeframeData } =
    useQuery<extractionAccessSharedTimeframe>(
      extractionAccessSharedTimeframeQuery,
      {
        variables: { extractionId, userId: userInfo.id },
        skip: !extractionId || !userInfo.id,
      },
    );

  const validateSelectTimeframes = (): void => {
    if (
      localStartTimeframe &&
      localEndTimeframe &&
      localStartTimeframe?.invalid &&
      localEndTimeframe?.invalid
    ) {
      setTimeframeError('Invalid timeframe');
      return;
    }

    if (
      localStartTimeframe &&
      localEndTimeframe &&
      !localStartTimeframe?.invalid &&
      !localEndTimeframe?.invalid
    ) {
      const { ts: startTs } = localStartTimeframe as unknown as {
        ts: number;
      };
      const { ts: endTs } = localEndTimeframe as unknown as { ts: number };

      const utcStart = DateTime.fromMillis(startTs, { zone: 'utc' });
      const utcEnd = DateTime.fromMillis(endTs, { zone: 'utc' });

      const utcStartString: string = utcStart.toUTC().toISO();
      const utcEndString: string = utcEnd.toUTC().toISO();

      addInvite({
        startDate: utcStartString,
        endDate: utcEndString,
      });
    }
  };

  const [createInvite, { loading, error }] =
    useMutation<createInviteWithExtractionAccess>(createInviteMutation);

  useEffect(() => {
    if (error) {
      setEmailSendError(true);
    }
  }, [error]);

  const closeShareModalAndResetController = (): void => {
    setExtractionShareModalController({
      isOpen: false,
      extraction: {} as
        | extractionByIdQuery_extractionById
        | allExtractions_allExtractions_edges_node,
      shareModalWasEntry: false,
    });
  };
  const onClose = (): void => {
    setIsAccessSettings(false);
    setTotalInvitations([]);
    setExtractionAccessInviteChoice('');
    closeShareModalAndResetController();
  };

  const switchToSelectionModal = (): void => {
    closeShareModalAndResetController();
    setExtractionSelectionModalIsOpen(true);
  };

  const selectInviteExtractionType = (value: string): void => {
    setExtractionAccessInviteChoice(value);
  };

  const illegalToInvite = (
    currentType: ExtractionAccessTypeEnum | null,
  ): Array<ExtractionAccessTypeEnum> => {
    if (!currentType) {
      return [
        ExtractionAccessTypeEnum.OWNER,
        ExtractionAccessTypeEnum.EDITOR,
        ExtractionAccessTypeEnum.READ_ONLY,
      ];
    }
    if (currentType === ExtractionAccessTypeEnum.EDITOR) {
      return [ExtractionAccessTypeEnum.OWNER];
    }
    if (currentType === ExtractionAccessTypeEnum.READ_ONLY) {
      return [ExtractionAccessTypeEnum.OWNER, ExtractionAccessTypeEnum.EDITOR];
    }
    return [];
  };

  const timebasedLimitScopedStart =
    sharedTimeframeData?.extractionAccessSharedTimeframe?.startDate;
  const timebasedLimitScopedEnd =
    sharedTimeframeData?.extractionAccessSharedTimeframe?.endDate;

  return (
    <Modal
      disableEnforceFocus
      open={isOpen}
      onClose={onClose}
      data-gid="17392068"
    >
      <div className={classes.modalContentContainer} data-gid="51588455">
        {!isAccessSettings ? (
          <div data-gid="39056539">
            {!shareModalWasEntry && (
              <div
                className={classes.arrowBackIcon}
                onClick={() => switchToSelectionModal()}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
                data-gid="22165137"
              >
                <ArrowBack data-gid="98327204" />
              </div>
            )}
            <Text
              size="6"
              weight={500}
              align="center"
              style={{ marginBottom: '15px' }}
              data-gid="50414088"
            >
              Share Extraction
            </Text>
            <ExtractionSelectionModalCard
              extraction={extraction}
              isInShareModal
              data-gid="19642033"
            />
            <div className={classes.extractionSearch} data-gid="40673574">
              <div className={classes.inviteForm} data-gid="56243339">
                <div className={classes.inputStack} data-gid="15982997">
                  <TextField
                    value={email || ''}
                    label="Email"
                    type="email"
                    style={{
                      width: 300,
                      minHeight: 'auto',
                      alignSelf: 'center',
                      margin: 0,
                      height: 70,
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    data-gid="63032711"
                    onFocus={() => {
                      if (emailSendError || invitationsSent) {
                        setInvitationsSent(false);
                        setEmailSendError(false);
                      }
                    }}
                  />
                  <FormControl style={{ minWidth: 120 }} data-gid="31112907">
                    <InputLabel id="eat" data-gid="19137446">
                      Extraction Access Type
                    </InputLabel>
                    <Select
                      labelId="eat"
                      style={{ minHeight: 0, width: 300 }}
                      value={extractionAccessInviteChoice}
                      label="Extraction Access Type"
                      onChange={(e) => {
                        selectInviteExtractionType(e.target.value);
                      }}
                      data-gid="54726253"
                    >
                      <MenuItem
                        value={ExtractionAccessTypeEnum.OWNER}
                        disabled={illegalToInvite(
                          extraction.accessType,
                        )?.includes(ExtractionAccessTypeEnum.OWNER)}
                        data-gid="17437179"
                      >
                        Owner
                      </MenuItem>
                      <MenuItem
                        value={ExtractionAccessTypeEnum.EDITOR}
                        disabled={illegalToInvite(
                          extraction.accessType,
                        )?.includes(ExtractionAccessTypeEnum.EDITOR)}
                        data-gid="10579157"
                      >
                        Editor
                      </MenuItem>
                      <MenuItem
                        value={ExtractionAccessTypeEnum.READ_ONLY}
                        disabled={illegalToInvite(
                          extraction.accessType,
                        )?.includes(ExtractionAccessTypeEnum.READ_ONLY)}
                        data-gid="19414747"
                      >
                        Read Only
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <Tooltip
                    placement="top"
                    disableHoverListener={
                      (!timebasedLimitScopedStart ||
                        !timebasedLimitScopedEnd) &&
                      isAnEntitledProUser
                    }
                    title={
                      <div data-gid="45198929">
                        {isAnEntitledProUser &&
                          timebasedLimitScopedStart &&
                          timebasedLimitScopedEnd &&
                          `This extraction has a specific time scope applied.`}
                        {!isAnEntitledProUser &&
                          `This is a pro feature to allow for sharing extractions with a specific time scope.`}
                      </div>
                    }
                    data-gid="62237378"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      data-gid="28520711"
                    >
                      <Button
                        variant="text"
                        data-gid="94991338"
                        disabled={
                          !!(
                            sharedTimeframeData?.extractionAccessSharedTimeframe
                              ?.startDate &&
                            sharedTimeframeData.extractionAccessSharedTimeframe
                              .endDate
                          ) || !isAnEntitledProUser
                        }
                        onClick={() => {
                          setLocalStartTimeframe(null);
                          setLocalEndTimeframe(null);
                          setShowTimeframeInputs(!showTimeframeInputs);
                          setTimeframeError(null);
                        }}
                      >
                        <CalendarMonth
                          style={{
                            color:
                              !!(
                                sharedTimeframeData
                                  ?.extractionAccessSharedTimeframe
                                  ?.startDate &&
                                sharedTimeframeData
                                  .extractionAccessSharedTimeframe.endDate
                              ) || !isAnEntitledProUser
                                ? 'gray'
                                : 'black',
                          }}
                          fontSize="medium"
                          data-gid="18181200"
                        />
                        <Typography
                          style={{
                            marginLeft: '.25vw',
                            marginTop: '.20vh',
                          }}
                          fontSize={16}
                          data-gid="27582647"
                        >
                          {showTimeframeInputs
                            ? `Remove Time Scope`
                            : `Add Time Scope`}
                        </Typography>
                      </Button>
                    </div>
                  </Tooltip>

                  <Button
                    onClick={() => {
                      if (localStartTimeframe && localEndTimeframe) {
                        validateSelectTimeframes();
                        return;
                      }

                      if (
                        timebasedLimitScopedStart &&
                        timebasedLimitScopedEnd
                      ) {
                        addInvite({
                          startDate: timebasedLimitScopedStart,
                          endDate: timebasedLimitScopedEnd,
                        });
                        return;
                      }
                      addInvite();
                    }}
                    color="primary"
                    disabled={
                      !extractionAccessInviteChoice ||
                      !email ||
                      !extractionAccessInviteChoice ||
                      !email ||
                      (showTimeframeInputs &&
                        (!localStartTimeframe || !localEndTimeframe))
                    }
                    style={{ alignSelf: 'center' }}
                    data-gid="64166257"
                  >
                    <AddCircleOutline
                      style={{ marginRight: '4px' }}
                      data-gid="46363227"
                    />
                    Add Invite
                  </Button>
                </div>
              </div>
              {showTimeframeInputs && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 20,
                  }}
                  data-gid="71980855"
                >
                  <DateTimePicker
                    label="Start"
                    className=""
                    onOpen={() => setStartPopUpOpen(true)}
                    onClose={() => setStartPopUpOpen(false)}
                    value={localStartTimeframe}
                    sx={{
                      width: 250,
                      marginRight: 1,
                      minHeight: 0,
                    }}
                    onChange={(newTimeframe) => {
                      setLocalStartTimeframe(newTimeframe);
                    }}
                    data-gid="53414274"
                  />
                  <DateTimePicker
                    label="End"
                    className=""
                    onOpen={() => setEndPopUpOpen(true)}
                    onClose={() => setEndPopUpOpen(false)}
                    value={localEndTimeframe}
                    sx={{
                      width: 250,
                      marginRight: 1,
                      minHeight: 0,
                    }}
                    onChange={(newTimeframe) => {
                      setLocalEndTimeframe(newTimeframe);
                    }}
                    data-gid="53414274"
                  />
                  {timeframeError && (
                    <Alert
                      severity="error"
                      className={classes.sharedTimeframeAlert}
                      style={{
                        borderRadius: '0px',
                      }}
                      data-gid="26344365"
                    >
                      {timeframeError}
                    </Alert>
                  )}
                </div>
              )}
              <div data-gid="50423042">
                <div className={classes.pillContainer} data-gid="65345262">
                  {totalInvitations.map((invitation, idx) => (
                    <div
                      key={`${invitation.email}-${invitation.accessType}`}
                      className={classes.pill}
                      data-gid="33938809"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 10,
                        }}
                        data-gid="77435483"
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                          data-gid="96774210"
                        >
                          {invitation.email}
                          <HighlightOffOutlined
                            style={{
                              marginLeft: '10px',
                              fontSize: '20px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const temp = [...totalInvitations];
                              temp.splice(idx, 1);
                              setTotalInvitations(temp);
                            }}
                            data-gid="47467538"
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                          data-gid="94616858"
                        >
                          {`${reformatPresentation(invitation.accessType)}`}

                          {invitation.sharedTimeframe?.startDate.toLocaleString() &&
                            invitation.sharedTimeframe?.endDate.toLocaleString() &&
                            ' | Timeframe Set'}
                          {invitation.sharedTimeframe?.startDate.toLocaleString() &&
                            invitation.sharedTimeframe?.endDate.toLocaleString() && (
                              <Tooltip
                                placement="bottom"
                                title={
                                  <div data-gid="45198929">
                                    {`Timeframes adjust to the viewer's timezone if necessary.`}
                                    <br data-gid="79782421" />
                                    {`Start:   ${DateTime.fromISO(
                                      invitation.sharedTimeframe?.startDate,
                                    ).toLocaleString(
                                      DateTime.DATE_MED,
                                    )} ${DateTime.fromISO(
                                      invitation.sharedTimeframe?.startDate,
                                    ).toLocaleString(
                                      DateTime.TIME_WITH_SHORT_OFFSET,
                                    )} 
  `}
                                    <br data-gid="10105925" />
                                    {`End:  ${DateTime.fromISO(
                                      invitation.sharedTimeframe?.endDate,
                                    ).toLocaleString(
                                      DateTime.DATE_MED,
                                    )} ${DateTime.fromISO(
                                      invitation.sharedTimeframe?.endDate,
                                    ).toLocaleString(
                                      DateTime.TIME_WITH_SHORT_OFFSET,
                                    )} 
   `}
                                  </div>
                                }
                                data-gid="62237378"
                              >
                                <InfoIcon
                                  style={{
                                    marginLeft: '10px',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                  }}
                                  data-gid="61700844"
                                />
                              </Tooltip>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.actionsContainer} data-gid="57104092">
              <Button
                color="primary"
                onClick={async () => {
                  const result = await createInvite({
                    variables: { invitations: totalInvitations, extractionId },
                  });
                  const { data } = result;
                  if (data?.createInviteWithExtractionAccess?.invites) {
                    setInvitationsSent(true);
                    setTotalInvitations([]);

                    if (process.env.REACT_APP_ENV === 'production') {
                      window.aptrinsic('track', 'Share', {
                        extractionId,
                        recipients: totalInvitations
                          .map((invite) => invite.email)
                          .join(', '),
                        count: totalInvitations.length,
                      });
                    }
                  }
                }}
                disabled={loading || totalInvitations.length === 0}
                loading={loading}
                data-gid="57132843"
              >
                Send Invite(s)
              </Button>
              {accessTypeForLoggedInUser === ExtractionAccessTypeEnum.OWNER &&
                !accessTypeLoading &&
                usersListData?.usersWithExtractionAccess.length && (
                  <div
                    className={classes.settingsButtonContainer}
                    data-gid="13956183"
                  >
                    {usersListData?.usersWithExtractionAccess.length}{' '}
                    {usersListData?.usersWithExtractionAccess?.length > 1
                      ? 'users have access'
                      : 'user has access'}
                    {' | '}
                    {
                      usersRequestingAccessData?.usersRequestingExtractionAccess
                        .length
                    }{' '}
                    {usersRequestingAccessData?.usersRequestingExtractionAccess
                      .length === 1
                      ? 'request'
                      : 'requests'}
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => setIsAccessSettings(true)}
                      data-gid="73247109"
                    >
                      Sharing Settings
                    </Button>
                  </div>
                )}
            </div>
            {invitationsSent && (
              <Alert severity="success" data-gid="82843700">
                Success! A unique invite link has been sent to each of the
                provided emails.
              </Alert>
            )}
            {emailSendError && (
              <Alert severity="error" data-gid="66285624">
                An error occurred while trying to send the invite links. Please
                try again later.
              </Alert>
            )}
          </div>
        ) : (
          <ExtractionAccessManager
            extractionId={extractionId}
            handleClose={onClose}
            usersListData={usersListData}
            requestingUsersData={usersRequestingAccessData}
            data-gid="49275606"
          />
        )}
        {isAccessSettings && (
          <div
            className={classes.arrowBackIcon}
            onClick={() => setIsAccessSettings(false)}
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            data-gid="17194953"
          >
            <ArrowBack data-gid="81734465" />
          </div>
        )}
        <Close
          onClick={onClose}
          className={classes.closeIcon}
          data-gid="44720668"
        />
      </div>
    </Modal>
  );
};
/* eslint-enable sonarjs/cognitive-complexity  */

export default ExtractionShareModal;
